import React from 'react';
import Preloader from "../../../utils/preloader";

import PersonalData from './personalData';
import AccountData from './accountData';


import './index.css';


class StudentProfileForm extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      loading: false,
      accountData: {},
      personalData: {},
      profileData: {},
      tab: 0,
    }
  }

  setLoading = (state) => {
    this.setState({loading: state});
  }

  selectedTab = (tabIndex) => {
    return this.state.tab === tabIndex ? "selected_tab" : ""
  }

  handleTabChange = (tabIndex) => {
    if (tabIndex !== this.tab) {
      this.setState({tab: tabIndex});
    }
  }

  collectData = (type, value) => {
    this.setState({[type]: value});
  }

  render() {
    const t = this;
    const p = t.props;
    const preloader = this.state.loading ? <Preloader className="student_profile_form_preloader"/> : '';
    
    return (
    <div className="student_profile_form">
      <div className="student_profile_tabs">
        <div className={'student_profile_tabs_account ' + this.selectedTab(0)} onClick={() => this.handleTabChange(0)}>
          <span>Аккаунт</span>
        </div>
        <div className={'student_profile_tabs_data ' + this.selectedTab(1)} onClick={() => this.handleTabChange(1)}>
          <span>Личные Данные</span>
        </div>
      </div>

      <div className="student_profile_layout">
        { this.state.tab === 0 ? 
        <AccountData {...p} onChangeForm={this.collectData} setLoading={this.setLoading} /> : 
        <PersonalData {...p} setLoading={this.setLoading} onChangeForm={this.collectData}/> }
      </div>

      {preloader}
    </div>
    )
  }
}

export default StudentProfileForm;