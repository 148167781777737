import React from 'react';
import LessonTypeText from "./text";
import LessonTypePdf from "./pdf";
// import LessonTypeVideo from "./video";
import LessonTypeWebinar from "./webinar";
import LessonTypeVideoUpload from "./video-upload";

class LessonTypeContent extends React.Component {
    constructor (props) {
        super();
    }

    render() {
        const t = this;
        const p = t.props;
        const {type} = p;

        const types = {
            text: <LessonTypeText {...p} />,
            pdf: <LessonTypePdf {...p} />,
            video: <LessonTypeVideoUpload {...p} />,
            video_upload: <LessonTypeVideoUpload {...p} />,
            webinar: <LessonTypeWebinar {...p} />
        };

        return types[type];
    }
}

export default LessonTypeContent;
