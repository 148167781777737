import React from 'react';
import { Redirect } from 'react-router-dom';
import {logout} from "../utils/utils";

class Logout extends React.Component {
    constructor (props) {
        super();
        this.state = {
            logged_out: false
        }
    }

    afterLogout = () => {
        this.props.authFunc();
        this.setState({logged_out: true});
    };

    componentDidMount() {
        logout(this.afterLogout);
    };

    render() {

        return this.state.logged_out ? <Redirect to={'/'} /> : '';
    }
}

export default Logout;