import React from 'react';

import './index.css';

import yc from './images/yandex-cloud.png';
import litres from './images/litres.png';
import vimeo from './images/vimeo.png';
import zoom from './images/zoom.png';
// import webinar from './images/webinar.png';

class MainPartners extends React.Component {
    constructor (props) {
        super();
    }

    render() {

        return (
            <div className="main_partners main_centrer">
                <h4>Партнеры</h4>

                <div className="partners_list">
                    <div className="pl_item">
                        <img src={yc} alt="" />
                    </div>
                    <div className="pl_item">
                        <img src={litres} alt="" />
                    </div>
                    <div className="pl_item">
                        <img src={zoom} alt="" />
                    </div>
                    <div className="pl_item">
                        <img src={vimeo} alt="" />
                    </div>
                    {/*<div className="pl_item">*/}
                        {/*<img src={webinar} alt="" />*/}
                    {/*</div>*/}
                </div>

            </div>
        );
    }
}

export default MainPartners;
