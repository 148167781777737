import React from "react";
import './index.css';

import {Burger, CategoriesListArrow, Close} from "../../utils/icons";
import {Link} from "react-router-dom";

class HeaderCategories extends React.Component {
    constructor (props) {
        super();
        this.state = {
            opened: false,
            categoryHovered: '',
            subcategoryHovered: '',
            subcategories: null
        }
    }

    toggleCategoriesList = () => {
        const t = this;
        const s = t.state;
        const p = t.props;

        p.onToggleCategories();

        t.setState({opened: !s.opened, categoryHovered: '', subcategoryHovered: ''});
    };

    prepareCategories = (data) => {
        const d = [];

        data.forEach((el) => {
            const id = el.id ? el.id : el['term_id'];
            const banList = [188,215,216];
            if (banList.indexOf(id) === -1) {
                d.push(el);
            }
        });

        return d;
    };

    renderSubcategories = (category) => {
        const t = this;
        const state = {categoryHovered: category.slug, subcategories: null};
        const hasChildren = category.children && category.children.length;

        if (hasChildren) {
            state.subcategories = category.children;
        }

        t.setState(state);

    };

    categoriesMapper = (category) => {
        const t = this;
        const {categoryHovered} = t.state;
        const key = category.id ? category.id : category['term_id'];

        const cls_hovered = categoryHovered === category.slug ? 'hovered' : '';

        const hasChildren = category.children && category.children.length;
        const arrow = hasChildren ? <CategoriesListArrow /> : '';

        if (category.isHidden) {
            return '';
        }

        return <li key={key} className={cls_hovered} onMouseEnter={() => t.renderSubcategories(category)}>
            <Link onClick={t.toggleCategoriesList} to={'/categories/' + category.slug}>
                {category.name}
                {arrow}
            </Link>
        </li>;
    };

    subcategoriesMapper = (subcategory) => {
        const t = this;
        const {categoryHovered, subcategoryHovered} = t.state;
        const key = subcategory.id ? subcategory.id : subcategory['term_id'];

        const cls_hovered = subcategoryHovered === subcategory.slug ? 'hovered' : '';

        if (subcategory.isHidden) {
            return '';
        }

        return <li key={key} className={cls_hovered}>
            <Link onClick={t.toggleCategoriesList} to={'/categories/' + categoryHovered + '/' + subcategory.slug}>
                {subcategory.name}
            </Link>
        </li>;
    };

    render() {
        const t = this;
        const s = t.state;
        const p = t.props;

        const categories = t.prepareCategories(p.categories);
        const categoriesFeed = categories.map(t.categoriesMapper);
        const subcategoriesFeed = s.subcategories ? s.subcategories.map(t.subcategoriesMapper) : '';
        const subcategoriesList = s.subcategories ? <ul className="child_list">{subcategoriesFeed}</ul> : '';

        const toggler = !s.opened ? <Burger /> : <Close />;
        const cls_opened = s.opened ? 'opened' : '';

        return (
            <div className={'header_categories_wrapper ' + cls_opened}>
                <div className="hc_button" onClick={t.toggleCategoriesList}>
                    {toggler}
                    <span>Категории</span>
                </div>
                <div className="categories_list_wrapper">
                    <ul className="parent_list">{categoriesFeed}</ul>
                    {subcategoriesList}
                </div>
            </div>
        );
    }
}

export default HeaderCategories;