import React, {useState, useEffect} from "react";
import { Link, Redirect, useLocation } from 'react-router-dom';

import './index.css';
import Preloader from "../utils/preloader";
import API from "../utils/api";
import {parseQS} from "../utils/utils";

function SignUpConfirm(props) {
    const [status, setStatus] = useState('wip');
    const [loading, setLoading] = useState(true);
    const [requested, setRequested] = useState(false);

    let params = parseQS(useLocation().search.replace('?',''));

    const requestConfirmation = () => {
        setRequested(true);

        if (params.user) {
            params.username = params.user;
            delete params.user;
        }

        API.post('/wp/v2/users/confirm', params)
            .then(function (r) {
                setLoading(false);
                setStatus('success');

                if (props.authorized) {
                    setStatus('success_authed');
                }
            })
            .catch(function (error) {
                setLoading(false);
                setStatus('error');
                console.log(error);
            });
    };

    useEffect(() => {
        document.body.classList.add('minimal');
        if (!requested) {
            requestConfirmation();
        }

        return () => {
            document.body.classList.remove('minimal');
        }
    });

    const content = {
        wip: '',
        error: <h1>Что-то пошло не так... :-(</h1>,
        success: <div><h1>Регистрация подтверждена!</h1><Link to={'/signin'} className="button_link"><button>Войти</button></Link></div>,
        success_authed: <div><h1>Регистрация подтверждена!</h1></div>
    };

    const preloader = loading ? <Preloader/> : '';
    const redirect = props.authorized && !params.set && !params.crunch ? <Redirect to={{pathname: "/"}}/> : '';

    return (
        <div className="sign_up_wrapper suw_confirm">

            {content[status]}
            {redirect}
            {preloader}
        </div>
    );
}

export default SignUpConfirm;