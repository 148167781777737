import React from "react";

import './sign-in-form.css';
import {PaymentLogos, LocationSearchCrawler} from "../utils/utils";
import {Link} from "react-router-dom";
import Preloader from "../utils/preloader";

class SignInForm extends React.Component {
    constructor (props) {
        super();

        this.state = {
            login: '',
            password: '',
            valid: false,
            loading: false
        }
    }

    validate = () => {
        const s = this.state;
        const test = s.login.length && s.password.length;

        if (test && !s.valid) {
            this.setState({valid: true});
        }

        if (!test && s.valid) {
            this.setState({valid: false});
        }
    };

    handleTextInput = (e) => {
        const obj = {};
        obj[e.target.name] = e.target.value;
        this.setState(obj, this.validate);
    };

    handleSubmit = () => {
        const t = this;
        const s = t.state;
        const p = t.props;
        const data = {
            username: s.login,
            password: s.password
        };

        t.setState({loading: true});

        p.authorizeFunc(data, (r = {}) => {
          if (!r.error) {
              console.log(p);
                if (t.props.onSuccess) {
                    t.props.onSuccess();
                }
            }
        });
    };

    handleRecoveryClick = () => {

        if (this.props.onClose) {
            this.props.onClose();
        }
    };

    handleLocation = (l) => {
        const t = this;
        if (l.email) {
            t.setState({login: l.email});
        }
        if (l.afterAuth) {
            t.props.updateRedirectPath(l.afterAuth);
        }
    };

    render() {
        const preloader = this.state.loading || this.props.loading ? <Preloader /> : '';

        const buttonLabel = this.props.signupButton ? 'Купить' : 'Войти';
        const paymentLogos = this.props.signupButton ? <PaymentLogos /> : '';

        return (
            <div className="sign_in_form">
                <LocationSearchCrawler onLocation={this.handleLocation} />
                <input onChange={this.handleTextInput} name="login" type="text" className="text_input" placeholder="e-mail" value={this.state.login} />
                <input onChange={this.handleTextInput} name="password" type="password" className="text_input" placeholder="пароль" />

                {paymentLogos}
                <button onClick={this.handleSubmit} disabled={!this.state.valid}>{buttonLabel}</button>

                <div className="password_recovery_link"><Link onClick={this.handleRecoveryClick} to={'/password-recovery'}>Забыли пароль?</Link></div>
                {preloader}
            </div>
        )
    }
}

export default SignInForm;