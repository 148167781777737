import React from 'react';
import {Link} from 'react-router-dom';
import API from '../../../utils/api';
import Preloader from "../../../utils/preloader";
import PasswordModal from './modals/passwordModal';
import DeleteModal from './modals/deleteModal';
import ProfileDatePickerCalendar from './ProfileDatePickerCalendar';

import maleIcon from './icons/male.svg';
import femaleIcon from './icons/female.svg';
import xmarkIcon from './icons/xmark.svg';
import xmarkGrayIcon from './icons/xmarkGray.svg';
import xmarkSelectedIcon from './icons/xmarkSelected.svg';
import searchIcon from './icons/search.svg';
import editIcon from './icons/edit.svg';
import arrowIcon from './icons/arrow.svg';
import checkmarkIcon from './icons/checkmark.svg';

import UserpicUpload from "../userpic-upload";

import StudentNameChangeModal from './modals/studentNameChangeModal';

import './index.css';

class AccountData extends  React.Component {
  constructor (props) {
    super(props)
    const ud = props.userData;
    const profile = ud.profile || {}

    this.interestsRef = React.createRef();
    this.topicsRef = React.createRef();

    this.state = {
      loading: false,
      shoHint: false,
      showModals: {
        password: false,
        proceedDelete: false,
        name: false,
      },
      interestsSeach: '',
      focusSearch: false,
      showTopics: false,
      availableInterests: [],
      availableTopics: [],
      form: {
        display_name: ud.display_name || "",
        date_of_birth: profile.date_of_birth || "",
        email: ud.email || "",
        password: "",
        gender: profile.gender || "",
        nicename: ud.slug || "",
        interests: [],
        preferred_topics: [],
        social_networks: profile.social_networks || {
          instagram: "",
          facebook: "",
          vk: "",
          telegram: "",
          whats_app: "",
          youtube: "",
          head_hunter: "",
          linked_in: "",
        },
      }
    }
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.outsideClick);

    this.setState({loading: true});
    const { interests, preferred_topics } = this.props.userData.profile;

    API.get('/whub/v3/profile/dictionary').then(data => {
      const { interests } = data.data;
      const topics = data.data["topics "];

      this.setState({
        availableInterests: interests.map(el => {
          return {
            value: el.slug,
            label: el.name,
            id: el.id
          }}),
        availableTopics: topics.map(el => {
          return {
            value: el.slug,
            label: el.name,
            id: el.id
          }
        })}, () => {
          this.setState({
            form: {
              ...this.state.form,
              preferred_topics: this.state.availableTopics.length > 0 && preferred_topics && preferred_topics !== "" ? preferred_topics.map((topic) => { 
                const topicData = this.state.availableTopics.find(el => topic === el.value || topic.value === el.value);
                if (topicData) {
                  return {
                    value: topicData.value,
                    label: topicData.label,
                  }
                }
                
                return null
              
              }) : []
            }
          })
        });
        
      this.setState({loading: false});
    });

    this.setState({
      form: {
        ...this.state.form,
        interests: interests && interests !== "" ? interests.map((interest) => {  return {
          value: interest.slug,
          label: interest.name,
        }}) : [],
      }
    }, () => this.props.onChangeForm("accountData", this.state.form));
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.outsideClick);
  }

  outsideClick = (event) => {
    if (this.interestsRef && 
      this.interestsRef.current && 
      !this.interestsRef.current.contains(event.target) && 
      this.state.interestsSeach) {
        this.setState({interestsSeach: ""})
    }

    if (this.topicsRef && 
      this.topicsRef.current && 
      !this.topicsRef.current.contains(event.target) && 
      this.state.showTopics) {
        this.setState({showTopics: false})
    }
  }

  handleFormChange = (field, value) => {
    const form = this.state.form;
    form[field] = value;
    this.setState({form},
      () => this.props.onChangeForm("accountData", this.state.form));
  }

  handleSocialsChange = (field, e) => {
    e.preventDefault();
    const { social_networks } = this.state.form;
    social_networks[field] = e.target.value;

    this.setState({form: {
      ...this.state.form,
      social_networks
    }}, () => this.props.onChangeForm("accountData", this.state.form));
  }

  handleGenderChange = (gender) => {
    this.setState({form: {
      ...this.state.form,
      gender
    }}, () => this.props.onChangeForm("accountData", this.state.form))
  }

  handleSelectedGender = (gen) => {
    return gen === this.state.form.gender ? ' selected_item' : '';
  }

  handleRemoveInterests = (value) => {
    const interests = this.state.form.interests.slice();
    const result = interests.filter(item => item.value !== value);

    this.setState({
      form: {
        ...this.state.form,
        interests: result
      }
    }, () => this.props.onChangeForm("accountData", this.state.form));
  }

  handleAddInterests = (item) => {
    if (item.selected) {
      this.handleRemoveInterests(item.value);
    } else {
      const interests = this.state.form.interests.slice();
      interests.push({
        value: item.value,
        label: item.label
      })

      this.setState({
        form: {
          ...this.state.form,
          interests
        }
      }, () => this.props.onChangeForm("accountData", this.state.form));
    }
  }

  handleAddTopics = (item) => {
    if (item.selected) {
      this.handleRemoveTopics(item.value);
    } else {
      const preferred_topics = this.state.form.preferred_topics.slice();
      preferred_topics.push({
        value: item.value,
        label: item.label
      })

      this.setState({
        form: {
          ...this.state.form,
          preferred_topics
        }
      }, () => this.props.onChangeForm("accountData", this.state.form));
    }
  }

  handleRemoveTopics = (value) => {
    const preferred_topics = this.state.form.preferred_topics.slice();
    const result = preferred_topics.filter(item => item.value !== value);

    this.setState({form: {
      ...this.state.form,
      preferred_topics: result
    }}, () => this.props.onChangeForm("accountData", this.state.form));
  }

  renderTopics = () => {
    const filtered = this.state.availableTopics.filter(interest => interest.label.toLowerCase().includes(this.state.interestsSeach.trimStart().toLowerCase()));
    const result = filtered.map(el => {
      return {
        value: el.value,
        label: el.label,
        selected: this.state.form.preferred_topics.find(topic => topic.value === el.value) ? true : false,
      }
    })

    return result;
  }

  renderInterestsSearch = () => {
    if (this.state.interestsSeach.trimStart() === '') {
      const result = this.state.availableInterests.slice();
      return result.map(el => {
        return {
          value: el.value,
          label: el.label,
          selected: this.state.form.interests.find(interest => interest.value === el.value) ? true : false,
        }
      })
    } else {
      const filtered = this.state.availableInterests.filter(interest => interest.label.toLowerCase().includes(this.state.interestsSeach.trimStart().toLowerCase()));
      const result = filtered.map(el => {
        return {
          value: el.value,
          label: el.label,
          selected: this.state.form.interests.find(interest => interest.value === el.value) ? true : false,
        }
      })

      return result;
    }
  }

  renderSocials = () => {
    const socialNames = {
      instagram: "Instagram",
      facebook: "Facebook",
      vk: "Вконтакте",
      telegram: "Telegram",
      whats_app: "WhatsApp",
      youtube: "Youtube",
      head_hunter: "HeadHunter",
      linked_in: "LinkedIn",
    }

    const socials = Object.keys(this.state.form.social_networks).map(social => {
      return (
        <div key={social} className="student_profile_form_row">
          <div className='student_profile_form_input_label'>
            <span>{socialNames[social]}</span>
          </div>
          <div className="student_profile_form_input">
            <input value={this.state.form.social_networks[social]} onChange={(e) => this.handleSocialsChange(social, e)}></input>
          </div>
        </div>
      )
    })

    return socials;
  }

  showPasswordModal = () => {
    const modals = this.state.showModals;
    modals.password = true;
    this.setState({showModals: modals});
  }

  closePasswordModal = () => {
    const modals = this.state.showModals;
    modals.password = false;
    this.setState({showModals: modals});
  }

  showDeleteModal = () => {
    const modals = this.state.showModals;
    modals.proceedDelete = true;
    this.setState({showModals: modals});
  }

  closeDeleteModal = () => {
    const modals = this.state.showModals;
    modals.proceedDelete = false;
    this.setState({showModals: modals});
  }

  showNameModal = () => {
    const modals = this.state.showModals;
    modals.name = true;
    this.setState({showModals: modals});
  }

  closeNameModal = () => {
    const modals = this.state.showModals;
    modals.name = false;
    this.setState({showModals: modals});
  }

  proceedDeleteAccount = (user_id) => {
    this.showDeleteModal()
    // this.setState({loading: true});
    // API.delete(`whub/v3/user/${user_id}`)
    //   .then(() => {
    //     this.setState({loading: false})
    //     this.props.authFunc();
    //   })
    // ;
  }

  handleShowHint = (show) => {
    this.setState({showHint: show});
  }

  saveData = () => {
    const t = this;
    const { form } = t.state;
    const ud = t.props.userData;
    const { slug, id } = ud;
    const loading = this.props.setLoading;

    const data = {
      date_of_birth: form.date_of_birth,
      gender: form.gender,
      interests: form.interests.map(el => el.value),
      preferred_topics: form.preferred_topics.map(el => el.value),
      social_networks: form.social_networks
    }
    
    loading(true);

    if (slug !== t.state.form.nicename) {
      API.put(`/whub/v3/user/${id}`, {nicename: t.form.nicename})
          .then((r) => {
            loading(false);
            t.props.authFunc();
          })
          .catch((error) => {
              loading(false);
              console.log(error);
          });
    }

    API.post('/whub/v3/profile', data)
          .then((r) => {
            loading(false);
            t.props.authFunc();
          })
          .catch((error) => {
            loading(false);
            console.log(error);
          });

  }

  render() {
    const t = this;
    const p = t.props;
    const preloader = this.state.loading ? <Preloader/> : '';

    return (
      <div>

        { this.state.showModals.password && <PasswordModal {...this.props} loading={this.props.setLoading} close={this.closePasswordModal} /> }

        { this.state.showModals.proceedDelete && <DeleteModal {...this.props} close={this.closeDeleteModal} />}

        { this.state.showModals.name && <StudentNameChangeModal {...this.props} loading={this.props.setLoading} close={this.closeNameModal} />}

        <div>
          <UserpicUpload {...p} />
          <dl className="link_info">
              <dt>Страница на W-hub:</dt>
              <dd><Link rel="noreferrer noopener" target="_blank" to={'/users/' + p.userData.slug}>{'https://w-hub.ru/users/' + p.userData.slug}</Link></dd>
              <dd 
                onClick={() => this.handleShowHint(!this.state.showHint)}
                onMouseOut={() => this.handleShowHint(false)}
                className="link_info_tip">?
                  { this.state.showHint ? 
                  <div className="link_info_tip_content">Ваш адрес страницы на W-HUB совпадает с вашим общедоступным именем пользователя, для изменения адреса страницы необходимо изменить имя пользователя</div> 
                  : "" }
              </dd>
          </dl>
        </div>



        <div className="student_profile_form_row">
          <div className='student_profile_form_input_label'>
            <span>Имя пользователя</span>
          </div>
          <div className="student_profile_form_input">
            <input disabled value={this.state.form.nicename} onChange={(e) => this.handleFormChange("nicename", e.target.value)}></input>
            <div onClick={this.showNameModal} style={{backgroundImage: `url(${editIcon})`}} className="student_profile_form_input_edit"></div>
          </div>
        </div>

        <div className="student_profile_form_row">
          <div className='student_profile_form_input_label'>
            <span>Email</span>
          </div>
          <div className="student_profile_form_input">
            <input disabled value={this.state.form.email} onChange={(e) => this.handleFormChange("email", e.target.value)}></input>
          </div>
        </div>

        <div className="student_profile_form_row">
          <div className='student_profile_form_input_label'>
            <span>Дата рождения</span>
          </div>
          <div>
            <ProfileDatePickerCalendar {...this.props} value={this.state.form.date_of_birth} onChange={(value) => this.handleFormChange("date_of_birth", value)}/>
          </div>
        </div>

        <div className="student_profile_form_row">
          <div className='student_profile_form_input_label'>
            <span>Пароль</span>
          </div>
          <div className="student_profile_form_input">
            <input disabled type="password" placeholder="******************"></input>
            <div onClick={() => {this.showPasswordModal()}}style={{backgroundImage: `url(${editIcon})`}} className="student_profile_form_input_edit"></div>
          </div>
        </div>

        <div className="student_profile_form_row">
          <div className='student_profile_form_input_label'>
            <span>Пол</span>
          </div>
          <div className="student_profile_form_g_selector">
            <div className={"student_profile_form_g_selector_item" + this.handleSelectedGender('m')}
              onClick={() => this.handleGenderChange('m')}>
              {<img alt="" src={maleIcon} />}
            </div>
            <div className={"student_profile_form_g_selector_item"  + this.handleSelectedGender('f')}
              onClick={() => this.handleGenderChange('f')}>
              {<img alt="" src={femaleIcon} />}
            </div>
          </div>
        </div>

        <div className='student_profile_form_row'>
          <div className='student_profile_form_input_label'>
            <span>Интересы</span>
          </div>
          <div className="student_profile_form_topic_wrapper">
            <div className="student_profile_form_topic_content">
                {this.state.form.interests.map(item => {
                  return (
                    <div key={item.label} className="student_profile_form_topic_content_item">
                      <span>{item.label}</span>
                      <div  
                        className="student_profile_form_topic_content_xmark"
                        style={{backgroundImage: `url(${xmarkIcon})`}}
                        onClick={() => this.handleRemoveInterests(item.value)}>
                        </div>
                    </div>
                  )
                })}
            </div>
            <div className="student_profile_form_topic_search">
                <div className="student_profile_form_topic_search_icon" style={{backgroundImage: `url(${searchIcon})`}}></div>
                <input 
                  value={this.state.interestsSeach}
                  placeholder='Поиск'
                  onChange={(e) => this.setState({interestsSeach: e.target.value})}
                  onFocus={() => this.setState({focusSearch: true, showTopics: false})}
                  ></input>
            </div>
            <div
              ref={this.interestsRef}
              style={this.state.focusSearch && this.state.interestsSeach.length > 0 ? {display: 'flex'} : {}}
              className="student_profile_form_topic_interests">
                {this.renderInterestsSearch().map(item => {
                  return (
                    <div 
                      className={`student_profile_form_topic_interests_item${item.selected ? '_selected' : ''}`}
                      key={item.label + item.value}
                      onClick={() => this.handleAddInterests(item)}>
                      <span>{item.label}</span>
                      <div  
                        className="student_profile_form_topic_content_xmark"
                        style={{backgroundImage: `url(${item.selected ? xmarkSelectedIcon : xmarkGrayIcon})`}}>
                        </div>
                    </div>
                  )
                })}
            </div>
          </div>
        </div>

        <div className='student_profile_form_row'>
          <div className='student_profile_form_input_label'>
            <span>Какие темы вам хочется изучать на W-HUB</span>
          </div>
          <div className="student_profile_form_topic_wrapper">
            <div className="student_profile_form_topic_content">
                {this.state.form.preferred_topics.map(item => {
                  return (
                    <div key={item.label} className="student_profile_form_topic_content_item">
                      <span>{item.label}</span>
                      <div
                        className="student_profile_form_topic_content_xmark"
                        style={{backgroundImage: `url(${xmarkIcon})`}}
                        onClick={() => this.handleRemoveTopics(item.value)}>
                        </div>
                    </div>
                  )
                })}
            </div>
            <div onClick={() => {this.setState({showTopics: !this.state.showTopics})}} className="student_profile_form_topic_select">
              <span>Выбрать темы</span>
              <div className="student_profile_form_topic_select_arrow" style={{backgroundImage: `url(${arrowIcon})`}}></div>
            </div>
            <div
              ref={this.topicsRef}
              style={this.state.showTopics ? {display: 'flex'} : {}} 
              className="student_profile_form_topic_topics">
                {this.renderTopics().map(item => {
                  return (
                    <div key={item.value+item.label} className={`student_profile_form_topic_topics_item${item.selected ? '' : ''}`}>
                      <div 
                        onClick={() => {this.handleAddTopics(item)}}
                        className={`student_profile_form_topic_topics_item_checkmark${item.selected ? ' student_profile_form_topic_topics_item_checkmark_selected' : ''}`}>
                        { item.selected ? 
                          <div
                            style={{backgroundImage: `url(${checkmarkIcon})`}}
                            className="student_profile_form_topic_topics_item_checkmark_selected_sign"
                            ></div> : ''}
                      </div>
                      <div className="student_profile_form_topic_topics_item_label">
                        <span>{item.label}</span>
                      </div>
                    </div>
                  )
                })}
            </div>
          </div>
        </div>

        <div className="student_profile_form_semi_title">Соц. сети</div>

        {this.renderSocials()}

        <div className="student_profile_form_delete_button" onClick={() => this.proceedDeleteAccount(t.props.userData.id)}>Удалить аккаунт</div>
        <div className="student_profile_form_save_button" onClick={this.saveData}>Сохранить</div>
        {preloader}
      </div>
    )
  }
}

export default AccountData;