import React from 'react';

import './index.css'
import '../styles/auth-tabs.css'
import AuthorizedSidebar from "./authorized";
import UnauthedForm from "./unauthed";

class Sidebar extends React.Component {
    constructor (props) {
        super();
    }

    componentDidMount() {
        const t = this;
        document.body.classList.add('scroll_locked');
        setTimeout(() => {
            t.props.onMount();
        }, 10)
    };
    componentWillUnmount() {
        document.body.classList.remove('scroll_locked');
    };

    render() {
        const p = this.props;

        const cls_hide = this.props.hideState ? 'hidden' : '';

        const authed = p.authorized ? <AuthorizedSidebar {...this.props} /> : '';

        const sidebarContent = p.authorized ? authed : <UnauthedForm {...this.props} />;

        return (
            <div className={'sidebar_wrapper ' + cls_hide}>
                {sidebarContent}
            </div>
        );
    }
}

export default Sidebar;
