import React from "react";

class ProductTypeIcon extends React.Component {
    render() {
        const p = this.props;
        const type = p.type;

        let svg;
        // let text;

        switch (type) {
            case 'video':
                svg = <svg {...p} xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                    <g transform="translate(-321 -1765)">
                        <rect className="a" fill="none" width="22" height="22" rx="11" transform="translate(321 1765)"/>
                        <path className="b"
                              fill="#fff"
                              d="M3.584.624a.5.5,0,0,1,.832,0l3.066,4.6A.5.5,0,0,1,7.066,6H.934a.5.5,0,0,1-.416-.777Z"
                              transform="translate(336 1772) rotate(90)"/>
                    </g>
                </svg>;
                // text = 'видео';
                break;
            case 'audio':
                svg = <svg {...p} xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                    <g transform="translate(-321 -2286)">
                        <rect className="a" fill="none" width="22" height="22" rx="11" transform="translate(321 2286)"/>
                        <path className="b"
                              fill="#fff"
                              d="M-7667.311-2103.109l-3.39-2.679h-2.8a.5.5,0,0,1-.5-.5v-3.424a.5.5,0,0,1,.5-.5h2.8l3.39-2.677a.5.5,0,0,1,.811.391v9a.5.5,0,0,1-.5.5A.492.492,0,0,1-7667.311-2103.109Zm2.311-2.875a2,2,0,0,0,2-2,2.006,2.006,0,0,0-2-2.007v-1a3.007,3.007,0,0,1,3,3.008,3,3,0,0,1-3,3Zm0-3.006a1,1,0,0,1,1,1,1,1,0,0,1-1,1Z"
                              transform="translate(8000.001 4405)"/>
                    </g>
                </svg>;
                // text = 'аудиокнига';
                break;
            case 'webinar':
                svg = <svg {...p} width="20" height="20" viewBox="0 0 20 20">
                    <g transform="translate(-670 -566)">
                        <circle cx="3" cy="3" r="3" transform="translate(677 570)" fill="#fff"/>
                        <path d="M-14554.295-2099.062A7,7,0,0,1-14548-2103a7,7,0,0,1,6.3,3.935,7.988,7.988,0,0,1-6.3,3.064A7.984,7.984,0,0,1-14554.295-2099.062Z" transform="translate(15228 2680)" fill="#fff"/>
                    </g>
                </svg>;
                // text = 'вебинар';
                break;
            default:
                svg = '';
                // text = '';
        }



        return (
            <div className="cp_pict">
                {svg}
            </div>

        );
    }
}

export default ProductTypeIcon;