import React from 'react';

import moment from "moment";
import 'moment/locale/ru';

import './webinar.css'
import {arr2obj} from "../../utils/utils";
import {BroadcastIcon, TimeWatchIcon} from "../../course-v3/landing/icons";
import API from '../../utils/api';

class LessonWebinar extends React.Component {
    constructor (props) {
        super();

        this.state = {
          statusSended: false,
        }
    }

    sendStatus = () => {
      const isStudent = this.props.userData.role === 'student';
      const {content, lessonId: id} = this.props;

      const { date } = content;
      const start = moment(date);
      const today = moment();

      const diff = today.diff(start, 'seconds');

      if (!this.state.statusSended && diff > 0 && isStudent) {
        this.setState({statusSended: true})
        API.put('/whub/v3/progress/' + id, {status: 'finished'})
        .then(result => {
          this.props.updateProgress(result.data);
        })
      }
    };

    componentDidMount() {
      this.sendStatus();
    }

    render() {
        const t = this;
        const p = t.props;
        const {content, courseData, lessonId} = p;

        const lessons = courseData.lessons;
        const lessonsObj = arr2obj(lessons, 'id');
        const lessonData = lessonsObj[lessonId];

        const duration = lessonData.duration || 0;
        const durationMS = duration * 60000;
        const lessonEnds = content.date + durationMS;

        const now = new Date().getTime();
        const isStarted = true;//now > content.date && now < lessonEnds;
        const isFinished = now > lessonEnds;

        let lecturer = '';

        if (content && content.lecturer) {
            const authors = arr2obj(courseData.authors, 'id');
            const lecturerObj = authors[content.lecturer];
            if (lecturerObj) {
                const name = authors[content.lecturer].name;
                lecturer = <div className="lecturer">
                    <div className="userpic">
                        <span>{name[0].toUpperCase()}</span>
                    </div>
                    {name}
                </div>;
            }
        }

        let button = '';

        if (content.url && isStarted) {
            button = <div className="btn_holder">
                <a target="_blank" rel="noreferrer noopener" href={content.url}><button className="v2">Открыть</button></a>
            </div>;
        }

        let text = 'Трансляция пройдет в ' + content.platform;
        if (isStarted) {
            text = 'Трансляция идет в ' + content.platform;
        }
        if (isFinished) {
            text = 'Трансляция завершена.';
        }

        const liveNowMark = isStarted ? <div className="live_now"> </div> : '';

        return (
            <div className="lesson_webinar_wrapper">
                <div className="lecturer_and_date_section">
                    {lecturer}
                    <div className="date">
                        <TimeWatchIcon />
                        <span>{moment(content.date).format('DD MMMM YYYY, HH:mm')}</span>
                    </div>
                </div>

                <div className="platform_section">
                    <div className="broadcast_icon">
                        <BroadcastIcon />
                        {liveNowMark}
                    </div>
                    <p className="ps_inner">{text}</p>
                    {button}
                </div>

                <div className="manual_section">

                </div>
            </div>
        )
    }
}

export default LessonWebinar;
