import React from 'react';
import './index.css';
import Spinner from "../../../utils/spinner";
import API from "../../../utils/api";
import FilesUploader from "./components/uploader";
import FilesList from "./components/list";

class SchoolFiles extends React.Component {
    constructor (props) {
        super();
        this.state = {
            loading: false,
            progress: 0,
            uploading: false,
            completed: false,
            uploadFailed: false,
            files: []
        };
    }

    componentDidMount() {
        document.body.classList.add('no_search');
        this.reloadFiles();
    };
    componentWillUnmount() {
        document.body.classList.remove('no_search');
    };

    uploadFunction = (uploadData) => {
        const t = this;
        const {formData, url, id} = uploadData;

        t.setState({completed: false});

        const config = {
            onUploadProgress: progressEvent => {
                const progress = Math.floor((progressEvent.loaded / progressEvent.total) * 100);
                t.setState({uploading: true, progress: progress});
            }
        };

        API.post(url, formData, config)
            .then(function (r) {
                console.log(r);
                t.confirmUpload(id);
            })
            .catch(function (error) {
                console.log('upload failed!')
                console.log(error.response);
                t.reportUploadFailed(id);
                t.setState({uploading: false, uploadFailed: true, progress: 0});
            });
    };

    confirmUpload = (id) => {
        const t = this;
        API.put('/whub/v3/cloud/' + id)
            .then(function (r) {
                console.log(r);
                t.setState({completed: true, uploading: false});
                t.reloadFiles();
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    reloadFiles = () => {
        const t = this;

        t.setState({loading: true});

        API.get('/whub/v3/cloud')
            .then((r) => {
                t.setState({loading: false, files: r.data});
                console.log(r)
            })
            .catch(function (error) {
                t.setState({loading: false});
                console.log(error);
            });

    };

    reportUploadFailed = (id) => {
        API.put('/whub/v3/cloud/' + id + '?failed=1')
            .then((r) => {
                console.log(r)
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    resetUploader = () => {
        this.setState({
            loading: false,
            progress: 0,
            uploading: false,
            completed: false,
            uploadFailed: false
        });
    };

    setLoading = (status) => {
        this.setState({loading: status})
    };

    render() {
        const t = this;
        const { loading, progress, uploading, completed, files, uploadFailed } = t.state;

        const filesList = files.length ? <FilesList reloadFiles={t.reloadFiles} files={files} /> : '';

        return (
            <div className="school_files">

                <h1>Мои файлы</h1>

                <FilesUploader
                    reset={t.resetUploader}
                    failed={uploadFailed}
                    progress={progress}
                    uploading={uploading}
                    completed={completed}
                    onUpload={t.uploadFunction}
                    setLoading={t.setLoading}
                />

                {filesList}

                <Spinner show={loading} />
            </div>
        );
    }
}

export default SchoolFiles;