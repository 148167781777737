import React from 'react';

import './index.css';

class Contacts extends React.Component {
    constructor (props) {
        super();
        this.state = {
        }
    }

    componentDidMount() {

    };
    componentWillUnmount() {
    };

    render() {

        return (
            <div className="about_wrapper">
                <h1>контакты</h1>

                <p>Телефон:   +7 (499) 110-88-40</p>
                <p>E-mail:  <a href="mailto:info@w-hub.ru">info@w-hub.ru</a></p>

            </div>
        );
    }
}

export default Contacts;