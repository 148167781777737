import React from 'react';
import {Link} from "react-router-dom";

import './video.css';
import Spinner from "../../../../../utils/spinner";
import API from "../../../../../utils/api";

class LessonTypeVideoUpload extends React.Component {
    constructor (props) {
        super();

        const content = props.content;

        const url = content.url ? content.url : '';

        this.state = {
            loading: false,
            list: [],
            url: url,
            originalUrl: url.slice(),
            valid: false,
            changes: false
        }
    }

    componentDidMount() {
        this.loadVideoList();
        this.validate();
    }

    loadVideoList = () => {
        const t = this;

        t.setState({loading: true});

        API.get('/whub/v3/cloud')
            .then(function (r) {
                t.setState({loading: false, list: r.data});
                console.log(r)
            })
            .catch(function (error) {
                t.setState({loading: false});
                console.log(error);
            });
    };

    validate = () => {
        const t = this;
        const {url, originalUrl} = t.state;
        let valid = false;
        let changes = false;

        if (url && url.length) {
            valid = true;
        }

        if (url !== originalUrl) {
            changes = true;
        }

        t.setState({valid: valid, changes: changes}, t.updateLesson);
    };

    updateLesson = () => {
        const t = this;
        const {updateTypeContent} = t.props;
        const {url, valid, changes} = t.state;

        const d = {
            content: {
                url: url,
                is_cloud: false,
                cdn: true
            },
            state: {
                valid: valid,
                changes: changes
            }
        };

        console.log(d)

        updateTypeContent(d)
    };

    filesMapper = (item) => {
        const t = this;
        const {url} = t.state;

        let thumbnail = '';
        let title = item.title;

        if (item.status) {
            if (item.status === 'publish') {

                if (item.previews && item.previews.length) {
                    thumbnail = <img src={item.previews[1]} alt='' />
                }

                const cls_selected = item.url === url ? 'selected' : '';

                return (
                    <li onClick={() => t.selectVideo(item)} className={cls_selected} key={item.id}>
                        <div className="thumbnail">{thumbnail}</div>
                        <div className="title">{title}</div>
                    </li>
                );
            }
        }

        return '';
    };

    selectVideo = (item) => {
        const { updateDuration } = this.props;
        if (item.duration) {
            updateDuration(item.duration);
        };
        this.setState({url: item.url}, this.validate);
    };

    render() {
        const t = this;
        const {loading, list} = t.state;

        return (
            <div>
                <dl>
                    <dt className="eli_dt_video_label">Выберите видео:</dt>
                    <dd className="eli_video">
                        <div className="cdn_video_list">
                            <ul>
                                {list.map(t.filesMapper)}
                            </ul>

                            <Spinner show={loading} />
                        </div>
                        <div className="course_form_hint">вы можете загрузить больше видеороликов в разделе <Link to="/user/files">Мои файлы</Link>. Если ролик, который вы уже загрузили, отсутствует в списке - возможно он обрабатывается.</div>
                    </dd>
                </dl>
            </div>
        );
    }
}

export default LessonTypeVideoUpload;
