import React from 'react';
import './private-route.css';

import {Route} from 'react-router-dom';
import PasswordReset from "../password-reset/reset";
import SignIn from "../sign-in";
import Preloader from "./preloader";


function PrivateRoute({ children, ...rest }) {

    const renderContent = ({location}) => {

        let out = children;
        const {props} = out;

        if (!rest.allowed) {
            if (rest.setPasswordState) {
                const content = {
                    loading: <Preloader />,
                    password: <PasswordReset {...props} {...rest} isLockscreen={true} />,
                    auth: <SignIn {...props} {...rest} />,
                    error: ''
                };

                out = content[rest.setPasswordStatus];
            }
            else if (!rest.authorized) {
                out = <SignIn {...props} {...rest} />;
            }
            else {
                console.log('Вероятно, у вас нет доступа к этой странице!');
                alert('Вероятно, у вас нет доступа к этой странице!');
                out = '';
            }

            return out;
        }

        return out;
    };
    return (
        <Route
            {...rest}
            render={renderContent}
        />
    );
}

export default PrivateRoute;