import React from 'react';
import FinishSignupForm from "./form";
import CompanyTypeSelect from "./company-type-select";

class SchoolData extends React.Component {
    constructor (props) {
        super();

        let data = props.userData.additional_data;
        if (typeof data === "string") {
            data = JSON.parse(props.userData.additional_data);
        }

        this.state = {
            companyType: data.company_type ? data.company_type : 'fiz',
            companyTypeSelected: props.userData.school_data_completed
        }
    }

    handleSelect = (s) => {
        this.setState({companyType: s, companyTypeSelected: true});
    };

    render() {
        const userData = this.props.userData;
        const content = this.state.companyTypeSelected ? <FinishSignupForm {...this.props} formCompleted={userData.school_data_completed} companyType={this.state.companyType} /> : <CompanyTypeSelect {...this.props} companyType={this.state.companyType} onSelect={this.handleSelect} />

        return (
            <div className="school_data_page">
                {content}
            </div>
        );
    }
}

export default SchoolData;