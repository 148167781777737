import React from 'react';
import { Plus } from '../../utils/icons';
import ExpertsModal from './modals/expertsModal';

import './experts.css';
import ExpertItem from "./expert-item";
import Spinner from "../../utils/spinner";

class Experts extends React.Component {
    constructor (props) {
        super();
        this.state = {
            loading: false,
            showModal: false,
            authorData: null,
        };
    }

    componentDidMount() {
        document.body.classList.add('no_search');
    };
    componentWillUnmount() {
        document.body.classList.remove('no_search');
    };

    mapper = (el, idx) => {
      return (
          <ExpertItem key={el.id} item={el} edit={this.goEdit}/>
        );
    };

    handleAdd = () => {
        const t = this;
        const {reloadUserData} = t.props;

        setTimeout(() => {
            reloadUserData(() => {
                t.setState({loading: false});
            });
        }, 100);

    };

    goEdit = (author) => {
      const data = {
        isEdit: true,
        ...author
      }

      this.setState({authorData: data}, () => this.openModal());
    }

    openModal = () => {
      this.setState({showModal: true})
    }

    closeModal = () => {
      this.setState({showModal: false, authorData: null})
    }

    switchLoader = (state) => {
      this.setState({loading: state});
    }

    render() {
        const t = this;
        const {loading, showModal, authorData} = t.state;

        const addExpert = <div onClick={this.openModal} className="experts_list_add_btn">
            <div className="cnc_plus"><Plus /></div>
            <p className="cnc_text">Добавить эксперта</p>
        </div>
        return (
            <div className="school_experts">
              {showModal && <ExpertsModal {...t.props} author={authorData} update={this.handleAdd} close={this.closeModal} loading={this.switchLoader}/>}
                <h1>Эксперты</h1>
                <p>В этом разделе можно просмотреть, добавить или удалить экспертов, которых вы сможете указывать в качестве авторов ваших курсов.</p>

                <div className="experts_list">
                  {addExpert}
                  {this.props.userData.authors.map(t.mapper)}
                </div>

                <Spinner show={loading} />
            </div>
        );
    }
}

export default Experts;