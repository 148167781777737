import React from 'react';
import {Link} from 'react-router-dom';

import './index.css';

class Button extends React.Component {
    constructor (props) {
        super();

    }

    render() {
        const {label, icon, url} = this.props;

        const btnIcon = icon ? icon : '';

        if (url) {
            return (
                <Link to={url} className="button_default_link">
                    <button {...this.props}>
                        {btnIcon}
                        <span>{label}</span>
                    </button>
                </Link>
            );
        }

        return (
            <button {...this.props}>
                {btnIcon}
                <span>{label}</span>
            </button>
        );
    }
}

export default Button;
