import React from 'react';
import { Link } from 'react-router-dom';
import { html2plaintext } from '../../../utils/utils';
import icon from './collection-ico.svg';

import './item.css'

class CourseFeaturedCollection extends React.Component {
    constructor (props) {
        super();
    }

    render() {
      const t = this;
      const p = t.props.data;

      return (
        <div className="course_featured_item">
            <div className="collection_label">
              <img src={icon} alt=''/>
              <span>Подборка</span>
            </div>

            <Link to={p.url} className="featured_link">
                <div className="additional_info_bar">

                </div>

                <div className="img_wrapper">
                    <img style={{width: '100%'}} src={p.img} alt=''/>
                    <div className="featured_fade"> </div>
                </div>
            </Link>

            <div className="collection_footer">

                <Link to={p.url} className="featured_link featured_overlay_link">
                    <div className="featured_text">
                        <h3>{html2plaintext(p.title)}</h3>
                    </div>
                </Link>

            </div>
        </div>

    );
    }
}

export default CourseFeaturedCollection;
