import React from 'react';

import './index.css';

import {
    FacebookShareButton,
    TwitterShareButton,
    TelegramShareButton,
    WhatsappShareButton,
    VKShareButton,
    OKShareButton,
    FacebookIcon,
    TwitterIcon,
    TelegramIcon,
    WhatsappIcon,
    VKIcon,
    OKIcon
} from 'react-share';
import {ShareArrow} from "../../icons";

class SocialShare extends React.Component {
    constructor (props) {
        super();

        this.state = {
            visible: false
        }
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClick, false);
    };
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClick, false);
    };

    handleClick = (e) => {
        e.stopPropagation();

        const t = this;
        const {visible} = t.state;

        if (visible) {
            if (t.node.contains(e.target)) {
                return;
            }
            t.setState({visible: false});
        }
    };

    switchVisible = () => {
        const t = this;
        const {visible} = t.state;

        t.setState({visible: !visible});
    };

    render() {
        const t = this;
        const sUrl = window.location.href;
        const {visible} = t.state;

        const cls_visible = visible ? 'visible' : '';

        return (
            <div ref={node => t.node = node} className={'social_share_widget ' + cls_visible}>
                <div onClick={t.switchVisible} className="ssw_switcher_wrapper"><ShareArrow /></div>

                <div className="ssw_bubble">
                    <div className="bubble_arrow"> </div>

                    <FacebookShareButton url={sUrl}><FacebookIcon size={20} round={true} /></FacebookShareButton>
                    <VKShareButton url={sUrl}><VKIcon size={20} round={true} /></VKShareButton>
                    <TwitterShareButton url={sUrl}><TwitterIcon size={20} round={true} /></TwitterShareButton>
                    <TelegramShareButton url={sUrl}><TelegramIcon size={20} round={true} /></TelegramShareButton>
                    <WhatsappShareButton url={sUrl}><WhatsappIcon size={20} round={true} /></WhatsappShareButton>
                    <OKShareButton url={sUrl}><OKIcon size={20} round={true} /></OKShareButton>
                </div>
            </div>
        );
    }
}

export default SocialShare;
