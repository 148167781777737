import React from "react";
import './index.css';

import { Link, Redirect } from 'react-router-dom';
import Spinner from "../../utils/spinner";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import PageParamsHook from "../../utils/page-params-hook";
import API from "../../utils/api";

class BlogEditor extends React.Component {
    constructor (props) {
        super();

        this.state = {
            newPost: false,
            loading: false,
            status: 'draft',
            title: '',
            file: null,
            cover: null,
            description: '',
            text: '',
            originalText: '',
            editorState: EditorState.createEmpty(),
            valid: false,
            changes: false,
            pageParams: null,
            redirectId: null
        }
    }

    loadPost = (postId) => {
        const t = this;

        t.setState({loading: true});
        API.get('/whub/v3/post/' + postId)
            .then(function (r) {

                console.log(r)
                const data = r.data;

                let editorState;
                let text = '';

                if (data.content && data.content.length) {
                    const contentBlock = htmlToDraft(data.content);
                    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);

                    editorState = EditorState.createWithContent(contentState);
                    text = data.content;
                }
                else  {
                    editorState = EditorState.createEmpty();
                }

                const cover = data.images_stack.cover ? data.images_stack.cover.normal : null;

                t.setState({
                    loading: false,
                    title: data.title,
                    status: data.status ? data.status : 'draft',
                    description: data.description,
                    editorState: editorState,
                    text: text,
                    cover: cover,
                    originalText: text.slice()
                });
            })
            .catch(function (error) {
                console.log(error.response);
                t.setState({loading: false});
            });
    };

    onParams = (params) => {
        const t = this;

        t.setState({pageParams: params});

        if (params.id) {
            t.loadPost(params.id)
        }
        else {
            t.setState({newPost: true});
        }
    };

    validate = () => {
        const t = this;
        const {title, description, text, originalText} = t.state;

        const valid = title.length && description.length && text.length > 20;

        t.setState({valid: valid, changes: text !== originalText});
    };

    handleChange = (e) => {
        const t = this;
        const obj = {};

        let value = e.target.value;
        if (e.target.name === 'file') {
            value = e.target.files[0];
        }

        obj[e.target.name] = value;

        t.setState(obj, t.validate);
    };

    onEditorStateChange = (editorState) => {

        this.setState({
            editorState,
            text: draftToHtml(convertToRaw(editorState.getCurrentContent()))
        }, this.validate);
    };

    handleSave = () => {
        const t = this;
        const {newPost} = t.state;

        t.setState({loading: true});

        if (newPost) {
            t.createNewPost();
        }
        else {
            t.savePost();
        }
    };

    createNewPost = () => {
        const t = this;
        const {title, description, text, file} = t.state;

        const formData = new FormData();
        formData.append('title', title);
        formData.append('description', description);
        formData.append('content', text);

        if (file) {
            formData.append('file', file);
            formData.append('image_type', 'cover');
        }

        API.post('/whub/v3/post', formData)
            .then(function (r) {
                console.log(r)

                if (r.data.post_id) {
                    t.setState({redirectId: r.data.post_id}, () => {
                        t.loadPost(r.data.post_id);
                    });
                }

                t.setState({loading: false, originalText: text}, t.validate);
            })
            .catch(function (error) {
                console.log(error.response);
                t.setState({loading: false});
            });
    };

    savePost = () => {
        const t = this;
        const {title, description, text, pageParams, file} = t.state;

        const formData = new FormData();
        formData.append('title', title);
        formData.append('description', description);
        formData.append('content', text);

        if (file) {
            formData.append('file', file);
            formData.append('image_type', 'cover');
        }

        API.post('/whub/v3/post/' + pageParams.id, formData)
            .then(function (r) {
                console.log(r)

                t.setState({loading: false, originalText: text}, t.validate);
            })
            .catch(function (error) {
                console.log(error.response);
                t.setState({loading: false});
            });
    };

    handlePublish = () => {
        const t = this;
        const {status, pageParams} = t.state;
        const newStatus = status === 'publish' ? 'draft' : 'publish';

        const formData = new FormData();
        formData.append('status', newStatus);

        t.setState({loading: true});
        API.post('/whub/v3/post/' + pageParams.id, formData)
            .then(function (r) {
                console.log(r)

                t.setState({loading: false, status: newStatus}, t.validate);
            })
            .catch(function (error) {
                console.log(error.response);
                t.setState({loading: false});
            });
    };

    render () {
        const t = this;
        const {loading, pageParams, status, title, description, cover, editorState, valid, changes, redirectId} = t.state;

        const toolbar = {
            options: ['inline', 'list', 'blockType', 'textAlign', 'link'],
            inline: {
                options: ['bold', 'italic', 'underline']
            },
            blockType: {
                inDropdown: false,
                options: ['H1', 'H2', 'H3', 'Blockquote'],

            },
            list: {
                options: ['unordered', 'ordered'],
            },
            link: {
                inDropdown: false,
                showOpenOptionOnHover: true,
                defaultTargetOption: '_blank',
                options: ['link', 'unlink']
            }
        };

        const paramsHook = pageParams ? '' : <PageParamsHook onParams={t.onParams}/>;
        const redirect = redirectId ? <Redirect to={'/blog/' + redirectId + '/edit'} /> : '';

        let postId = null;

        if (pageParams && pageParams.id) {
            postId = pageParams.id;
        }
        if (redirectId) {
            postId = redirectId;
        }

        const isPublished = status === 'publish';

        const viewLink = postId ? <Link to={'/blog/' + postId}><button className="v2 small light_border">Просмотр</button></Link> : '';
        const publishLink = postId ? <button onClick={t.handlePublish} className="v2 small light_border">{isPublished ? 'Скрыть' : 'Опубликовать'}</button> : '';

        const coverPreview = cover ? <img width={240} src={cover} alt="" /> : '';

        return (
            <div className="blog_editor_wrapper">
                {paramsHook}
                {redirect}

                <div className="bc_top">
                    <h2>редактор статьи</h2>
                </div>

                <div className="blog_editor">
                    <dl>
                        <dt>Заголовок:</dt>
                        <dd>
                            <input onChange={t.handleChange} value={title} name="title" />
                        </dd>
                    </dl>
                    <dl>
                        <dt>Обложка:</dt>
                        <dd>
                            <input type="file" onChange={t.handleChange} name="file" />
                            {coverPreview}
                        </dd>
                    </dl>
                    <dl>
                        <dt>Описание:</dt>
                        <dd>
                            <textarea onChange={t.handleChange} value={description} name="description" />
                        </dd>
                    </dl>

                    <dl className="edit_lesson_type_text">
                        <dt>Текст:</dt>
                        <dd>
                            <Editor
                                editorState={editorState}
                                toolbar={toolbar}
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                onEditorStateChange={t.onEditorStateChange}
                                stripPastedStyles={true}
                            />
                        </dd>
                    </dl>

                    <div className="buttons_holder">
                        <dl>
                            <dt> </dt>
                            <dd>
                                <button className="v2 small" onClick={t.handleSave} disabled={!valid || !changes}>Сохранить</button>
                                {viewLink}
                                {publishLink}
                            </dd>
                        </dl>
                    </div>
                </div>

                <Spinner show={loading} />
            </div>
        );
    }
}

export default BlogEditor;