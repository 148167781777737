import React from 'react';
import moment from 'moment';
import calendarIcon from './icons/calendar.svg';
import arrowIcon from './icons/arrow.svg';
import './ProfileDatePickerCalendar.css';

class ProfileDatePickerCalendar extends React.Component {
  constructor(props) {
    super(props);

    this.wrapperRef = React.createRef();

    this.state = {
      todayDate: "",
      selectedDate: "",
      currentYear: "",
      currentMonth: "",
      currentMonthIndex: "",
      currentDay: "",
      showCalendar: false,
    }
  }

  componentDidUpdate(prevProps) {
    const isDateValid = moment(this.props.value).isValid();
    if (this.props.value !== prevProps.value && isDateValid) {
      let initialDate = this.props.value;
      this.initDate(initialDate);
    }
  }

  componentDidMount() {
    this.initDate(this.validatePropsDate());
    document.addEventListener("mousedown", this.outsideClick);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.outsideClick);
  }

  validatePropsDate = () => {
    const isDateValid = moment(this.props.value).isValid();

    let initialDate = new Date();

    if (isDateValid) {
      initialDate = this.props.value;
    }

    return initialDate;
  }

  initDate = (initialDate) => {
    const year = moment(initialDate).format('YYYY')
    const month = moment(initialDate).format('MMMM');
    const monthIndex = moment(initialDate).format('MM');
    const day = moment(initialDate).format('DD');
    const date = moment().format('YYYY-MM-DD');

    this.setState({
      todayDate: date,
      selectedDate: moment(initialDate).format('YYYY-MM-DD'),
      currentYear: year,
      currentMonth: month.substring(0, 1).toUpperCase() + month.substring(1),
      currentMonthIndex: monthIndex,
      currentDay: day,
    }, () => this.daysArray());
  }

  moveNav = (step) => {
    const { currentMonthIndex: monthIndex, currentYear: year, currentDay: day } = this.state;

    if (step === 'back') {
      const currentDate = `${year}-${monthIndex}-${day}`
      const prev = moment(currentDate).subtract('1', 'month');

      const prevYear = prev.format('YYYY')
      const prevMonth = prev.format('MMMM');
      const prevMonthIndex = prev.format('MM');
      const prevDay = prev.format('DD');
      
      this.setState({
        currentYear: prevYear,
        currentMonth: prevMonth.substring(0, 1).toUpperCase() + prevMonth.substring(1),
        currentMonthIndex: prevMonthIndex,
        currentDay: prevDay,
      })
    } else if ( step === 'forward') {
      const currentDate = `${year}-${monthIndex}-${day}`
      const next = moment(currentDate).add('1', 'month');

      const nextYear = next.format('YYYY')
      const nextMonth = next.format('MMMM');
      const nextMonthIndex = next.format('MM');
      const nextDay = next.format('DD');
      
      this.setState({
        currentYear: nextYear,
        currentMonth: nextMonth.substring(0, 1).toUpperCase() + nextMonth.substring(1),
        currentMonthIndex: nextMonthIndex,
        currentDay: nextDay,
      })
    }
  }

  selectDate = (day) => {
    const { currentMonthIndex: monthIndex, currentYear: year } = this.state;
    const date = moment(`${year}-${monthIndex}-${day}`);

    this.setState({
      selectedDate: date.format('YYYY-MM-DD')
    })
  }

  saveDate = () => {
    this.props.onChange(this.state.selectedDate);
    this.setState({
      showCalendar: false
    })
  }

  switchCalendar = () => {
    const currentState = this.state.showCalendar;

    this.setState({
      showCalendar: !currentState
    }, () => {
      if (!this.showCalendar) {
        this.initDate(this.validatePropsDate())
      }
    })
  }

  isToday = (day) => {
    return moment(`${this.state.currentYear}-${this.state.currentMonthIndex}-${day}`).isSame(this.state.todayDate)
  }

  isSelected = (day) => {
    return moment(`${this.state.currentYear}-${this.state.currentMonthIndex}-${day}`).isSame(this.state.selectedDate);
  }

  dayClass = (day) => {
    if (this.isSelected(day)) {
      return "profile_date_picker_body_table_row_selected"
    } else if (this.isToday(day)) {
      return "profile_date_picker_body_table_row_current_day"
    }
  }

  daysArray = () => {
    const { currentMonthIndex: monthIndex, currentYear: year } = this.state;

    if (monthIndex !== "" && year !== "") {
      const dayNames = ["понедельник", "вторник", "среда", "четверг", "пятница", "суббота", "воскресенье"];
      const totalDays = moment(`${year}-${monthIndex}`).daysInMonth();
      const firstDay = moment(`${year}-${monthIndex}-01`).format('dddd');
      const table = [];
      const offset = dayNames.indexOf(firstDay);
      let index = 1;

      for (let rowIndex = 0; rowIndex < 6 && index < totalDays + 1; rowIndex++) {
        let row = rowIndex === 0 ? new Array(offset).fill(null) : [];
        for (let dayIndex = rowIndex === 0 ? offset : 0; dayIndex < 7 && index < totalDays + 1; dayIndex++) {
          row.push(index);
          index++;
        }
        table.push(row);
      }
      return table;
    }

    return [];
  }


  getYearOptions = () => {
    const { currentMonthIndex: monthIndex, currentYear, currentDay} = this.state;

    if (this.state.todayDate.length > 0) {
      const years = [];
      const currentDate = moment(`${currentYear}-${monthIndex}-${currentDay}`)
      const year = currentDate.diff(moment(this.state.todayDate)) <= 0 ? Number(moment(this.state.todayDate).format('YYYY')) : Number(moment(currentDate).format('YYYY'));
      for (let i = year ; i > 1900; i--) {
        years.push(i);
      }

      return years.map((year) => {
        return (
          <option key={year}>{year.toString()}</option>
        )
      })
    }
    return [];
  }

  getMonthOptions = () => {
    const months = Array.apply(0, Array(12)).map(function(_,i){return moment().month(i).format('MMMM')});

    return (
      months.map(month => {
        return (
          <option key={month}>{month.substring(0, 1).toUpperCase() + month.substring(1)}</option>
        )
      })
    )
  }

  handleMonthSelect = (month) => {
    const months = Array.apply(0, Array(12)).map(function(_,i){return moment().month(i).format('MMMM')});
    const monthIndex = months.indexOf(month.toLowerCase());

    this.setState({
      currentMonth: month,
      currentMonthIndex: `${0}${monthIndex + 1}`
    })
  }

  outsideClick = (event) => {
    if (this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target) && this.state.showCalendar) {
      this.switchCalendar();
    }
  }

  renderDateTable = () => {
    const daysArray = this.daysArray();

    return (
      <div className="profile_date_picker_body_table">
        {daysArray && daysArray.map((row, i) => {
          return (
            <div key={row.join(`+${i}`)} className="profile_date_picker_body_table_row">
              {row.map((day, i) => {
                return (
                  <span key={day + i}
                    onClick={() => this.selectDate(day)}
                    className={this.dayClass(day)}>
                  {day}</span>
                )
              })}
            </div>
          )
        })}
      </div>
    )
  }

  handleChange = (e) => {
    let { value } = e.target;

    this.props.onChange(value);
  }

  inputClass = (type = 'profile') => {
    if (type === 'profile') {
      return 'profile_date_picker_wrapper_profile'
    } else if ( type === 'modal') {
      return 'profile_date_picker_wrapper_modal'
    }
  }

  render() {
    this.getYearOptions()
    return (
      <div ref={this.wrapperRef} className="profile_date_picker_wrapper">
        <div className={this.inputClass(this.props.type)}>
          {/* { this.props.value && this.props.value.length > 0 ?  */}
          { true ?
          <input
            type="date"
            max="2025-12-12"
            value={this.props.value}
            onChange={(e) => this.handleChange(e)} className="student_profile_form_input_date"></input> :
          // <span className="student_profile_form_input_date">{this.props.value.replace(/-/g, ".")}</span> : 
          <span className="student_profile_form_input_date_placeholder">{this.props.placeholder || "Введите дату"}</span> }
          { !this.props.disabled ? 
          <div onClick={() => {this.switchCalendar()}} style={{backgroundImage: `url(${calendarIcon})`}} className="profile_date_picker_icon"></div> : ""}
        </div>
        { this.state.showCalendar ? <div className="profile_date_picker">
          <div className="profile_date_picker_header">
            <div className="profile_date_picker_header_date">
              <select value={this.state.currentMonth} onChange={(e) => this.handleMonthSelect(e.target.value)}>
                {this.getMonthOptions()}
              </select>
              <select value={this.state.currentYear} onChange={(e) => this.setState({currentYear: e.target.value})}>
                {this.getYearOptions()}
              </select>
            </div>

            <div className="profile_date_picker_header_nav">
              <div onClick={() => this.moveNav('back')} className="profile_date_picker_header_nav_arrow_left" style={{backgroundImage: `url(${arrowIcon})`}}></div>
              <div onClick={() => this.moveNav('forward')} className="profile_date_picker_header_nav_arrow_right" style={{backgroundImage: `url(${arrowIcon})`}}></div>
            </div>
          </div>
          <div className="profile_date_picker_body">
            <div className="profile_date_picker_body_days">
              <div className="profile_date_picker_body_days_week">
                <span>Пн</span>
                <span>Вт</span>
                <span>Ср</span>
                <span>Чт</span>
                <span>Пт</span>
              </div>
              <div className="profile_date_picker_body_days_weekend">
                <span>Сб</span>
                <span>Вс</span>
              </div>
            </div>
            {this.renderDateTable()}
          </div>
          <div className="profile_date_picker_footer">
            <div onClick={() => {this.setState({showCalendar: false})}} className="profile_date_picker_footer_cancel">
              <span>Отмена</span>
            </div>
            <div onClick={() => {this.saveDate()}} className="profile_date_picker_footer_save">
              <span>Сохранить</span>
            </div>
          </div>
        </div> : "" }
      </div>
    )
  }
}

export default ProfileDatePickerCalendar;