import React from 'react';

import './index.css';

import free from './free.svg';
import maze from './maze.svg';
import pie from './pie-chart.svg';
import puzzle from './puzzle.svg';
import docs from './docs.svg';
import target from './target.svg';

class LAdvantages extends React.Component {
    constructor (props) {
        super();

        this.state = {
        };
    }

    componentDidMount = () => {
    };

    componentWillUnmount = () => {
    };

    render() {
        return (
            <div className="ls_advantages_wrapper">
                <div className="wrapper_gradient"> </div>
                <div className="l_content">
                    <h2>Преимущества <nobr>W-HUB</nobr></h2>

                    <ul className="plates">
                        <li>
                            <div className="pic_wrapper p0"><img src={free} alt={''} /></div>
                            <p><span>Бесплатное создание и размещение</span> курсов на витрине. Загрузите курс, мы запустим продажи</p>
                        </li>
                        <li>
                            <div className="pic_wrapper p1"><img src={docs} alt={''} /></div>
                            <p>Работаем с юридическими лицами, индивидуальными предпринимателями (ИП) и самозанятыми</p>
                        </li>
                        <li>
                            <div className="pic_wrapper p2"><div className="crunch"><img src={target} alt={''} /></div></div>
                            <p>Профессиональное <span>продвижение</span> и&nbsp;<span>реклама</span> в поисковых системах и социальных сетях</p>
                        </li>
                        <li>
                            <div className="pic_wrapper p2"><img src={pie} alt={''} /></div>
                            <p>Инструменты расширенной <span>аналитики</span> продаж</p>
                        </li>
                        <li>
                            <div className="pic_wrapper p1"><img src={puzzle} alt={''} /></div>
                            <p><span>Кросс-продажи</span> и привлечение других клиентов маркетплейса</p>
                        </li>
                        <li>
                            <div className="pic_wrapper p0"><img src={maze} alt={''} /></div>
                            <p><span>Геймификация</span> и повышение вовлеченности в решение прикладных задач</p>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default LAdvantages;
