import React from "react";
import { Redirect } from 'react-router-dom';

import './index.css';
import '../styles/auth-tabs.css';
import API from "../utils/api";
import Spinner from "../utils/spinner";

class PasswordRecovery extends React.Component {
    constructor (props) {
        super();
        this.state = {
            loading: false,
            login: '',
            status: 'success',
            sent: false
        }
    }

    componentDidMount() {
        document.body.classList.add('minimal');
    };
    componentWillUnmount() {
        document.body.classList.remove('minimal');
    };

    handleTextInput = (e) => {
        const obj = {};
        obj[e.target.name] = e.target.value;
        this.setState(obj);
    };

    handleSubmit = () => {
        const t = this;
        const s = t.state;
        const data = {
            email: s.login
        };

        t.setState({loading: true});

        API.post('/whub/v3/auth/amnesia\n', data, {headers: {'Authorization': ''}})
            .then(function (r) {
                t.setState({sent: true, loading: false});
            })
            .catch(function (error) {
                console.log(error.response);
                t.setState({sent: true, status: 'fail', loading: false});
            });
    };

    render() {
        const {loading} = this.state;

        const redirect = this.props.authorized ? <Redirect to={{pathname: "/"}}/> : '';

        const form = <div className="password_reset_form">
            <input onChange={this.handleTextInput} name="login" type="text" className="text_input" placeholder="e-mail" />
            <button onClick={this.handleSubmit} disabled={!this.state.login.length}>Восстановить</button>
        </div>;

        const text = {
            success: 'ссылка для восстановления пароля отправлена на ваш электронный адрес',
            fail: 'логин или e-mail не найден('
        };

        const afterMessage = <div className="after_message">{text[this.state.status]}</div>;

        const content = this.state.sent ? afterMessage : form;

        return (
            <div className="password_reset_wrapper">
                <h4>восстановление пароля</h4>
                {content}
                {redirect}
                <Spinner show={loading} />
            </div>
        )
    }
}

export default PasswordRecovery;