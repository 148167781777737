import React from 'react';
import './index.css';
import Header from "./components/header";
import LandingInfoBar from './components/info-bar';
import Params from "./components/params";
import Advert from "./components/advert";
import FutureSkills from "./components/future_skills";
import Description from "./components/description";
import Content from "./components/content";
import AboutSchool from "./components/about_school";
import Authors from "./components/authors";
import Roadmap from "./components/roadmap";
import Requirements from "./components/requirements";
import FAQ from "./components/faq";
import Reviews from "./components/reviews";
import Related from "./components/related";
import Purchase from "./components/purchase";
import Perks from "./components/perks";
import LeaveReview from "./components/leave-review";
import API from "../../utils/api";
import Modal from "../../modal";
import Tracker from "../../utils/tracker";
import {checkOwner} from "../../utils/utils";
import CourseStatistics from "./components/course-statistics";
import {crumbBuilder} from "../../utils/breadcrumbs/crumb-builder";
import Breadcrumbs from "../../utils/breadcrumbs";

class CourseLanding extends React.Component {
    constructor (props) {
        super();

        this.state = {
            showGiftModal: false,
            showReportModal: false
        };
    }

    checkAuthorization = (cb) => {
        const t = this;
        const {authorized, modalAuth} = t.props;

        if (authorized) {
            cb()
        }
        else {
            modalAuth(cb, false);
        }
    };

    handleEntryClick = () => {
        const t = this;
        const {data, userData} = t.props;

        t.checkAuthorization(() => {
            const hasEntry = userData.course_entries && userData.course_entries.indexOf(data.id) > -1;

            if (!hasEntry) {
                t.processEntry();
            }
        });
    };

    processEntry = () => {
        const t = this;
        const {data, reloadUserData} = t.props;

        t.setState({loading: true});
        API.put('/whub/v3/subscribe/' + data.id, {'entity': 'course'})
            .then(function (r) {
                reloadUserData();

                t.setState({loading: false});
            })
            .catch(function (error) {
                console.log(error.response);

                t.setState({loading: false});
            });
    };

    cancelEntry = () => {
        const t = this;
        const {data, reloadUserData} = t.props;

        t.setState({loading: true});
        API.delete('/whub/v3/subscribe/' + data.id, {data: {entity: 'course'}})
            .then(function (r) {
                reloadUserData();

                t.setState({loading: false});
            })
            .catch(function (error) {
                console.log(error.response);

                t.setState({loading: false});
            });
    };

    handlePurchaseClick = () => {
        const t = this;
        const {data, onPurchase} = t.props;

        t.setState({loading: true});

        onPurchase(data, () => {
            t.setState({loading: false});
        });
    };

    scrollToContent = () => {
        document.getElementById('landingContentBlockAnchor').scrollIntoView({behavior: 'smooth'});
    };

    checkIfRated = (ud, data) => {
        if (data.reviews && data.reviews.length) {
            for (let i=0;i<data.reviews.length;i++) {
                if (parseInt(ud.id) === parseInt(data.reviews[i].user.id)) {
                    return true;
                }
            }
        }
        return false;
    };

    handleReportClick = () => {
        this.setState({showReportModal: true});
    };

    handleGiftClick = () => {
        this.setState({showGiftModal: true});
    };

    giftPurchase = (email) => {
        const t = this;
        const {data, setLoading, modalPurchase} = t.props;

        t.setState({showGiftModal: false});
        setLoading(true);

        API.post('/whub/v3/order', {product_id: data.id, payment_method: 'ukassa', gift_recipient: email})
            .then((r) => {
                setLoading(false);
                const purchaseLink = r.data.payment_data.payment_url;
                modalPurchase(purchaseLink);
                window.open(purchaseLink);

                //withId(62510686)('reachGoal','purchase');
            })
            .catch((error) => {
                setLoading(false);
                alert('Произошла ошибка! Попробуйте еще раз, если ошибка будет повторяться - напишите на support@w-hub.ru');
                console.log(error);
            });
    };

    handleFavouriteClick = () => {
        const t = this;

        t.checkAuthorization(() => {
            // const {data, userData} = t.props;
            // const isFavourited = userData.favourites && userData.favourites.indexOf(data.id) > -1;
            
            t.addFavourite();
        });
    };

    addFavourite = () => {
        const t = this;
        const {data, reloadUserData, setLoading} = t.props;
        setLoading(true);
        API.post('/whub/v1/favorite/' + data.id)
            .then(function (r) {
                reloadUserData(setLoading(false));
            })
            .catch(function (error) {
                console.log(error.response);
                setLoading(false);
            });
    };

    render() {
        const t = this;
        const p = t.props;
        const {data, userData, authorized, pageParams} = p;
        const meta = data.meta;

        const {showGiftModal, showReportModal} = t.state;

        const isOwner = checkOwner(data.id, userData);
        const isPurchased = userData.purchased && userData.purchased.indexOf(data.id) > -1;
        const isRated = isPurchased && t.checkIfRated(userData, data);
        const isSchool = authorized && userData.role === 'school';

        const hasDiscount = !!parseInt(data.discount_amount_percentage);
        const price = hasDiscount ? data.price : data.regular_price;
        const isFree = !price;

        const advert = meta.self_advert ? <Advert {...p} /> : '';
        const futureSkills = meta.future_skills ? <FutureSkills {...p} /> : '';
        const perks = meta.perks ? <Perks {...p} /> : '';
        const authors = data.authors && data.authors.length > 0 ? <Authors {...p} /> : '';
        const roadmap = meta.roadmap ? <Roadmap {...p} /> : '';
        const requirements = meta.requirements ? <Requirements {...p} /> : '';
        const faq = meta.faq ? <FAQ {...p} /> : '';
        const courseStatistics = isOwner ? <CourseStatistics {...p} /> : '';

        const leaveReview = isPurchased && !isRated ? <LeaveReview {...p} /> : '';
        const reviews = data.reviews && data.reviews.length ? <Reviews {...p} /> : '';

        const related = data.related_ids ? <Related {...p} /> : '';

        const purchase = !isSchool ? <Purchase {...p}
                                               isOwner={isOwner}
                                               scrollToContent={t.scrollToContent}
                                               handleGift={t.handleGiftClick}
                                               handleReport={t.handleReportClick}
                                               handleEntry={t.handleEntryClick}
                                               cancelEntry={t.cancelEntry}
                                               handlePurchase={t.handlePurchaseClick}
                                               handleFavourite={t.handleFavouriteClick} /> : '';

        const giftModal = showGiftModal ? <Modal modalContent={'gift'} onSuccess={t.giftPurchase} onModalClose={() => t.setState({showGiftModal: false})} /> : '';
        const reportModal = showReportModal ? <Modal {...p} modalContent={'abuse'} onModalClose={() => t.setState({showReportModal: false})} /> : '';

        const crumbs = crumbBuilder('course', {data: data, pageParams: pageParams});

        return (
            <div className="course_landing_wrapper">
                <Breadcrumbs data={crumbs} />

                <Header {...p}
                        isOwner={isOwner}
                        scrollToContent={t.scrollToContent}
                        handleGift={t.handleGiftClick}
                        handleReport={t.handleReportClick}
                        handleEntry={t.handleEntryClick}
                        cancelEntry={t.cancelEntry}
                        handlePurchase={t.handlePurchaseClick}
                        handleFavourite={t.handleFavouriteClick}/>
                <Params {...p} />

                <LandingInfoBar {...p}/>

                {courseStatistics}

                {advert}
                {futureSkills}

                <Description {...p} />

                {perks}

                <Content {...p} isPurchased={isPurchased} isFree={isFree} isOwner={isOwner} ref={this.contentBlock} />
                <AboutSchool {...p} />

                {authors}
                {roadmap}
                {requirements}
                {faq}

                {leaveReview}
                {reviews}

                {related}

                {purchase}

                {giftModal}
                {reportModal}

                <Tracker id={data.id} event="view" />
            </div>
        );
    }
}

export default CourseLanding;
