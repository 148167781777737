import React from "react";

import {Link} from "react-router-dom";
import './index.css';
import '../styles/auth-tabs.css';
import API from "../utils/api";
import Checkbox from "../utils/checkbox/checkbox";
import Preloader from "../utils/preloader";

class PasswordResetForm extends React.Component {
    constructor (props) {
        super();
        this.state = {
            valid: false,
            password: '',
            password_check: '',
            agreement: false,
            status: 'success',
            done: false,
            reloaded: false
        }
    }

    componentDidMount() {
        document.body.classList.add('minimal');
        if (this.props.authorized) {
            this.props.setPasswordResetPage(true);
        }
    };
    componentWillUnmount() {
        document.body.classList.remove('minimal');
        this.props.setPasswordResetPage(false);
    };


    componentDidUpdate() {
        this.checkUpdate();
    };

    checkUpdate = () => {
        const t = this;
        const s = t.state;
        const p = t.props;

        if (s.done && s.status === 'success' && !s.reloaded) {
            t.setState({reloaded: true});
            p.reloadUserData();
        }
    };

    validate = () => {
        const t = this;
        const p = t.props;
        const s = t.state;

        let test = s.password.length
            && s.password === s.password_check;

        if (p.authorized && !p.userData.tos_accepted) {
            test = s.password.length
                && s.password === s.password_check
                && s.agreement;
        }

        if (test && !s.valid) {
            t.setState({valid: true});
        }

        if (!test && s.valid) {
            t.setState({valid: false});
        }
    };

    handleTextInput = (e) => {
        const obj = {};
        obj[e.target.name] = e.target.value;
        this.setState(obj, this.validate);
    };

    handleSubmit = () => {
        const t = this;
        const p = t.props;
        const s = t.state;
        const data = this.props.params;
        data.password = s.password;

        if (data.user) {
            data.username = data.user;
            delete data.user;
        }

        if (p.authorized && !p.userData.tos_accepted) {
            data.tos_accepted = true;
        }

        API.post('/whub/v3/auth/password', data, {headers: {'Authorization': ''}})
            .then(function (r) {
                t.setState({done: true});

                if (p.isLockscreen) {
                    t.setState({status: 'loading'})
                }

                if (!t.props.authorized) {
                    p.authorizeFunc({
                        username: data.username,
                        password: data.password
                    });
                }
            })
            .catch(function (error) {
                console.log(error);

                t.setState({done: true, status: 'fail'});
            });
    };

    handleCheckbox = (e) => {
        const obj = {};
        obj[e.target.name] = e.target.checked;

        this.setState(obj, this.validate);
    };

    render() {
        const t = this;
        const p = t.props;
        const s = t.state;

        const labels = {
            student: <span>Согласен на обработку персональных данных, ознакомлен с <a rel="noopener noreferrer" target="_blank" href="/docs/privacy_policy.docx">политикой конфиденциальности</a>, принимаю условия <a rel="noopener noreferrer" target="_blank" href="/docs/offer_student.docx">договора-оферты</a></span>,
            school: <span>Согласен на обработку персональных данных, ознакомлен с <a rel="noopener noreferrer" target="_blank" href="/docs/privacy_policy.docx">политикой конфиденциальности</a>, принимаю условия <a rel="noopener noreferrer" target="_blank" href="/docs/offer_school.docx">Лицензионного договора-оферты</a></span>
        };
        const label = p.authorized ? labels[p.userData.role] : '';

        const confirm = p.authorized && !p.userData.tos_accepted ? <Checkbox onChange={this.handleCheckbox} name="agreement" checked={s.agreement} label={label} /> : '';

        const form = <div>
            <input onChange={this.handleTextInput} value={s.password} type="password" name="password" className="text_input" placeholder="новый пароль" />
            <input onChange={this.handleTextInput} value={s.password_check} type="password" name="password_check" className="text_input" placeholder="ещё раз новый пароль" />
            {confirm}
            <button onClick={this.handleSubmit} disabled={!s.valid}>Cохранить</button>

        </div>;

        const text = {
            success: <span>пароль успешно сохранен!<br /><Link to={'/'}>на главную</Link></span>,
            success_no_auth: <span>пароль успешно сохранен!<br /><Link to={'/signin'}>вход</Link></span>,
            fail: 'что-то пошло не так(',
            loading: <Preloader />
        };

        const afterMessage = <div className="after_message">{text[s.status]}</div>;

        const content = s.done ? afterMessage : form;

        return (
            <div className="password_reset_form">
                {content}
            </div>
        )
    }
}

export default PasswordResetForm;