import React from 'react';

import './index.css';

import girl from './girl.png';
import getMoney from './get-money.png';
import create from './create.png';
import publish from './publish.png';
import {Link} from "react-router-dom";
import {reachGoalLink} from "../../../../utils/utils";

class LSWelcome extends React.Component {
    constructor (props) {
        super();

        this.state = {
        };
    }

    componentDidMount = () => {
    };

    componentWillUnmount = () => {
    };

    hitGoal = () => {

    };

    render() {
        return (
            <div className="ls_welcome_wrapper">
                <div className="l_content">
                    <div className="ls_welcome_text">
                        {/*<h1><nobr>W-HUB</nobr> — это маркетплейс </h1>*/}
                        {/*<p>для продажи образовательных онлайн-курсов, видеокурсов, вебинаров, аудиокниг и услуг репетиторов, благодаря которому вы можете:</p>*/}
                        {/*<ul>*/}
                            {/*<li>быстро и без вложений создать обучающие курсы</li>*/}
                            {/*<li>легко осуществить продажи через сервис <strong>«безопасная сделка»</strong></li>*/}
                            {/*<li>эффективно использовать инфраструктуру W-Hub</li>*/}
                        {/*</ul>*/}
                        <h1><nobr>W-HUB</nobr> — это первый в России проект предлагающий бесплатные сервисы: </h1>
                        <ul className="large_list">
                            <li>Цифровую платформу для создания онлайн-школ, обучающих курсов, видеокурсов, вебинаров и онлайн-курсов.</li>
                            <li><Link to={'/'} data-tag="80212912" data-goal="marketplace_link_click" onClick={reachGoalLink}>Маркетплейс</Link> для продвижения и продажи контента</li>
                        </ul>
                    </div>
                </div>
                <div className="ls_bg">
                    <div className="girl"><img src={girl} alt={''} /></div>

                    <div className="create circle">
                        <img src={create} alt={''} />
                        <div className="bg_text">создавай</div>
                    </div>
                    <div className="publish circle">
                        <img src={publish} alt={''} />
                        <div className="bg_text">продавай</div>
                    </div>
                    <div className="get_money circle">
                        <img src={getMoney} alt={''} />
                        <div className="bg_text">зарабатывай</div>
                    </div>

                    <div className="ellipse"> </div>

                </div>
            </div>
        );
    }
}

export default LSWelcome;
