import React from 'react';

import API from "../utils/api";

import {Link, useParams} from 'react-router-dom';
import {logout} from "../utils/utils";

import notificationIcon from './ico/notification.svg';
import calendarIcon from './ico/calendar.svg';
import eduIcon from './ico/edu.svg';
import readIcon from './ico/read.svg';
import requisiteIcon from './ico/requisite.svg';
import filesIcon from './ico/files.svg';
import expertsIcon from './ico/experts.svg';
import studentsIcon from './ico/students.svg';
import homeworkIcon from './ico/homework.svg';
import chatIcon from './ico/chat.svg';
import reviewsIcon from './ico/reviews.svg';
import profileIcon from './ico/profile.svg';
import supportIcon from './ico/support.svg';
import statsIcon from './ico/stats.svg';
import paymentsIcon from './ico/payment.svg';
import reviewIcon from './ico/review.svg';
import exitIcon from './ico/exit.svg';


function SchoolDashboardLinks(props) {
    const userData = props.userData;

    const [unreadNotifications, setUnreadNotifications] = React.useState(0);

    React.useEffect(() => {
      API.get('/whub/v3/notification/').then(result => {
        const { data } = result;
        setUnreadNotifications(data.unread);
      })
    }, [])

    let pageParams = useParams();

    const activeCls = (c) => {
        return c === pageParams.profilePage ? 'active' : ''
    };

    const cls_red = !userData.school_data_completed ? 'red' : '';
    const schoolDataText = props.schoolCompleted ? 'Реквизиты' : 'завершение регистрации';


    const statsLink = props.schoolCompleted ? 
    <Link className={"img_link " + activeCls('stats')} to={'/user/stats'}>
      <img alt="stats" src={statsIcon}/>Статистика</Link> 
      : '';
    // <sup>ß</sup>

    const logoStyle = !userData.avatar ? {} : 
      {
        backgroundImage: `url(${userData.avatar})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain'
      }

    const coursesLink = <Link onClick={props.hideMenu} className={"img_link " + activeCls('courses')} to={'/user/courses?status=publish'}>
      <img alt="edu" src={eduIcon}/>Курсы</Link>

    // const onlineCoursesLink = <Link onClick={props.hideMenu} className={"img_link " + activeCls('online-courses')} to={'/user/online-courses?status=publish'}>
    //   <img alt="edu" src={eduIcon}/>Онлайн-курсы</Link>

    const notificationsLink = <Link onClick={props.hideMenu} className={"img_link " + activeCls('notifications')} to={'/user/notifications'}>
      <img alt="bell" src={notificationIcon} /> Уведомления
      { unreadNotifications > 0 ? <div className="notifications_count">{ unreadNotifications }</div> : "" }
    </Link>

    const calendarLink = <Link onClick={props.hideMenu} className={"img_link " + activeCls('calendar')} to={'/user/calendar'}>
      <img alt="calendar" src={calendarIcon}/>Календарь</Link>
      
    const blogLink = <Link onClick={props.hideMenu} className={"img_link " + activeCls('posts')} to={'/user/posts'}>
      <img alt="read" src={readIcon}/>Статьи</Link>

    const filesLink = <Link onClick={props.hideMenu} className={"img_link " + activeCls('files')} to={'/user/files'}>
      <img alt="files" src={filesIcon}/>Мои файлы</Link>

    const expertsLink  = <Link onClick={props.hideMenu} className={"img_link " + activeCls('experts')} to={'/user/experts'}>
      <img alt="experts" src={expertsIcon}/>Эксперты</Link>

    const studentsLink = <Link onClick={props.hideMenu} className={"img_link " + activeCls('students')} to={'/user/students'}>
      <img alt="students" src={studentsIcon}/>Студенты</Link>

    const homeworkLink = <Link onClick={props.hideMenu} className={"img_link " + activeCls('homework')} to={'/user/homework'}>
      <img alt="homework" src={homeworkIcon}/>Проверка заданий</Link>

    const chatsLink = <Link onClick={props.hideMenu} className={"img_link " + activeCls('chat')} to={'/user/chat'}>
      <img alt="chat" src={chatIcon}/>Чаты</Link>

    const reviewsLink = <Link onClick={props.hideMenu} className={"img_link " + activeCls('reviews')} to={'/user/reviews'}>
      <img alt="reviews" src={reviewsIcon}/>Отзывы</Link>

    const profileLink = <Link onClick={props.hideMenu} className={"img_link " + activeCls('profile')} to={'/user/profile'}>
      <img alt="profile" src={profileIcon}/>Профиль</Link>

    const requisiteLink = <Link className={"img_link " + activeCls('school-data') + ' ' + cls_red} to={'/user/school-data'}>
      <img alt="req" src={requisiteIcon}/>{schoolDataText}</Link>

    const paymentsLink = <Link onClick={props.hideMenu} className={"img_link " + activeCls('payments')} to={'/user/payments'}>
      <img alt="profile" src={paymentsIcon}/>Продажи</Link>

    const exitLink = <a href='/' onClick={logout} className={"img_link " + activeCls('exit')}>
      <img alt="exit" src={exitIcon}/>Выйти</a>

    const supportBtn = <div className="img_link">
      <img alt="support" src={supportIcon}/>Служба поддержки
    </div>

    const reviewBtn = <div className="img_link">
      <img alt="review" src={reviewIcon}/>Оставь свой отзыв
    </div>

    return (
        <div className="profile_nav_feed">
          <div className="profile_user_data">
            <div className="profile_user_data_avatar_outer">
              <div className="profile_user_data_avatar_inner" style={logoStyle}>
                {userData.avatar ? '' : Array.from(userData.display_name)[0]}
              </div>
            </div>
            <div className="profile_user_data_name">
              {/* {userData.first_name && userData.last_name? <span className="profile_user_data_name_main">{userData.first_name}</span> : ""}
              {userData.first_name && userData.last_name? <span className="profile_user_data_name_main">{userData.last_name}</span> : ""} */}
              {/* <span className="profile_user_data_name_display">{userData.display_name}</span>
              <span className="profile_user_data_name_email">{userData.email}</span> */}
              <span className="profile_user_data_name_display">{userData.slug}</span>
            </div>
          </div>

            {notificationsLink}
            {calendarLink}
            <div className="profile_user_data_divider"></div>

            {coursesLink}
            {/* {onlineCoursesLink} */}
            {blogLink}
            {filesLink}
            {expertsLink}
            {studentsLink}
            {homeworkLink}
            {chatsLink}
            {reviewsLink}

            <div className="profile_user_data_divider"></div>

            {profileLink}
            {paymentsLink}
            {requisiteLink}
            
            {statsLink}
            {exitLink}
            {/* <Link className={"img_link " + activeCls('help')} to={'/user/help'}>помощь</Link> */}
            {/*<Link className={activeCls('support')} to={'/user/support'}>тех. поддержка</Link>*/}

            <div className="profile_user_data_divider"></div>

            <div>
              <script data-b24-form="click/17/1z3pl2" data-skip-moving="true">{(function(w,d,u){var s=d.createElement('script');s.async=true;s.src=u+'?'+(Date.now()/180000|0);var h=d.getElementsByTagName('script')[0];h.parentNode.insertBefore(s,h);})(window,document,'https://cdn-ru.bitrix24.ru/b23230650/crm/form/loader_17.js')}</script>
              {supportBtn}
            </div>
            
            <div>
              <script data-b24-form="click/19/113ip0" data-skip-moving="true">{(function(w,d,u){var s=d.createElement('script');s.async=true;s.src=u+'?'+(Date.now()/180000|0);var h=d.getElementsByTagName('script')[0];h.parentNode.insertBefore(s,h);})(window,document,'https://cdn-ru.bitrix24.ru/b23230650/crm/form/loader_19.js')}</script>
              {reviewBtn}
            </div>
        </div>
    );
}

export default SchoolDashboardLinks;