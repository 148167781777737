import React from 'react';
import Hammer from 'react-hammerjs';

import './index.css';
import {ArrowPrev, ArrowRightBlue} from "../../utils/icons";
import BlogItem from "./item";
import {Link} from "react-router-dom";

class BlogFeed extends React.Component {
    constructor (props) {
        super();

        this.feedWrapper = React.createRef();
        this.feed = React.createRef();

        this.state = {
            currentTab: props.currentTab ? props.currentTab : null,
            curStep: 0,
            stepSize: 640,
            steps: 1,
            prevHidden: true,
            nextHidden: false
        };
    }

    initialArrowsCheck = () => {
        const t = this;
        const {articles} = t.props;
        const s = t.state;

        const wrapper = t.feedWrapper.current;
        const wrapperWidth = wrapper.clientWidth;
        const feed = t.feed.current;
        let feedWidth = feed.clientWidth;

        let itemsLength = articles.length;
        const steps = itemsLength;


        const itemWidth = feedWidth/itemsLength;
        const itemsVisible = Math.floor(wrapperWidth/itemWidth);

        t.setState({stepSize: itemWidth, steps: steps})
        if (itemsLength <= itemsVisible) {
            t.setState({prevHidden: true, nextHidden: true});
        }
        else {
            if (s.curStep <= 0) {
                t.setState({prevHidden: true, nextHidden: false});
            }
            else if (s.curStep >= steps-itemsVisible-1) {
                t.setState({prevHidden: false, nextHidden: true});
            }
            else {
                t.setState({prevHidden: false, nextHidden: false});
            }
        }
    };

    slideNext = (dir) => {
        const t = this;
        const s = t.state;

        const next = s.curStep + dir;

        if (next >= 0 && next < s.steps) {
            t.setState({curStep: next}, t.initialArrowsCheck);
        }
    };

    componentDidMount = () => {
        this.initialArrowsCheck();
    };

    render() {
        const s = this.state;
        const {articles,
            title,
            tagLine,
            linkText,
            linkUrl} = this.props;

        const feed = articles.map((article, idx) => {
            return <BlogItem {...this.props} post={article} key={article.id} itemId={idx} />;
        });


        const arrPrev = !s.prevHidden ? <div onClick={() => this.slideNext(-1)} className="arr arr_prev"><i><ArrowPrev /></i></div> : '';
        const arrNext = !s.nextHidden ? <div onClick={() => this.slideNext(1)} className="arr arr_next"><i><ArrowPrev /></i></div> : '';

        const style = {left: this.state.curStep * s.stepSize * -1};

        const options = {
            touchAction:'compute',
            recognizers: {
                swipe: {
                    threshold: 10
                }
            }
        };

        let moreLink = '';

        if (linkText && linkUrl) {
            const tag = tagLine ? <span>{tagLine}</span> : '';

            moreLink = <div className="feed_link_more">
                {tag}
                <Link to={linkUrl}>{linkText}<ArrowRightBlue /></Link>
            </div>
        }

        return (
            <div className="blog_feed_holder">
                <div className="blog_feed_outer">
                    <h2>{title}</h2>
                    <div className="blog_feed">
                        {arrPrev}
                        {arrNext}
                        <div className="blog_feed_wrapper" ref={this.feedWrapper}>
                            <div style={style} className="blog_feed_block" ref={this.feed}>
                                <Hammer
                                    direction={'DIRECTION_HORIZONTAL'}
                                    options={options}
                                    onSwipeLeft={() => this.slideNext(1)}
                                    onSwipeRight={() => this.slideNext(-1)}>
                                    <div>
                                        {feed}
                                    </div>
                                </Hammer>
                            </div>
                        </div>
                    </div>

                    {moreLink}
                </div>
            </div>

        );
    }
}

export default BlogFeed;
