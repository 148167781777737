import React from 'react';
import API from '../../../../utils/api';
import ProfileModal from './commonModal';
import './index.css';

class PasswordModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      old: '',
      new: '',
      confirm: '',
      errorMessage: '',
    }
  }
  
  updatePass = () => {
    const url = "/whub/v3/auth/password/0";

    const data = {
      old: this.state.old,
      new: this.state.new
    }

    if (this.state.confirm.length === 0) {
      this.setState({errorMessage: "Заполните поле \"Старый пароль\""});
      return;
    }

    if (this.state.new.length === 0) {
      this.setState({errorMessage: "Заполните поле \"Новый пароль\""});
      return;
    }

    if (this.state.old.length === 0) {
      this.setState({errorMessage: "Заполните поле \"Подтвердить пароль\""});
      return;
    }

    if (this.state.new !== this.state.confirm) {
      this.setState({errorMessage: "Новый пароль не совпадает"});
    } else {

      this.props.loading(true);

      API.put(url, data).then((data) => {
        
      }).catch(error => {
        const errorMessage = error && error.response && error.response.data && error.response.data.error ? error.response.data.error : "Ошибка обновления пароля";

        this.setState({errorMessage});
      }).finally(() => {
        this.props.loading(false);
        this.props.authFunc();
        this.props.close();
      })
    }
  }

  render() {
    return (
      <div>
        <ProfileModal title="" width="345px" closeModal={this.props.close}>
          <div className="password_modal">
            <div className="password_modal_title">
              <span>Сменить пароль</span>
            </div>

            <div className='password_modal_row'>
              <div className='password_modal_input_label'>
                <span>Старый пароль</span>
              </div>
              <div className='password_modal_input old_password'>
                <input type="password" value={this.state.old} onChange={(e) => this.setState({old: e.target.value})}/>
              </div>
            </div>

            <div className='password_modal_row'>
              <div className='password_modal_input_label'>
                <span>Новый пароль</span>
              </div>
              <div className='password_modal_input'>
                <input type="password" value={this.state.new} onChange={(e) => this.setState({new: e.target.value})}/>
              </div>
            </div>

            <div className='password_modal_row'>
              <div className='password_modal_input_label'>
                <span>Подтвердить пароль</span>
              </div>
              <div className='password_modal_input'>
                <input type="password" value={this.state.confirm} onChange={(e) => this.setState({confirm: e.target.value})}/>
              </div>
            </div>

            <div className='password_modal_row'>
              {this.state.errorMessage.length > 0 ? 
              <div className="password_modal_error">
                <span>{this.state.errorMessage}</span>
              </div> : ""}
            </div>

            <div className='password_modal_button_block'>
              <div onClick={this.props.close} className='password_modal_button_block_decline'>
                <span>Отменить</span>
              </div>
              <div onClick={() => this.updatePass()} className='password_modal_button_block_confirm'>
                <span>Сохранить</span>
              </div>
            </div>
          </div>

        </ProfileModal>
      </div>
    )
  }
}

export default PasswordModal;