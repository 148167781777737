import React from 'react';

import './index.css';

import bg from './images/main-header-l@2x.png';
import MainSearchBar from '../../main/v3/search-bar';

class CollectionsHeader extends React.Component {
    constructor (props) {
        super();

    }

    render() {
        const title = this.props.title ? this.props.title : 'W-Hub – это площадка, где живет образование' ;
        const subtitle = this.props.subtitle ? this.props.subtitle : 'Смотри онлайн-курсы, видеокурсы, вебинары и многое другое или размещай свой образовательный контент' ;

        return (
            <div className="collections_header">

                <div className="ch_bg">
                    <div className="ch_bg_l" />
                    <div className="ch_bg_r" />
                </div>

                <div className="ch_content">
                    <div className="ch_text">
                        <h1>{title}</h1>
                        <p>{subtitle}</p>

                        <img className="mini_bg" src={bg} alt="" />
                    </div>

                    <div className="ch_search"><MainSearchBar /></div>
                </div>
            </div>
        );
    }
}

export default CollectionsHeader;
