import React from 'react';

import './index.css';
import img from './img.png';
import {Link} from "react-router-dom";
import Button from "../../../utils/button";

class SchoolsInvitation extends React.Component {
    constructor (props) {
        super();
    }

    render() {

        return (
            <div className="schools_invitation main_centrer">
                <div className="si_inner">
                    <div className="si_pic">
                        <img src={img} alt="" />
                    </div>
                    <div className="si_text">
                        <h4>Есть чему научить? Регистрируйся на <nobr>w-hub</nobr> пользуйся инструментами площадки:</h4>
                        <p>+ создание обучающих курсов + создание видеокурсов + создание вебинаров + оформление старицы курса + рассылка писем и приглашение студентам + аналитика + встроенная система приема платежей + домашние задания + чаты </p>
                        <div className="btn_holder">
                            <Link to={'/signup/school'}><Button className="si_btn_invite" label="Создать курс" /></Link>
                        </div>

                    </div>
                </div>

            </div>
        );
    }
}

export default SchoolsInvitation;
