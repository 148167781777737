import React, {useState, useEffect} from 'react';

import {useParams} from 'react-router-dom';
import API from "../utils/api";

import PublicProfile from "./public-profile";
import Error404 from "../utils/404";

function UserPage(props) {
    let pageParams = useParams();

    const plug = {
        plug: true
    };
    const [loading, setLoading] = useState(true);
    const [userSlug, setUserSlug] = useState(null);
    const [userData, setUserData] = useState(plug);
    const [u404, setU404] = useState(false);

    useEffect(() => {
        statusChange();
        document.body.classList.add('with_background');
        return () => {
            document.body.classList.remove('with_background');
        }
    });

    const statusChange = () => {
        if (!userSlug || userSlug !== pageParams.slug) {
            if (!loading) {
                setLoading(true);
            }
            setUserSlug(pageParams.slug);
            loadData();
        }
    };

    const loadData = () => {
        setLoading(true);
        API.get('/whub/v1/users/' + pageParams.slug)
            .then(function (r) {
                setLoading(false);
                if (r.data.code && r.data.code === 404) {
                    setU404(true);
                }
                setUserData(r.data);
            })
            .catch(function (error) {
                setLoading(false);
                alert('что-то пошло не так...')
            });
    };

    const profile = !userData.plug && !u404 ? <PublicProfile {...props} userData={userData} user={props.userData ? props.userData : null}/> : '';
    const notFound = u404 ? <Error404 text="такого пользователея не существует." /> : '';

    return (
        <div className="public_profile_wrapper">
            {profile}
            {notFound}
        </div>
    );
}

export default UserPage;