import React from 'react';

import './index.css';

import girl from './girl.png';
import girlEllipse from './girl-ellipse.png';
import ellipse from './ellipse.svg';
import ellipse2 from './ellipse-2.svg';

class LPWelcome extends React.Component {
    constructor (props) {
        super();

        this.state = {
        };
    }

    componentDidMount = () => {
    };

    componentWillUnmount = () => {
    };

    render() {
        return (
            <div className="lp_welcome_wrapper">
                <div className="l_content">
                    <div className="lp_welcome_text">
                        <h1>Программа поддержки и&nbsp;развития онлайн-образования</h1>
                        <p>Чтобы поддержать компании в период кризиса, мы разработали специальную программу, дающую возможность бесплатного использования сервисов платформы <nobr>W-HUB</nobr></p>
                    </div>
                </div>
                <div className="lp_bg">
                    <div className="girl">
                        <img className="pic_ellipse" src={girlEllipse} alt={''} />
                        <img className="pic_girl" src={girl} alt={''} />
                    </div>

                    <div className="ellipse_1"><img src={ellipse} alt={''} /></div>
                    <div className="ellipse_2"><img src={ellipse2} alt={''} /></div>
                </div>
            </div>
        );
    }
}

export default LPWelcome;
