import React from "react";
import {Link, useParams} from "react-router-dom";

import './tabs.css';
import ReactTooltip from "react-tooltip";

function EditCourseTabs(props) {
    let pageParams = useParams();

    const data = props.courseData;
    const courseId = data.id;
    const courseUrl = '/course/' + courseId + '/edit/';

    const meta = data.meta;
    const isOnlineCourse = !!meta.date_start;

    const checkActiveTab = (tab) => {
        if (tab === pageParams.tab) {
            return 'active';
        }

        return '';
    };

    const status = props.tabsStatus;

    const tabFinishHint = isOnlineCourse ? 'добавьте описание курса<br />для доступа к этой вкладке' : 'добавьте описание курса и уроки<br />для доступа к этой вкладке'

    const tabDescriptions = status > 0 ? <Link to={courseUrl + 'descriptions'}>описание</Link> : <span data-tip="заполните вкладку «Общее»<br />для доступа к этой вкладке">описание</span>;
    const tabCovers = status > 0 ? <Link to={courseUrl + 'covers'}>обложка</Link> : <span data-tip="заполните вкладку «Общее»<br />для доступа к этой вкладке">обложка</span>;
    const tabLanding = status > 0 ? <Link to={courseUrl + 'landing'}>страница курса</Link> : <span data-tip="заполните вкладку «Общее»<br />для доступа к этой вкладке">страница курса</span>;
    const tabLessons = status > 0 ? <Link to={courseUrl + 'lessons'}>уроки</Link> : <span data-tip="заполните вкладку «Общее»<br />для доступа к этой вкладке">уроки</span>;

    const tabPrice = status > 0 && props.userData.school_data_completed ? <Link to={courseUrl + 'price'}>цена</Link> : <span data-tip="завершите регистрацию школы<br />для доступа к этой вкладке">цена</span>;
    //const tabAdvert = status > 1 ? <Link to={courseUrl + 'advert'}>реклама</Link> : <span data-tip="добавьте уроки<br />для доступа к этой вкладке">реклама</span>;
    const tabFinish = status > 1 ? <Link to={courseUrl + 'finish'}>публикация</Link> : <span data-tip={tabFinishHint}>публикация</span>;

    // useEffect(() => {
    //     console.log(props.courseStatus)
    // }, [status]);

    return (
        <ul className="cc_progress">
            <li className={checkActiveTab('general')}><Link to={courseUrl + 'general'}>общее</Link></li>
            <li className={checkActiveTab('descriptions')}>{tabDescriptions}</li>
            <li className={checkActiveTab('covers')}>{tabCovers}</li>
            <li className={checkActiveTab('landing')}>{tabLanding}</li>
            <li className={checkActiveTab('lessons')}>{tabLessons}</li>
            <li className={checkActiveTab('price')}>{tabPrice}</li>
            {/*<li className={checkActiveTab('advert')}>{tabAdvert}</li>*/}
            <li className={checkActiveTab('finish')}>{tabFinish}</li>
            <ReactTooltip effect={'solid'} multiline={true} />
        </ul>
    );
}

export default EditCourseTabs;