import React from "react";
import {CheckboxTick} from "../icons";

import './checkbox.css';

class Checkbox extends React.Component {
    constructor (props) {
        super();
    };

    handleChange = e => {
        if (this.props.onChange) {
            this.props.onChange(e);
        }
    };

    render() {
        const label = this.props.label.type === 'span' ? this.props.label : <span>{this.props.label}</span>;
        const legacyClassName = this.props.className ? this.props.className : '';
        const cls_checked = this.props.checked ? 'checked' : '';
        const cls_disabled = this.props.disabled ? 'disabled' : '';
        return (
            <div className={'checkbox ' + legacyClassName + ' ' + cls_checked + ' ' + cls_disabled}>
                <label>
                    <input {...this.props} type="checkbox" checked={this.props.checked} onChange={this.handleChange} />
                    <ins><CheckboxTick /></ins>
                    {label}
                </label>
            </div>
        )
    }
}

export default Checkbox;