import React from 'react';

import { Link } from 'react-router-dom';
import {html2plaintext, Plural} from "../../../../../utils/utils";

class ParamSchool extends React.Component {
    constructor (props) {
        super();

        this.state = {
        };
    }

    render() {
        const t = this;
        const p = t.props;
        const data = p.data;
        const schoolData = data.author_data;

        const title = html2plaintext(schoolData.slug);
        const firstLetter = title ? title[0].toUpperCase() : '';

        const slug = schoolData.slug;

        const userpic = schoolData.avatar.length ? <img src={schoolData.avatar} alt="" /> : <span>{firstLetter}</span>;

        const withAvatar = schoolData.avatar.length;

        const courseCount = schoolData.publications > 1 ? <Link to={'/users/' + slug}>{schoolData.publications} курс{Plural(schoolData.publications, '', 'а', 'ов')}</Link> : ''

        return (
            <div className="params_section">
                <h3>Автор курса</h3>

                <div className="cl_param_school">
                    <div style={withAvatar ? {'backgroundColor': 'none'} : {}} className="userpic"><Link to={'/users/' + slug}>{userpic}</Link></div>
                    <div className="title"><Link to={'/users/' + slug}>{title}</Link></div>
                    <div className="courses_count">{courseCount}</div>
                </div>
            </div>
        );
    }
}

export default ParamSchool;
