import React from "react";
import {Star} from "../icons";
import './index.css';

class StarRating extends React.Component {
	constructor (props) {
		super();
	}

	handleChange = e => {
		if (this.props.onChange) {
			this.props.onChange(e);
		}
	};

	renderStars = () => {
		let arr = [];

		const defaultLabel = {
			5: 'Отлично - 5 звёзд',
			4: 'Очень хорошо - 4 звезды',
			3: 'Средне - 3 звезды',
			2: 'Плохо - 2 звезды',
			1: 'Ужасно - 1 звезда'
		};

		let i = 5;
		while (i > 0) {
			arr.push(<input {...this.props} onChange={this.handleChange} type="radio" id={'star'+i} checked={i === parseInt(this.props.value)} value={i} key={i}/>);
			arr.push(<label htmlFor={'star'+i} title={defaultLabel[i]} key={'l' + i}><Star /></label>);
			i--;
		}
		return arr;
	};

	render() {

		return (
			<div className="star-rating__container" ref={this.props.name}>
				<div className={'star-rating__label'}>{this.props.label}</div>
				<div className="star-rating">
					{this.renderStars()}
				</div>
			</div>
		)
	}
}

export default StarRating;