import React from 'react';

import './index.css';
import {ArrowPrev} from "../../../utils/icons";

class MainCerts extends React.Component {
  constructor(props) {
    super(props);

    this.feedWrapper = React.createRef();
    this.feed = React.createRef();

    this.state = {
      activeTab: props.defaultTab ? props.defaultTab : 'video',
      curStep: 0,
      stepSize: 200,
      prevHidden: true,
      nextHidden: false,
      showImage: null,
    };
  }

  initialArrowsCheck = () => {
    const t = this;
    const p = t.props;
    const s = t.state;

    const wrapper = t.feedWrapper.current;
    const wrapperWidth = wrapper.clientWidth;
    const feed = t.feed.current;
    let feedWidth = feed.clientWidth;

    if (window.innerWidth <= 600) {
        feedWidth -= Math.floor(window.innerWidth/10);
    }

    let itemsLength = p.data.length;

    const itemWidth = feedWidth/itemsLength;
    const itemsVisible = Math.round(wrapperWidth/itemWidth);

    if (itemWidth) {
        t.setState({stepSize: itemWidth})
    }

    if (itemsLength <= itemsVisible && s.curStep === 0) {
        t.setState({prevHidden: true, nextHidden: true});
    }
    else {
        if (s.curStep <= 0) {
            t.setState({prevHidden: true, nextHidden: false});
        }
        else if (s.curStep >= itemsLength - itemsVisible) {
            t.setState({prevHidden: false, nextHidden: true});
        }
        else {
            t.setState({prevHidden: false, nextHidden: false});
        }
    }
  };

  slideNext = (dir) => {
    const t = this;
    const p = t.props;
    const s = t.state;

    const wrapper = t.feedWrapper.current;
    const wrapperWidth = wrapper.clientWidth;
    const feed = t.feed.current;
    let feedWidth = feed.clientWidth;

    if (window.innerWidth <= 600) {
        feedWidth -= Math.floor(window.innerWidth/10);
    }

    let itemsLength = p.data.length;

    const itemWidth = feedWidth/itemsLength;
    const itemsVisible = Math.round(wrapperWidth/itemWidth);

    let next = s.curStep + dir;

    if ((next >= 0 && next <= itemsLength - itemsVisible) || (s.curStep > 0 && dir === -1)) {
        t.setState({curStep: next}, t.initialArrowsCheck);
    }
  };

  moveToLink = (link) => {
    if (link) {
      window.open(link);
    }
  }

  openImage = (img) => {
    console.log(this.state.showImage);
    if (img) {
      document.body.style.overflow="hidden";
      this.setState({showImage: img});
    }
  }

  closeImage = () => {
    document.body.style.overflow="scroll";
    this.setState({showImage: null});
  }

  certsMapper = (item, idx) => {
    const background = item.preview;
    const link = !item.image && item.link ? item.link : null;
    const image = item.image ? item.image : null;

    return (
      <div 
        onClick={() => {link ? this.moveToLink(link) : this.openImage(image)}}
        key={idx}
        style={{backgroundImage: `url(${background})`}} className="mc_item">
      </div>
    )
  }

  componentDidMount = () => {
    this.initialArrowsCheck();
    window.addEventListener('resize', this.initialArrowsCheck);
  };

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.initialArrowsCheck);
  }

  render() {
    const t = this;
    const s = t.state;
    const {data} = this.props;

    const arrPrev = !s.prevHidden ? <div onClick={() => this.slideNext(-1)} className="arr arr_prev"><i><ArrowPrev /></i></div> : '';
    const arrNext = !s.nextHidden ? <div onClick={() => this.slideNext(1)} className="arr arr_next"><i><ArrowPrev /></i></div> : '';

    const style = {left: s.curStep * s.stepSize * -1};

    return (
      <div className="main_certs_wrapper main_centrer">
        { s.showImage ?
        <div onClick={() => {this.closeImage()}} className="mc_image_background">
          <div style={{backgroundImage: `url(${s.showImage})`}} className="mc_image_content"></div>
        </div> : ""}

        <div className="mc_feed_outer">
          {arrPrev}
          {arrNext}
          <div className="mc_feed_wrapper" ref={t.feedWrapper}>
            <div style={style} className="mc_feed" ref={t.feed}>
              {data.map(t.certsMapper)}
            </div>
          </div>
        </div>
      </div>
    )
  }
}


export default MainCerts;
