import React from 'react';
import {Link} from 'react-router-dom';

import logo from '../../header-v2/logo.png'


import '../experts.css';
import Preloader from "../../utils/preloader";
import {Plural} from "../../utils/utils";

class UserExperts extends React.Component {
  constructor (props) {
    super();
    this.state = {
        loading: true,
        schools: [],
    };
  }

  schoolsMapper = (item, idx) => {
    const avatar = item.avatar.length ? item.avatar : logo;
    const type = item.type.length ? item.type : 'Онлайн-школа';

    return (
        <div className="user_experts_item" key={idx}>
            <Link to={'/users/' + item.slug}>
                <div className="user_experts_avatar"><img src={avatar} alt="" /></div>
                <h5>{item.name}</h5>
                <h6>{type}</h6>
            </Link>

            <p className="user_experts_count">
                <Link to={'/users/' + item.slug}>{item.courses_count} курс{Plural(item.courses_count, '', 'а', 'ов')}</Link>
            </p>

        </div>

    );
};

  componentDidMount() {
    const schools = this.props.userData.subscriptions.school_data;
    this.setState({loading: false, schools});
  }

  render() { 
    const preloader = this.state.loading ? <Preloader/> : '';

    return (<div>
      {preloader}
      <div className="user_experts_container">
        {this.state.schools.map(this.schoolsMapper)}
      </div>
    </div>)
  }
}

export default UserExperts;