import React from 'react';

import './index.css';

import add from './add.svg';
import arrow from './arrow.svg';
import coins from './coins.svg';
import create from './create.svg';
import user from './new-user.svg';
import doc from './doc.svg';
import {reachGoalLink} from "../../../../utils/utils";

class LSHowItWorks extends React.Component {
    constructor (props) {
        super();

        this.state = {
        };
    }

    componentDidMount = () => {
    };

    componentWillUnmount = () => {
    };

    render() {
        return (
            <div className="ls_how_it_works_wrapper">
                <div className="l_content">
                    <h2>Как это работает?</h2>

                    <ul className="plates">
                        <li>
                            <div className="img_wrapper">
                                <div className="num"><span>1</span></div>
                                <img src={arrow} alt={''} />
                                <div className="dots"><i></i><i></i><i></i><i></i><i></i></div>
                            </div>
                            <p>Зайди на сайт</p>
                        </li>
                        <li>
                            <div className="img_wrapper">
                                <div className="num"><span>2</span></div>
                                <img src={user} alt={''} />
                                <div className="dots"><i></i><i></i><i></i><i></i><i></i></div>
                            </div>
                            <p>Зарегистрируйся</p>
                        </li>
                        <li>
                            <div className="img_wrapper">
                                <div className="num"><span>3</span></div>
                                <img src={add} alt={''} />
                                <div className="dots"><i></i><i></i><i></i><i></i><i></i></div>
                            </div>
                            <p>Заполни профиль</p>
                        </li>
                        <li>
                            <div className="img_wrapper">
                                <div className="num"><span>4</span></div>
                                <img src={create} alt={''} />
                                <div className="dots"><i></i><i></i><i></i><i></i><i></i></div>
                            </div>
                            <p>Создай и опубликуй курс</p>
                        </li>
                        <li>
                            <div className="img_wrapper">
                                <div className="num"><span>5</span></div>
                                <img src={coins} alt={''} />
                            </div>
                            <p>Получай доход от продаж</p>
                        </li>
                    </ul>

                    <div className="download_manual">
                        <a href="/docs/whub-manual-school.pdf" target="_blank" rel="noopener noreferrer" data-tag="80212912" data-goal="download_manual" onClick={reachGoalLink}>
                        <img src={doc} alt={''} />
                        <p>Разместить курс просто</p>
                        <span>Скачать инструкцию</span>
                        </a>
                    </div>
                </div>
                <div className="ls_bg"></div>
            </div>
        );
    }
}

export default LSHowItWorks;
