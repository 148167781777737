import React from "react";
import TextareaAutosize from "react-autosize-textarea";
import EditCoursePriceWidget from "./price-widget";

class EditCoursePrice extends React.Component {
    constructor (props) {
        super();
    }

    render () {
        const p = this.props;

        return (
            <div className="ceb_price">

                <div className="course_name_block">
                    <TextareaAutosize spellCheck="false" className="course_name_input" disabled={true} value={p.courseData.title} name="courseName" placeholder="название курса" />
                </div>

                <EditCoursePriceWidget {...p} />
            </div>
        );
    }
}

export default EditCoursePrice;