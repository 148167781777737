import React, {useEffect, useState} from 'react';

import {useParams} from 'react-router-dom';


import './index.css';
import MyCourses from "../profile/school/my-courses";
import SchoolData from "../profile/finish-signup";
import Profile from "../profile";
import SchoolStatistics from "../profile/school/stats";
// import PurchasedCourses from "../profile/student/purchased";
// import Favourites from "../profile/student/favourites";
// import ArchivedCourses from "../profile/student/archived";
// import Entries from "../profile/student/entries";
import Posts from "../profile/school/posts";
import SchoolDashboardLinks from "./school-dashboard-links";
import StudentDashboardLinks from "./student-dashboard-links";
import Help from "../about/help";
import MyOnlineCourses from "../profile/school/my-online-courses";
import Students from "../profile/school/students";
import Reviews from "../profile/school/reviews";
import Experts from "../profile/school/experts";
import SchoolHomeworks from "../profile/school/homework";
import SchoolChats from "../profile/school/chat";
import SchoolFiles from "../profile/school/files";
import UserNotifications from "../profile/student/notifications";
import UserExperts from "../profile/student/experts";
import StudentEducation from '../profile/student/education';
import StudentReading from '../profile/student/reading';
import ContinueEducation from '../profile/student/continue';
import StudentPayments from '../profile/student/payments';

function User(props) {
    const userData = props.userData;

    const [showMenu, setShowMenu] = useState(false);

    const showMobileMenu = () => {
      setShowMenu(!showMenu);
    }

    const hideMobileMenu = () => {
      setShowMenu(false);
    }

    let pageParams = useParams();

    let schoolCompleted = userData.school_data_completed;
    const schoolData = schoolCompleted ? JSON.parse(userData.additional_data) : {};
    const schoolSZ = schoolCompleted && schoolData.company_type === 'sz';
    const schoolSZChecked = userData.sz_inn_status;
    const isStudent = userData.role === 'student';

    if (schoolSZ && !schoolSZChecked) {
        schoolCompleted = false;
    }

    const links = userData.role === 'school' ? <SchoolDashboardLinks {...props} hideMenu={hideMobileMenu} schoolCompleted={schoolCompleted} /> : <StudentDashboardLinks hideMenu={hideMobileMenu} {...props} />;

    const pageInDevelopment = <div className="profile_development_link">Раздел в стадии разработки.</div>
    const pageLocked = <div className="profile_locked_page">404. Страница не найдена</div>

    const profileMap = {
        'profile': <Profile {...props} />,
        'files': <SchoolFiles {...props} />,
        'school-data': <SchoolData {...props} />,
        'courses': isStudent ? pageLocked : <MyCourses {...props} />,
        'online-courses': <MyOnlineCourses {...props} />,
        'posts': <Posts {...props} />,
        'students': <Students {...props} />,
        'experts': isStudent ? <UserExperts {...props} /> : <Experts {...props} />,
        'reviews': <Reviews {...props} />,
        'homework': <SchoolHomeworks {...props} />,
        'chat': <SchoolChats {...props} />,
        'stats': <SchoolStatistics {...props} />,
        'help': <Help {...props} />,


        // 'favourites': <Favourites {...props} />,
        // 'purchased': <PurchasedCourses {...props} />,
        // 'entries': <Entries {...props} />,
        // 'archived': <ArchivedCourses {...props} />,
        'notifications': <UserNotifications {...props} />,

        'favourites': <StudentEducation tab="favourites" {...props} />,
        'purchased': <StudentEducation tab="purchased" {...props} />,
        'entries': <StudentEducation tab="entries" {...props} />,
        'archived': <StudentEducation tab="archived" {...props} />,
        'continue-education': <StudentEducation tab="continue-education" {...props} />,
        'finished': <StudentEducation tab="finished" {...props} />,

        'calendar': pageInDevelopment,
        'certificates': pageInDevelopment,
        'specialists': pageInDevelopment,
        'reading': <StudentReading {...props} />,
        'continue': <ContinueEducation {...props} />,
        'payments': <StudentPayments {...props } />,

    };


    useEffect(() => {
        document.body.classList.add('with_background');
        document.body.classList.add('profile_dashboard');
        return () => {
            document.body.classList.remove('with_background');
            document.body.classList.remove('profile_dashboard');
        }
    });

    return (
        
        <div className="profile_wrapper">
            <div className="mobile_switcher" onClick={showMobileMenu}></div>
            <div className="profile_inner">
                <div className={"profile_nav" + (showMenu ? " mobile_open" : "")}>
                    {links}
                </div>
                {profileMap[pageParams.profilePage]}
            </div>

        </div>
    );
}

export default User;