import React from 'react';
import {Link} from "react-router-dom";
import './stats.css';
import {obj2arr, PriceFormat} from "../../utils/utils";
import API from "../../utils/api";

class SchoolStatistics extends React.Component {
    constructor (props) {
        super();

        this.state = {
            activeTab: 'purchased',
            statsData: {}
        }
    }

    componentDidMount() {
        this.loadStatsData();
    };

    loadStatsData = () => {
        const t = this;
        API.get('/whub/v1/actions')
            .then(function (r) {
                if (Object.keys(r.data).length) {
                    t.processStatsData(r.data);

                    if (!r.data.purchased && r.data.favorites) {
                        t.setState({activeTab: 'favorites'})
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    processStatsData = (d) => {
        const t = this;
        Object.keys(d).forEach((key) => {
            const statsArray = obj2arr(d[key]);
            const statsObj = t.state.statsData;
            statsObj[key] = statsArray;
            t.setState({statsData: statsObj});
        });
    };

    checkActiveTabs = (tab) => {
        if (tab === this.state.activeTab) {
            return 'pseudo_link active';
        }
        return 'pseudo_link';
    };

    render() {
        const t = this;
        const s = t.state;
        const ud = t.props.userData;

        const coursesPublished = ud.courses_created.publish ? <dl>
            <dt>Курсов опубликовано:</dt>
            <dd>{ud.courses_created.publish.length}</dd>
        </dl> : '';

        const salesAmmount = ud.sales.amount;
        const earnings = salesAmmount - salesAmmount*.15;

        const basicStats = <div className="school_stats">
            <h2>Общее:</h2>

            {coursesPublished}

            <dl>
                <dt>Курсов продано:</dt>
                <dd>{ud.sales.count}</dd>
            </dl>

            <dl>
                <dt>Денег заработано:</dt>
                <dd>{PriceFormat(earnings)} ₽ <span>за вычетом 15% комиссии W-Hub</span></dd>
            </dl>
        </div>;

        const interactionStatsList = s.statsData[s.activeTab] ? s.statsData[s.activeTab].map((row, idx) => {
            return <li key={idx}>
                <span className="sl_title"><Link to={'/course/' + row.slug}>{row.title}</Link></span>
                <span className="sl_count">{Object.keys(row.users).length}</span>

                </li>
        }) : '';

        const switcherPurchased = s.statsData.purchased ? <span onClick={() => t.setState({activeTab: 'purchased'})} className={t.checkActiveTabs('purchased')}>покупка</span> : '';
        const switcherFavorites = s.statsData.favorites ? <span onClick={() => t.setState({activeTab: 'favorites'})} className={t.checkActiveTabs('favorites')}>избранное</span> : '';


        return (
            <div className="stats_wrapper">
                {basicStats}

                <div className="interaction_stats">
                    <h2>Конверсии:</h2>
                    <h5>
                        {switcherPurchased}
                        {switcherFavorites}
                    </h5>
                    <ul className="interaction_list">
                        <li className="list_header">
                            <span className="sl_title">курс:</span>
                            <span className="sl_count">студенты:</span>
                        </li>
                        {interactionStatsList}
                    </ul>
                </div>

                {/*<div className="interaction_stats">*/}
                    {/*<h2>Траффик:</h2>*/}
                    {/*<ul className="interaction_list">*/}
                        {/*<li className="list_header">*/}
                            {/*<span className="sl_title">курс:</span>*/}
                            {/*<span className="sl_count">просмотров:</span>*/}
                        {/*</li>*/}
                    {/*</ul>*/}
                {/*</div>*/}
            </div>

        );
    }
}

export default SchoolStatistics;