import React from 'react';

import './index.css';
import UPWItem from "./upw-item";

class UploadProgressWidget extends React.Component {
    constructor (props) {
        super();
    }

    render() {
        const p = this.props;

        const queueData = p.uploadQueueData;
        const progressData = p.uploadProgressData;

        const queue = queueData.map((el) =>
            <UPWItem {...p}
                     key={el.lesson_id}
                     title={el.lesson_title}
                     lessonId={el.lesson_id}
                     file={el.file}
                     uploadObj={el}
                     progress={progressData[el.lesson_id]}/>
        );

        return (
            <div className="upload_progress_widget">
                <h3>идет загрузка в облако...</h3>

                <ul>
                    {queue}
                </ul>
            </div>
        );
    }
}

export default UploadProgressWidget;
