import React from 'react';

import './index.css';

import authors from './authors.svg';
import entrepreneur from './entrepreneur.svg';
import legal from './legal.svg';
import producers from './producers.svg';
import schools from './schools.svg';
import self from './self-employed.svg';
import teachers from './teachers.svg';
import you from './you.svg';
import {Link} from "react-router-dom";

class LSWhoCanUse extends React.Component {
    constructor (props) {
        super();

        this.state = {
        };
    }

    componentDidMount = () => {
    };

    componentWillUnmount = () => {
    };

    render() {
        return (
            <div className="ls_who_can_use_wrapper">
                <div className="l_content">
                    <h2>Кто может размещать свои курсы на <nobr>W-HUB</nobr>?</h2>

                    <ul className="plates">
                        <li>
                            <img src={you} alt={''} />
                            <p>Ты</p>
                        </li>
                        <li>
                            <img src={schools} alt={''} />
                            <p>Онлайн-школы</p>
                        </li>
                        <li>
                            <img src={authors} alt={''} />
                            <p>Авторы</p>
                        </li>
                        <li>
                            <img src={teachers} alt={''} />
                            <p>Учителя</p>
                        </li>
                        <li>
                            <img src={producers} alt={''} />
                            <p>Продюсеры</p>
                        </li>
                        <li>
                            <img src={legal} alt={''} />
                            <p>Юридические лица</p>
                        </li>
                        <li>
                            <img src={entrepreneur} alt={''} />
                            <p>ИП</p>
                        </li>
                        <li>
                            <img src={self} alt={''} />
                            <p>Самозанятые</p>
                        </li>
                    </ul>

                    <div className="button_holder">
                        <Link to={'/signup/school'}><button>Присоединиться</button></Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default LSWhoCanUse;
