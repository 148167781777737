import React from 'react';
import './index.css';
import {LessonFold, LessonUnfold} from "../../icons";

class FAQ extends React.Component {
    constructor (props) {
        super();

        this.state = {
            opened: []
        };
    }

    switchFoldItem = (itemIdx) => {
        const t = this;
        const {opened} = t.state;
        const idx = opened.indexOf(itemIdx);

        if (idx === -1) {
            opened.push(itemIdx);
        }
        else {
            opened.splice(idx, 1);
        }

        t.setState({opened: opened});
    };

    faqMapper = (item, idx) => {
        const t = this;
        const {opened} = t.state;
        const isOpened = opened.indexOf(idx) > -1;

        const foldIcon = isOpened ? <LessonFold /> : <LessonUnfold />;
        const cls_opened = isOpened ? 'opened' : '';

        return (
            <dl key={idx} className={'faq_item ' + cls_opened}>
                <dt onClick={() => t.switchFoldItem(idx)}>
                    {item.question}

                    <div className="fold_icon">{foldIcon}</div>
                </dt>
                <dd>{item.answer}</dd>
            </dl>
        );
    };

    render() {
        const t = this;
        const p = t.props;
        const faq = p.data.meta.faq;


        return (
            <div className="cl_faq">
                <div className="cl_centrer">
                    <h2>Часто задаваемые вопросы</h2>

                    <div className="faq_block">
                        {faq.map(t.faqMapper)}
                    </div>
                </div>
            </div>
        );
    }
}

export default FAQ;
