import React from 'react';
import Spinner from "../utils/spinner";
import API from "../utils/api";
import BlogItem from "../course-snippets/blog/item";

class ArticlesPage extends React.Component {
    constructor (props) {
        super();

        this.state = {
            loading: false,
            articles: []
        };
    }

    componentDidMount() {
        this.loadArticles()
    }

    loadArticles = () => {
        const t = this;

        t.setState({loading: true});

        API.get('/whub/v3/post')
            .then((r) => {
                t.setState({loading: false, articles: r.data});
            })
            .catch((error) => {
                t.setState({loading: false});
                console.log(error);
            });
    };

    render() {
        const t = this;
        const {loading, articles} = t.state;

        const feed = articles.map((article, idx) => {
            return <BlogItem {...this.props} post={article} key={article.id} itemId={idx} />;
        });

        return (
            <div className="search_results search_articles">
                <h1>Блог</h1>

                {feed}

                <Spinner show={loading} />
            </div>
        );
    }
}

export default ArticlesPage;
