import React from 'react';
import './index.css';

class Requirements extends React.Component {
    constructor (props) {
        super();

        this.state = {
        };
    }

    render() {

        return (
            <div className="cl_requirements">
                <div className="cl_centrer">
                    <h2>Вам понадобится</h2>

                    <div className="requirements_block">
                        {this.props.data.meta.requirements}
                    </div>
                </div>
            </div>
        );
    }
}

export default Requirements;
