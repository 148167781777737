import {obj2arr} from "../../utils/utils";

export const sortLessonsByType = (lessons) => {
    const output = {};
    let duration = 0;

    lessons.forEach((el) => {
        const type = el.type || 'video';
        if (output[type]) {
            output[type].count += 1;
        }
        else {
            output[type] = {
                count: 1,
                type: type
            };
        }

        if (el.duration) {
            duration += el.duration;
        }
    });

    return {
        sorted: output,
        sortedArr: obj2arr(output),
        duration: duration
    };
};

export const timeConverter = (m) => {
    const hours = m / 60;
    const rHours = Math.floor(hours);
    const minutes = (hours - rHours) * 60;
    const rMinutes = Math.round(minutes);

    return {hours: rHours, minutes: rMinutes};
};