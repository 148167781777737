import React from 'react';
import { Link } from 'react-router-dom';
import API from '../../utils/api';

import './index.css';
import {DropdownArrow, TipPoint} from "../../utils/icons";
import {html2plaintext, logout} from "../../utils/utils";
import AuthorizedPlus from "./plus";

import resumeIcon from '../../user/ico/play.svg';
import notifIcon from '../../user/ico/notification.svg';

class AuthorizedUser extends React.Component {
    constructor (props) {
        super();
        this.state = {
            opened: false,
            unread: 0,
        }
    }

    toggleOpened = () => {
        this.setState({opened: !this.state.opened});
    };

    collapse = () => {
        this.setState({opened: false});
    };

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClick, false);

        API.get('/whub/v3/notification/').then(result => {
          const { data } = result;
          this.setState({ unread: data.unread});
        }).catch(error => console.log(error));
    };
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClick, false);
    };

    handleClick = (e) => {
        e.stopPropagation();
        if (this.state.opened) {
            if (this.node.contains(e.target)) {
                return;
            }
            this.collapse();
        }
    };

    processLogout = (e) => {
        const t = this;
        const p = t.props;

        e.preventDefault();

        p.closeFunc();

        logout(p.authFunc);

    };

    onCloseHandler = () => {
      this.setState({opened: false});
      this.props.closeFunc();
    }

    openLink = (url) => {
      window.location = url;
    }

    render() {
        const t = this;
        const p = t.props;

        const data = p.userData;
        const isTester = data.tester;
        const hasBlog = data.blog_posts && data.blog_posts.length;
        const isSchool = data.role === 'school';
        const isStudent = data.role === 'student';
        const isUserProfilePage = window.location.pathname.includes('/user/');
        const hasElka = data['elka_registered'];

        const elkaUrls = {
            social: '/dedmorozonline',
            commercial: '/greeting'
        };

        let outputName = data.name.length ? data.name : data.email;
        
        if (data.display_name && data.display_name.length) {
            outputName = data.display_name;
        }

        outputName = data.slug;

        const cls_opened = t.state.opened ? 'opened' : '';

        const elkaLink = hasElka ? <Link onClick={t.onCloseHandler} to={elkaUrls[hasElka]} className="red">Елка!</Link> : '';

        const profileLink =  <Link onClick={t.onCloseHandler} to={'/user/profile'}>Профиль</Link>;

        const coursesLink = isSchool ? <Link onClick={t.onCloseHandler} to={'/user/courses'}>Курсы</Link> : '';
        const blogLink = isTester && hasBlog ? <Link onClick={t.onCloseHandler} to={'/user/posts'}>Статьи</Link> : '';

        const purchasedLink = isStudent && <Link onClick={t.onCloseHandler} to={'/user/purchased'}>Мое обучение</Link>;
        const favouritesLink = isStudent && <Link onClick={t.onCloseHandler} to={'/user/favourites'}>Избранное</Link>;

        const plus = isSchool ? <AuthorizedPlus {...p} tester={isTester} /> : '';

        const avatar = data.avatar ? <div className="avatar"><img src={data.avatar} alt="" /></div> : <span>{outputName[0]}</span>;

        const notifCounter = t.state.unread ? <span className="ua_notif_counter">{t.state.unread}</span> : '';

        const notifBtn = !isUserProfilePage ? <Link className={ isSchool ? "ua_resume ua_menu_btn" : "ua_notif ua_menu_btn"} to={'/user/notifications'}>
          <img alt="notif" style={t.state.unread ? {marginLeft: '6px'} : {}} src={notifIcon} />
          {notifCounter}
        </Link> : ""

        const resumeBtn = !isUserProfilePage && isStudent ? <Link className="ua_resume ua_menu_btn" to={'/user/continue'}>
          <img alt="resume" src={resumeIcon} />
        </Link> : ""

        return (
            <div className="user_authorized">
                <div ref={node => t.node = node} className={'ua_username ' + cls_opened}>
                {notifBtn}
                {resumeBtn}
                    <div className="ua_userpic">{avatar}

                        <div className="ua_dropdown">
                            <TipPoint />
                            <div className="dropdown_list">
                                {elkaLink}
                                {purchasedLink}
                                {favouritesLink}
                                {profileLink}

                                {coursesLink}
                                {blogLink}

                                <a href="/" onClick={t.processLogout} className="ua_logout">Выход</a>
                            </div>
                        </div>
                    </div>
                    <span className="ua_username_text" onClick={t.toggleOpened}>{html2plaintext(outputName)}</span>
                    <DropdownArrow />

                </div>

                {plus}
            </div>
        );
    }
}

export default AuthorizedUser;