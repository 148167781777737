import React from 'react';
import { Link } from 'react-router-dom';
import {html2plaintext} from "../../../utils/utils";

import './index.css';
// import API from "../utils/api";
import SimpleDropdown from "../../../utils/simple-dropdown";
import Spinner from "../../../utils/spinner";
import API from "../../../utils/api";

class PostPreview extends React.Component {
    constructor (props) {
        super();

        const data = props.itemData;
        this.state = {
            id: data.id,
            status: data.status ? data.status : 'draft',
            loading: false
        }
    }

    handleClick = () => {
        if (this.props.closeFunc) {
            this.props.closeFunc();
        }
    };

    handlePublish = () => {
        const t = this;
        const {status, id} = t.state;
        const newStatus = status === 'publish' ? 'draft' : 'publish';

        const formData = new FormData();
        formData.append('status', newStatus);

        if (this.props.closeFunc) {
            this.props.closeFunc();
        }

        t.setState({loading: true});
        API.post('/whub/v3/post/' + id, formData)
            .then(function (r) {
                console.log(r)

                t.setState({loading: false, status: newStatus}, t.validate);
            })
            .catch(function (error) {
                console.log(error.response);
                t.setState({loading: false});
            });
    };


    render() {
        const t = this;
        const {loading, status} = t.state;
        const {itemData, linkType} = t.props;

        const url = '/blog/' + itemData.id;

        let title = itemData.title;

        if (title && title.length && title.length > 50) {
            title = title.substring(0,50) + '...';
        }

        const bgStyle = {backgroundColor: '#434A5C'};
        const patternPlug = <div className="img_plug" style={bgStyle}>&nbsp;</div>;

        const cover = itemData.images_stack && itemData.images_stack.cover ? itemData.images_stack.cover.normal : null;
        const img = cover ? <img src={cover} alt="" /> : '';

        let teaser = '';

        if (itemData.description && itemData.description.length) {
            teaser = <p className="teaser">{html2plaintext(itemData.description)}</p>;
        }

        const isPublished = status === 'publish';
        let linksDropdownList = [];
        if (linkType) {

            if (linkType === 'edit') {
                linksDropdownList.push(<span key="k0" onClick={this.handlePublish}>{isPublished ? 'Скрыть' : 'Опубликовать'}</span>);
                linksDropdownList.push(<Link key="k1" onClick={this.handleClick} to={url + '/edit'}>Редактировать</Link>);
            }
        }

        const linksDropdownButton = <div className="ld_button"><ins><i></i><i></i><i></i></ins></div>
        const linksDropdown = linksDropdownList.length ? <SimpleDropdown className="cp_links_dropdown" list={linksDropdownList} clickArea={linksDropdownButton} /> : '';


        return (
            <div className="course_preview_v2">
                <Link onClick={this.handleClick} to={url} className="course_preview_link">
                    <div className="img_wrapper">
                        {patternPlug}
                        {img}
                    </div>
                </Link>

                <Link onClick={this.handleClick} to={url} className="course_preview_link">
                    <h3>{html2plaintext(title)}</h3>
                    {teaser}
                </Link>

                <Spinner show={loading} />
                {linksDropdown}
            </div>

        );
    }
}

export default PostPreview;
