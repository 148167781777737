import React from 'react';
import './index.css';

class RadioDefault extends React.Component {
    constructor(props) {
        super();
    }

    handleChange = () => {
        const p = this.props;
        p.onChange(p.name, p.value);
    };

    render() {
        const t = this;
        const p = t.props;

        const cls_checked = p[p.name] === p.value ? 'checked' : '';

        return(
            <div className={'radio_wrapper radio_default ' + cls_checked} onClick={t.handleChange}>
                <div className="radio_box"> </div>
                <div className="radio_label">{p.label}</div>
            </div>
        )
    }
}

export default RadioDefault;