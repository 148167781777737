import React from 'react';
import { Link } from 'react-router-dom';

import logo from './logo.png';
import {SearchIcon2, UserSidebar} from "../utils/icons";

import './index.css';
import Sidebar from "../sidebar";
import UserHeader from "./user";
import SearchBar from "../search-v2/search-bar";
import HeaderCategories from "./categories";

class HeaderV2 extends React.Component {
    constructor (props) {
        super();
        this.state = {
            focused: false,
            searchRequest: '',
            sidebarOpened: false,
            sidebarHide: true,
            dataLoaded: null,
            loading: false,
            showSearch: false
        };
    }

    toggleSidebar = () => {
        const t = this;
        if (t.state.sidebarOpened) {
            t.closeSidebar();
        }
        else {
            t.setState({sidebarOpened: true, focused: false, searchRequest: ''});
        }
    };

    closeSidebar = () => {
        const t = this;
        t.setState({sidebarHide: true});
        setTimeout(() => {
            t.setState({sidebarOpened: false});
        }, 400);
    };

    showSidebar = () => {
        this.setState({sidebarHide: false});
    };

    handleFocus = () => {
        if (!this.state.focused) {
            this.setState({focused: true});
        }
        this.closeSidebar();
    };

    handleBlur = () => {
        this.setState({focused: false, searchRequest: '', dataLoaded: null, loading: false});
    };

    handleClose = () => {
        this.handleBlur();
        this.closeSidebar();
    };

    componentDidUpdate () {

    }

    render() {
        const t = this;
        const s = t.state;
        const p = t.props;
        const data = p.userData;
        const cls_active = s.sidebarOpened || s.focused || p.searchRequest.length ? 'active' : '';

        const sidebar = s.sidebarOpened ? <Sidebar {...p} onMount={t.showSidebar} closeFunc={t.closeSidebar} onClose={() => t.setState({sidebarOpened: false})} hideState={s.sidebarHide} /> : '';
        const cls_sidebar_opened = s.sidebarOpened ? 'opened' : '';
        const cls_show_search = s.showSearch ? 'show_search' : '';

        const avatar = data.avatar ? <div className="avatar"><img src={data.avatar} alt="" /></div> : <UserSidebar/>;

        return (
            <div className={'header_v2 ' + cls_active + ' ' + cls_show_search}>
                <div className="">

                    <HeaderCategories {...p} onToggleCategories={() => t.setState({sidebarOpened: false})} />
                    <Link onClick={t.handleClose} className="logo" to={'/'}>
                        <img src={logo} alt={''} />
                        <span className="beta">ß</span>
                    </Link>

                    <SearchBar {...p}
                               focused={s.focused}
                               onFocus={t.handleFocus}
                               onBlur={t.handleBlur} closeSearch={() => t.setState({showSearch: false})} />

                    <div className="search_toggler" onClick={() => t.setState({showSearch: true, sidebarOpened: false})}><SearchIcon2 /></div>

                    <UserHeader {...p} closeFunc={this.handleClose} />
                    <div onClick={t.toggleSidebar} className={'sidebar_switcher ' + cls_sidebar_opened}>
                        {avatar}
                        {/*{sidebarSwitcherIcon}*/}
                    </div>
                </div>

                {sidebar}

            </div>
        );
    }
}

export default HeaderV2;
