import React, {useState} from 'react';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import API from '../../../utils/api';

import '../form.css';
import Preloader from "../../../utils/preloader";
import Dropdown from "../../../utils/dropdown/dropdown";
import {reachGoal} from "../../../utils/utils";

function FSFormIP(props) {
    const [loading, setLoading] = useState(false);
    let data = props.userData.additional_data;
    if (typeof data === "string") {
        data = JSON.parse(props.userData.additional_data);
    }
    //console.log(data)
    if (!data.company_ogrnip) {
        data.company_ogrnip = ''
    }
    if (!data.company_document) {
        data.company_document = 'ustav'
    }
    if (!data.company_tax_system) {
        data.company_tax_system = 'osn'
    }
    if (!data.company_nds_ammount) {
        data.company_nds_ammount = 'free_of_charge'
    }
    if (!data.company_doverennost_number) {
        data.company_doverennost_number = '';
    }

    const [optionsState, setOptionsState] = useState({
        company_document: data.company_document,
        company_tax_system: data.company_tax_system,
        company_nds_ammount: data.company_nds_ammount
    });

    const { register, errors, watch, handleSubmit } = useForm();
    watch();

    const onSubmit = formData => {
        if(formData === {}) {
            formData = data;
        }
        setLoading(true);
        const newData = {};
        Object.keys(formData).forEach((el) => {
            newData[el] = checkQuote(formData[el]);
        });

        Object.assign(newData, optionsState);

        API.post('/whub/v1/school/data/' + props.userData.id, newData)
            .then(function (r) {
                setLoading(false);
                props.authFunc();
                reachGoal(80212912,'school_signup_finish');
            })
            .catch(function (error) {
                setLoading(false);
                console.log(error);
            });
    };

    const checkQuote = (str) => {
        const regex = /([^"]*)"([^"]*)"([^"]*)/gm;
        const subst = `$1«$2»$3`;

        return str.replace(regex, subst);
    };

    const preloader = loading ? <Preloader /> : '';
    const completed = props.formCompleted;
    const button = !completed ? <button type="submit">подтвердить</button> : '';

    const options_document = [
        {label: 'свидетельства', value: 'ustav'},
        {label: 'доверенности', value: 'doverennost'}
    ];

    const options_tax_system = [
        {label: 'ОСН', value: 'osn'},
        {label: 'УСН', value: 'usn'},
        {label: 'ЕНВД', value: 'envd'},
        {label: 'ЕСХН', value: 'eshn'},
        {label: 'ПСН', value: 'psn'}
    ];

    const options_nds_ammount = [
        {label: 'НДС не облагается', value: 'free_of_charge'},
        {label: 'НДС 0%', value: '0'},
        {label: 'НДС 10%', value: '10'},
        {label: 'НДС 20%', value: '20'}
    ];

    const getSelectedOption = (arr, val) => {
        if (val === null) {
            return arr[0];
        }
        for (let i=0; i < arr.length; i++) {
            if (arr[i].value === val) {
                return arr[i];
            }
        }
        return arr[0];
    };

    const handleDropdownChange = (d) => {
        const params = JSON.parse(JSON.stringify(optionsState));

        data[d.name] = d.data.value;
        params[d.name] = d.data.value;

        setOptionsState(params);

    };

    const doverennost_number_field = optionsState.company_document === 'doverennost' ? <div className="input_block ib_256">
        <input disabled={completed} placeholder=" " defaultValue={data.company_doverennost_number} name="company_doverennost_number" ref={register} />
        <label>номер доверенности</label>
    </div> : '';

    const nds_ammount_field = optionsState.company_tax_system === 'osn' ? <div className="input_block ib_256">
        <Dropdown disabled={completed} onChange={handleDropdownChange} name="company_nds_ammount" selected={getSelectedOption(options_nds_ammount, optionsState.company_nds_ammount )} ddArr={options_nds_ammount} />
        <label className="up">ставка НДС</label>
    </div> : '';

    return (
        <form className="default_form" onSubmit={handleSubmit(onSubmit)}>
            <input type="hidden" defaultValue={props.companyType} name="company_type" ref={register} />
            <div className="form_block">
                <p className="form_block_label">данные юридического лица</p>

                <div className="input_block ib_864">
                    <input disabled={completed} placeholder=" " defaultValue={data.company_fullname} name="company_fullname" ref={register({
                        required: 'необходимо заполнить'
                    })} />
                    <label>полное наименование предпринимателя как в учредительных документах</label>
                    <span className="form_error"><ErrorMessage errors={errors} name="company_fullname" /></span>
                </div>

                <div className="input_block ib_528">
                    <input disabled={completed} placeholder=" " defaultValue={data.company_inn} name="company_inn" ref={register({
                        required: 'необходимо заполнить',
                        maxLength: {
                            value: 12,
                            message: '12 цифр'
                        },
                        pattern: {
                            value: /^\d+$/,
                            message: 'поле может одержать только цифры',
                        },
                        minLength: {
                            value: 12,
                            message: '12 цифр'
                        } })} />
                    <label>ИНН</label>
                    <span className="form_error"><ErrorMessage errors={errors} name="company_inn" /></span>
                </div>

                <div className="input_block ib_304">
                    <input disabled={completed} placeholder=" " defaultValue={data.company_ogrnip} name="company_ogrnip" ref={register({
                        required: 'необходимо заполнить',
                        maxLength: {
                            value: 15,
                            message: '15 цифр'
                        },
                        pattern: {
                            value: /^\d+$/,
                            message: 'поле может одержать только цифры',
                        },
                        minLength: {
                            value: 15,
                            message: '15 цифр'
                        } })} />
                    <label>ОГРНИП</label>
                    <span className="form_error"><ErrorMessage errors={errors} name="company_ogrnip" /></span>
                </div>

                <div className="input_block ib_528">
                    <input disabled={completed} placeholder=" " defaultValue={data.company_ceo} name="company_ceo" ref={register({
                        required: 'необходимо заполнить'
                    })} />
                    <label>руководитель (Ф.И.О.)</label>
                    <span className="form_error"><ErrorMessage errors={errors} name="company_ceo" /></span>
                </div>

                <div>
                    <div className="input_block ib_256">
                        <Dropdown disabled={completed} onChange={handleDropdownChange} name="company_document" selected={getSelectedOption(options_document, optionsState.company_document )} ddArr={options_document} />
                        <label className="up">действует на основании</label>
                    </div>

                    {doverennost_number_field}
                </div>


                <div className="input_block ib_528">
                    <input disabled={completed} placeholder=" " defaultValue={data.company_address} name="company_address" ref={register({
                        required: 'необходимо заполнить'
                    })} />
                    <label>юридический адрес</label>
                    <span className="form_error"><ErrorMessage errors={errors} name="company_address" /></span>
                </div>

                <div className="input_block ib_528">
                    <input disabled={completed} placeholder=" " defaultValue={data.company_post_address} name="company_post_address" ref={register({
                        required: 'необходимо заполнить'
                    })} />
                    <label>почтовый адрес</label>
                    <span className="form_error"><ErrorMessage errors={errors} name="company_post_address" /></span>
                </div>
            </div>

            <div className="form_block">
                <p className="form_block_label">банковские данные</p>

                <div className="input_block ib_528">
                    <input disabled={completed} placeholder=" " defaultValue={data.bank_fullname} name="bank_fullname" ref={register({
                        required: 'необходимо заполнить'
                    })} />
                    <label>название банка</label>
                    <span className="form_error"><ErrorMessage errors={errors} name="bank_fullname" /></span>
                </div>

                <div className="input_block ib_304">
                    <input disabled={completed} placeholder=" " defaultValue={data.bank_accout_number} name="bank_accout_number" ref={register({
                        required: 'необходимо заполнить',
                        maxLength: {
                            value: 20,
                            message: '20 цифр'
                        },
                        pattern: {
                            value: /^\d+$/,
                            message: 'поле может одержать только цифры',
                        },
                        minLength: {
                            value: 20,
                            message: '20 цифр'
                        }
                    })} />
                    <label>номер счёта</label>
                    <span className="form_error"><ErrorMessage errors={errors} name="bank_accout_number" /></span>
                </div>

                <div className="input_block ib_528">
                    <input disabled={completed} placeholder=" " defaultValue={data.bank_bik} name="bank_bik" ref={register({
                        required: 'необходимо заполнить',
                        maxLength: {
                            value: 9,
                            message: '9 цифр'
                        },
                        pattern: {
                            value: /^\d+$/,
                            message: 'поле может одержать только цифры',
                        },
                        minLength: {
                            value: 9,
                            message: '9 цифр'
                        }
                    })} />
                    <label>БИК</label>
                    <span className="form_error"><ErrorMessage errors={errors} name="bank_bik" /></span>
                </div>

                <div className="input_block ib_304">
                    <input disabled={completed} placeholder=" " defaultValue={data.bank_offset_account} name="bank_offset_account" ref={register({
                        required: 'необходимо заполнить',
                        maxLength: {
                            value: 20,
                            message: '20 цифр'
                        },
                        pattern: {
                            value: /^\d+$/,
                            message: 'поле может одержать только цифры',
                        },
                        minLength: {
                            value: 20,
                            message: '20 цифр'
                        }
                    })} />
                    <label>корр. счёт</label>
                    <span className="form_error"><ErrorMessage errors={errors} name="bank_offset_account" /></span>
                </div>
            </div>

            <div className="form_block">
                <p className="form_block_label">информация о&nbsp;налогообложении</p>

                <div className="input_block ib_256">
                    <Dropdown disabled={completed} onChange={handleDropdownChange} name="company_tax_system" selected={getSelectedOption(options_tax_system, optionsState.company_tax_system )} ddArr={options_tax_system} />
                    <label className="up">система налогообложения</label>
                </div>

                {nds_ammount_field}
            </div>

            <div className="form_block">
                <p className="form_block_label">контактные данные</p>

                <div className="input_block ib_304">
                    <input disabled={completed} placeholder=" " defaultValue={data.contact_fullname} name="contact_fullname" ref={register({
                        required: 'необходимо заполнить'
                    })} />
                    <label>Ф.И.О.</label>
                    <span className="form_error"><ErrorMessage errors={errors} name="contact_fullname" /></span>
                </div>

                <div className="input_block ib_192">
                    <input disabled={completed} placeholder=" " defaultValue={data.contact_phone} name="contact_phone" ref={register({
                        required: 'необходимо заполнить',
                        pattern: {
                            value: /^\d+$/,
                            message: 'поле может одержать только цифры',
                        }
                    })} />
                    <label>телефон</label>
                    <span className="form_error"><ErrorMessage errors={errors} name="contact_phone" /></span>
                </div>

                <div className="input_block ib_304">
                    <input disabled={completed} placeholder=" " defaultValue={data.contact_email} name="contact_email" ref={register({
                        required: 'необходимо заполнить',
                        pattern: {
                            value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: 'необходимо ввести адрес электронной почты',
                        }
                    })} />
                    <label>е-мейл</label>
                    <span className="form_error"><ErrorMessage errors={errors} name="contact_email" /></span>
                </div>
            </div>

            <div className="button_holder">
                {button}
            </div>
            {preloader}
        </form>
    );
}

export default FSFormIP;