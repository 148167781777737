import React from 'react';

import './index.css';
import pic1 from './images/1.svg';
import pic2 from './images/2.svg';
import pic3 from './images/3.svg';
import pic4 from './images/4.svg';
import pic5 from './images/5.svg';

class LSProfitable extends React.Component {
    constructor (props) {
        super();

        this.state = {
        };
    }

    componentDidMount = () => {
    };

    componentWillUnmount = () => {
    };

    render() {
        return (
            <div className="ls_profitable_wrapper">
                <div className="l_content">
                    <h2>W-HUB – это выгодно</h2>

                    <ul>
                        <li>
                            <div className="item_bg"><img className="pic_1" src={pic1} alt="" /></div>
                            <div className="item_text">Размещай платные и бесплатные курсы</div>
                        </li>

                        <li>
                            <div className="item_bg"><img className="pic_2" src={pic2} alt="" /></div>
                            <div className="item_text">Собирай собственную аудиторию</div>
                        </li>

                        <li>
                            <div className="item_bg"><img className="pic_3" src={pic3} alt="" /></div>
                            <div className="item_text">Открывай запись на онлайн курс</div>
                        </li>

                        <li>
                            <div className="item_bg"><img className="pic_4" src={pic4} alt="" /></div>
                            <div className="item_text">Встроенная система для приёма платежей</div>
                        </li>

                        <li>
                            <div className="item_bg corner"><img className="pic_5" src={pic5} alt="" /></div>
                            <div className="item_text">Привлекай аудиторию:
                                <ul>
                                    <li>проводи акции</li>
                                    <li>проводи бесплатные вебинары о своем курсе</li>
                                    <li>тестируй свои гипотезы бесплатно</li>
                                </ul>
                            </div>
                        </li>
                    </ul>

                </div>
                <div className="ls_bg"> </div>
            </div>
        );
    }
}

export default LSProfitable;
