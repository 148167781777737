import React from "react";

import './landing.css';
import './modals/modals.css';

import TextareaAutosize from "react-autosize-textarea";
import API from "../../utils/api";
import Modal from "../../modal";
import CourseForModal from "./modals/course_for";
import CourseFaqModal from "./modals/faq";
import CourseRoadmapModal from "./modals/roadmap";
import CoursePerksModal from "./modals/perks";
import CourseFutureSkillsModal from "./modals/future_skills";
import Preloader from "../../utils/preloader";
import CourseAuthorsModal from "./modals/authors";
import RequirementsModal from "./modals/requirements";
import SelfAdvertModal from "./modals/self_advert";


class EditCourseLanding extends React.Component {
    constructor (props) {
        super();

        const blocksList = [
            // 'keywords',
            'self_advert',
            'authors',
            'course_for',
            'requirements',
            'faq',
            'roadmap',
            'future_skills',
            'perks'
        ];

        const blockNames = {
            self_advert: 'Реклама',
            course_for: 'Курс для',
            requirements: 'Вам понадобится',
            authors: 'Авторы',
            faq: 'Вопросы и ответы',
            perks: 'Особенности',
            roadmap: 'Процесс обучения',
            future_skills: 'Навыки'
        };

        this.state = {
            loading: false,
            blockNames: blockNames,
            list: blocksList,
            modalView: null
        }
    }

    removeBlock = (name) => {
        const t = this;
        const p = t.props;
        const d = {data: {meta: [name]}};

        t.setState({loading: true});
        API.delete('/whub/v3/meta/' + p.courseData.id, d)
            .then(function (r) {
                console.log(r)
                t.setState({loading: false});
                p.reloadData();
            })
            .catch(function (error) {
                console.log(error.response);
                t.setState({loading: false});
            });
    };

    listMapper = (el, idx) => {
        const t = this;
        const data = t.props.courseData;
        const meta = data.meta;
        const {blockNames} = t.state;
        const isAuthors = el === 'authors';
        const hasAuthors = isAuthors && data.authors && data.authors.length;


        if (!meta[el] && !hasAuthors) {
            return <li key={idx}>
                <span onClick={() => t.setState({modalView: el})}>Добавить блок "{blockNames[el]}"</span>
            </li>
        }

        return '';
    };

    blocksMapper = (el, idx) => {
        const t = this;
        const {blockNames} = t.state;
        return <div className="block_item" key={idx}>
            <div onClick={() => t.setState({modalView: el.name})} className="bi_inner">Блок "{blockNames[el.name]}"</div>
            <div onClick={() => t.removeBlock(el.name)} className="bi_delete">X</div>
        </div>
    };

    hideModal = () => {
        this.setState({modalView: null});
    };

    handleSave = (d, cb) => {
        const t = this;
        const p = t.props;
        API.put('/whub/v3/course/' + p.courseData.id, d)
            .then(function (r) {
                t.hideModal();
                p.reloadData();
            })
            .catch(function (error) {
                console.log(error.response);
                cb(error.response);
            });
    };

    render () {
        const t = this;
        const {courseData, userData} = t.props;
        const meta = courseData.meta;
        const {list, modalView, loading} = t.state;

        const blocks = [];
        list.forEach((el) => {
            const isAuthors = el === 'authors';
            const hasAuthors = isAuthors && courseData.authors && courseData.authors.length;
            if (meta[el] || hasAuthors) {
                blocks.push({
                    name: el,
                    content: meta[el]
                });
            }
        });

        const optionsList = list.map(t.listMapper);
        const blocksList = blocks.map(t.blocksMapper);


        const blockComponents = {
            self_advert: <SelfAdvertModal courseMeta={meta} onSave={t.handleSave} onModalClose={t.hideModal} />,
            course_for: <CourseForModal courseMeta={meta} onSave={t.handleSave} onModalClose={t.hideModal} />,
            requirements: <RequirementsModal courseMeta={meta} onSave={t.handleSave} onModalClose={t.hideModal} />,
            faq: <CourseFaqModal courseMeta={meta} onSave={t.handleSave} onModalClose={t.hideModal} />,
            roadmap: <CourseRoadmapModal courseMeta={meta} onSave={t.handleSave} onModalClose={t.hideModal} />,
            perks: <CoursePerksModal courseMeta={meta} onSave={t.handleSave} onModalClose={t.hideModal} />,
            future_skills: <CourseFutureSkillsModal courseMeta={meta} onSave={t.handleSave} onModalClose={t.hideModal} />,
            authors: <CourseAuthorsModal userData={userData} courseData={courseData} onSave={t.handleSave} onModalClose={t.hideModal} />
        };

        const modal = modalView ? <Modal modalContent={'edit_course'} blockComponent={blockComponents[modalView] || modalView} onModalClose={t.hideModal} /> : '';

        const preloader = loading ? <Preloader/> : '';
        return (
            <div className="ceb_landing">

                <div className="course_name_block">
                    <TextareaAutosize spellCheck="false" className="course_name_input" disabled={true} value={this.props.courseData.title} name="courseName" placeholder="название курса" />
                </div>

                <ul className="add_blocks_list">
                    {optionsList}
                </ul>

                <div className="blocks">
                    {blocksList}
                </div>

                {modal}

                {preloader}
            </div>
        );
    }
}

export default EditCourseLanding;