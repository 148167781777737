import React from 'react';

class ParamAuthors extends React.Component {
    constructor (props) {
        super();

        this.state = {
        };
    }

    render() {
        const t = this;
        const p = t.props;
        const data = p.data;

        const authorsList = data.authors.map((el, idx) => {
            return <li key={idx}>{el.name} {el.surname}</li>
        });

        return (
            <div className="params_section">
                <h3>Преподаватели</h3>

                <ul className="cl_param_authors">
                    {authorsList}
                </ul>
            </div>
        );
    }
}

export default ParamAuthors;
