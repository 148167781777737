import React from 'react';

import './index.css';
class LSFreeServices extends React.Component {
    constructor (props) {
        super();

        this.state = {
        };
    }

    componentDidMount = () => {
    };

    componentWillUnmount = () => {
    };

    render() {
        return (
            <div className="ls_free_services_wrapper">
                <div className="l_content">
                    <h2>W-HUB – это бесплатные сервисы</h2>

                    <ul>
                        <li>
                            <div className="icon_plus"><span>+</span></div>
                            Создание онлайн-курсов
                        </li>
                        <li>
                            <div className="icon_plus"><span>+</span></div>
                            Создание видеокурсов
                        </li>
                        <li>
                            <div className="icon_plus"><span>+</span></div>
                            Рассылка писем и приглашений для студентов
                        </li>
                        <li>
                            <div className="icon_plus"><span>+</span></div>
                            Создание вебинаров
                        </li>
                        <li>
                            <div className="icon_plus"><span>+</span></div>
                            Оформление страницы курса
                        </li>
                        <li>
                            <div className="icon_plus"><span>+</span></div>
                            Аналитика
                        </li>
                    </ul>
                </div>
                <div className="ls_bg"> </div>
            </div>
        );
    }
}

export default LSFreeServices;
