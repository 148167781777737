import React from 'react';

import './index.css';
import {Icon1, Icon2,
    // Icon3,
    Icon4, Icon5, Icon6} from "./icons";
class LSStatistics extends React.Component {
    constructor (props) {
        super();

        this.state = {
        };
    }

    componentDidMount = () => {
    };

    componentWillUnmount = () => {
    };

    render() {
        return (
            <div className="ls_statistics_wrapper">
                <div className="l_content">
                    <h2>W-HUB – наша статистика:</h2>

                    <ul>
                        <li>
                            <div className="count">
                                <div className="icon"><Icon1 /></div>
                                50 000+</div>
                            Активных пользователей и подписчиков в социальных сетях
                        </li>
                        <li>
                            <div className="count">
                                <div className="icon"><Icon2 /></div>
                                1 000+</div>
                            Доступные курсы на маркетплейсе
                        </li>
                        {/*<li>*/}
                            {/*<div className="count">*/}
                                {/*<div className="icon"><Icon3 /></div>*/}
                                {/*11 000+</div>*/}
                            {/*Аудиокниги и аудиокурсы*/}
                        {/*</li>*/}
                        <li>
                            <div className="count">
                                <div className="icon"><Icon4 /></div>
                                500+</div>
                            Лайков к каждому посту в социальных сетях
                        </li>
                        <li>
                            <div className="count">
                                <div className="icon"><Icon5 /></div>
                                2 000+</div>
                            Зарегистрированных студентов
                        </li>
                        <li>
                            <div className="count">
                                <div className="icon"><Icon6 /></div>
                                1 000+</div>
                            Активных онлайн школ, авторов и экспертов
                        </li>
                    </ul>

                </div>
                <div className="ls_bg"> </div>
            </div>
        );
    }
}

export default LSStatistics;
