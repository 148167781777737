import React from 'react';
import { Range, getTrackBackground } from 'react-range';
import {Close} from "../icons";
import './index.css';

class TwoThumbs extends React.Component {
	constructor (props) {
		super();

		this.state = {
			values: [props.curMin, props.curMax],
		}
	}

	handleChange = values => {
		this.setState({ values });
		if (this.props.onChange) {
			this.props.onChange(values);
		}
	};

	handleClear = () => {
		const values = [this.props.min, this.props.max];
		this.setState({ values });
		if (this.props.onChange) {
			this.props.onChange(values);
		}
	};

	componentDidUpdate () {
		if (!this.state.qStr && this.props.qStr) {
			this.setState({qStr: true});
			const values = [this.props.qStr.min_price || this.state.values[0], this.props.qStr.max_price || this.state.values[1]];
			this.setState({ values });
		}
	}

	render() {
		return (
			<div className={'range'}>
				<div className={'range_label'}>от {this.state.values[0].toLocaleString()} Р</div>
				<Range
					values={this.state.values}
					step={this.props.step}
					min={this.props.min}
					max={this.props.max}

					onChange={values => {
						this.setState({ values })
					}}
					onFinalChange={this.handleChange}
					renderTrack={({ props, children }) => (
						<div
							onMouseDown={props.onMouseDown}
							onTouchStart={props.onTouchStart}
							className={'range_container'}
							style={{...props.style}}
						>
							<div
								ref={props.ref}
								className={'range_line'}
								style={{
									background: getTrackBackground({
										values: this.state.values,
										colors: ['#C0C2C8', '#040E26', '#C0C2C8'],
										min: this.props.min,
										max: this.props.max
									})
								}}
							>
								{children}
							</div>
						</div>
					)}
					renderThumb={({ props, isDragged }) => (
						<div
							{...props}
							className={'range_thumb'}
							style={{...props.style}}
						></div>
					)}
				/>
				<div className={'range_label'}>до {this.state.values[1].toLocaleString()} Р</div>
				{ (this.state.values[0] !== this.props.min || this.state.values[1] !== this.props.max) &&
					<span className={'range_clear'} onClick={this.handleClear}><Close/></span>
				}
			</div>
		);
	}
}

export default TwoThumbs;