import React from 'react';

import './index.css';

import docIcon from './doc-icon.svg';
import rubIcon from './rub-icon.svg';
import lamp from './lamp.svg';
import ellipse from './ellipse.svg';
import girl from './girl.svg';

class LPOpportunities extends React.Component {
    constructor (props) {
        super();

        this.state = {
        };
    }

    componentDidMount = () => {
    };

    componentWillUnmount = () => {
    };

    render() {
        return (
            <div className="lp_opportunities_wrapper">
                <div className="l_content">
                    <h2>W-HUB открывает следующие возможности</h2>

                    <ul className="plates">
                        <li className="plate_1">
                            <p><strong>Быстро</strong> и без вложений создать обучающие курсы</p>
                            <img className="plate_bg" src={lamp} alt={''} />
                            <div className="plate_round">
                                <img src={docIcon} alt={''} />
                            </div>
                        </li>
                        <li className="plate_2">
                            <p><strong>Легко разместить</strong> свои курсы на витрине и осуществить первые продажи</p>
                            <img className="plate_bg" src={girl} alt={''} />
                        </li>
                        <li className="plate_3">
                            <p><strong>Эффективно</strong> использовать инфраструктуру W-Hub</p>
                            <img className="plate_bg" src={ellipse} alt={''} />
                            <div className="plate_round">
                                <img src={rubIcon} alt={''} />
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default LPOpportunities;
