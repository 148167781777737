import React from 'react';
import './text.css';

import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import TestCustomOption from './addTestCustomOption';

class LessonTypeText extends React.Component {
    constructor (props) {
        super();

        const content = props.content;
        let editorState;
        let text = '';

        if (content.text && content.text.length) {
            const contentBlock = htmlToDraft(content.text);
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);

            editorState = EditorState.createWithContent(contentState);
            text = content.text;
        }
        else  {
            editorState = EditorState.createEmpty();
        }

        this.state = {
            text: text,
            originalText: text.slice(),
            editorState: editorState,
            valid: false,
            changes: false
        }
    }

    componentDidMount() {
        this.validate();
    }

    validate = () => {
        const t = this;
        const {text, originalText} = t.state;

        t.setState({valid: text.length > 10, changes: text !== originalText}, t.updateLesson);
    };

    updateLesson = () => {
        const t = this;
        const {updateTypeContent} = t.props;
        const {text, valid, changes} = t.state;

        const d = {
            content: {
                text: text
            },
            state: {
                valid: valid,
                changes: changes
            }
        };

        updateTypeContent(d)
    };

    onEditorStateChange = (editorState) => {

        this.setState({
            editorState,
            text: draftToHtml(convertToRaw(editorState.getCurrentContent()))
        }, this.validate);
    };

    render() {
        const t = this;

        const { editorState } = t.state;

        const toolbar = {
            options: ['inline', 'list', 'blockType', 'textAlign', 'link'],
            inline: {
                options: ['bold', 'italic', 'underline']
            },
            link: {
              options: ['unlink']
            },
            blockType: {
                inDropdown: false,
                options: ['H1', 'H2', 'H3', 'Blockquote'],

            },
            list: {
                options: ['unordered', 'ordered'],
            }
        };

        return (
            <dl className="edit_lesson_type_text">
                <dt>Текст:</dt>
                <dd>
                    <Editor
                        editorState={editorState}
                        toolbar={toolbar}
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor"
                        onEditorStateChange={t.onEditorStateChange}
                        toolbarCustomButtons={[<TestCustomOption />]}
                        stripPastedStyles={true}
                    />
                </dd>
            </dl>
        );
    }
}

export default LessonTypeText;
