import React from 'react';
import {Link} from "react-router-dom";
import CIMultiSelect from "../../../utils/controlled-input/ci-multi-select/index";
import Spinner from "../../../utils/spinner/index";
import {convertToSelectArr, simplifyOptionsArray} from "../../../utils/utils";
import './authors.css';

class CourseAuthorsModal extends React.Component {
    constructor (props) {
        super();

        const d = props.courseData;
        const authors = d['authors'] && d['authors'].length ? convertToSelectArr(d['authors'], 'name', 'id') : [];

        this.state = {
            loading: false,
            maxItems: 6,
            authors: authors
        };
    }

    // handleNewAuthors = (arr, cb) => {
    //     if (!arr.length) {
    //         cb()
    //     }
    //     else {
    //         API.post('/whub/v3/author', {authors: arr})
    //             .then(function (r) {
    //                 cb({ids: r.data.ids});
    //             })
    //             .catch(function (error) {
    //                 console.log(error.response);
    //                 cb({error: error.response});
    //             });
    //     }
    // };
    //
    // handleExistingAuthors = (arr, cb) => {
    //     if (!arr.length) {
    //         cb()
    //     }
    //     else {
    //         API.put('/whub/v3/author/batch', {authors: arr})
    //             .then(function (r) {
    //                 cb();
    //             })
    //             .catch(function (error) {
    //                 console.log(error.response);
    //                 cb({error: error.response});
    //             });
    //     }
    // };



    handleSave = () => {
        const t = this;
        const {onSave} = t.props;
        const {authors} = t.state;
        const ids = simplifyOptionsArray(authors, 'value');

        t.setState({loading: true});
        onSave({authors: ids}, () => {
            t.setState({loading: false});
        });
    };

    stateSetter = (stateObj) => {
        this.setState(stateObj);
    };

    render() {
        const t = this;
        const {authors, maxItems, loading} = t.state;
        const {onModalClose, userData} = t.props;

        const authorsArr = userData.authors && userData.authors.length ? userData.authors : [];

        const authorsOptions = convertToSelectArr(authorsArr, 'name', 'id');

        return (
            <div className="course_edit_modal authors_select_modal">
                <div className="cem_inner">
                    <div className="cem_text">
                        <h3>Блок "Авторы"</h3>
                        <p className="block_description">Вы можете добавить до {maxItems} авторов курса из списка экспертов вашей школы.<br />
                        Добавить новых экспертов вы можете на <Link to="/user/experts">странице "Эксперты"</Link> в панеле управления школой.</p>
                    </div>
                    <div className="cem_content cem_dl">
                        <CIMultiSelect
                            name="authors"
                            defaultValue={authors}
                            options={authorsOptions}
                            isSearchable={true}
                            onChange={t.stateSetter}
                            isOptionDisabled={(option) => authors.length >= maxItems}
                        />
                    </div>
                    <div className="cem_buttons">
                        <button className="small" onClick={onModalClose}>отмена</button>
                        <button disabled={!authors.length} className="small" onClick={t.handleSave}>сохранить</button>
                    </div>
                </div>

                <Spinner show={loading} />
            </div>
        );
    }
}

export default CourseAuthorsModal;
