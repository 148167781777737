import React from "react";
import API from "../api";

const trackerBaseName = 'WHUB_STATISTICS_TRACKER_V2';

export const trackEvent = (trackData) => {
    const  {event, id, childId, trackName} = trackData;
    const trackerData = {event: event};
    const entityId = childId ? childId : id;

    if (trackName) {
        localStorage.setItem(trackName, true);
    }

    API.put('/whub/v3/stat/' + entityId, trackerData)
        .then(function (r) {
            console.log(r)
        })
        .catch(function (error) {
            console.log(error)
            if (trackName) {
                localStorage.removeItem(trackName);
            }
        });
};

class Tracker extends React.Component {
    constructor (props) {
        super();
    }

    componentDidMount() {
        this.checkTrack(this.setTrack);
    }
    componentDidUpdate() {
        this.checkTrack(this.setTrack);
    }

    checkTrack = (cb) => {
        const {event, id, childId} = this.props;
        let trackName = trackerBaseName + '__' + event + '__' + id;

        if (childId) {
            trackName += '_' + childId;
        }

        if (!localStorage.getItem(trackName)) {
            cb();
        }

    };

    setTrack = () => {
        const  {event, id, childId} = this.props;
        let trackName = trackerBaseName + '__' + event + '__' + id;
        let trackData = {
            id: id,
            event: event,
            trackName: trackName
        };

        if (childId) {
            trackData.childId = childId;
            trackData.trackName = trackName +  '_' + childId;
        }

        trackEvent(trackData);

    };

    render() {

        return false;
    }
}

export default Tracker;