import React from 'react';
import {Link} from 'react-router-dom';

import './feed.css';

import CoursesFeed from "../../course-snippets/feed/v3";
import {ArrowRightBlue} from "../../utils/icons";

class MainFeed extends React.Component {
    constructor (props) {
        super();

        this.state = {
            activeTab: props.defaultTab ? props.defaultTab : 'video'
        };
    }

    switchRange = (e) => {
        const range = e.currentTarget.getAttribute('data-selection');
        this.setState({activeTab: range})
    };

    render() {
        const {
            noTabs,
            title,
            courses,
            authorized,
            firstElement,
            withBackground,
            tagLine,
            linkText,
            linkUrl
        } = this.props;

        const tab = this.state.activeTab;

        const data = !noTabs ? courses[tab] : courses;
        const first = !authorized && firstElement ? firstElement : null;

        const cls_bg = withBackground ? 'with_background' : '';

        let moreLink = '';

        if (linkText && linkUrl) {
            const tag = tagLine ? <span>{tagLine}</span> : '';

            moreLink = <div className="feed_link_more">
                {tag}
                <Link to={linkUrl}>{linkText}<ArrowRightBlue /></Link>
            </div>
        }

        return (
            <div className={'main_feed_wrapper ' + cls_bg}>
                <h2>{title}</h2>

                <CoursesFeed {...this.props} courses={data} currentTab={tab} firstElement={first} />

                {moreLink}
            </div>
        );
    }
}

export default MainFeed;
