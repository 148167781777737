import React from "react";

import './price.css';
import Preloader from "../../utils/preloader";
import API from "../../utils/api";
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import Checkbox from "../../utils/checkbox/checkbox";
import RadioDefault from "../../utils/radio/radio-default";

class EditCoursePriceWidget extends React.Component {
    constructor (props) {
        super();

        const {courseData, minPrice} = props;

        const regularPrice = courseData.regular_price;
        const salePrice = courseData.sale_price;

        this.state = {
            loading: false,
            data: courseData,
            price: regularPrice ? regularPrice : '',
            salePrice: salePrice ? salePrice : '',
            originalPrice: parseInt(regularPrice.toString()),
            originalSalePrice: salePrice ? parseInt(salePrice.toString()) : '',
            minPrice: minPrice,
            valid: false,
            saleValid: false,
            saleEnabled: !!salePrice,
            freeCourse: !regularPrice
        };
    }

    updatePrice = (e) => {
        const t = this;
        const type = e.target.name;
        const {price, salePrice, data} = t.state;
        const {updateData} = t.props;
        let d = {regular_price: price};

        if (type === 'update_sale') {
            d = {sale_price: salePrice};
        }

        if (type === 'update_price' && price < salePrice) {
            d.sale_price = '';
            t.setState({saleEnabled: false, salePrice: ''});
        }

        if (data.status === 'publish') {
            d.edit = 1;
        }

        t.setState({loading: true});

        API.put('/whub/v3/course/' + data.id, d)
            .then(function (r) {
                let original = {loading: false, originalPrice: price};
                if (type === 'update_sale') {
                    original = {loading: false, originalSalePrice: salePrice};
                }
                t.setState(original, t.validate);
                updateData(r.data);
            })
            .catch(function (error) {
                t.setState({loading: false});
                console.log(error.response);
                alert(error)
            });
    };

    handleInput = (e) => {
        const t = this;
        const obj = {};
        let value = parseInt(e.target.value.replace(/,/g, '').replace('₽', ''));
        if (isNaN(value)) {
            value = '';
        }
        obj[e.target.name] = value;

        t.setState(obj, t.validate);
    };

    validate = () => {
        const t = this;
        const {price, originalPrice, salePrice, originalSalePrice, minPrice} = t.state;

        const testChanges = price !== originalPrice;
        const saleChanges = salePrice !== originalSalePrice;

        const valid = (price === 0 || price >= minPrice) && testChanges;
        const saleValid = salePrice > 0 && salePrice < price && saleChanges;

        t.setState({valid: valid, saleValid: saleValid});
    };

    autoSave = () => {
        const t = this;
        const {price, salePrice} = t.state;
        const {updateData} = t.props;

        const update = {regular_price: price};
        let save = {regular_price: price};

        if (salePrice) {
            update.sale_price = salePrice;
            save.sale_price = salePrice;
        }

        if (this.state.data.status === 'publish') {
            save.edit = 1;
        }
        if (!this.state.valid) {
            save = null;
        }
        updateData(update,save);
    };

    checkFreeCourse = () => {
        const t = this;
        const {freeCourse, price} = t.state;

        if (freeCourse && price) {
            t.setState({price: '', salePrice: ''}, () => {
                t.updatePrice({target: {name: 'update_price'}});
            });
        }
    };

    checkSale = () => {
        const t = this;
        const {saleEnabled, salePrice} = t.state;

        if (!saleEnabled && salePrice) {
            t.setState({salePrice: ''}, () => {
                t.updatePrice({target: {name: 'update_sale'}});
            });
        }
    };

    handleCheckbox = (e) => {
        const t = this;
        const name = e.target.name;
        const state = t.state[name]
        const obj = {};

        obj[name] = !state;

        t.setState(obj, () => {
            if (name === 'saleEnabled') {
                t.checkSale();
            }

            if (name === 'freeCourse') {
                t.checkFreeCourse();
            }
        })
    };

    handleRadioChange = (name, value) => {
        const t = this;
        const obj = {};
        obj[name] = value;

        this.setState(obj, () => {
            if (name === 'freeCourse') {
                t.checkFreeCourse();
            }
        });
    };

    componentDidMount() {
        if (this.state.price === '') {
            this.setState({mask: createNumberMask({prefix: '', suffix: '0 ₽'})})
        }
    };

    componentWillUnmount() {
        this.autoSave();
    };

    render () {
        const t = this;

        const {price, salePrice, saleEnabled, minPrice, valid, saleValid, freeCourse, loading} = t.state;

        const mask = createNumberMask({prefix: '', suffix: ' ₽'});

        const minPriceNote = price > 0 && price < minPrice ? <p className="min_price_error">Минимальная цена для платных курсов - {minPrice} ₽</p>: '';

        const preloader = loading ? <Preloader/> : '';

        return (
            <div className="edit_course_price_widget">
                <dl className="free_course_toggler_wrapper">
                    <dt> </dt>
                    <dd>
                        <RadioDefault onChange={t.handleRadioChange} freeCourse={freeCourse} name="freeCourse" value={true} label="Бесплатный курс" />
                        <RadioDefault onChange={t.handleRadioChange} freeCourse={freeCourse} name="freeCourse" value={false} label="Платный курс" />
                    </dd>
                </dl>
                {!freeCourse ? <div>
                    <dl>
                        <dt>стоимость курса</dt>
                        <dd>
                            <MaskedInput
                                showMask={true}
                                guide={false}
                                placeholderChar={'\u2000'}
                                placeholder="0"
                                mask={mask}
                                value={price} name="price" onChange={t.handleInput}/>
                            <button className="small" name="update_price" onClick={t.updatePrice} disabled={!valid}>сохранить</button>
                        </dd>
                    </dl>

                    <dl>
                        <dt> </dt>
                        <dd><Checkbox onChange={t.handleCheckbox} name="saleEnabled" checked={saleEnabled} label="включить скидку" /></dd>
                    </dl>

                    {saleEnabled ? <dl>
                        <dt>цена со скидкой</dt>
                        <dd>
                            <MaskedInput
                                showMask={true}
                                guide={false}
                                placeholderChar={'\u2000'}
                                placeholder="0"
                                mask={mask}
                                value={salePrice} name="salePrice" onChange={t.handleInput}/>
                            <button className="small" name="update_sale" onClick={t.updatePrice} disabled={!saleValid}>сохранить</button>
                        </dd>
                    </dl> : ''}

                    {minPriceNote}

                    <p className="price_disclamer">Комиссия при продаже контента составит 15%</p>
                </div> : ''}


                {preloader}
            </div>

        );
    }
}

export default EditCoursePriceWidget;