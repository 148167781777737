import React from 'react';
import './pdf.css';

import PdfViewer from "../../../../../utils/pdf-viewer";

class LessonTypePdf extends React.Component {
    constructor (props) {
        super();

        const content = props.content;

        const url = content.url ? content.url : '';

        this.state = {
            url: url,
            originalUrl: url.slice(),
            checked: false,
            pdfValid: true,
            valid: false,
            changes: false
        }
    }

    componentDidMount() {
        this.validate();
    }

    validate = () => {
        const t = this;
        const {url, originalUrl, pdfValid} = t.state;

        t.setState({valid: !!url.length && pdfValid, changes: url !== originalUrl}, t.updateLesson)
    };

    updateLesson = () => {
        const t = this;
        const {updateTypeContent} = t.props;
        const {url, valid, changes} = t.state;

        const d = {
            content: {
                url: url
            },
            state: {
                valid: valid,
                changes: changes
            }
        };

        updateTypeContent(d)
    };

    handleTextInput = (e) => {
        const obj = {};
        obj[e.target.name] = e.target.value;
        this.setState(obj, this.validate);
    };

    checkPDF = (n) => {
        const t = this;

        if (n <= 0) {
            t.setState({checked: false, pdfValid: false});
            alert('Ошибка при загрузке PDF!');
        }
        else {
            t.setState({pdfValid: true}, t.validate);
        }
    };

    handlePdfChange = (e) => {
        this.setState({url: e.target.value, checked: false});
    };

    render() {
        const t = this;

        const {url, checked, pdfValid} = t.state;
        const pdf = !!url.length && checked ? <PdfViewer url={url} onCount={t.checkPDF} /> : '';

        return (
            <dl className="edit_lesson_type_pdf">
                <dt>ссылка на PDF</dt>
                <dd>

                    <div className="pdf_url_field">
                        <input placeholder="ссылка на PDF" type="text" value={url} onChange={t.handlePdfChange} />
                        {!checked && url.length ? <button className="small btn_check_source" onClick={() => t.setState({checked: true})}>проверить</button> : ''}
                    </div>
                    {!checked ? <div className="course_form_hint">поддерживаются
                        {!pdfValid ? <span>если вы используете Google-документы или Dropbox - попробойте проверить настройки конфиденциальности!<br /></span> : ''}
                        прямые ссылки на PDF-документы, ссылки на PDF в Dropbox, Google-презентации, Google-документы и Google-таблицы.<br/>
                        Документы, размещенные на Google Drive и Yandex.Disk не поддерживаются!
                    </div> : ''}
                    {pdf}
                </dd>
            </dl>
        );
    }
}

export default LessonTypePdf;
