import React from "react";
import { Link, Redirect } from 'react-router-dom';

import './index.css';
import '../styles/auth-tabs.css';

import SignUpRadioBlock from "./sign-up-radio-block";

class SignUp extends React.Component {
    constructor (props) {
        super();

        this.state = {
            registrationType: 'student'
        }
    }
    componentDidMount() {
        document.body.classList.add('minimal');
    };
    componentWillUnmount() {
        document.body.classList.remove('minimal');
    };

    handleRadioChange = (obj) => {
        this.setState(obj);
    };

    handleButtonClick = () => {
        if (this.props.closeFunc) {
            this.props.closeFunc();
        }
    };

    render() {

        const redirect = this.props.authorized ? <Redirect to={{pathname: "/"}}/> : '';
        const s = this.state;

        return (
            <div className="sign_up_wrapper">
                <div className="auth_tabs">
                    <Link to={'/signin'}>вход</Link>
                    <span className="active">регистрация</span>
                </div>

                <div className="sign_up_landing">

                    <SignUpRadioBlock registrationType={s.registrationType} onRadioChange={this.handleRadioChange} />

                    <div className="button_holder">
                        <Link onClick={this.handleButtonClick} to={'/signup/' + s.registrationType}><button>Продолжить</button></Link>
                    </div>
                </div>
                {redirect}
            </div>
        )
    }
}

export default SignUp;