import React from 'react';
import API from '../../utils/api';

import pencilIco from './ico/pencil.svg';
import binIco from './ico/bin.svg';

class ExpertItem extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            loading: false,
            id: props.item.id || "new_item",
            avatar: props.item.avatar || "",
            name: props.item.name || "",
            middle_name: props.item.middle_name || "",
            surname: props.item.surname || "",
            profession: props.item.profession || "",
            about: props.item.about || "",
            email: props.item.email || ""
        };
    }

    componentDidUpdate(prevProps) {
      if(JSON.stringify(prevProps.item) !== JSON.stringify(this.props.item)) {
        const { props } = this;
        this.setState({
          id: props.item.id || "new_item",
          avatar: props.item.avatar || "",
          name: props.item.name || "",
          middle_name: props.item.middle_name || "",
          surname: props.item.surname || "",
          profession: props.item.profession || "",
          about: props.item.about || "",
          email: props.item.email || ""
      });
      }
    }

    handleSave = () => {
        const t = this;
        const {id, name, about} = t.state;
        const {onSave} = t.props;

        t.setState({loading: true});
        if (id === 'new_item') {
            const d = [{name: name, about: about}]; 
            API.post('/whub/v3/author', {authors: d})
                .then(function (r) {
                    t.setState({loading: false, isEdit: false});
                    if (onSave) {
                        onSave();
                    }
                })
                .catch(function (error) {
                    console.log(error.response);
                });
        }
        else {
            const d = [{id: id, name: name, about: about}];
            API.put('/whub/v3/author/batch', {authors: d})
                .then(function (r) {
                    t.setState({loading: false, isEdit: false});
                })
                .catch(function (error) {
                    console.log(error.response);
                });
        }
    };


    stateSetter = (stateObj) => {
        this.setState(stateObj);
    };

    render() {
        const t = this;
        const {id, avatar, name, surname, profession, about, email, middle_name} = t.state;
        const isAvatarSet = avatar && avatar.length > 0;
        const letter = name.length ? name.split('')[0] : "";

        const author = {
          id,
          avatar,
          name,
          surname,
          profession,
          about,
          email,
          middle_name
        };

        return (
            <div className="expert_item">
                <div className="expert_item_header">
                  <div
                    style={avatar ? {backgroundImage: `url(${avatar})`} : {}}
                    className={`expert_item_header_avatar ${isAvatarSet ? "" : "letter"}`}>
                      {isAvatarSet ? "" : letter}
                  </div>
                  <div className="expert_item_header_info">
                    <div className="expert_item_header_info_title">
                      <span>{name} {surname}</span>
                    </div>
                    <div className="expert_item_header_info_semititle"> 
                      <span>{profession}</span>
                    </div>
                  </div>
                </div>

                <div className="expert_item_content">
                  <span>{about}</span>
                </div>

                <div className="expert_item_footer">
                  <div
                    onClick={() => this.props.edit(author)}
                    style={{backgroundImage: `url(${pencilIco})`}}
                    className="expert_item_footer_edit">
                  </div>
                  <div style={{backgroundImage: `url(${binIco})`}} className="expert_item_footer_dlt"></div>
                </div>
            </div>
        );
    }
}

export default ExpertItem;