import React from 'react';
import ReactPlayer from 'react-player'

import './index.css';
import {pushPlaybackProgress} from "../utils";

class PlayerWithAd extends React.Component {
    constructor (props) {
        super();
        this.player = React.createRef();

        this.state = {
            ready: false,
            playing: true,
            playingUrl: null,
            adStarted: false,
            adPlayed: false,
            adPlayerHidden: true
        }
    }

    componentDidMount() {
        const {url, onCanNotPlay} = this.props;

        if (!ReactPlayer.canPlay(url) && onCanNotPlay) {
            onCanNotPlay();
        }
        else {
            this.setState({playingUrl: url})
        }
    };

    componentDidUpdate() {
        const {url} = this.props;
        const {playingUrl} = this.state;

        if (url !== playingUrl) {
            this.setState({
                playingUrl: url,
                adStarted:false,
                adPlayed: false,
                playing: false,
                adPlayerHidden: true
            });

        }
    }

    handlePlay = () => {
        const t = this;
        const {adStarted, adPlayed} = t.state;
        const {onPlayStarted} = t.props;

        if (onPlayStarted && adPlayed) {
            onPlayStarted();
        }

        if (!adStarted && window.videoAd) {
            t.setState({adStarted: true, playing: false}, t.handleAd);
        }
        else {
            t.handleProgress();
        }
    };

    handleAd = () => {
        const t = this;
        const playerParent = document.querySelectorAll('#VideoPlayerAd')[0];
        const player = playerParent.getElementsByTagName('video')[0];

        t.setState({adPlayerHidden: false});

        window.videoAd
            .loadModule('AdLoader')
            .then((module) => {
                return module.AdLoader.create(
                    {
                        partnerId: '1504370',
                        category: 1015
                    },
                );
            })
            .then((adLoader) => {
                return adLoader.loadAd();
            })
            .then((adStore) => {
                // Создаем контроллер воспроизведения
                const adPlaybackController = adStore.createPlaybackController(player, playerParent);
                // Подписываемся на событие окончания рекламы
                adPlaybackController.subscribe('AdStopped', t.handleAdPlayed);
                // Запускаем проигрывание рекламы
                adPlaybackController.playAd();
            })
            // Если что-то пошло не так, логируем ошибку в консоль
            .catch((error) => {
                console.error(error);
                t.handleAdPlayed()
            });
    };

    handleAdPlayed = () => {

        setTimeout(() => {
            this.setState({adPlayed: true, playing:true});

        },10);
    };

    handleProgress = () => {
        const {courseId, lessonId, authorized} = this.props;
        const player = this.player.current;
        const time = player.getCurrentTime();

        if (time && courseId && lessonId && authorized) {
            pushPlaybackProgress(courseId, {file_index: lessonId, time_in_secs: Math.round(time)});
        }
    };

    handleReady = () => {
        const {ready} = this.state;
        const {lessonId, progress} = this.props;
        const player = this.player.current;

        if (!ready) {
            this.setState({ready: true});
            if (lessonId && progress && parseInt(lessonId) === parseInt(progress.file_index)) {
                player.seekTo(progress.time_in_secs);
            }
            else {
                player.seekTo(0);
            }
        }
    };

    onDuration = () => {

    };

    render() {
        const t = this;
        const {playing, playingUrl, adPlayed, adPlayerHidden} = t.state;
        const {onDuration = () => {}} = t.props;

        const cls_adPlayer_hidden = adPlayerHidden ? 'hidden' : '';

        const adPlayer = adPlayed ? '' : <div id="VideoPlayerAd" className={'react-player react-player-ad ' + cls_adPlayer_hidden}>
            <video src={playingUrl} width="100%" height="100%" />
        </div>;

        return (
            <div className="player_v2_wrapper">
                <ReactPlayer
                    ref={this.player}
                    url={playingUrl}
                    progressInterval={10000}
                    onProgress={t.handleProgress}
                    onReady={t.handleReady}
                    onPlay={t.handlePlay}
                    onDuration={onDuration}
                    controls
                    light
                    playing={playing}
                    id="VideoPlayer"
                    className="react-player"
                    width="100%"
                    height="100%"/>

                {adPlayer}

            </div>
        );
    }
}

export default PlayerWithAd;