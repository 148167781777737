import React from 'react';

import PageParamsHook from '../utils/page-params-hook';
import Spinner from "../utils/spinner";

class Statistics extends React.Component {
    constructor (props) {
        super();

        this.state = {
            loading: false,
            pageParams: null
        }
    }

    onParams = (pageParams) => {
        this.setState({pageParams: pageParams});
    };

    render() {
        const t = this;
        const {loading, pageParams} = t.state;

        const paramsHook = pageParams ? '' : <PageParamsHook onParams={t.onParams}/>;

        return (
            <div className="statistics_wrapper">
                {paramsHook}

                <Spinner show={loading || !pageParams} />
            </div>

        );
    }
}

export default Statistics;