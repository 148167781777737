import React from 'react';
import Hammer from 'react-hammerjs';

import './index.css';
import {ArrowPrev} from "../../../utils/icons";

class MainReviews extends React.Component {
    constructor (props) {
        super();

        this.feedWrapper = React.createRef();
        this.feed = React.createRef();

        this.state = {
            curStep: 0,
            stepSize: 336,
            prevHidden: true,
            nextHidden: true
        }
    }

    componentDidMount = () => {
        this.arrowsCheck();
    };


    arrowsCheck = () => {
        const t = this;
        const {data} = t.props;
        const {curStep} = t.state;

        const wrapper = t.feedWrapper.current;
        const wrapperWidth = wrapper.clientWidth;

        let itemsLength = data.length;

        t.setState({stepSize: wrapperWidth});

        if (itemsLength === 1) {
            t.setState({prevHidden: true, nextHidden: true});
        }
        else {
            if (curStep <= 0) {
                t.setState({prevHidden: true, nextHidden: false});
            }
            else if (curStep >= itemsLength - 1) {
                t.setState({prevHidden: false, nextHidden: true});
            }
            else {
                t.setState({prevHidden: false, nextHidden: false});
            }
        }
    };

    slideNext = (dir) => {
        const t = this;
        const {data} = t.props;
        const {curStep} = t.state;

        let next = curStep + dir;

        if (next >= 0 && next <= data.length - 1) {
            t.setState({curStep: next}, t.arrowsCheck);
        }
    };


    reviewsMapper = (review, idx) => {
        const {curStep, stepSize} = this.state;
        const clsHidden = idx !== curStep ? 'item_hidden' : '';

        const icon = <svg className="ri_icon" width="27" height="20" viewBox="0 0 27 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.625 7.875C4.92188 7.875 4.33594 8.05078 3.75 8.22656V7.875C3.75 5.82422 5.39062 4.125 7.5 4.125C8.49609 4.125 9.375 3.30469 9.375 2.25C9.375 1.25391 8.49609 0.375 7.5 0.375C3.33984 0.375 0 3.77344 0 7.875V13.5C0 16.6641 2.46094 19.125 5.625 19.125C8.73047 19.125 11.25 16.6641 11.25 13.5C11.25 10.3945 8.73047 7.875 5.625 7.875ZM20.625 7.875C19.9219 7.875 19.3359 8.05078 18.75 8.22656V7.875C18.75 5.82422 20.3906 4.125 22.5 4.125C23.4961 4.125 24.375 3.30469 24.375 2.25C24.375 1.25391 23.4961 0.375 22.5 0.375C18.3398 0.375 15 3.77344 15 7.875V13.5C15 16.6641 17.4609 19.125 20.625 19.125C23.7305 19.125 26.25 16.6641 26.25 13.5C26.25 10.3945 23.7305 7.875 20.625 7.875Z" fill="#1F57C3"/>
        </svg>;

        const style = {width: stepSize}

        return (
            <div key={idx} className={'review_item ' + clsHidden} style={style}>
                <div className="ri_photo"><img src={review.image} alt="" /></div>
                <div className="ri_author_abs">{review.name}</div>
                {icon}
                <div className="ri_text">
                    {review.content}
                    <div className="ri_footer">
                      <p className="ri_author">{review.name}</p>
                      { review.link ? <div onClick={() => window.open(review.link)} className="ri_link">Читать</div> : ""}
                    </div>
                </div>
            </div>
        );
    };

    render() {
        const t = this;
        const {data} = t.props;
        const {prevHidden, nextHidden, curStep, stepSize} = t.state;


        const arrPrev = !prevHidden ? <div onClick={() => this.slideNext(-1)} className="arr arr_prev"><i><ArrowPrev /></i></div> : '';
        const arrNext = !nextHidden ? <div onClick={() => this.slideNext(1)} className="arr arr_next"><i><ArrowPrev /></i></div> : '';

        const style = {marginLeft: curStep * stepSize * -1};

        const options = {
            touchAction:'compute',
            recognizers: {
                swipe: {
                    threshold: 10
                }
            }
        };

        return (
            <div className="main_reviews_wrapper main_centrer">
                <h2>О нас говорят</h2>

                <div className="review_feed_outer">
                    {arrPrev}
                    {arrNext}

                    <div className="review_feed_inner" ref={this.feedWrapper}>
                        <div style={style} className="reviews_feed" ref={this.feed}>
                            <Hammer
                                direction={'DIRECTION_HORIZONTAL'}
                                options={options}
                                onSwipeLeft={() => this.slideNext(1)}
                                onSwipeRight={() => this.slideNext(-1)}>
                                <div className="feeder">
                                    {data.map(t.reviewsMapper)}
                                </div>
                            </Hammer>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default MainReviews;
