import React from 'react';
import {Link} from 'react-router-dom';

import './index.css';
import {Plural} from "../../../utils/utils";
import {ArrowPrev} from "../../../utils/icons";

class MainSchools extends React.Component {
    constructor (props) {
        super();

        this.feedWrapper = React.createRef();
        this.feed = React.createRef();

        this.state = {
            activeTab: props.defaultTab ? props.defaultTab : 'video',
            curStep: 0,
            stepSize: 200,
            prevHidden: true,
            nextHidden: false
        };
    }

    initialArrowsCheck = () => {
      const t = this;
      const p = t.props;
      const s = t.state;

      const wrapper = t.feedWrapper.current;
      const wrapperWidth = wrapper.clientWidth;
      const feed = t.feed.current;
      let feedWidth = feed.clientWidth;

      if (window.innerWidth <= 600) {
          feedWidth -= Math.floor(window.innerWidth/10);
      }

      let itemsLength = p.data.length;

      const itemWidth = feedWidth/itemsLength;
      const itemsVisible = Math.floor(wrapperWidth/itemWidth);

      if (itemWidth) {

          t.setState({stepSize: itemWidth})
      }
      if (itemsLength <= itemsVisible) {
          t.setState({prevHidden: true, nextHidden: true});
      }
      else {
          if (s.curStep <= 0) {
              t.setState({prevHidden: true, nextHidden: false});
          }
          else if (s.curStep >= itemsLength - itemsVisible - 1) {
              t.setState({prevHidden: false, nextHidden: true});
          }
          else {
              t.setState({prevHidden: false, nextHidden: false});
          }
      }
    };

    slideNext = (dir) => {
      const t = this;
      const p = t.props;
      const s = t.state;

      const wrapper = t.feedWrapper.current;
      const wrapperWidth = wrapper.clientWidth;
      const feed = t.feed.current;
      let feedWidth = feed.clientWidth;

      if (window.innerWidth <= 600) {
          feedWidth -= Math.floor(window.innerWidth/10);
      }

      let itemsLength = p.data.length;

      const itemWidth = feedWidth/itemsLength;
      const itemsVisible = Math.floor(wrapperWidth/itemWidth);

      let next = s.curStep + dir;

      if (next >= 0 && next <= itemsLength - itemsVisible) {
          t.setState({curStep: next}, t.initialArrowsCheck);
      }
    };

    componentDidMount = () => {
      this.initialArrowsCheck();
    }; 

    schoolsMapper = (item, idx) => {
        const no_avatar = item.avatar.length === 0;
        const avatar = !no_avatar ? <img src={item.avatar} alt="" /> : <div className="ms_letter">{item.name.split('')[0]}</div>;
        const type = item.type.length && item.type !== "online-school" ? item.type : 'Онлайн-школа';

        return (
            <div className="ms_item" key={idx}>
                <Link to={'/users/' + item.slug}>
                    <div className={no_avatar ? "ms_avatar no_img" : "ms_avatar"}>{avatar}</div>
                    <h5>{item.name}</h5>
                    <h6>{type}</h6>
                </Link>

                <p className="ms_count">
                    <Link to={'/users/' + item.slug}>{item.courses_count} курс{Plural(item.courses_count, '', 'а', 'ов')}</Link>
                </p>

            </div>

        );
    };

    render() {
        const t = this;
        const s = t.state;
        const {data} = this.props;

        const arrPrev = !s.prevHidden ? <div onClick={() => this.slideNext(-1)} className="arr arr_prev"><i><ArrowPrev /></i></div> : '';
        const arrNext = !s.nextHidden ? <div onClick={() => this.slideNext(1)} className="arr arr_next"><i><ArrowPrev /></i></div> : '';

        const style = {left: s.curStep * s.stepSize * -1};


        return (
            <div className="main_schools_wrapper">
                <h2>Школы и эксперты</h2>

                {/* <div className="ms_feed">
                    {data.map(t.schoolsMapper)}
                </div> */}
                <div className='ms_feed_outer'>
                  {arrPrev}
                  {arrNext}
                  <div className="ms_feed_wrapper" ref={this.feedWrapper}>
                    <div style={style} className="ms_feed" ref={this.feed}>
                      {data.map(t.schoolsMapper)}
                    </div>
                  </div>
                </div>
            </div>
        );
    }
}

export default MainSchools;
