import React from 'react';

import './plus.css';
import {Plus, TipPoint} from "../../utils/icons";
import {Link} from "react-router-dom";

class AuthorizedPlus extends React.Component {
    constructor (props) {
        super();
        this.state = {
            opened: false
        }
    }

    toggleOpened = () => {
        this.setState({opened: !this.state.opened});
    };

    collapse = () => {
        this.setState({opened: false});
    };

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClick, false);
    };
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClick, false);
    };

    handleClick = (e) => {
        e.stopPropagation();
        if (this.state.opened) {
            if (this.node.contains(e.target)) {
                return;
            }
            this.collapse();
        }
    };

    render() {
        const t = this;
        const p = t.props;
        const cls_opened = t.state.opened ? 'opened' : '';

        return (
            <div className="header_plus_wrapper">
                <div ref={node => t.node = node} className={'header_plus_button ' + cls_opened} onClick={t.toggleOpened}>
                    <Plus />

                    <div className="ua_dropdown">
                        <TipPoint />
                        <div className="dropdown_list">
                            <Link onClick={p.closeFunc} to={'/course/new'}>Новый курс</Link>
                            <Link onClick={p.closeFunc} to={'/blog/new'}>Новая статья</Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AuthorizedPlus;