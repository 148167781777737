import React from 'react';
import {Link} from 'react-router-dom';

import './index.css';

class MainCatalog extends React.Component {
    constructor (props) {
        super();

        this.state = {
            showAll: false
        }
    }

    catalogMapper = (el, idx) => {
        const {showAll} = this.state;

        if (idx > 7 && !showAll) {
            return '';
        }

        const {icon, image, name, slug} = el;
        const catLinkIcon = <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.25 1.1875V9.0625C12.25 9.37891 11.9688 9.625 11.6875 9.625C11.3711 9.625 11.125 9.37891 11.125 9.0625V2.55859L1.94922 11.7344C1.84375 11.8398 1.70312 11.875 1.5625 11.875C1.38672 11.875 1.24609 11.8398 1.14062 11.7344C0.929688 11.5234 0.929688 11.1367 1.14062 10.9258L10.3164 1.75H3.77734C3.49609 1.75 3.21484 1.46875 3.25 1.1875C3.25 0.871094 3.49609 0.625 3.8125 0.625H11.6875C11.9688 0.625 12.25 0.90625 12.25 1.1875Z" fill="white"/>
        </svg>;

        const catImg = image && image.length ? <img className="mc_bg" src={image} alt="" /> : '';
        const catIcon = icon && icon.length ? <div className="mc_icon"><img src={icon} alt="" /></div> : '';

        return (
            <Link key={slug} to={'/categories/' + slug}>
                {catImg}
                {catIcon}

                <div className="cat_label">
                    {name}
                    {catLinkIcon}
                </div>
                <div className="cat_fade" />
            </Link>
        )
    };

    handleShowAll = () => {
        this.setState({showAll: true});
    };

    render() {
        const {data} = this.props;
        const {showAll} = this.state;

        const arrowIcon = <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.7812 8.28125L7.53125 13.7812C7.375 13.9375 7.1875 14 7 14C6.78125 14 6.59375 13.9375 6.4375 13.7812L1.1875 8.28125C0.90625 7.96875 0.90625 7.5 1.21875 7.21875C1.5 6.9375 2 6.9375 2.28125 7.25L6.25 11.4062V0.78125C6.25 0.34375 6.5625 0.03125 6.96875 0.03125C7.34375 0.03125 7.75 0.34375 7.75 0.78125V11.4062L11.6875 7.25C11.9688 6.9375 12.4688 6.9375 12.75 7.21875C13.0625 7.5 13.0625 7.96875 12.7812 8.28125Z" fill="#1F57C3"/>
        </svg>;

        const showLink = !showAll ? <span onClick={this.handleShowAll} className="show_all">Посмотреть полный список{arrowIcon}</span> : '';

        return (
            <div className="main_catalog main_feed_wrapper">
                <h2>Категории</h2>

                <div className="mc_feed">
                    {data.map(this.catalogMapper)}
                </div>

                {showLink}
            </div>
        );
    }
}

export default MainCatalog;
