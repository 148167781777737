import React from 'react';
import Preloader from "../../../utils/preloader/index";
import DlItem from "./dl-item";
import './dl-item.css';
import {addKeyIds, generateKeyId, removeKeyIds} from "./key-ids";

class CourseRoadmapModal extends React.Component {
    constructor (props) {
        super();

        const meta = props.courseMeta;
        const data = meta['roadmap'] ? addKeyIds(meta['roadmap']) : [];

        this.state = {
            loading: false,
            maxItems: 6,
            data: data,
            valid: false
        };

        this.removeItem = this.removeItem.bind(this);
    }

    validate = (cb) => {
        const t = this;
        const {data} = t.state;

        let valid = !!data.length;

        data.forEach((el) => {
            if (!el.title.length || !el.description.length) {
                valid = false;
            }
        });

        t.setState({
            valid: valid
        }, cb)
    };

    updateData = (idx, k, v) => {
        const t = this;
        const {data} = t.state;
        const item = data[idx];

        item[k] = v;

        t.setState({data: data}, t.validate);
    };

    handleSave = () => {
        const t = this;
        const p = t.props;

        const {data} = t.state;
        const d = {'roadmap': removeKeyIds(data)};

        t.setState({loading: true});
        p.onSave(d, (r) => {
            t.setState({loading: false});
        });
    };

    dataMapper = (el, idx) => {
        const t = this;

        return <DlItem
            key={el.key_id}
            dt={el.title}
            dtLength={50}
            dtPlaceholder={'шаг ' + (idx + 1) + ' (макс. 50 символов)' }
            dtName="title"
            dd={el.description}
            ddLength={75}
            ddPlaceholder="описание (макс. 75 символов)"
            ddName="description"
            idx={idx}
            update={t.updateData}
            removeItem={t.removeItem.bind(t, el)} />
    };


    removeItem(id){
        this.setState(prevState => ({
            data: prevState.data.filter(el => el !== id )
        }), this.validate);
    }

    addItem = () => {
        const {data, maxItems} = this.state;

        if (data.length < maxItems) {
            data.push({title: '', description: '', key_id: generateKeyId()});
            this.setState({data: data}, this.validate);
        }
    };

    render() {
        const t = this;
        const {data, maxItems, loading, valid} = t.state;
        const p = t.props;

        const addButtonText = data.length ? 'добавить еще' : 'добавить';

        const preloader = loading ? <Preloader/> : '';

        return (
            <div className="course_edit_modal">
                <div className="cem_inner">
                    <div className="cem_text">
                        <h3>Блок "Процесс обучения"</h3>
                        <p className="block_description">Вы можете добавить до {maxItems} шагов, описывающих процесс прохождения курса.</p>
                    </div>
                    <div className="cem_content cem_dl">
                        {data.map(t.dataMapper)}
                        <button disabled={data.length >= maxItems} className="small light_border" onClick={t.addItem}>{addButtonText}</button>
                    </div>
                    <div className="cem_buttons">
                        <button className="small" onClick={p.onModalClose}>отмена</button>
                        <button disabled={!valid} className="small" onClick={t.handleSave}>сохранить</button>
                    </div>
                </div>

                {preloader}
            </div>
        );
    }
}

export default CourseRoadmapModal;
