import React from 'react';
import './index.css';
import ParamSchool from "./components/school";
import ParamAuthors from "./components/authors";
import ParamDuration from "./components/duration";
import ParamCourseFor from "./components/course-for";

class Params extends React.Component {
    constructor (props) {
        super();

        this.state = {
        };
    }

    render() {
        const t = this;
        const p = t.props;
        const data = p.data;
        const meta = data.meta;

        const authors = data.authors && data.authors.length ? <ParamAuthors {...p} /> : '';
        const courseFor = meta.course_for ? <ParamCourseFor {...p} /> : '';
        const duration = parseInt(meta.lessons_count) ? <ParamDuration {...p} /> : '';

        return (
            <div className="cl_params">
                <div className="cl_centrer">
                    <ParamSchool {...p} />
                    {authors}
                    {duration}
                    {courseFor}
                </div>
            </div>
        );
    }
}

export default Params;
