import React from 'react';
import './index.css';

import xmark from '../icons/xmarkSelected.svg';

class ProfileModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      active: false,
    }
  }

  render() {
    const {title = 'Title Example', width = "300px", contentStyle = {}} = this.props;
    return (
      <div style={{width}} className="profile_modal">
        <div className="profile_modal_head"></div>
        <div style={{backgroundImage: `url(${xmark})`}} className="profile_modal_close" onClick={() => this.props.closeModal()}></div>
        <div className="profile_modal_title">
          <span>{title}</span>
        </div>
        <div style={contentStyle} className="modal-content">
          {this.props.children}
        </div>
      </div>
    )
  }
}

export default ProfileModal;