import React from "react";
import {Redirect} from 'react-router-dom';
import './index.css';
import {arr2obj, checkOwner} from "../utils/utils";
import Tracker from "../utils/tracker";
import {Close} from "../utils/icons";
import LessonContent from "./content";
import LessonSidebar from "./sidebar";

class LessonV2 extends React.Component {
    constructor (props) {
        super();

        this.state = {
            authBannerClosed: false,
            sidebarOpened: false
        }
    }

    toggleSidebar = () => {
        const t = this;
        const {sidebarOpened} = t.state;

        t.setState({sidebarOpened: !sidebarOpened});
    };

    componentDidMount() {
      document.body.classList.add('lesson_view');
    }

    componentWillUnmount() {
      document.body.classList.remove('lesson_view');
    }

    render() {
        const t = this;
        const {courseData, lessonId, authorized, userData, modalAuth, freeCoursePurchase} = t.props;
        const {authBannerClosed, sidebarOpened} = t.state;

        const isFree = parseInt(courseData.regular_price) === 0;
        const isPurchased = authorized && userData.purchased && userData.purchased.indexOf(courseData.id) > -1;
        let redirect = '';

        if (!isFree && !isPurchased && !checkOwner(courseData.id, userData)) {
            redirect = <Redirect to={{pathname: '/course/' + courseData.slug}}/>
        }

        const lessons = courseData.lessons;
        const lessonsObj = arr2obj(lessons, 'id');
        const lessonData = lessonsObj[lessonId];

        if (!lessonData) {
            console.log(lessonsObj)
            return '';
        }

        const authedBanner = !authorized && !authBannerClosed ? <div className="unauthed_banner">
            <span onClick={() => modalAuth(freeCoursePurchase)}>Зарегистрируйтесь или авторизуйтесь</span> чтобы ваш прогресс сохранялся автоматически!
            <ins onClick={() => t.setState({authBannerClosed: true})}><Close /></ins>
        </div> : '';

        const type = lessonData.type ? lessonData.type : 'video';
        const content = lessonData.content ? lessonData.content : {url: lessonData.video_code, is_cloud: lessonData.is_cloud};

        if (lessonData.lesson_from) {
            content.lesson_from = lessonData.lesson_from;
        }
        if (lessonData.embed_link) {
            content.embed_link = lessonData.embed_link;
        }

        const cls_sidebar_opened = sidebarOpened ? 'sidebar_opened' : '';

        return (
            <div className={'lesson_wrapper ' + cls_sidebar_opened}>
                {authedBanner}
                <div className="lesson_dashboard">
                    <LessonSidebar {...t.props} onToggleSidebar={t.toggleSidebar} />
                    <LessonContent {...t.props} type={type} content={content} lessonId={lessonId} onToggleSidebar={t.toggleSidebar} />
                    <div onClick={t.toggleSidebar} className="lesson_overlay"> </div>
                </div>

                <Tracker id={courseData.id} event="view" childId={lessonId} />
                {redirect}
            </div>
        );
    }
}

export default LessonV2;