import React from "react";
import './index.css';

class Spinner extends React.Component {
    constructor (props) {
        super();
    }

    render() {
        const t = this;
        const {show, className} = t.props;

        const cls = className ? className : '';

        const spinner = <div className={'preloader_wrapper ' + cls}>
            <div className="logo_wrapper">
                <div className="logo">
                    <span className="w">W</span><span className="hub">H</span>
                </div>
            </div>
        </div>;

        return show ? spinner : '';
    }
}

export default Spinner;