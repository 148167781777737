import React from "react";
import ReactModal from 'react-modal';
import {Link} from 'react-router-dom';

import './index.css';
import ModalAuthWrapper from "../sign-up/modal-auth-wrapper";
import SupportModal from "./support";
import GiftModal from "./gift";
import TextModal from "./text-modal";
import AbuseModal from "./abuse";
import VideoModal from "./video-modal";

ReactModal.setAppElement('#root');

class Modal extends React.Component {
    constructor (props) {
        super();
        this.state = {
            showModal: true,
            loading: true,
            content: props.modalContent,
            additional_data: null
        };

        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleCallback = this.handleCallback.bind(this);
    }

    componentDidMount = () => {
        const pos = window.scrollY;
        document.body.style.position = 'fixed';
        document.body.style.top = `-${pos}px`;
    };

    componentWillUnmount = () => {
        const scrollY = document.body.style.top;
        document.body.style.position = '';
        document.body.style.top = '';
        window.scrollTo(0, parseInt(scrollY || '0') * -1);
    };

    // handleLoading = (loading) => {
    //     this.setState({
    //         loading: loading
    //     });
    // };

    handleCloseModal (data) {
        const p = this.props;
        if (!data) {
            data = {};
        }

        if (p.onModalClose) {
            p.onModalClose(data);
        }
    }

    handleCallback (content, additional_data) {
        if (additional_data) {
            this.setState({additional_data: additional_data});
        }
        this.setState({ content: content });

        if (content === 'close') {
            this.handleCloseModal();
        }
    }

    handleAuthSuccess = () => {
        this.props.onModalAuthSuccess();
    };

    render () {
        let content = '';
        let cls_additional = '';

        switch (this.state.content) {
            case 'auth':
                content = <ModalAuthWrapper {...this.props} onSuccess={this.handleAuthSuccess} />;
                cls_additional = 'no_padding';
                break;

            case 'invoice_generation':
                content = <div className="modal_purchase_link">
                    <p>Заказ успешно создан, счет на оплату формируется!</p>
                    <p>Через 10-15 секунд он будет доступен для скачивания в разделе "Заказы".</p>

                    <Link onClick={this.handleCloseModal} to={'/user/orders'}><button>к заказам</button></Link>
                </div>;
                break;

            case 'unsubscribe':
                content = <div className="modal_purchase_link">
                    <p>Подписка отменена!</p>
                    <button onClick={this.handleCloseModal}>закрыть</button>
                </div>;
                break;

            case 'purchase_link':
                content = <div className="modal_purchase_link">
                    <p>Сейчас вы будете перенаправлены на сайт платежного сервиса для оплаты вашего заказа. </p>
                    <p>Если вдруг это не произошло автоматически, пожалуйста, воспользуйтесь кнопкой:</p>
                    <a rel="noopener noreferrer" href={this.props.modalUrl} target="_blank"><button>перейти</button></a>
                </div>;
                break;

            case 'support':
                content = <SupportModal {...this.props} />;
                break;

            case 'abuse':
                content = <AbuseModal {...this.props} />;
                break;

            case 'gift':
                content = <GiftModal {...this.props} />;
                break;

            case 'text':
                content = <TextModal {...this.props} onCloseBtn={this.handleCloseModal} />;
                break;

            case 'video':
                content = <VideoModal {...this.props} onCloseBtn={this.handleCloseModal} />;
                cls_additional = 'no_padding';
                break;

            case 'edit_course':
                content = this.props.blockComponent;
                break;

            case 'custom':
                content = <div className="custom_modal_content">{this.props.customContent}</div>;
                break;

            default:
                content = <div>error</div>;

        }

        return (
            <ReactModal
                isOpen={true}
                onRequestClose={this.handleCloseModal}
                className={'Modal ' + cls_additional}
                overlayClassName="Overlay"
            >
                {content}
            </ReactModal>
        );
    }
}

export default Modal;