import React from "react";

import './general.css';
import ImgUpload from '../../utils/img-upload';

import TextareaAutosize from 'react-autosize-textarea';

class EditCourseCovers extends React.Component {
    constructor(props) {
        super();
    }

    render() {
        const t = this;
        const p = t.props;

        const courseId = p.courseData.id;

        const imgs = p.courseData.images_stack || {};
        const cls_preview_loaded = imgs.preview ? 'loaded' : '';
        const cls_cover_loaded = imgs.cover ? 'loaded' : '';

        const previewImg = imgs.preview ? <img src={imgs.preview.normal} alt="" /> : '';
        const coverImg = imgs.cover ? <img src={imgs.cover.normal} alt="" /> : '';

        return (
            <div className="course_edit_block ceb_general">

                <div className="course_name_block">
                    <TextareaAutosize spellCheck="false" className="course_name_input" disabled={true} value={p.courseData.title} name="courseName" placeholder="название курса" />
                </div>

                <div className="course_covers_block">
                    <div className="ce_grid">
                        <div className="ce_grid_block ce_left_block">
                            <div className="ce_upload_wrapper">
                                <h5>превью</h5>
                                <div className={cls_preview_loaded + ' ce_upload ce_upload_preview'}>
                                    <ImgUpload courseId={courseId} className="cc_preview" id="coursePreviewImg" imgType="preview" onUpload={p.reloadData} />
                                    {previewImg}
                                </div>
                                <div className="course_form_hint">*.png или *.jpg в разрешении не менее 608px х 296px</div>
                            </div>

                        </div>
                        <div className="ce_grid_block ce_right_block">
                            <h5>обложка</h5>
                            <div className={cls_cover_loaded + ' ce_upload ce_upload_cover'}>
                                <ImgUpload courseId={courseId} className="cc_cover" id="courseCoverImg" imgType="cover" onUpload={p.reloadData} />
                                {coverImg}
                            </div>
                            <div className="course_form_hint">*.png или *.jpg в разрешении не менее 1440px х 765px</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default EditCourseCovers;