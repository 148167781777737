import React from 'react';
import ProfileModal from './commonModal';
import API from '../../../../utils/api';
import _debounce from 'lodash/debounce';
import './index.css';

class AddressModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      country: "Россия",
      region: "",
      city: "",
      district: "",
      area: "",
      settlement: "",
      street: "",
      building: "",
      flat: "",
      zip: "",
      firstName: '',
      lastName: '',
      middleName: '',
      loading: {
        region: false,
        city: false,
        area: false,
        settlement: false,
        street: false,
        building: false,
      },
      suggestions: {
        region: [],
        city: [],
        area: [],
        settlement: [],
        street: [],
        building: [],
      },
      fias: {
        region: "",
        city: "",
        area: "",
        settlement: "",
        street: "",
        building: ""
      },
      focus: {
        region: true,
        city: false,
        area: false,
        settlement: false,
        street: false,
        building: false,
      }
    }
  }

  componentDidMount() {
    const { profile } = this.props.userData;
    const address = profile.address || {};

    delete address.country;

    const newState = {
      ...address,
      area: address.locality,
      firstName: profile.full_name && profile.full_name.first_name ? profile.full_name.first_name : '',
      lastName: profile.full_name && profile.full_name.surname ? profile.full_name.surname : '',
      middleName: profile.full_name && profile.full_name.middle_name ? profile.full_name.middle_name : '',
    }

    this.setState({...newState}, () =>  {
      this.loadFiases();
    });
  }

  loadFiases = () => {
    this.props.loading(true)
    const requests = [];
    
    if (this.state.region) {
      let type = 'region'
      let url = `/whub/v3/dadata/suggest?type=${type}&query=${this.state.region}&bound=${type}`

      let request = API.get(url)
        .then(data => {
          let suggest = data.data.suggestions[0];

          this.selectSuggest(type, suggest);
        })
      
      requests.push(request);
    }

    if (this.state.city) {
      let type = "city";
      let url = `/whub/v3/dadata/suggest?type=${type}&query=${this.state.city}&bound=${type}`

      let request = API.get(url)
        .then(data => {
          let suggest = data.data.suggestions[0];

          this.selectSuggest(type, suggest);
        })

      requests.push(request);
    }

    if (this.state.street) {
      let type = "street";
      let url = `/whub/v3/dadata/suggest?type=${type}&query=${this.state.street}&bound=${type}`

      let request = API.get(url)
        .then(data => {
          let suggest = data.data.suggestions[0];

          this.selectSuggest(type, suggest);
        })
      
      requests.push(request);  
    }

    Promise.allSettled(requests).then(() => {
      this.props.loading(false)
    })
  }

  suggestData = (type, query) => {
    let url = `/whub/v3/dadata/suggest?type=${type}&query=${query}&bound=${type}`

    if (type === 'city' && this.state.fias.region.length > 0) {
      url += `&locations[region_fias_id]=${this.state.fias.region}`
    }

    if (type === 'settlement' && this.state.fias.region.length > 0) {
      url += `&locations[region_fias_id]=${this.state.fias.region}`
    }

    if (type === 'area' && this.state.fias.city.length > 0) {
      url += `&locations[city_fias_id]=${this.state.fias.city}`
    }

    if (type === 'street' && this.state.fias.city.length > 0) {
      url += `&locations[city_fias_id]=${this.state.fias.city}`
    }

    if (type === 'building' && this.state.fias.street.length > 0) {
      url += `&locations[street_fias_id]=${this.state.fias.street}`
    }


    const { loading } = this.state;
    loading[type] = true;
    this.setState({loading});

    API.get(url)
    .then(data => {
      const { suggestions } = this.state;
      suggestions[type] = data.data.suggestions;

      this.setState({suggestions});
    })
    .finally(() => {
      loading[type] = false;
      this.setState({loading});
    });
  }

  debouceSuggest = _debounce((type, query) => {
    this.suggestData(type, query);
  }, 700)

  changeFocus = (type, value, fromSelect = false) => {
    const {state} = this;
    const { focus } = state;

    focus[type] = value;
    if (!value) {
      setTimeout(() => { this.setState({focus}) }, 500)
    } else {
      this.setState({focus})
    }
  }

  handleFormChange = (field, value) => {
    const { fias } = this.state;

    if (field === 'region' && value !== this.state.region) {
      fias.region = "";
    }

    if (field === 'city' && value !== this.state.city) {
      fias.city = "";
    }

    if (field === 'street' && value !== this.state.street) {
      fias.street = "";
    }

    const {state} = this;
    state[field] = value;

    const { suggestions } = state;
    suggestions[field] = [];

    this.setState({...state, suggestions, fias});

    if (value.length > 0) {
      this.debouceSuggest(field, value);
    }
  }

  saveAddress = () => {
    const address = {
      country: this.state.country,
      region: this.state.region,
      district: this.state.area,
      city: this.state.city,
      locality: this.state.area,
      street: this.state.street,
      building: this.state.building,
      flat: this.state.flat,
      zip: this.state.zip
    }

    const data = {
      address
    }

    this.props.loading(true);

    API.post('/whub/v3/profile', data)
      .then(function (r) {

      })
      .catch(function (error) {
          console.log(error);
      })
      .finally(() => {
        this.props.loading(false);
        this.props.authFunc();
        this.props.close();
      });
  }

  selectSuggest = (type, suggest) => {
    const { state } = this;
    const fias_id = suggest.data?.fias_id;
    const { region_fias_id, city_fias_id, street_fias_id, settlement_fias_id, area_fias_id } = suggest.data;
    const { city_with_type: city, 
      area_with_type: area,
      settlement_with_type: settlement,
      street_with_type: street, 
      region_with_type: region,
      flat,
      postal_code } = suggest.data;

    const { fias } = this.state;
    fias[type] = fias_id;

    if (state.region.length === 0 && region !== null) {
      this.handleFormChange('region', region);
      fias.region = region_fias_id;
    }

    if (state.city.length === 0 && city !== null) {
      this.handleFormChange('city', city);
      fias.city = city_fias_id;
    }

    if (state.settlement.length === 0 && settlement !== null) {
      this.handleFormChange('settlement', settlement);
      fias.settlement = settlement_fias_id;
    }

    if (state.area.length === 0 && area !== null) {
      this.handleFormChange('area', area);
      fias.area = area_fias_id;
    }

    if (state.street.length === 0 && street !== null) {
      this.handleFormChange('street', street);
      fias.street = street_fias_id;
    }

    if (postal_code !== null && type === 'street') {
      this.handleFormChange('zip', postal_code);
    }

    if (flat) {
      this.handleFormChange('flat', flat)
    }

    this.setState({fias});

    if (type !== 'building') {
      this.handleFormChange(type, suggest.data[`${type}_with_type`]);
    } else {
      const block = suggest.data.block || null;
      const block_type = suggest.data.block_type || null;
      const house_type = suggest.data.house_type || null;
      const house = suggest.data.house || null;
      let value = "";

      if (house_type) value += house_type
      if (house) value += ` ${house}`    
      if (block_type) value += ` ${block_type}`
      if (block) value += ` ${block}`
      
      this.handleFormChange(type, value);
    }

    this.changeFocus(type, false, true)
  }

  renderSuggestions = (type) => {
    const { suggestions } = this.state;

    return suggestions[type].map((suggest) => {
      return (
        <span onClick={() => this.selectSuggest(type, suggest)} key={suggest.value}>{suggest.value}</span>
      )
    })
  }

  render() {
    const modalStyle = {
      display: 'flex',
      flexDirection: 'column',
      gap: '10px'
    }

    return (
      <div>
        <ProfileModal contentStyle={modalStyle} title="" width="370px" closeModal={this.props.close}>
          <div className="address_modal">
            <div className="address_modal_title">
              <span>Адрес доставки</span>
            </div>

            <div>
              <div className="address_modal_input_label">
                <span>Страна</span>
              </div>
              <div className="address_modal_input">
                <input disabled value={this.state.country} onChange={(e) => this.setState({country: e.target.value})}></input>
                {/*  */}
              </div>
            </div>

            <div>
              <div className="address_modal_input_label">
                <span>Регион</span>
              </div>
              <div className="address_modal_input">
                <input 
                  value={this.state.region}
                  onChange={(e) => this.handleFormChange('region', e.target.value)}
                  onFocus={() => this.changeFocus('region', true)}
                  onBlur={() => this.changeFocus('region', false)}
                  ></input>
                
              </div>
              {this.state.focus.region ? <div className="address_modal_suggest">
                { this.state.loading.region ? <div className="address_modal_suggest_loader"></div> : "" }
                {this.renderSuggestions('region')}
              </div> : ""}
            </div>

            <div>
              <div className="address_modal_input_label">
                <span>Город</span>
              </div>
              <div className="address_modal_input">
                <input
                  value={this.state.city}
                  onChange={(e) => this.handleFormChange('city', e.target.value)}
                  onFocus={() => this.changeFocus('city', true)}
                  onBlur={() => this.changeFocus('city', false)}
                  ></input>
                
              </div>
              {this.state.focus.city ? <div className="address_modal_suggest">
                { this.state.loading.city ? <div className="address_modal_suggest_loader"></div> : "" }
                {this.renderSuggestions('city')}
              </div> : ""}
            </div>

            <div>
              <div className="address_modal_input_label">
                <span>Район</span>
              </div>
              <div className="address_modal_input">
                <input 
                  value={this.state.area}
                  onChange={(e) => this.handleFormChange('area', e.target.value)}
                  onFocus={() => this.changeFocus('area', true)}
                  onBlur={() => this.changeFocus('area', false)}
                  ></input>
                
              </div>
              {this.state.focus.area ? <div className="address_modal_suggest">
                { this.state.loading.area ? <div className="address_modal_suggest_loader"></div> : "" }
                {this.renderSuggestions('area')}
              </div> : ""}
            </div>

            <div>
              <div className="address_modal_input_label">
                <span>Населенный пункт</span>
              </div>
              <div className="address_modal_input">
                <input
                  value={this.state.settlement}
                  onChange={(e) => this.handleFormChange('settlement', e.target.value)}
                  onFocus={() => this.changeFocus('settlement', true)}
                  onBlur={() => this.changeFocus('settlement', false)}
                  ></input>
                
              </div>
              {this.state.focus.settlement ? <div className="address_modal_suggest">
                { this.state.loading.settlement ? <div className="address_modal_suggest_loader"></div> : "" }
                {this.renderSuggestions('settlement')}
              </div> : ""}
            </div>

            <div>
              <div className="address_modal_input_label">
                <span>Индекс</span>
              </div>
              <div className="address_modal_input">
                <input value={this.state.zip} onChange={(e) => this.setState({zip: e.target.value})}></input>
                
              </div>
            </div>

            <div>
              <div className="address_modal_input_label">
                <span>Улица</span>
              </div>
              <div className="address_modal_input">
                <input
                  value={this.state.street}
                  onChange={(e) => this.handleFormChange('street', e.target.value)}
                  onFocus={() => this.changeFocus('street', true)}
                  onBlur={() => this.changeFocus('street', false)}
                  ></input>
                
              </div>
              {this.state.focus.street ? <div className="address_modal_suggest">
                { this.state.loading.street ? <div className="address_modal_suggest_loader"></div> : "" }
                {this.renderSuggestions('street')}
              </div> : ""}
            </div>

            <div className="address_modal_row">
              <div>
                <div className="address_modal_input_label">
                  <span>Дом</span>
                </div>
                <div style={{width: '110px'}} className="address_modal_input">
                  <input
                    style={{width: '70px'}}
                    value={this.state.building}
                    onChange={(e) => this.handleFormChange('building', e.target.value)}
                    onFocus={() => this.changeFocus('building', true)}
                    onBlur={() => this.changeFocus('building', false)}
                    ></input>
                  
                </div>
                {this.state.focus.building ? <div className="address_modal_suggest">
                  { this.state.loading.building ? <div className="address_modal_suggest_loader"></div> : "" }
                  {this.renderSuggestions('building')}
                </div> : ""}
              </div>

              <div>
                <div className="address_modal_input_label">
                  <span>Квартира</span>
                </div>
                <div style={{width: '180px'}} className="address_modal_input">
                  <input style={{width: '135px'}} maxLength="12" value={this.state.flat} onChange={(e) => this.setState({flat: e.target.value})}></input>
                  
                </div>
              </div>
            </div>

            <div className="address_modal_title">
              <span>Получатель</span>
            </div>

            <div>
              <div className="address_modal_input_label">
                <span>Фамилия</span>
              </div>
              <div className="address_modal_input">
                <input maxLength="30" value={this.state.lastName} onChange={(e) => this.setState({lastName: e.target.value})}></input>
                
              </div>
            </div>

            <div>
              <div className="address_modal_input_label">
                <span>Имя</span>
              </div>
              <div className="address_modal_input">
                <input maxLength="25"value={this.state.firstName} onChange={(e) => this.setState({firstName: e.target.value})}></input>
                
              </div>
            </div>

            <div>
              <div className="address_modal_input_label">
                <span>Отчество (если есть)</span>
              </div>
              <div className="address_modal_input">
                <input maxLength="30" value={this.state.middleName} onChange={(e) => this.setState({middleName: e.target.value})}></input>
                
              </div>
            </div>
          
            <div>
              <div onClick={this.saveAddress} className="address_modal_save_btn">
                <span>Сохранить</span>
              </div>
            </div>
          </div>
        </ProfileModal>
      </div>
    )
  }
}

export default AddressModal;