import React from 'react';
import Preloader from "../../../utils/preloader/index";
import {arrayWithKeyIds2List, generateKeyId, list2ArrayWithKeyIds} from "./key-ids";
import ListItem from "./list-item";
import './list-item.css';
import Dropdown from "../../../utils/dropdown/dropdown";
import {arr2obj, Plural} from "../../../utils/utils";
import './future_skills.css';

class CourseFutureSkillsModal extends React.Component {
    constructor (props) {
        super();

        const meta = props.courseMeta;
        const data = meta['future_skills'] ? meta['future_skills'] : {title: '1 days', items: []};

        const title = data.title.length ? data.title : '1 days';
        const splitTitle = title.split(' ');

        this.state = {
            loading: false,
            maxItems: 6,
            valid: false,
            title: title,
            range: parseInt(splitTitle[0]),
            rangeUnit: splitTitle[1],
            items: list2ArrayWithKeyIds(data.items)
        };

        this.removeItem = this.removeItem.bind(this);
    }

    validate = (cb) => {
        const t = this;
        const {items, range} = t.state;

        let valid = !!items.length && range;

        items.forEach((el) => {
            if (!el.generated_list_item.length) {
                valid = false;
            }
        });

        t.setState({
            valid: valid
        }, cb)
    };

    updateData = (idx, k, v) => {
        const t = this;
        const {items} = t.state;

        items[idx][k] = v;

        t.setState({items: items}, t.validate);
    };

    handleSave = () => {
        const t = this;
        const p = t.props;

        const {items, range, rangeUnit} = t.state;
        const list = arrayWithKeyIds2List(items);
        const d = {'future_skills': {title: range + ' ' + rangeUnit, items: list}};

        t.setState({loading: true});
        p.onSave(d, (r) => {
            t.setState({loading: false});
        });
    };

    dataMapper = (el, idx) => {
        const t = this;

        return <ListItem
            key={el.key_id}
            value={el.generated_list_item}
            maxLength={300}
            placeholder={'навык ' + (idx + 1) + ' (макс. 300 символов)' }
            name="generated_list_item"
            idx={idx}
            update={t.updateData}
            removeItem={t.removeItem.bind(t, el)} />
    };


    removeItem(id){
        this.setState(prevState => ({
            items: prevState.items.filter(el => el !== id )
        }), this.validate);
    }

    addItem = () => {
        const {items, maxItems} = this.state;

        if (items.length < maxItems) {
            items.push({generated_list_item: '', key_id: generateKeyId()});
            this.setState({items: items}, this.validate);
        }
    };

    getSelectedOption = (arr, val) => {
        const obj = arr2obj(arr, 'value');
        if (obj[val]) {
            return obj[val];
        }
        return arr[0];
    };

    onRangeUnitChange = (d) => {
        this.setState({rangeUnit: d.data.value}, this.validate);
    };

    onRangeChange = (e) => {
        this.setState({range: e.target.value}, this.validate);
    };

    render() {
        const t = this;
        const {items, range, rangeUnit, maxItems, loading, valid} = t.state;
        const p = t.props;

        const rangeUnitsList = [
            {label: 'час' + Plural(range, '', 'а', 'ов'), value: 'hours'},
            {label: Plural(range, 'день', 'дня', 'дней'), value: 'days'},
            {label: 'недел' + Plural(range, 'я', 'и', 'ь'), value: 'weeks'},
            {label: 'месяц' + Plural(range, '', 'а', 'ев'), value: 'months'}
        ];

        const addButtonText = items.length ? 'добавить еще' : 'добавить';
        const rangeUnitSelected = t.getSelectedOption(rangeUnitsList, rangeUnit);

        const preloader = loading ? <Preloader/> : '';

        return (
            <div className="course_edit_modal cem_future_skills">
                <div className="cem_inner">
                    <div className="cem_text">
                        <h3>Блок "Навыки"</h3>
                        <p className="block_description">Вы можете добавить до {maxItems} навыков, которые студент получит за определенный отрезок времени.</p>
                    </div>
                    <div className="cem_content cem_list">
                        <div className="list_header">
                            <span>За</span>
                            <input className="inline_text_input" type="number" onInput={(e) => e.target.value = e.target.value.slice(0, 2)} value={range} onChange={t.onRangeChange} />
                            <Dropdown onChange={t.onRangeUnitChange} name="difficulty" className="dd_cats" selected={rangeUnitSelected} ddArr={rangeUnitsList} />
                            <span>вы научитесь:</span>
                        </div>
                        <ul className="course_edit_list_wrapper">
                            {items.map(t.dataMapper)}
                        </ul>
                        <button disabled={items.length >= maxItems} className="small light_border" onClick={t.addItem}>{addButtonText}</button>
                    </div>
                    <div className="cem_buttons">
                        <button className="small" onClick={p.onModalClose}>отмена</button>
                        <button disabled={!valid} className="small" onClick={t.handleSave}>сохранить</button>
                    </div>
                </div>

                {preloader}
            </div>
        );
    }
}

export default CourseFutureSkillsModal;
