import React from 'react';
import Hammer from 'react-hammerjs';
import './index.css';
import {BtnPlay} from "../../icons";
import Modal from "../../../../modal";
import PlayerV2 from "../../../../utils/player-v2";

class Advert extends React.Component {
    constructor (props) {
        super();

        this.state = {
            current: 0,
            step: 400,
            modalUrl: null
        };
    }

    componentDidMount = () => {
        this.updateStep();
    };

    updateStep = () => {
        const t = this;
        const wW = window.innerWidth;
        let step = 400;

        if (wW <= 700) {
            step = 310;
        }

        t.setState({step: step});
    };

    handleAdvertClick = (idx) => {
        const t = this;
        const p = t.props;
        const adverts = p.data.meta.self_advert;
        const advert = adverts[idx];
        const wW = window.innerWidth;
        const {current} = t.state;

        if (wW < 700 && idx !== current) {
            t.slideTo(idx);
        }

        t.setState({modalUrl: advert.url})
    };

    advertMapper = (advert, idx) => {
        return (
            <div key={idx} onClick={() => this.handleAdvertClick(idx)} className="feed_item">

                <div className="video_bg">
                    <div className="preview"><PlayerV2 playIcon={''} url={advert.url} /></div>
                    <div className="btn_play"><BtnPlay /></div>
                </div>
                <h5>{advert.title}</h5>
            </div>
        );
    };

    slide = (dir) => {
        const t = this;
        const p = t.props;
        const adverts = p.data.meta.self_advert;

        const {current} = t.state;

        const next = current + dir;

        if (next >= 0 && next < adverts.length) {
            t.slideTo(next);
        }
    };

    slideTo = (idx) => {
        this.setState({current: idx});
    };

    render() {
        const t = this;
        const {modalUrl} = t.state;
        const p = t.props;
        const adverts = p.data.meta.self_advert;

        const advertsLength = adverts.length;

        const {current, step} = t.state;

        const style = {marginLeft: current * -step};

        const arrLeft = current > 0 ? <div onClick={() => t.slide(-1)} className="arr arr_left"> </div> : '';

        const hasRightArr = current < advertsLength-1 && advertsLength > 2 && current < advertsLength - 2;
        const arrRight = hasRightArr ? <div onClick={() => t.slide(1)} className="arr arr_right"> </div> : '';

        const cls_short = advertsLength <= 2 ? 'short' : '';

        const hammerOptions = {
            touchAction:'compute',
            recognizers: {
                swipe: {
                    threshold: 10
                }
            }
        };

        const modal = modalUrl ? <Modal modalContent={'video'} url={modalUrl} onModalClose={() => t.setState({modalUrl: null})} /> : '';

        return (
            <div className="cl_adverts">
                <div className="cl_centrer">
                    <div className="adverts_carousel">
                        <div ref={t.holder} className={'feed_holder ' + cls_short}>
                            <Hammer
                                direction={'DIRECTION_HORIZONTAL'}
                                options={hammerOptions}
                                onSwipeLeft={() => this.slide(1)}
                                onSwipeRight={() => this.slide(-1)}>
                                <div className="feed" style={style}>
                                    {adverts.map(t.advertMapper)}
                                </div>
                            </Hammer>
                        </div>

                        {arrLeft}
                        {arrRight}
                    </div>
                </div>

                {modal}
            </div>
        );
    }
}

export default Advert;
