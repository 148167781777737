import React, {useEffect} from 'react';
import { Link, useLocation } from 'react-router-dom';

import '../my-courses.css';
import API from "../../utils/api";
import {parseQS} from "../../utils/utils";
import CoursePreview from "../../course-snippets/preview/v3";
import {Plus} from "../../utils/icons";
import Spinner from "../../utils/spinner";


export function Crawler(props) {
    const location = useLocation();

    const locate = () => {
        props.onLocation(parseQS(location.search.replace('?','')));
    };

    useEffect(() => {
        locate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search]);

    return <i> </i>;
}

class MyCourses extends React.Component {
    constructor (props) {
        super();
        this.state = {
            expanded: false,
            loading: false,
            courses: [],
            loadedLength: 0,
            no_courses: false,
            status: ''
        };

        this.delete = this.delete.bind(this);
    }

    loadCourses = () => {
        const t = this;
        const {status} = t.state;

        t.setState({loading: true});
        API.get('/whub/v3/course?my=1&limit=30&status=' + status)
            .then(function (r) {
                t.setState({loading: false});
                if (r.data.results) {
                    t.setState({courses: r.data.results});
                }
            })
            .catch(function (error) {
                t.setState({loading: false});
                console.log(error);
            });
    };

    delete(id){
        this.setState(prevState => ({
            courses: prevState.courses.filter(el => el !== id )
        }));
    }

    componentDidMount() {
        document.body.classList.add('no_search');
    };
    componentWillUnmount() {
        document.body.classList.remove('no_search');
    };

    onLocation = (params) => {
        const t = this;
        const {status} = t.state;
        let newStatus = 'publish';

        if (params.status && params.status !== status) {
            newStatus = params.status;
        }

        t.setState({status: newStatus}, t.loadCourses);
    };

    activeCls = (cls) => {
        const t = this;
        const {status} = t.state;

        return cls === status ? 'active' : '';
    };

    activeTab = (c) => {
      const t = this;
        const {status} = t.state;

      return c === status ? 'education_active_tab' : ''
    }
    
    expandTab = () => {
      this.setState({ expanded: !this.state.expanded });
    }

    render() {
        const t = this;
        const {courses, loading} = t.state;
        const ud = this.props.userData;

        const coursesList = courses.map((course, idx) =>
            <CoursePreview {...this.props} deleteFunc={this.delete.bind(this, course)} itemData={course} itemId={idx} key={course.id} linkType="edit" />
        );

        const createNewCourse = <div key="random_key" className="course_preview_v2 cp_create_new_course">
            <Link to="/course/new" className="course_preview_link">
                <div className="cnc_plus"><Plus /></div>
                <p className="cnc_text">создать курс</p>
            </Link>
        </div>;

        coursesList.unshift(createNewCourse);

        return (
            <div className="profile_courses_list">
                <Crawler onLocation={t.onLocation} />

                <div onClick={this.expandTab} className={"student_education_tabs " + (this.state.expanded ? "expanded_tab" :  "") }>
                    <Link className={t.activeTab('publish')} to='?status=publish'>
                    <span className="student_education_tabs_tab">
                      <span className="tab_name">Опубликованные</span> 
                      <span className="courses_counter">{ud.courses_created && ud.courses_created.publish ? ud.courses_created.publish.length : "0"}</span>
                    </span>
                    </Link>
                    <Link className={t.activeTab('draft')} to='?status=draft'>
                    <span className="student_education_tabs_tab">
                      <span className="tab_name">Черновик</span> 
                      <span className="courses_counter">{ud.courses_created && ud.courses_created.draft ? ud.courses_created.draft.length : "0"}</span>
                    </span>
                    </Link>
                </div>
                <div className="courses_block">
                    {coursesList}
                </div>
                <Spinner show={loading} />
            </div>
        );
    }
}

export default MyCourses;