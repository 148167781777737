import React from 'react';
import './bg.css';
import GeoPattern from "geopattern";

class HeaderBg extends React.Component {
    constructor (props) {
        super();

        this.state = {
        };
    }

    render() {
        const t = this;
        const p = t.props;
        const data = p.data;
        const images = data.images_stack;

        const coverSrc = images.cover && images.cover.normal ? images.cover.normal : null;
        const cover = coverSrc ? <div className="cover_wrapper"><img src={coverSrc} alt="" /></div> : <div className="cover_wrapper" style={{backgroundImage:  GeoPattern.generate(data.title, {color: '#434A5C'}).toDataUrl()}}> </div>

        return (
            <div className="header_bg">
                {cover}
            </div>
        );
    }
}

export default HeaderBg;
