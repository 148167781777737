import React from 'react';
import CoursesBlock from "../course-snippets/block/v3/courses-block";

import './search-result.css';
import Dropdown from "../utils/dropdown/dropdown";
import Checkbox from "../utils/checkbox/checkbox";
import StarRating from "../utils/star-rating";
import SearchEmpty from "./search-empty";
import TwoThumbs from "../utils/range";
import {Plug} from "../utils/icons";
import Preloader from "../utils/preloader";
import {arr2obj} from "../utils/utils";

class SearchResultV2 extends React.Component {
    constructor (props) {
        super();

        const searchParams = props.searchParams;

        this.state = {
            activeTab: 'relevant',
            filtersOpened: false,
            sort: searchParams.sortState || false,
            rating: searchParams.rating || false,
            qStr: null,
        };
    }

    makeCatsDropdownArr = (obj, first) => {
        const arr = [first];

        if (obj) {
            if (obj[204]) {
                arr.push({label: obj[204].name, value: obj[204].slug});
            }

            Object.keys(obj).forEach((n) => {
                const el = obj[n];
                if (el.id !== 204 && !el.isHidden) {
                    arr.push({label: el.name, value: el.slug});
                }
            });
        }



        return arr;
    };

    getSelectedOption = (arr, val) => {
        if (val === null) {
            return arr[0];
        }
        for (let i=0; i < arr.length; i++) {
            if (arr[i].value === val) {
                return arr[i];
            }
        }
        return arr[0];
    };

    handleDropdownChange = (d) => {
        const params = this.props.searchParams;

        const t = this;
        if (d.name === 'sort') {

            params.order = d.data.value.split('_')[0];
            params.orderby = d.data.value.split('_')[1];


        } else if (d.name === 'type') {
            console.log(d)
            params['video'] = !d.data.value;
            params['audio'] = !d.data.value;

            if (!d.clear) {
                params[d.data.value] = !params[d.data.value];
            }
        }
        else {
            params[d.name] = d.data.value;
        }

        t.props.paramsFunc(params);

    };

    handleCheckbox = e => {
        const p = this.props;
        const obj = JSON.parse(JSON.stringify(p.searchParams));

        if (e.target.type === 'radio') {
            obj[e.target.name] = e.target.value;
        } else {
            obj[e.target.name] = !obj[e.target.name];
        }

        p.filterCheckboxFunc(obj);
    };

    handleRange = values => {
        const params = {
            min_price: values[0],
            max_price: values[1]
        };
        this.setState({params}, () => this.props.paramsFunc(params));
    };


    render() {
        const t = this;
        const s = t.state;
        const p = t.props;
        const sp = p.searchParams;

        const cls_opened = s.filtersOpened ? 'opened' : '';

        const categories = p.categories;
        const categoriesWithSlug = arr2obj(categories, 'slug');

        const categoriesDefault = {label: 'все категории', value: null};
        const categoriesList = t.makeCatsDropdownArr(categories, categoriesDefault);
        const categorySelected = t.getSelectedOption(categoriesList, p.searchParams.category);

        const categoryDropdown = <Dropdown
            onChange={this.handleDropdownChange}
            name="category"
            className="dd_cats"
            selected={categorySelected}
            ddArr={categoriesList}
            clear="true" />;


        const currentCategory = categorySelected.value;

        let subcategoriesList = null;
        let subcategorySelected = null;
        let subcategoryDropdown = '';
        let slash = '';

        if (currentCategory
            && categoriesWithSlug[currentCategory].children
            && categoriesWithSlug[currentCategory].children.length)
        {
            const children = arr2obj(categoriesWithSlug[currentCategory].children, 'term_id');
            const subcategoriesDefault = {label: 'подкатегории...', value: null};
            subcategoriesList = t.makeCatsDropdownArr(children, subcategoriesDefault);
            subcategorySelected = t.getSelectedOption(subcategoriesList, p.searchParams.subcategory);
            subcategoryDropdown = <Dropdown
                onChange={this.handleDropdownChange}
                name="subcategory"
                className="dd_cats"
                selected={subcategorySelected}
                ddArr={subcategoriesList}
                clear="true" />;

            slash = <span className="category_slash">/</span>
        }

        const typeDefaultOption = [
            {label: 'любой формат', value: null},
            {label: 'только видео', value: 'video'},
            {label: 'только аудио', value: 'audio'}
        ];

        let curType = null;

        if (sp.video && !sp.audio) {
            curType = 'video';
        }
        else if (!sp.video && sp.audio) {
            curType = 'audio';
        }
        else {
            curType = null;
        }

        const typeSelected = this.getSelectedOption(typeDefaultOption, curType );

        const sortDefaultOption = [
            {label: 'сначала популярные', value: 'desc_rating'},
            {label: 'сначала новые', value: 'desc_date'}
            // {label: 'сначала старые', value: 'asc_date'},
            // {label: 'desc_author', value: 'desc_author'},
            // {label: 'asc_author', value: 'asc_author'},
            // {label: 'desc_id', value: 'desc_id'},
            // {label: 'asc_id', value: 'asc_id'},
            // {label: 'desc_include', value: 'desc_include'},
            // {label: 'asc_include', value: 'asc_include'},
            // {label: 'desc_title', value: 'desc_title'},
            // {label: 'asc_title', value: 'asc_title'},
            // {label: 'desc_relevance', value: 'desc_relevance'},
            // {label: 'asc_relevance', value: 'asc_relevance'}
        ];
        const curSort = sp.order && sp.orderby ? sp.order + '_' + sp.orderby : 'desc_rating';
        const sortSelected = this.getSelectedOption(sortDefaultOption, curSort );

        const result = this.props.dataLoaded.length ? <CoursesBlock {...this.props} data={this.props.dataLoaded} /> : <SearchEmpty {...this.props} />;

        const resultsCounter = p.loadedLength > 0 ? '(' + p.loadedLength + ')' : '';

        const preloader = this.props.loading ? <Preloader className="search_preloader" /> : '';
        const bottomLoader = this.props.bottomLoading ? <Preloader className="bottom_loader" /> : '';

        return (
            <div className="search_results">
                <div className="search_cats">
                    {categoryDropdown}
                    {slash}
                    {subcategoryDropdown}
                </div>
                <div className={'search-type'}>
                    <div>
                        <ul className={'switcher'}>
                            <li className={'active'}><h3>курсы {resultsCounter}</h3></li>
                            {/*<li><h3>школы</h3></li>*/}
                            {/*<li><h3>лекторы</h3></li>*/}
                        </ul>
                    </div>
                    <div className={'search-type__sort'}>
                        <Dropdown onChange={this.handleDropdownChange} name="sort" selected={sortSelected} ddArr={sortDefaultOption} />
                    </div>
                </div>

                <div className={'search-filters ' + cls_opened}>
                    <div className={'search-filters__top'}>
                        <Dropdown disabled icon={<Plug/>} onChange={this.handleDropdownChange} name="type" selected={typeSelected} ddArr={typeDefaultOption} clear="true" />

                        <StarRating label={'рейтинг'} name="rating" onChange={this.handleCheckbox} value={sp.rating} />
                        <Checkbox className="ch_free" label={'бесплатные'} onChange={this.handleCheckbox} name="free" checked={sp.free} />
                    </div>
                    <div className={`search-filters__bottom ${sp.free && '_disabled' }`}>
                        <TwoThumbs {...this.props} min={0} max={100000} curMin={parseInt(sp.min_price)} curMax={parseInt(sp.max_price)} step={1} onChange={this.handleRange} />
                    </div>
                </div>

                {result}
                {bottomLoader}
                {preloader}
            </div>
        );
    }
}

export default SearchResultV2;
