import React from 'react';
import LHeader from "../common/header";
import LAdvantages from "../common/advantages";
import LEasyToUse from "../common/easy-to-use";

import './index.css';
import '../common/index.css';
import LSWelcome from "./components/welcome";
import LSWhoCanUse from "./components/who-can-use";
import LPayments from "../common/payments";
import LSHowItWorks from "./components/how-it-works";
import LSComparison from "./components/comparison";
import LSPreFooter from "./components/pre-footer";
import LPForm from "../promo/components/form";
import LSFreeServices from "./components/free-services";
import LSProfitable from "./components/profitable";
import LSStatistics from "./components/statistics";
import {YMInitializer} from "react-yandex-metrika";
import {reachGoal} from "../../utils/utils";

class LandingSchool extends React.Component {
    constructor (props) {
        super();

        this.state = {
            bottomReached: false
        };
    }

    componentDidMount = () => {
        document.body.classList.add('no_header');
        window.addEventListener("scroll", this.handleScroll);
    };

    componentWillUnmount = () => {
        document.body.classList.remove('no_header');
        window.removeEventListener("scroll", this.handleScroll);
    };

    handleScroll = () => {
        const t = this;
        const {bottomReached} = t.state;
        const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
        const body = document.body;
        const html = document.documentElement;
        const footerHeight = document.querySelectorAll('.page_footer')[0].clientHeight;
        const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight) - footerHeight;
        const windowBottom = windowHeight + window.pageYOffset;
        if (windowBottom >= docHeight) {
            if (!bottomReached) {
                t.setState({bottomReached: true});
                reachGoal(80212912,'scroll_to_bottom');
            }
        }
    };

    render() {
        const env = process.env.REACT_APP_CUSTOM_NODE_ENV;
        const isProd = env !== 'dev' && env !== 'stage';
        const counter = isProd ? <YMInitializer accounts={[80212912]} options={{
            webvisor: true,
            clickmap:true,
            trackLinks:true,
            accurateTrackBounce:true}} version="2"/> : '';

        return (
            <div className="landing_wrapper school_landing_wrapper">
                {counter}
                <LHeader />
                <LSWelcome />
                <LSWhoCanUse />
                <LAdvantages />
                <LSFreeServices />
                <LEasyToUse />
                <LSProfitable />
                <LPayments />
                <LSHowItWorks />
                <LSStatistics />
                <LSComparison />
                <LSPreFooter />
                <LPForm {...this.props} />
            </div>
        );
    }
}

export default LandingSchool;
