import React from 'react';
import { Link } from 'react-router-dom';

import './index.css';

import logo from '../../../header-v2/logo.png';
import {Plural} from "../../../utils/utils";

class MainStatistics extends React.Component {
    constructor (props) {
        super();
    }

    schoolsMapper = (el, idx) => {
        const avatar = el.avatar.length ? el.avatar : logo;
        const clsBorder = !el.avatar.length ? 'with_border' : '';
        return (
            <Link key={idx} className={'si si_link ' + clsBorder} to={'/users/' + el.slug}>
                <img src={avatar} alt="" />
            </Link>
        )
    };

    render() {
        const t = this;
        const {data} = t.props;
        const {top_school, total} = data;

        return (
            <div className="main_statistics main_centrer">
                <h4>C нами уже</h4>

                <div className="stat_items">
                    <div className="si si_counter">
                        <div className="si_num">{total.students}</div>
                        <div className="si_label">студент{Plural(total.students, '', 'а', 'ов')}</div>
                    </div>
                    <div className="si si_counter">
                        <div className="si_num">{total.school}</div>
                        <div className="si_label">школ{Plural(total.school, 'а', 'ы', '')}</div>
                    </div>
                    {top_school.map(t.schoolsMapper)}
                </div>

            </div>
        );
    }
}

export default MainStatistics;
