import React from 'react';
import { Link } from 'react-router-dom';

import '../my-courses.css';
import CoursePreview from "../../course-snippets/preview/v3";

class PurchasedCourses extends React.Component {
    constructor (props) {
        super();
        this.state = {
            courses: props.courses,
            loadedLength: 0,
            no_courses: false
        };
    }

    checkAuth = () => {
        const p = this.props;

        if (!p.authorized) {
            alert('пользователь не авторизован!')
        }
        else {
            if (this.props.userData.purchased) {
                this.loadCourses();
            }
            else {
                this.setState({loading: false, no_courses: true});
            }
        }
    };

    componentDidMount() {
        // document.body.classList.add('no_search');
    };
    componentWillUnmount() {
        // document.body.classList.remove('no_search');
    };

    render() {
        const isStudent = this.props.userData.role === "student";
        const coursesData = this.state.courses;
        const archive = this.props.userData.archived_courses;
        const courses = coursesData.map((course, idx) => {
            if (archive.indexOf(course.id) === -1) {
                return <CoursePreview {...this.props} itemData={course} itemId={idx} key={course.id} linkType="purchased" />
            }
            return '';
        });

        const studentNoCoursesText = this.props.userData.purchased.length === 0 ? <p className="no_courses">У вас пока нет приобретенных курсов.</p> : '';

        const noCoursesText = !isStudent && this.state.no_courses ? <p className="no_courses">У вас нет опубликованных курсов. <Link to="/course/new">Создать курс?</Link></p> : '';
        const draftsText = !isStudent && this.state.no_courses && this.props.userData?.courses_created?.draft ? <p className="no_courses">Также вы можете опубликовать один из <Link to="/user/drafts">черновиков</Link>.</p> : '';

        return (
            <div className="profile_courses_list">

                <div className="courses_block">
                    {courses}
                </div>
                {isStudent ? studentNoCoursesText : noCoursesText}
                {draftsText}
            </div>
        );
    }
}

export default PurchasedCourses;