import React from 'react';
import './index.css';
import API from '../../../utils/api';

import citizenshipList from '../../../utils/citizenship/citizenshipList.json';

import ProfileDatePickerCalendar from './ProfileDatePickerCalendar';

import PassportModal from './modals/passportModal';
import DiplomaModal from './modals/diplomaModal';
import AddressModal from './modals/addressModal';
import SnilsModal from './modals/snilsModal';

import maleIcon from './icons/male.svg';
import femaleIcon from './icons/female.svg';
import editIcon from './icons/edit.svg';
import pinIcon from './icons/pin.svg';

import passportIcon from './icons/passport.svg';
import snilsIcon from './icons/snils.svg';
import diplomaIcon from './icons/diploma.svg';
import starIcon from './icons/star.svg';

import ruFlagIcon from './icons/ruFlag.svg';
import arrowIcon from './icons/arrow.svg';

import checkmark from './icons/smallCheckMark.svg';

class PersonalData extends  React.Component {
  constructor (props) {
    super(props)
    const ud = props.userData;
    const profile = ud.profile || {}

    this.state = {
      full_name: profile.full_name || {
        surname: "",
        first_name: "",
        middle_name: "",
      },
      citizenship: profile.citizenship || {
        country: "Россия",
      },
      date_of_birth: profile.date_of_birth || "",
      phone_number: profile.phone_number || "+7",
      position: profile.position || "",
      gender: profile.gender || "",
      showModals: {
        passport: false,
        degree: false,
        address: false,
        snils: false,
      },
      showHint: {
        cert: false,
        diploma: false
      }
    }
  }

  componentDidMount() {
    this.props.onChangeForm("personalData", this.state);
  }

  handleFormChange = (field, value) => {
    this.setState({[field]: value},
      () => this.props.onChangeForm("personalData", this.state));
  }

  handlePhoneChange = (value) => {
    if (value.match(/^\+7\d*/gm) && value.length >= 2) {
      this.setState({
        phone_number: value
      }, () => this.props.onChangeForm("personalData", this.state))
    }
  }

  handleChangeCitizenShip = (field, e) => {
    e.preventDefault();
    const { citizenship } = this.state;
    citizenship[field] = e.target.value;
    this.setState({citizenship},
      () => this.props.onChangeForm("personalData", this.state));
  }

  handleNameChange = (field, e) => {
    e.preventDefault();
    const { full_name } = this.state;
    
    if (e.target.value.match(/[-_a-zA-Zа-яА-Я]+$/gm) || e.target.value.length === 0) {
      full_name[field] = e.target.value;
      this.setState({full_name},
        () => this.props.onChangeForm("personalData", this.state));
    }
  }

  handleGenderChange = (gender) => {
    this.setState({gender})
  }

  handleSelectedGender = (gen) => {
    return gen === this.state.gender ? ' selected_item' : '';
  }

  handleShowHint = (type, show) => {
    const {showHint} = this.state;
    showHint[type] = show;
    this.setState({showHint});
  }

  showPassportModal = () => {
    const modals = this.state.showModals;
    modals.passport = true;
    this.setState({showModals: modals});
  }

  closePassportModal = () => {
    const modals = this.state.showModals;
    modals.passport = false;
    this.setState({showModals: modals});
  }

  showDegreeModal = () => {
    const modals = this.state.showModals;
    modals.degree = true;
    this.setState({showModals: modals});
  }

  closeDegreeModal = () => {
    const modals = this.state.showModals;
    modals.degree = false;
    this.setState({showModals: modals});
  }

  showAddressModal = () => {
    const modals = this.state.showModals;
    modals.address = true;
    this.setState({showModals: modals});
  }

  closeAddressModal = () => {
    const modals = this.state.showModals;
    modals.address = false;
    this.setState({showModals: modals});
  }

  showSnilsModal = () => {
    const modals = this.state.showModals;
    modals.snils = true;
    this.setState({showModals: modals});
  }

  closeSnilsModal = () => {
    const modals = this.state.showModals;
    modals.snils = false;
    this.setState({showModals: modals});
  }

  isSnilsAccepted = () => {
    const ud = this.props.userData;
    const { profile } = ud;

    if (profile && profile.snils && profile.snils.length > 0) {
      return true
    }
    
    return false;
  }

  isPassportAccepted = () => {
    const ud = this.props.userData;
    const { profile } = ud;
    const {series, number, issued, department_code, date_issued } = profile.passport;
    const {passport_1} = profile.documents;

    if (!series || !number || !issued || !department_code || !date_issued || !passport_1 ) {
      return false;
    }
    
    return true;
  }

  isDataFilled = () => {
    const { profile } = this.props.userData;
    const full_name = profile.full_name || {};
    const firstName = full_name.first_name || "";
    const lastName = full_name.surname || "";
    const middleName = full_name.middle_name || "";

    if (firstName.length === 0 || lastName.length === 0 || middleName.length === 0) {
      return false
    } 

    return true;
  }

  saveData = () => {
    const t = this;
    const loading = this.props.setLoading;

    const data = {
      full_name: this.state.full_name,
      date_of_birth: this.state.date_of_birth,
      citizenship: this.state.citizenship,
      gender: this.state.gender,
      phone_number: this.state.phone_number,
      position: this.state.position
    }

    loading(true);

    API.post('/whub/v3/profile', data)
      .then((r) => {
        loading(false);
        t.props.authFunc();
      })
      .catch((error) => {
        loading(false);
        console.log(error);
      });

  }

  renderAddress = () => {
    const { profile } = this.props.userData;
    const address = profile.address || {};
    const full_name = profile.full_name || {};

    const addressString = [];

    addressString.push(address.zip || "");
    addressString.push(address.city || "");
    addressString.push(address.street || "");
    addressString.push(address.building || "");
    addressString.push(address.flat || "");

    const firstName = full_name.first_name || "";
    const lastName = full_name.surname || "";
    const middleName = full_name.middle_name || "";

    return (
      <div className="student_profile_layout_address">
        <span>{addressString.filter(el => el.length >= 1).join(', ')}</span>
        <span>{lastName} {firstName} {middleName}</span>
      </div>
    )
  }

  renderCitizenshipOptions = () => {
    const citizenships = citizenshipList.map(el => {
      return el.label.substring(0,1) + el.label.toLowerCase().substring(1);
    })

    return (
      citizenships.map(citizenship => {
        return (
          <option key={citizenship}>{citizenship}</option>
        )
      })
    )
  }

  render() {
    return (
      <div>

        { this.state.showModals.passport && <PassportModal {...this.props} close={() => this.closePassportModal()} loading={this.props.setLoading}/> }
        { this.state.showModals.degree && <DiplomaModal {...this.props} close={() => this.closeDegreeModal()} loading={this.props.setLoading}/> }
        { this.state.showModals.address && <AddressModal {...this.props} close={() => this.closeAddressModal()} loading={this.props.setLoading} /> }
        { this.state.showModals.snils && <SnilsModal {...this.props} close={() => this.closeSnilsModal()} loading={this.props.setLoading}/> }

        <div className="student_profile_layout_heading">Личная Информация</div>

        <div className="student_profile_layout_account_heading">
          <span>Пожалуйста, указывайте настоящие данные, они будут вписаны в сертификаты и дипломы</span>
        </div>

        <div className="student_profile_layout_statuses">
          <div className="student_profile_layout_statuses_status">
            <div className={`student_profile_layout_statuses_status_icon ${this.isDataFilled() ? '' :  'not-filled'}`} style={{backgroundImage: `url(${starIcon})`}}>
              <span>W</span>
            </div>
            <div className="student_profile_layout_statuses_status_link">
              <span>Сертификат W-Hub</span>
            </div>
            <div 
              className="student_profile_layout_statuses_status_hint"
              onMouseOver={() => this.handleShowHint('cert', true)}
              onMouseOut={() => this.handleShowHint('cert', false)}>
              <span>?</span>
              { this.state.showHint.cert ?
              <div className="student_profile_layout_statuses_status_hint_text student_profile_layout_statuses_status_hint_text_left">
                <span>Для получения «Сертификатов» необходимо заполнить: Фамилию, Имя, Отчество</span>
              </div> : ''}
            </div>
          </div>

          <div className="student_profile_layout_statuses_status">
            <div className="student_profile_layout_statuses_status_icon_def">
              <div style={{backgroundImage: `url(${diplomaIcon})`}}></div>
            </div>
            <div className="student_profile_layout_statuses_status_text"> 
              <span>Диплом и удостоверение</span>
            </div>
            <div 
              className="student_profile_layout_statuses_status_hint"
              onMouseOver={() => this.handleShowHint('diploma', true)}
              onMouseOut={() => this.handleShowHint('diploma', false)}>
              <span>?</span>
              { this.state.showHint.diploma ?
              <div className="student_profile_layout_statuses_status_hint_text student_profile_layout_statuses_status_hint_text_right">
                <span>Для получения «Диплома о профессиональной переподготовке» или «Удостоверение о повышении квалификации», необходимо заполнить все поля личных данных и загрузить Паспорт, СНИЛС и Диплом.</span>
              </div> : ''}
            </div>
          </div>
        </div>

        <div className="student_profile_form_row">
          <div className='student_profile_form_input_label'>
            <span>Фамилия</span>
          </div>
          <div className="student_profile_form_input">
            <input maxLength="30" value={this.state.full_name.surname} onChange={(e) => this.handleNameChange("surname", e)}></input>
          </div>
        </div>

        <div className="student_profile_form_row">
          <div className='student_profile_form_input_label'>
            <span>Имя</span>
          </div>
          <div className="student_profile_form_input">
            <input maxLength="25" value={this.state.full_name.first_name} onChange={(e) => this.handleNameChange("first_name", e)}></input>
          </div>
        </div>

        <div className="student_profile_form_row">
          <div className='student_profile_form_input_label'>
            <span>Отчество (если есть)</span>
          </div>
          <div className="student_profile_form_input">
            <input maxLength="30" value={this.state.full_name.middle_name} onChange={(e) => this.handleNameChange("middle_name", e)}></input>
          </div>
        </div>

        <div className="student_profile_form_row">
          <div className='student_profile_form_input_label'>
            <span>Дата рождения</span>
          </div>
          <div>
            <ProfileDatePickerCalendar {...this.props} value={this.state.date_of_birth} onChange={(value) => this.handleFormChange("date_of_birth", value)}/> 
          </div>
        </div>

        <div className="student_profile_form_row">
          <div className='student_profile_form_input_label'>
            <span>Гражданство</span>
          </div>
          <div className="student_profile_form_input">
            <select value={this.state.citizenship.country} onChange={(e) => this.handleChangeCitizenShip("country", e)}>
                {this.renderCitizenshipOptions()}
            </select>
            <div className="diploma_modal_input_arrow" style={{backgroundImage: `url(${arrowIcon})`}}></div>
          </div>
        </div>

        <div className="student_profile_form_row">
          <div className='student_profile_form_input_label'>
            <span>Пол</span>
          </div>
          <div className="student_profile_form_g_selector">
            <div className={"student_profile_form_g_selector_item" + this.handleSelectedGender('m')}
              onClick={() => this.handleGenderChange('m')}>
              {<img alt="" src={maleIcon} />}
            </div>
            <div className={"student_profile_form_g_selector_item"  + this.handleSelectedGender('f')}
              onClick={() => this.handleGenderChange('f')}>
              {<img alt="" src={femaleIcon} />}
            </div>
          </div>
        </div>

        <div className="student_profile_form_row">
          <div className='student_profile_form_input_label'>
            <span>Телефон</span>
          </div>
          <div className="student_profile_form_input student_profile_form_input_withPhone">
            <div className="student_profile_form_input_phone">
              <div className="student_profile_form_input_phone_flag" style={{backgroundImage: `url(${ruFlagIcon})`}}></div>
              <div className="student_profile_form_input_phone_select" style={{backgroundImage: `url(${arrowIcon})`}}></div>
            </div>
            <input maxLength="12" type="phone" value={this.state.phone_number} onChange={(e) => this.handlePhoneChange(e.target.value)}></input>
          </div>
        </div>

        <div className="student_profile_form_row">
          <div className='student_profile_form_input_label'>
            <span>Должность</span>
          </div>
          <div className="student_profile_form_input">
            <input value={this.state.position} onChange={(e) => this.handleFormChange("position", e.target.value)}></input>
          </div>
        </div>

        <div className="student_profile_form_save_button" onClick={this.saveData}>Сохранить</div>

        <div className="student_profile_form_data_divider"></div>
        <div className="student_profile_layout_heading">Документы</div>
        <div className="student_profile_layout_account_heading">
          <span>Документы необходимо загрузить для получения диплома при прохождении курсов повышения квалификации или профессиональной переподготовки</span>
        </div>

        <div className="student_profile_form_data_documents_container">
          <div className="student_profile_form_row">
            <div className='student_profile_form_input_label'>
              <span>Паспорт</span>
            </div>
            <div
              onClick={() => {this.showPassportModal()}}
              className={`student_profile_form_data_document ${this.isPassportAccepted() ? "student_profile_form_data_document_accepted" : ""}`}>
              <div style={{backgroundImage: `url(${passportIcon})`}} className="student_profile_form_data_document_icon"></div>
              <div className="student_profile_form_data_document_text">
                <span>{this.isPassportAccepted() ? "Подтвержден" : "Загрузить файл" }</span>
              </div>
              <div style={{backgroundImage: `url(${this.isPassportAccepted() ? checkmark : pinIcon})`}} className="student_profile_form_data_document_icon"></div>
            </div>
          </div>

          <div className="student_profile_form_row">
            <div className='student_profile_form_input_label'>
              <span>СНИЛС</span>
            </div>
            <div onClick={() => {this.showSnilsModal()}}
            className={`student_profile_form_data_document ${this.isSnilsAccepted() > 0 ? "student_profile_form_data_document_accepted" : ""}`}>
              <div style={{backgroundImage: `url(${snilsIcon})`}} className="student_profile_form_data_document_icon"></div>
              <div className="student_profile_form_data_document_text">
                <span>{this.isSnilsAccepted() ? this.props.userData.profile.snils : "Предоставить данные"}</span>
              </div>
              <div style={{backgroundImage: `url(${this.isSnilsAccepted() ? checkmark : pinIcon})`}} className="student_profile_form_data_document_icon"></div>
            </div>
          </div>

          <div className="student_profile_form_row">
            <div className='student_profile_form_input_label'>
              <span>Диплом</span>
            </div>
            <div onClick={() => {this.showDegreeModal()}} className="student_profile_form_data_document">
              <div style={{backgroundImage: `url(${diplomaIcon})`}} className="student_profile_form_data_document_icon"></div>
              <div className="student_profile_form_data_document_text">
                <span>Загрузить файл</span>
              </div>
              <div style={{backgroundImage: `url(${pinIcon})`}} className="student_profile_form_data_document_icon"></div>
            </div>
          </div>
        </div>

        <div className="student_profile_form_data_divider"></div>

        <div className="student_profile_layout_heading">
          <span>Адреса доставки</span>
          <div 
            onClick={() => this.showAddressModal()}
            style={{cursor: 'pointer', backgroundImage: `url(${editIcon})`}}
            className="student_profile_form_data_document_icon">
          </div>
        </div>
        {this.renderAddress()}
      </div>
    )
  }
}

export default PersonalData;