import React from 'react';
import API from '../../../../utils/api';
import ProfileModal from '../../student-profile/modals/commonModal';

import './nameChangeModal.css';

class NameChangeModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      nickname: props.userData.slug || "",
      error: null,
    }
  }

  handleChange = (e) => {
    const {value} = e.target;
    if (value.match(/[-_a-zA-Z0-9]+$/gm) || value.length === 0) {
      this.setState({nickname: value})
    }
  }

  handleSave = () => {
    if (this.props.userData.slug !== this.state.nickname) {
      this.props.loading(true);

      API.put(`/whub/v3/user/${this.props.userData.id}`, {nicename: this.state.nickname})
          .then((r) => {
            console.log(r);
            this.props.loading(false);
            if (r.data.error) {
              this.setState({error: r.data.error})
            } else {
              this.props.authFunc();
              this.props.close();
            }
          })
          .catch((error) => {
            this.props.loading(false);
            console.log(error);
          });
    } else {
      this.setState({error: 'Ник совпадает с текущим'})
    }
  }

  render() {
    return (
      <div>
        <ProfileModal title="" width="420px" closeModal={this.props.close}>
          <div className="change_name_modal">
            <div className="change_name_modal_title">
              <span>Имя пользователя</span>
            </div>

            <div className="change_name_modal_desc">
              <span>Придумайте себе уникальное имя пользователя (nickname), по нему пользователи платформы будут узнавать тебя в чатах и форумах.</span>
              <span>Ваше общедоступное имя пользователя совпадает с адресом вашей страницы школы на W-hub.</span>
            </div>

            {this.state.error ? <div className="change_name_modal_error">
              <span>{this.state.error}</span>
            </div> : ""}

            <div className="change_name_modal_input">
              <input value={this.state.nickname} onChange={this.handleChange} />
              <span className="change_name_modal_input_desc">Можно использовать буквы латинского алфавита, цифры и точки</span>
            </div>

            <div className="change_name_modal_footer">
              <div onClick={() => this.props.close()} className="change_name_modal_footer_decline">
                <span>Отменить</span>
              </div>

              <div onClick={() => this.handleSave()} className="change_name_modal_footer_confirm">
                <span>Сохранить</span>
              </div>
            </div>
          </div>
        </ProfileModal>
      </div>
    )
  }
}

export default NameChangeModal;