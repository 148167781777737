import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {CourseRatingIcon} from "../../../utils/icons";
import {Plural, html2plaintext} from "../../../utils/utils";

import calendarIcon from "../../../user/ico/calendar.svg";

import './index.css'
import API from "../../../utils/api";
import SimpleDropdown from "../../../utils/simple-dropdown";
import CourseLevel from "../../../utils/course-level";
import PatternPlug from "../../../utils/pattern-plug";
import Spinner from "../../../utils/spinner";
import CourseButton from "../../button";

import favDefaultIcon from "../../../course-v3/landing/components/header/icons/favoritesGray.svg";
import favClickedIcon from "../../../course-v3/landing/components/header/icons/favoritesRed.svg";

class CoursePreview extends React.Component {
    constructor (props) {
        super();

        const data = props.itemData;
        this.state = {
            published: data.status === 'publish',
            loading: false
        }
    }

    checkAuthorization = (cb) => {
      const t = this;
      const {authorized, modalAuth} = t.props;

      if (authorized) {
          cb()
      }
      else {
          modalAuth(cb, false);
      }
    };

    addFavourite = () => {
      const t = this;
      const {reloadUserData, itemData} = t.props;
      this.setState({loading: true})
      API.post('/whub/v1/favorite/' + itemData.id)
          .then((r) => {
              reloadUserData();
              this.setState({loading: false})
          })
          .catch((error) => {
              console.log(error.response);
              this.setState({loading: false})
          });
    };

    handleClick = () => {
        if (this.props.closeFunc) {
            this.props.closeFunc();
        }
    };

    handlePurchaseClick = () => {
        const t = this;
        const {data, onPurchase} = t.props;

        t.setState({loading: true});

        onPurchase(data, () => {
            t.setState({loading: false});
        });
    };

    deleteCourse = () => {
        const t = this;
        t.setState({loading: true});
        API.delete('/whub/v1/course/' + this.props.itemData.id)
            .then(function (r) {
                t.setState({loading: false});
                t.props.deleteFunc();
            })
            .catch(function (error) {
                t.setState({loading: false});
                alert(error)
            });
    };

    removeFavourite = (with_del = true) => {
        const t = this;
        t.setState({loading: true});
        API.post('/whub/v1/favorite/' + this.props.itemData.id)
            .then(function (r) {
                if (with_del) { 
                  t.props.deleteFunc();
                } else {
                  t.props.reloadUserData(t.props.reloadCourses());
                }

                t.setState({loading: false});
            })
            .catch(function (error) {
                t.setState({loading: false});
                alert(error)
            });
    };

    publishCourse = () => {
        const t = this;
        const data = t.props.itemData;
        const courseId = data.id;
        const d = {id: courseId};
        t.setState({loading: true});

        API.put('/whub/v1/course/publish/' + courseId, d)
            .then(function (r) {
                t.setState({loading: false, published: true});
                t.props.authFunc();
            })
            .catch(function (error) {
                t.setState({loading: false});
                alert(error)
            });
    };

    hideCourse = () => {
        const t = this;
        const data = t.props.itemData;
        const courseId = data.id;
        t.setState({loading: true});

        API.put('/whub/v1/course/update/' + courseId, {status: 'draft'})
            .then(function (r) {
                t.setState({loading: false, published: false});
                t.props.authFunc();

            })
            .catch(function (error) {
                t.setState({loading: false});
                alert(error)
            });
    };

    archivateCourse = () => {
        const t = this;
        const p = t.props;
        const data = t.props.itemData;
        const courseId = data.id;
        t.setState({loading: true});

        API.post('/whub/v1/archive/' + courseId)
            .then(function (r) {
                console.log(r)
                p.reloadUserData();
                t.setState({loading: false});
            })
            .catch(function (error) {
                t.setState({loading: false});
                alert(error)
            });
    };

    unarchivateCourse = () => {
        const t = this;
        const p = t.props;
        const data = t.props.itemData;
        const courseId = data.id;
        t.setState({loading: true});

        API.delete('/whub/v1/archive/' + courseId)
            .then(function (r) {
                console.log(r)
                p.reloadUserData();
                t.setState({loading: false});
            })
            .catch(function (error) {
                t.setState({loading: false});
                alert(error)
            });
    };

    unfollowCourse = () => {
        const t = this;
        const p = t.props;
        const data = t.props.itemData;
        const courseId = data.id;
        t.setState({loading: true});

        API.delete('/whub/v3/subscribe/' + courseId, {data: {entity: 'course'}})
            .then(function (r) {
                p.reloadUserData(p.reloadCourses());

                t.setState({loading: false});
            })
            .catch(function (error) {
                console.log(error.response);

                t.setState({loading: false});
            });
    }

    handleGiftSelect = () => {
        const t = this;
        const p = t.props;

        p.onGiftSelect(p.itemData);
    };

    handleFavClick = (e) => {
      e.stopPropagation();
      
      const t = this;

      t.checkAuthorization(() => {
          t.addFavourite();
      });
    }

    getDateStart = (date) => {
      const parseDate = new Date(date);
      return moment(parseDate).format('DD MMMM YYYY')
    }

    render() {
        const t = this;
        const {loading, published} = t.state;
        const {authorized, userData, itemData, linkType} = t.props;
        const {author_data} = itemData;
        const isStudent = userData && userData.role === 'student';
        const isFavourited = userData && userData.favourites && userData.favourites.length > 0 && userData.favourites.indexOf(itemData.id) > -1;

        if (itemData.product_type && itemData.product_type === 'audio') {
            console.log('book!')
            return '';
        }

        const baseUrl = '/course/';
        const url = baseUrl + itemData.slug;

        const purchased = authorized && userData.purchased && userData.purchased.indexOf(itemData.id) > -1;

        let title = itemData.title || '';

        if (title && title.length && title.length > 50) {
            title = title.substring(0,50) + '...';
        }


        let img = '';
        let src = null;
        if (itemData.images_stack) {
            const stack = itemData.images_stack;

            if (stack.preview) {
                src = stack.preview.normal;
                img = <img src={src} alt="" />;
            }
            else if (stack.cover) {
                src = stack.cover.normal;
                img = <img src={src} alt="" />;
            }
        }

        let category = '';
        if (itemData.categories && itemData.categories[0]) {
            const categoryData = itemData.categories[0]
            category = categoryData.name;
        }

        const hasAuthor = author_data && author_data.display_name && author_data.display_name.length;
        const author = hasAuthor ? 'от ' + author_data.display_name : '';

        const meta = itemData.meta || {};

        let lessons = '';
        let lessonsCount = meta.lessons_count || null;
        if (lessonsCount) {
            lessons = <p className="cp_lessons_count">{lessonsCount} урок{Plural(lessonsCount, '', 'а', 'ов')}</p>;
        }

        let teaser = '';
        if (itemData.short_description) {
            teaser = <p className="teaser">{html2plaintext(itemData.short_description)}</p>;
        }


        let linksDropdownList = [];
        if (linkType) {

            if (linkType === 'edit') {
                linksDropdownList.push(published ? <span key="k0" onClick={this.hideCourse}>Скрыть</span> : <span key="k0" onClick={this.publishCourse}>Опубликовать</span>);
                linksDropdownList.push(<Link key="k1" onClick={this.handleClick} to={baseUrl + itemData.id + '/edit/general'}>Редактировать</Link>);
                if (!parseInt(itemData.total_sales)) {
                    linksDropdownList.push(<span key="k2" onClick={this.deleteCourse}>Удалить</span>);
                }
            }

            if (linkType === 'favourite') {
                linksDropdownList.push(<span key="k0" onClick={this.removeFavourite}>Убрать</span>);
            }

            if (linkType === 'purchased') {
                linksDropdownList.push(<span key="k0" onClick={this.archivateCourse}>Архивировать</span>);
            }

            if (linkType === 'archived') {
                linksDropdownList.push(<span key="k0" onClick={this.unarchivateCourse}>Восстановить</span>);
            }

            if (linkType === 'entries') {
              linksDropdownList.push(<span key="k0" onClick={this.unfollowCourse}>Отписаться</span>);

              if (isFavourited) {
                linksDropdownList.push(<span key="k1" onClick={() => this.removeFavourite(false)}>Убрать</span>);
              }
            }
        }
        const linksDropdownButton = <div className="ld_button"><ins><i></i><i></i><i></i></ins></div>
        const linksDropdown = linksDropdownList.length ? <SimpleDropdown className="cp_links_dropdown" direction="top-left" list={linksDropdownList} clickArea={linksDropdownButton} /> : '';


        const rating = parseInt(itemData.average_rating) ? <CourseRatingIcon rating={itemData.average_rating} /> : '';
        const cls_purchased = purchased ? 'purchased' : '';

        const date_start = itemData.meta && itemData.meta.date_start ? 
        <div className="additional_info_bar_date">
          <div className="additional_info_bar_date_icon" style={{backgroundImage: `url(${calendarIcon})`}}></div>
          <span>{this.getDateStart(itemData.meta.date_start)}</span>
        </div> : "";

        const plug = !src ? <PatternPlug title={title} /> : '';

        const onGoingIco = <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22.1875 13.75H12.8125C11.875 13.75 11.25 14.4531 11.25 15.3125V24.6875C11.25 25.625 11.875 26.25 12.8125 26.25H22.1875C23.0469 26.25 23.75 25.625 23.75 24.6875V15.3125C23.75 14.4531 23.0469 13.75 22.1875 13.75ZM29.6094 15.2344L26.25 17.5781V22.5L29.6094 24.8438C30.3125 25.3125 31.25 24.8438 31.25 24.0625V16.0156C31.25 15.2344 30.3125 14.7656 29.6094 15.2344ZM20 0C8.90625 0 0 8.98438 0 20C0 31.0938 8.90625 40 20 40C31.0156 40 40 31.0938 40 20C40 8.98438 31.0156 0 20 0ZM20 36.25C11.0156 36.25 3.75 28.9844 3.75 20C3.75 11.0938 11.0156 3.75 20 3.75C28.9062 3.75 36.25 11.0938 36.25 20C36.25 28.9844 28.9062 36.25 20 36.25Z" fill="white"/>
        </svg>        

        const mask = <div className="cp_online_mask">
          {onGoingIco}
          <span>Идет</span>
        </div>

        const percents = itemData.progress_percentage ? itemData.progress_percentage : null;

        const progressBar = percents > 0 && isStudent ? <div className="cp_progress_wrapper">
          <div style={{width: `${percents}%`}} className="cp_progress_bar"></div>
        </div> : ''

        const isOnlineCourse = itemData.meta && itemData.meta.hasOwnProperty('online_course_status');
        const startDate = itemData.meta ? moment(itemData.meta.date_start) : null;
        const started = itemData.meta ? startDate.diff(moment()) < 0 : null;

        const favouriteIcon = isFavourited ? favClickedIcon : favDefaultIcon ;
        const favouriteBlock = isStudent && authorized ? <div onClick={(e) => this.handleFavClick(e)} style={{backgroundImage: `url(${favouriteIcon})`}} className="cp_fav_switcher"></div> : '';

        return (
            <div className={'course_preview ' + cls_purchased}>
                {favouriteBlock}
                <Link onClick={this.handleClick} to={url} className="course_preview_link">
                    <div className="additional_info_bar">
                      {isOnlineCourse && date_start ? date_start : ""}
                    </div>

                    <div className="img_wrapper">
                        {started ? mask : ''}
                        {plug}
                        {img}
                        {progressBar}

                        <div className="cp_params">
                            {rating}
                            {lessons}
                            <CourseLevel lvl={meta.difficulty || 0} />
                        </div>
                        <div className="cp_fade"> </div>
                    </div>
                </Link>

                <h4>{category} {author}</h4>
                <Link onClick={this.handleClick} to={url} className="course_preview_link">
                    <h3>{html2plaintext(title)}</h3>
                    {teaser}
                </Link>

                <div className="button_holder">
                    <CourseButton
                        {...this.props}
                        onClick={this.handleClick}
                        onPurchase={this.handlePurchaseClick}
                        data={itemData}/>

                    {linksDropdown}
                </div>

                <Spinner show={loading} />
            </div>

        );
    }
}

export default CoursePreview;
