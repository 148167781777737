import React from 'react';
import { Link } from 'react-router-dom';

import '../my-courses.css';
import API from "../../utils/api";
import {Plus} from "../../utils/icons";
import Spinner from "../../utils/spinner";
import {Crawler} from "./my-courses";
import BlogItem from "../../course-snippets/blog/item";

class Posts extends React.Component {
    constructor (props) {
        super();
        this.state = {
            loading: false,
            expanded: false,
            posts: [],
            status: ''
        };
    }

    loadPosts = () => {
        const t = this;
        const {status} = t.state;

        t.setState({loading: true});
        API.get('/whub/v3/post?my=1&posts_per_page=30&status=' + status)
            .then(function (r) {
                t.setState({loading: false, posts: r.data});
            })
            .catch(function (error) {
                console.log(error.response);
                t.setState({loading: false});
            });
    };

    componentDidMount() {
        document.body.classList.add('no_search');
    };
    componentWillUnmount() {
        document.body.classList.remove('no_search');
    };

    onLocation = (params) => {
        const t = this;
        const {status} = t.state;
        let newStatus = 'publish';

        if (params.status && params.status !== status) {
            newStatus = params.status;
        }

        t.setState({status: newStatus}, t.loadPosts);
    };

    activeCls = (cls) => {
        const t = this;
        const {status} = t.state;

        return cls === status ? 'active' : '';
    };

    activeTab = (c) => {
      const t = this;
        const {status} = t.state;

      return c === status ? 'education_active_tab' : ''
    }

    render() {
        const t = this;

        const {posts, loading} = t.state;

        const postsList = posts.map((post, idx) =>
            <BlogItem {...t.props} post={post} key={post.id} itemId={idx} />
        );

        const createNewPost = <div key="random_key" className="cp_create_new_course cp_new_article">
            <Link to="/blog/new" className="course_preview_link">
                <div className="cnc_plus"><Plus /></div>
                <p className="cnc_text">создать статью</p>
            </Link>
        </div>;

        postsList.unshift(createNewPost);

        return (
            <div className="profile_courses_list">
                <Crawler onLocation={t.onLocation} />

                {/* <div className="status_switcher">
                    <Link className={t.activeCls('publish')} to='?status=publish'>Опубликованные</Link>
                    <span>|</span>
                    <Link className={t.activeCls('draft')} to='?status=draft'>Черновики</Link>
                </div> */}

              <div onClick={this.expandTab} className={"student_education_tabs " + (this.state.expanded ? "expanded_tab" :  "") }>
                  <Link className={t.activeTab('publish')} to='?status=publish'>
                  <span className="student_education_tabs_tab">
                    <span className="tab_name">Опубликованные</span> 
                    <span className="courses_counter">{posts ? posts.length : ""}</span>
                  </span>
                  </Link>
                  <Link className={t.activeTab('draft')} to='?status=draft'>
                  <span className="student_education_tabs_tab">
                    <span className="tab_name">Черновик</span> 
                    <span className="courses_counter">{posts ? posts.length : ""}</span>
                  </span>
                  </Link>
              </div>

                <div className="courses_block">
                    {postsList}
                </div>


                <Spinner show={loading} />
            </div>
        );
    }
}

export default Posts;