import React from 'react';
import ReactPlayer from 'react-player'

import './index.css';
import {pushPlaybackProgress} from "../utils";

class GreetingPlayer extends React.Component {
    constructor (props) {
        super();
        this.player = React.createRef();

        this.state = {
            ready: false
        }
    }

    componentDidMount() {
        const t = this;
        const p = t.props;
        if (!ReactPlayer.canPlay(p.url) && p.onCanNotPlay) {
            p.onCanNotPlay();
        }
    };
    componentWillUnmount() {
    };

    handleProgress = () => {
        const p = this.props;
        const player = this.player.current;
        const time = player.getCurrentTime();

        if (time && p.courseId && p.lessonId && p.authorized) {
            pushPlaybackProgress(p.courseId, {file_index: p.lessonId, time_in_secs: Math.round(time)});
        }
    };

    handleReady = () => {
        const s = this.state;
        const p = this.props;
        const player = this.player.current;

        if (!s.ready) {
            this.setState({ready: true});
            if (p.lessonId && p.progress && parseInt(p.lessonId) === parseInt(p.progress.file_index)) {
                player.seekTo(p.progress.time_in_secs);
            }
            else {
                player.seekTo(0);
            }
        }
    };

    render() {
        const t = this;
        const p = t.props;

        return (
            <div className="player_v2_wrapper">
                <ReactPlayer
                    ref={this.player}
                    url={p.url}
                    progressInterval={10000}
                    onProgress={t.handleProgress}
                    onReady={t.handleReady}
                    onPlay={p.handlePlay}
                    onPause={p.handlePause}
                    playing={p.playing}
                    className="react-player"
                    width="100%"
                    height="100%"/>
            </div>
        );
    }
}

export default GreetingPlayer;