import React from 'react';
import KinescopePlayer from '@kinescope/react-kinescope-player';

import './index.css';
import {pushPlaybackProgress} from "../utils";

class Kinescope extends React.Component {
    constructor (props) {
        super();
        this.player = React.createRef();

        this.state = {
            ready: false
        }
    }
    handlePlay = () => {
        const {onPlayStarted} = this.props;
        if (onPlayStarted) {
            onPlayStarted(true);
        }
        this.handleProgress();
    };

    handleProgress = () => {
        const {courseId, lessonId, authorized} = this.props;
        const player = this.player.current;
        const getTime = player.getCurrentTime();

        getTime.then((time) => {
            if (time && courseId && lessonId && authorized) {
                pushPlaybackProgress(courseId, {file_index: lessonId, time_in_secs: Math.round(time)});
            }
        });


    };

    handleReady = (data) => {
        const {ready} = this.state;
        const {lessonId, progress, onDuration} = this.props;
        const player = this.player.current;


        if (!ready) {
            this.setState({ready: true});
            if (onDuration && data.duration) {
                onDuration(data.duration);
            }

            if (lessonId && progress && parseInt(lessonId) === parseInt(progress.file_index)) {
                player.seekTo(progress.time_in_secs);
            }
            else {
                player.seekTo(0);
            }
        }
    };

    render() {
        const t = this;
        const {url} = t.props;
        const videoId = url.split('io/')[1];

        return (
            <div className="player_v2_wrapper">
                <KinescopePlayer
                    ref={this.player}
                    className="react-player"
                    videoId={videoId}
                    onProgress={t.handleProgress}
                    onReady={t.handleReady}
                    onPlay={t.handlePlay}
                    width="100%"
                    height="100%" />
            </div>
        );
    }
}

export default Kinescope;