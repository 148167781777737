import React from 'react';
import moment from 'moment';

import arrowIco from '../profile-forms/student-profile/icons/arrow.svg'

import '../payments.css';

import API from "../../utils/api";
import Preloader from "../../utils/preloader";

class UserPayments extends React.Component {
  constructor (props) {
    super();

    this.state = {
        loading: true,
        limit: 10,
        page: 1,
        pages: 1,
        orders: []
    };
  }

  loadPayments = (offset = 0) => {
    API.get(`/whub/v3/order?limit=${this.state.limit}&offset=${offset}`).then(result => {
      const { data } = result;
      this.setState({ orders: data.orders, pages: data.max_num_pages, loading: false});
    }).catch(error => {
      console.log(error);
      this.setState({ loading: false});
    });
  }
  
  changePagination(value) {
    const offset = (value - 1) * this.state.limit;
    this.setState({page: value, loading: true});
    this.loadPayments(offset);
  }

  renderPaginationOptions() {
    const options = Array.apply(0, Array(this.state.pages)).map((el, i) => {
      return (
        <option key={(i+1)*54}>{i + 1}</option>
      )
    });

    return options;
  }

  componentDidMount() {
    this.loadPayments();
  }; 

  render() {
    const isSchool = this.props.userData.role === 'school';
    const paymentStatuses = {
      pending: "В обработке",
      failed: "Не прошел",
      completed: "Выполнен",
      refunded: "Возврат"
    }

    const preloader = this.state.loading ? <Preloader/> : '';

    return (
      <div>
        <table className="orders_table">
          <thead>
            <tr>
              <th>Дата</th>
              <th>Заказ №</th>
              <th>{ isSchool ? "Наименование курса" : "Описание Заказа"}</th>
              {isSchool ? <th>Студент</th> : null}
              <th>Сумма платежа</th>
              <th>Статус платежа</th>
            </tr>
          </thead>
          <tbody>
            {this.state.orders.map((order, idx) => {
              return (
                <tr key={order.order_id}>
                  <td>{moment(order.date.date).format('YYYY-MM-DD HH:mm')}</td>
                  <td>{order.order_id}</td>
                  <td>{order.item ? order.item.name : ""}</td>
                  {isSchool ? <td>{order.customer || ""}</td> : ""}
                  <td>{order.item ? order.item.total : 0}</td>
                  <td>{paymentStatuses[order.status]}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
        { this.state.pages > 1 ? <div className="payments_pagination_selector">
          <select value={this.state.page} onChange={(e) => this.changePagination(e.target.value)}>
            {this.renderPaginationOptions()}
          </select>
          <div className="payments_pagination_selector_ico" style={{backgroundImage: `url(${arrowIco})`}}></div>
        </div> : "" }
        {preloader}
      </div>
    )
  }
}

export default UserPayments;