import React from 'react';

import './index.css';

class LPSplash extends React.Component {
    constructor (props) {
        super();

        this.state = {
        };
    }

    componentDidMount = () => {
    };

    componentWillUnmount = () => {
    };

    render() {
        return (
            <div className="lp_splash_wrapper">
                <div className="l_content">
                    <p>Воспользуйтесь возможностью удобного и бесплатного размещения на витрине маркетплейса образовательных курсов</p>
                    <p>Мы обеспечим продвижение ваших продуктов, поможем в увеличении продаж и расскажем пользователям о ваших курсах</p>
                </div>
            </div>
        );
    }
}

export default LPSplash;
