import React from "react";
import { Redirect } from 'react-router-dom';

import {useLocation} from 'react-router-dom';

import './index.css';
import '../styles/auth-tabs.css';
import PasswordResetForm from "./reset-form";
import {parseQS} from "../utils/utils";
import SignUpConfirm from "../sign-up/confirm";

function PasswordReset(props) {
    let params = parseQS(useLocation().search.replace('?',''));


    const redirect = props.authorized && !params.set && !params.crunch && !props.userData.pass_not_set ? <Redirect to={{pathname: "/"}}/> : '';

    let header = params.set || params.setPassword ? 'создание пароля' : 'восстановление пароля';
    if (params.crunch) {
        header = 'подтверждение регистрации'
    }

    const content = params.crunch ? <SignUpConfirm {...props} /> : <PasswordResetForm {...props} params={params} />;

    return (
        <div className="password_reset_wrapper">
            <h4>{header}</h4>
            {content}
            {redirect}
        </div>
    );
}


export default PasswordReset;