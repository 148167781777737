import React from 'react';
import { Redirect } from 'react-router-dom';

import './index.css';
import {EmailTest, reachGoal, setToken} from "../../../../utils/utils";
import API from "../../../../utils/api";
import Preloader from "../../../../utils/preloader";
import {withId} from "react-yandex-metrika";

class LPForm extends React.Component {
    constructor (props) {
        super();

        this.state = {
            valid: false,
            email: '',
            description: '',
            checked: false,
            sent: false,
            loading: false
        };
    }

    componentDidMount = () => {
    };

    componentWillUnmount = () => {
    };

    validate = () => {
        const t = this;
        const s = t.state;

        if (s.description && s.email && EmailTest(s.email) && s.checked) {
            t.setState({valid: true})
        }
        else {
            t.setState({valid: false})
        }

        console.log(s)
    };

    handleChange = (e) => {
        const obj = {};
        obj[e.target.name] = e.target.value;
        this.setState(obj, this.validate);
    };

    handleCheckbox = () => {
        const t = this;
        const s = t.state;
        t.setState({checked: !s.checked}, t.validate);
    };

    handleSubmit = (e) => {
        const t = this;
        const s = t.state;
        const data = {
            email: s.email,
            description: s.description
        };

        e.preventDefault();

        t.setState({loading: true});
        API.post('/whub/v1/request', data)
            .then(function (r) {

                t.setState({loading: false, sent: true});

                reachGoal(80212912,'form_submit');
                t.instantRegistration(s.email);
            })
            .catch(function (error) {
                if (error.response) {
                    const message = error.response.data.message;
                    alert(message);
                }
                t.setState({loading: false});
            });
    };

    instantRegistration = (email) => {
        const t = this;
        const data = {
            email: email,
            role: 'school'
        };

        t.setState({loading: true});
        API.post('/wp/v2/users/register', data, {headers: {'Authorization': ''}})
            .then(function (r) {
                console.log(r)
                t.setState({loading: false});

                if (r.data.token) {

                    t.instantAuth(r.data);

                    withId(62510686)('reachGoal','registration');
                }
                else {
                    alert('произошла ошибка!')
                }
            })
            .catch(function (error) {
                if (error.response) {
                    const message = error.response.data.message;
                    alert(message);
                }
                t.setState({loading: false});
            });
    };

    instantAuth = (data) => {
        const t = this;
        setToken(data.token, {login: true});

        t.props.authFunc();
    };

    render() {
        const t = this;
        const s = t.state;
        const cls_checked = s.checked ? 'checked' : '';
        const cls_sent = s.sent ? 'sent' : '';
        const preloader = s.loading ? <Preloader /> : '';

        const redirect = t.props.authorized && s.sent ? <Redirect to={{pathname: "/user/profile"}}/> : '';

        return (
            <div className="lp_form_wrapper">
                <div className="l_centrer">
                    <div className={'form_outer ' + cls_sent}>
                        {preloader}
                        <div className="form_part_left">
                            <div className="sent_message">
                                <h2>Cпасибо за вашу заявку!</h2>
                                <h3>Вам на почту отправлено письмо для завершения регистрации на <nobr>W-HUB</nobr>!</h3>
                            </div>
                            <div className="form_description">
                                <h2>Мы готовы предложить особые условия для школ, желающих разместить более 10 курсов либо эксклюзивный контент</h2>
                                <h3>Заполните короткую форму для участия в программе, чтобы мы могли рассмотреть вашу заявку</h3>
                            </div>
                        </div>
                        <div className="form_inner">
                            <form onSubmit={t.handleSubmit}>
                                <div className="lp_input_wrapper">
                                    <label htmlFor="lp_form_mail">
                                        <span>Адрес электронной почты</span>
                                        <input name="email" onChange={t.handleChange} type="text" id="lp_form_mail" />
                                    </label>
                                    <p className="note">На эту почту будет зарегистрирована школа и активировано предложение</p>
                                </div>

                                <div className="lp_input_wrapper">
                                    <label htmlFor="lp_form_description">
                                        <span>Ваше сообщение</span>
                                        <textarea
                                            name="description"
                                            onChange={t.handleChange}
                                            id="lp_form_description"
                                            placeholder="Расскажите нам о своих курсах, тематике и кол-во, чтобы мы могли правильно подобрать вам программу">

                                        </textarea>
                                    </label>
                                </div>

                                <div className="lp_input_wrapper">
                                    <div className={'lp_checkbox_switcher ' + cls_checked} onClick={t.handleCheckbox}>
                                        <div className="lp_checkbox"></div>
                                        <div className="lp_checkbox_label">Я принимаю условия <a href="/docs/offer_school.docx">Лицензионного договора-оферты</a> и даю свое согласие на обработку моих персональных данных, определенных <a target="_blank" href="/docs/privacy_policy.docx">Политикой конфиденциальности</a></div>
                                    </div>
                                </div>

                                <div className="button_holder">
                                    <button type="submit" disabled={!s.valid}>Отправить</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {redirect}
            </div>
        );
    }
}

export default LPForm;
