import React from 'react';
import Hammer from 'react-hammerjs';
import './index.css';

class Perks extends React.Component {
    constructor (props) {
        super();

        this.state = {
            screens: 1,
            current: 0,
            step: 1200
        };

        this.holder = React.createRef();
        this.feed = React.createRef();
    }

    componentDidMount = () => {
        this.updateStep();
        this.updateScreens();
    };

    updateStep = () => {
        const t = this;
        const wW = window.innerWidth;
        let step = t.holder.current.clientWidth + 30;

        if (wW <= 700) {
            step = wW - 60;
        }

        t.setState({step: step});
    };

    updateScreens = () => {
        const t = this;
        const p = t.props;
        const perks = p.data.meta.perks;
        const wW = window.innerWidth;

        let screens = Math.ceil(perks.length / 2);

        if (wW <= 900) {
            screens = perks.length;
        }

        t.setState({screens: screens});
    };

    handlePerkClick = (idx) => {
        const t = this;
        const wW = window.innerWidth;
        const {current} = t.state;

        if (wW < 700 && idx !== current) {
            t.slideTo(idx);
        }

    };

    perksMapper = (perk, idx) => {
        return (
            <dl key={idx} onClick={() => this.handlePerkClick(idx)} className="feed_item">
                <dt>{perk.title}</dt>
                <dd>{perk.description}</dd>
            </dl>
        );
    };

    bullits = () => {
        const t = this;
        const bullits = [];

        const {current, screens} = t.state;

        for (let i=0;i<screens;i++) {
            const cls_active = i === current ? 'active' : '';
            bullits.push(<li key={i} onClick={() => t.slideTo(i)} className={cls_active}> </li>);
        }

        return bullits;
    };

    slide = (dir) => {
        const t = this;

        const {current, screens} = t.state;

        const next = current + dir;

        if (next >= 0 && next < screens) {
            t.slideTo(next);
        }
    };

    slideTo = (idx) => {
        this.setState({current: idx});
    };

    render() {
        const t = this;
        const p = t.props;
        const perks = p.data.meta.perks;

        const {current, step, screens} = t.state;

        const style = {marginLeft: current * -step};

        const arrLeft = current > 0 ? <div onClick={() => t.slide(-1)} className="arr arr_left"> </div> : '';
        const arrRight = current < screens-1 ? <div onClick={() => t.slide(1)} className="arr arr_right"> </div> : '';

        const hammerOptions = {
            touchAction:'compute',
            recognizers: {
                swipe: {
                    threshold: 10
                }
            }
        };

        return (
            <div className="cl_perks">
                <div className="cl_centrer">
                    <h2>Особенности</h2>

                    <div className="perks_carousel">
                        <div ref={t.holder} className="feed_holder">
                            <Hammer
                                direction={'DIRECTION_HORIZONTAL'}
                                options={hammerOptions}
                                onSwipeLeft={() => this.slide(1)}
                                onSwipeRight={() => this.slide(-1)}>
                                <div ref={t.feed} className="feed" style={style}>
                                    {perks.map(t.perksMapper)}
                                </div>
                            </Hammer>
                        </div>

                        {arrLeft}
                        {arrRight}

                        <ul className="bullits">
                            {t.bullits()}
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

export default Perks;
