import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {PriceFormat} from "../../utils/utils";
import './index.css';
import ProductTypeIcon from "../../utils/product-type-icon";

class CourseButton extends React.Component {
    constructor (props) {
        super();
    }

    render() {
        const t = this;
        const {data, onClick, onPurchase, authorized, userData, isFeatured} = t.props;

        const productType = data.product_type;
        const productIcon = <ProductTypeIcon type={productType} />;

        const finishedLessons = data.progress_percentage && data.progress_percentage > 0 ? data.progress_percentage : 0;

        let cls = ['course_button'];
        let buttonText = 'Бесплатно';
        let oldPrice = '';
        let button;

        const purchased = authorized && userData.purchased && userData.purchased.indexOf(data.id) > -1;
        const subscribed = authorized && userData.subscriptions && userData.subscriptions.courses && userData.subscriptions.courses.indexOf(data.id) > -1;
        const isStudent = authorized && userData && userData.role === 'student';
        const isOnlineCourse = data.meta && data.meta.hasOwnProperty('online_course_status');
        const hasPrice = parseInt(data.price);
        let url = '/course/' + data.slug;

        if (isFeatured) {
            cls.push('featured_button');
        }

        if (hasPrice) {
            buttonText = PriceFormat(data.price) + ' ₽';

            if (data.discount_amount_percentage) {
                cls.push('discount_button');
                oldPrice = <div className="old_price"> {PriceFormat(data.regular_price)} ₽</div>
            }
        }

        if (purchased) {
            buttonText = 'смотреть';

            if (finishedLessons > 0 && isStudent) {
              const nextLesson = data.lessons ? data.lessons.filter(lesson => lesson.studyStatus && lesson.studyStatus === 'pending')[0] : [];

              if (nextLesson && nextLesson.id) {
                url = url + '/' + nextLesson.id
              }

              cls.push('continue')
              buttonText = 'продолжить'

              if (finishedLessons === 100) {
                buttonText = 'завершен'
              }
            }
        }

        if (isOnlineCourse) {
          // let available = data.meta.online_course_status.available_for_purchase;
          let startDate = moment(data.meta.date_start);
          let sellStartDate = data.meta.online_course_status.sell_starts_date ? data.meta.online_course_status.sell_starts_date : 0;
          let now = moment();
          let courseStarted = startDate.diff(now) < 0;
          const dateNow = new Date();
          const sellsStarted = dateNow.getTime() >= sellStartDate;

          if (subscribed && !purchased && !sellsStarted) {
            cls.push('subscribed_online')
            buttonText = 'записан'
          }
  
          if (subscribed && sellsStarted && hasPrice && !purchased) {
            cls.push('purchase_online')
  
            buttonText = 'Оплатить ' + PriceFormat(data.price) + ' ₽';
          }
          
          if (purchased && !courseStarted) {
            buttonText = 'Старт ' + moment(startDate).format('DD.MM.YY')
            
            if (hasPrice) {
              cls.push('purchase_online')
            }
          }

          if (purchased && courseStarted) {
            buttonText = 'начать'

            if (finishedLessons > 0 && isStudent) {
              const nextLesson = data.lessons ? data.lessons.filter(lesson => lesson.studyStatus && lesson.studyStatus === 'pending')[0] : [];

              if (nextLesson && nextLesson.id) {
                url = url + '/' + nextLesson.id
              }

              cls.push('continue')
              buttonText = 'продолжить'

              if (finishedLessons === 100) {
                buttonText = 'завершен'
              }
            }

            if (hasPrice) {
              cls.push('purchase_online')
            }
          }

        }

        const stockStatus = data.stock_status;
        if (stockStatus !== 'outofstock') {
            if (hasPrice && !purchased) {
                button = <div className={cls.join(' ')} onClick={onPurchase}>
                    <div className="button_text">{buttonText}{oldPrice}</div>
                    <div className="button_hover">{productIcon}</div>
                </div>
            }
            else {
                button = <Link className={cls.join(' ')} onClick={onClick} to={url}>
                    <div className="button_text">{buttonText}</div>
                    <div className="button_hover">{productIcon}</div>
                </Link>
            }
        }       

        return button;
    }
}

export default CourseButton;
