import React from 'react';

import './index.css';

import c1 from './images/cloud/1.svg';
import c2 from './images/cloud/2.svg';
import c3 from './images/cloud/3.svg';
import c4 from './images/cloud/4.svg';
import c5 from './images/cloud/5.svg';

import vimeo from './vimeo.svg';
import youtube from './youtube.svg';
import link from './link.svg';

import presentation from './images/presentation.png';
import docs from './images/docs.png';
import sheets from './images/sheets.png';
import zoom from './images/zoom.png';
import webinar from './images/webinar.png';

class LEasyToUse extends React.Component {
    constructor (props) {
        super();

        this.state = {
        };
    }

    componentDidMount = () => {
    };

    componentWillUnmount = () => {
    };

    render() {
        return (
            <div className={'ls_easy_to_use_wrapper ' + this.props.className}>
                <div className="l_content">
                    <h2><nobr>W-HUB</nobr> — это удобно</h2>

                    <div className="storage">
                        <h3>Храните свой контент на безопасной облачной платформе W-HUB</h3>

                        <div className="cloud_solution">
                            <div className="cs_item">
                                <div className="img_wrapper iw1">
                                    <img src={c1} alt={''} />
                                </div>

                                <h5>W-HUB<br />
                                    Облачная платформа</h5>

                                <p>Масштабируемая платформа для хранения, обработки и визуализации данных любого типа и объема. Хранение осуществляется на серверах расположенных на территории России.</p>
                            </div>
                            <div className="cs_item">
                                <div className="img_wrapper iw2">
                                    <img src={c2} alt={''} />
                                </div>

                                <h5>W-HUB<br />
                                    Видеохостинг</h5>

                                <p>Собственный видеохостинг с функцией транскодирования аудио и видеофайлов, обеспечивающий их конвертацию для воспроизведения на различных устройствах.</p>
                            </div>
                            <div className="cs_item">
                                <div className="img_wrapper iw3">
                                    <img src={c3} alt={''} />
                                </div>

                                <h5>W-HUB<br />
                                    Сервис ускоренной доставки контента</h5>

                                <p>Ускоренная загрузка и доставка контента конечным пользователям, благодаря механизмам маршрутизации и кеширования контента на географически распределенных CDN-серверах.</p>
                            </div>
                            <div className="cs_item">
                                <div className="img_wrapper iw4">
                                    <img src={c4} alt={''} />
                                </div>

                                <h5>W-HUB<br />
                                    Видеоплеер</h5>

                                <p>Удобный, быстрый плеер W-HUB обеспечивает высокое качество воспроизведения без задержек, адаптируется под любые устройства и форматы экранов. Мобильная версия позволяет удобно смотреть видео и со смартфона.</p>
                            </div>
                            <div className="cs_item">
                                <div className="img_wrapper iw5">
                                    <img src={c5} alt={''} />
                                </div>

                                <h5>Защита<br />данных</h5>

                                <p>Постоянно улучшающаяся защита от скачивания, а также защита от несанкционированного доступа, вирусов и DDOS-атак</p>
                            </div>
                        </div>

                        <div className="plate_section">
                            <h4>Вебинары</h4>
                            <ul className="plates plates_webinar">
                                <li>
                                    <div className="img_wrapper">
                                        <img src={youtube} alt={''} />
                                    </div>
                                    <p>Youtube</p>
                                </li>
                                <li>
                                    <div className="img_wrapper">
                                        <img className="img_zoom" src={zoom} alt={''} />
                                    </div>
                                    <p>Zoom</p>
                                </li>
                                <li>
                                    <div className="img_wrapper">
                                        <img className="img_webinarru" src={webinar} alt={''} />
                                    </div>
                                    <p>Webinar.ru</p>
                                </li>
                            </ul>
                        </div>

                        <div className="plate_section">
                            <h4>Презентации</h4>
                            <ul className="plates plates_pdf">
                                <li>
                                    <div className="img_wrapper">
                                        <img src={link} alt={''} />
                                    </div>
                                    <p>Прямые ссылки</p>
                                </li>
                                <li>
                                    <div className="img_wrapper">
                                        <img src={vimeo} alt={''} />
                                    </div>
                                    <p>Dropbox *</p>
                                </li>
                                <li>
                                    <div className="img_wrapper">
                                        <img className="img_g_service" src={presentation} alt={''} />
                                    </div>
                                    <p>Google Презентации</p>
                                </li>
                                <li>
                                    <div className="img_wrapper">
                                        <img className="img_g_service" src={docs} alt={''} />
                                    </div>
                                    <p>Google Документы</p>
                                </li>
                                <li>
                                    <div className="img_wrapper">
                                        <img className="img_g_service" src={sheets} alt={''} />
                                    </div>
                                    <p>Google Таблицы</p>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default LEasyToUse;
