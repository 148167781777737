import React from 'react';
import {TypeText, TypeVideo, TypeWebinar} from "../../icons";
import {sortLessonsByType} from "../../duration-utils";

import './index.css';

class LandingInfoBar extends React.Component {
  constructor(props) {
    super()
  }

  defineCourseType = (sorted, count) => {
    let type = 'text';

    const typesMap = {
        video: {
            icon: <TypeVideo />,
            title: 'Видеокурс'
        },
        text: {
            icon: <TypeText />,
            title: 'Обучающий курс'
        },
        webinar: {
            icon: <TypeWebinar />,
            title: 'Вебинар'
        },
        online: {
            icon: <TypeWebinar />,
            title: 'Онлайн курс'
        }
    };

    if (sorted.video && sorted.video.count > count/2) {
        type = 'video';
    }

    if (sorted.webinar) {
        type  = 'webinar';

        if (sorted.webinar.count > 1) {
            type = 'online'
        }
    }

    return typesMap[type];
  };
  

  render() {
    const t = this;
    const { data } = t.props;

    const sortedLessons = sortLessonsByType(data.lessons);
    let courseType = t.defineCourseType(sortedLessons.sorted, data.lessons.length);

    if (data.meta && data.meta.online_course_status) {
      courseType = {
          icon: <TypeWebinar />,
          title: 'Онлайн курс'
      };
    }

    return (
      <div className="l_info_bar">
        <div className="l_info_bar_type">{courseType.title}</div>
        <span className="l_info_bar_title">{data.title}</span>
        <span className="l_info_bar_author">от {data.author_data.display_name}</span>
      </div>
    )
  }
}


export default LandingInfoBar;
