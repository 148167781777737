import React from 'react';

import './index.css';

class FilesList extends React.Component {
    constructor (props) {
        super();
    }

    filesMapper = (item) => {
        let thumbnail = '';
        let title = item.title;

        if (item.status) {
            if (item.status === 'publish') {

                if (item.previews && item.previews.length) {
                    thumbnail = <img src={item.previews[1]} alt='' />
                }
            }
            else {
                let percent = item.transcode && item.transcode.progress ? ': ' + item.transcode.progress + '%' : '';
                title += ' (идет транскодинг' +  percent +', по окончании обработки вы сможете использовать видео в видеоуроках)';
            }
        }

        return (
            <tr key={item.id}>
                <td className="thumbnail">{thumbnail}</td>
                <td>{title}</td>
            </tr>
        )
    };


    render() {
        const t = this;
        const {files, reloadFiles} = t.props;

        return (
            <div className="files_list">
                <h2>Загруженные файлы:</h2>

                <button onClick={reloadFiles} className="v2 small btn_reload">Обновить</button>
                <table>
                    <tbody>{files.map(t.filesMapper)}</tbody>
                </table>

            </div>
        );
    }
}

export default FilesList;