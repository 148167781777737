import React from "react";
import {DropdownArrow, Close, TipPoint} from "../icons";

import './dropdown.css';

class Dropdown extends React.Component {
    constructor (props) {
        super();

        this.state = {
            selected: props.selected,
            opened: false
        }
    }

    handleSelect = (e, clear) => {
        let obj = {
            name: this.props.name,
            data: {label: e.target.getAttribute('data-label'), value: e.target.getAttribute('data-value')}
        };

        if (clear) {
            obj.clear = true;
        }

        this.setState({selected: obj.data});

        this.collapse();

        if (!this.props.disabled) {
            this.props.onChange(obj);
        }

    };

    handleClear = (e) => {
        this.handleSelect(e, true);
    };

    toggleOpened = () => {
        if (!this.props.disabled) {
            this.setState({opened: !this.state.opened});
        }
    };

    collapse = () => {
        this.setState({opened: false});
    };

    renderDropdownList = () => {
        let arr = [];
        const t = this;
        const p = t.props;
        const list = p.ddArr;

        list.forEach((el, i) => {
            let cls = '';

            if (el.label === t.props.selected.label) {
                cls = 'selected';
            }

            if (!el.value && el.label !== t.props.selected.label && !t.props.numerous) {
                // skip default if not selected

            }
            else {
                arr.push(<li className={cls} onClick={t.handleSelect} data-value={el.value} data-label={el.label} key={i}>{el.label}</li>);
            }
        });

        return arr;
    };

    renderCaret = () => {
        const t = this;
        const p = t.props;
        let caret = <DropdownArrow />;

        if (p.clear) {
            const list = p.ddArr;

            list.forEach((el, i) => {
                if (!el.value && el.label !== t.props.selected.label) {
                    caret =
                        <span className={'clear'} onClick={this.handleClear} data-value={el.value} data-label={el.label}><Close/></span>;
                }
            });
        }

        return caret;
    };

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClick, false);
    };
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClick, false);
    };

    handleClick = (e) => {
        e.stopPropagation();
        if (this.state.opened) {
            if (this.node.contains(e.target)) {
                return;
            }
            this.collapse();
        }
    };

    render() {
        const cls = this.props.className ? this.props.className : '';
        const cls_opened = this.state.opened ? 'opened' : '';
        const cls_empty = !this.props.selected || !this.props.selected.value ? 'empty' : '';
        const cls_disabled = this.props.disabled ? 'disabled' : '';

        const selected_label = this.props.selected ? this.props.selected.label : '';
        const selected_value = this.props.selected ? this.props.selected.value : '';

        return (
            <div ref={node => this.node = node} className={'dropdown ' + cls + ' ' + cls_opened + ' ' + cls_empty + ' ' + cls_disabled}>
                <span onClick={this.toggleOpened} className={'dropdown_opener'}>
                    {this.props.icon}
                    <span data-value={selected_value}>{selected_label}</span>
                </span>
                {this.renderCaret()}

                <div className="dropdown_list_wrapper">
                    <TipPoint />
                    <ul className={'dropdown_list '}>
                        {this.renderDropdownList()}
                    </ul>
                </div>
            </div>
        )
    }
}

export default Dropdown;