import React from 'react';
import Preloader from "../../utils/preloader";

import BlogItem from "../../course-snippets/blog/item";

import API from "../../utils/api";

class StudentReading extends React.Component {
  constructor (props) {
    super();
    this.state = {
        loading: true,
        loadedLength: 0,
        articles: []
    };
  }

  filterArticles(arr) {
    const schools_data = this.props.userData.subscriptions.school_data;
    const schools = {};

    schools_data.forEach(school => {
      if (!schools[school.slug]) {
        schools[school.slug] = 1
      }
    })

    return arr.filter(article => {
      const authourName = article.author.slug;

      if (schools[authourName]) {
        return true
      }

      return false
    })
    
  }

  componentDidMount() {
    API.get('/whub/v3/post?isubscribed=1').then(result => {
      this.setState({articles: this.filterArticles(result.data), loading: false});
    }).catch(error => {
      this.setState({loading: false});
      console.log(error)
    });
  }

  render() {
    const preloader = this.state.loading ? <Preloader/> : '';
    const noArticles = this.state.articles.length === 0 ? <span>Статьи экспертов отсутствуют</span> : '';

    return (
      <div>
        <div className="student_articles_list">
          {this.state.articles.map((article, idx) => {
            return <BlogItem {...this.props} post={article} key={article.id} itemId={idx}/>
          })}
        </div>
        {noArticles}
        {preloader}
      </div>
    )
  }
}

export default StudentReading;