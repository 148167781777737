import React from 'react';
import Preloader from "../../utils/preloader";
import API from '../../utils/api';
import { Redirect } from 'react-router-dom'

class ContinueEducation extends React.Component {
  constructor(props) {
    super();
    this.state = {
      loading: true,
      noData: false,
      redirect: null,
    }
  }

  loadLastProgress = () => {
    API.get('whub/v3/progress/latest/').then(res => {
      const data = res.data;
      const url = data.watching_now;
      if (url)  {
        this.setState({loading: false, redirect: url});
      }
    }).catch(err => {
      console.log(err);
      this.setState({noData: true})
    });
  }

  componentDidMount() {
    this.loadLastProgress();
  }

  render() {
    const preloader = this.state.loading ? <Preloader/> : '';
    const redirect = this.state.redirect ? <Redirect to={`/course/${this.state.redirect}`}/> : '';
    const notStartedText = this.state.noData ? <span>Вы еще ничего не смотрели</span> : '';

    return (
      <div>
        {preloader}
        {redirect}
        {notStartedText}
      </div>
    )
  }
}

export default ContinueEducation;