export const generateKeyId = () => {
    return 'key_' + Math.random() * 10000000;
};

export const addKeyIds = (arr) => {
    const newArr = arr.slice();

    newArr.forEach((el) => {
        el.key_id = generateKeyId();
    });

    return newArr;
};

export const removeKeyIds = (arr) => {
    const newArr = arr.slice();

    newArr.forEach((el) => {
        delete el.key_id;
    });

    return newArr;
};

export const list2ArrayWithKeyIds = (arr) => {
    const newArr = [];

    arr.forEach((el) => {
        newArr.push({generated_list_item: el, key_id: generateKeyId()});
    });

    return newArr;
};

export const arrayWithKeyIds2List = (arr) => {
    const newArr = [];

    arr.forEach((el) => {
        newArr.push(el.generated_list_item);
    });

    return newArr;
};

