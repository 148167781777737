import React from 'react';

export class ArrowRightBlue extends React.Component {
    render() {
        return(
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.28125 1.21875L13.7812 6.46875C13.9375 6.625 14 6.8125 14 7C14 7.21875 13.9375 7.40625 13.7812 7.5625L8.28125 12.8125C7.96875 13.0937 7.5 13.0937 7.21875 12.7812C6.9375 12.5 6.9375 12 7.25 11.7187L11.4062 7.75L0.78125 7.75C0.34375 7.75 0.0312498 7.4375 0.0312497 7.03125C0.0312497 6.65625 0.34375 6.25 0.78125 6.25L11.4062 6.25L7.25 2.3125C6.9375 2.03125 6.9375 1.53125 7.21875 1.25C7.5 0.9375 7.96875 0.9375 8.28125 1.21875Z" fill="#1F57C3"/>
            </svg>
        )
    }
}

export class ZenIcon extends React.Component {
    render() {
        return(
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
                <path fill="#2C3036"
                      d="M16.7 16.7c-2.2 2.27-2.36 5.1-2.55 11.3 5.78 0 9.77-.02 11.83-2.02 2-2.06 2.02-6.24 2.02-11.83-6.2.2-9.03.35-11.3 2.55M0 14.15c0 5.59.02 9.77 2.02 11.83 2.06 2 6.05 2.02 11.83 2.02-.2-6.2-.35-9.03-2.55-11.3-2.27-2.2-5.1-2.36-11.3-2.55M13.85 0C8.08 0 4.08.02 2.02 2.02.02 4.08 0 8.26 0 13.85c6.2-.2 9.03-.35 11.3-2.55 2.2-2.27 2.36-5.1 2.55-11.3m2.85 11.3C14.5 9.03 14.34 6.2 14.15 0c5.78 0 9.77.02 11.83 2.02 2 2.06 2.02 6.24 2.02 11.83-6.2-.2-9.03-.35-11.3-2.55">
                </path>
                <path fill="#fff"
                      d="M28 14.15v-.3c-6.2-.2-9.03-.35-11.3-2.55-2.2-2.27-2.36-5.1-2.55-11.3h-.3c-.2 6.2-.35 9.03-2.55 11.3-2.27 2.2-5.1 2.36-11.3 2.55v.3c6.2.2 9.03.35 11.3 2.55 2.2 2.27 2.36 5.1 2.55 11.3h.3c.2-6.2.35-9.03 2.55-11.3 2.27-2.2 5.1-2.36 11.3-2.55">
                </path>
            </svg>
        )
    }
}

export class ZenIconGrey extends React.Component {
    render() {
        return(
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
                <path fill="#fff"
                      d="M16.7 16.7c-2.2 2.27-2.36 5.1-2.55 11.3 5.78 0 9.77-.02 11.83-2.02 2-2.06 2.02-6.24 2.02-11.83-6.2.2-9.03.35-11.3 2.55M0 14.15c0 5.59.02 9.77 2.02 11.83 2.06 2 6.05 2.02 11.83 2.02-.2-6.2-.35-9.03-2.55-11.3-2.27-2.2-5.1-2.36-11.3-2.55M13.85 0C8.08 0 4.08.02 2.02 2.02.02 4.08 0 8.26 0 13.85c6.2-.2 9.03-.35 11.3-2.55 2.2-2.27 2.36-5.1 2.55-11.3m2.85 11.3C14.5 9.03 14.34 6.2 14.15 0c5.78 0 9.77.02 11.83 2.02 2 2.06 2.02 6.24 2.02 11.83-6.2-.2-9.03-.35-11.3-2.55">
                </path>
                <path fill="#96A3AE"
                      d="M28 14.15v-.3c-6.2-.2-9.03-.35-11.3-2.55-2.2-2.27-2.36-5.1-2.55-11.3h-.3c-.2 6.2-.35 9.03-2.55 11.3-2.27 2.2-5.1 2.36-11.3 2.55v.3c6.2.2 9.03.35 11.3 2.55 2.2 2.27 2.36 5.1 2.55 11.3h.3c.2-6.2.35-9.03 2.55-11.3 2.27-2.2 5.1-2.36 11.3-2.55">
                </path>
            </svg>
        )
    }
}

export class EditPencil extends React.Component {

    render() {
        return(
            <div className="edit_pencil_icon">
                <svg width="528.899px" height="528.899px" viewBox="0 0 528.899 528.899">
                    <g>
                        <path d="M328.883,89.125l107.59,107.589l-272.34,272.34L56.604,361.465L328.883,89.125z M518.113,63.177l-47.981-47.981
                        c-18.543-18.543-48.653-18.543-67.259,0l-45.961,45.961l107.59,107.59l53.611-53.611
                        C532.495,100.753,532.495,77.559,518.113,63.177z M0.3,512.69c-1.958,8.812,5.998,16.708,14.811,14.565l119.891-29.069
                        L27.473,390.597L0.3,512.69z"/>
                    </g>
                </svg>
            </div>
        )
    }
}

export class CategoriesListArrow extends React.Component {

    render() {
        return(
            <svg width="5.061" height="8.707" viewBox="0 0 5.061 8.707">
                <path className="a" d="M1657.6,3821.912l4,4-4,4" transform="translate(-1657.247 -3821.558)"/>
            </svg>
        )
    }
}

export class Plus extends React.Component {

    render() {
        return(
            <svg className="plus_icon" width="15.999" height="15.999" viewBox="0 0 15.999 15.999">
                <path fill="#fff" d="M-1311-2095v-7h-7v-2h7v-7h2v7h7v2h-7v7Z" transform="translate(1318 2111)"/>
            </svg>
        )
    }
}

export class WhatsappIconSocial extends React.Component {

    render() {
        return(
            <svg width="32" viewBox="-0.003 -293.41895027729095 1172.923 1474.5159502772908" xmlns="http://www.w3.org/2000/svg"
                 fillRule="evenodd" clipRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2">
                <path d="M308.678 1021.49l19.153 9.576a499.739 499.739 0 0 0 258.244 70.227c279.729-.638 509.563-231.016 509.563-510.744 0-135.187-53.692-265.012-149.169-360.713-95.35-96.69-225.62-151.18-361.383-151.18-278.451 0-507.552 229.133-507.552 507.552 0 2.203 0 4.373.032 6.576a523.81 523.81 0 0 0 76.612 268.14l12.768 19.153-51.074 188.337 192.806-46.925z"
                      fill="#00E676" fillRule="nonzero"/>
                <path d="M1003.29 172.378C894.597 61.482 745.49-.732 590.225 0h-.99C269.479.001 6.35 263.131 6.35 582.888c0 1.5.032 2.969.032 4.47a616.759 616.759 0 0 0 76.612 290.485L-.003 1181.097l309.32-79.804a569.202 569.202 0 0 0 278.993 70.228c320.939-1.756 584.036-266.385 583.844-587.356.766-154.213-60.044-302.52-168.864-411.787m-413.065 900.186a473.935 473.935 0 0 1-245.476-67.035l-19.153-9.577-184.187 47.883 47.882-181.953-12.768-19.153a484.242 484.242 0 0 1-72.558-254.957c0-265.65 218.599-484.25 484.25-484.25 265.65 0 484.248 218.6 484.248 484.25 0 167.269-86.666 323.11-228.781 411.372a464.838 464.838 0 0 1-251.86 73.42m280.59-354.329l-35.114-15.96s-51.075-22.346-82.996-38.306c-3.192 0-6.384-3.192-9.577-3.192a46.308 46.308 0 0 0-22.345 6.384c-6.799 3.99-3.192 3.192-47.882 54.266-3.032 5.97-9.257 9.705-15.96 9.577h-3.193a24.328 24.328 0 0 1-12.768-6.384l-15.961-6.385a309.91 309.91 0 0 1-92.573-60.65c-6.384-6.385-15.96-12.77-22.345-19.154a357.13 357.13 0 0 1-60.65-76.611l-3.193-6.384a46.475 46.475 0 0 1-6.384-12.769 23.915 23.915 0 0 1 3.192-15.96c2.905-4.789 12.769-15.962 22.345-25.538 9.577-9.576 9.577-15.96 15.961-22.345a39.33 39.33 0 0 0 6.384-31.922 1246.398 1246.398 0 0 0-51.074-121.301 37.099 37.099 0 0 0-22.345-15.961H380.82c-6.384 0-12.768 3.192-19.153 3.192l-3.192 3.192c-6.384 3.192-12.768 9.577-19.153 12.769-6.384 3.192-9.576 12.769-15.96 19.153a162.752 162.752 0 0 0-35.114 98.956 189.029 189.029 0 0 0 15.96 73.42l3.193 9.576a532.111 532.111 0 0 0 118.11 162.8l12.768 12.769a193.037 193.037 0 0 1 25.537 25.537c66.141 57.554 144.7 99.052 229.516 121.302 9.576 3.192 22.345 3.192 31.921 6.384h31.922a118.126 118.126 0 0 0 47.882-12.769c7.82-3.543 15.29-7.82 22.345-12.768l6.384-6.385c6.385-6.384 12.769-9.576 19.153-15.96a84.393 84.393 0 0 0 15.961-19.153c6.129-14.301 10.438-29.304 12.769-44.69V724.62a40.107 40.107 0 0 0-9.577-6.385"
                      fill="#fff" fillRule="nonzero"/>
            </svg>
        )
    }
}

export class TelegramIconSocial extends React.Component {

    render() {
        return(
            <svg enableBackground="new 0 0 24 24" height="32" viewBox="0 0 24 24" width="32">
                <circle cx="12" cy="12" fill="#039be5" r="12"/>
                <path d="m5.491 11.74 11.57-4.461c.537-.194 1.006.131.832.943l.001-.001-1.97 9.281c-.146.658-.537.818-1.084.508l-3-2.211-1.447 1.394c-.16.16-.295.295-.605.295l.213-3.053 5.56-5.023c.242-.213-.054-.333-.373-.121l-6.871 4.326-2.962-.924c-.643-.204-.657-.643.136-.953z" fill="#fff"/>
            </svg>
        )
    }
}

export class TelegramIconSocialGrey extends React.Component {

    render() {
        return(
            <svg enableBackground="new 0 0 24 24" height="32" viewBox="0 0 24 24" width="32">
                <circle cx="12" cy="12" fill="#fff" r="12"/>
                <path d="m5.491 11.74 11.57-4.461c.537-.194 1.006.131.832.943l.001-.001-1.97 9.281c-.146.658-.537.818-1.084.508l-3-2.211-1.447 1.394c-.16.16-.295.295-.605.295l.213-3.053 5.56-5.023c.242-.213-.054-.333-.373-.121l-6.871 4.326-2.962-.924c-.643-.204-.657-.643.136-.953z" fill="#96A3AE"/>
            </svg>
        )
    }
}

export class UserSidebar extends React.Component {

    render() {
        return(
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                {/*<defs>*/}
                    {/*<style>.a{fill:#e8e8e8;}.b,.d{fill:none;}.b{stroke:#040e26;stroke-width:2px;}.c{stroke:none;}</style>*/}
                {/*</defs>*/}
                <g transform="translate(-275 -10)">
                    <circle className="a" fill="none" cx="20" cy="20" r="20" transform="translate(275 10)"/>
                    <g transform="translate(-858.367 -1482)">
                        <g className="b" strokeWidth="2" stroke="#040e26" fill="none" transform="translate(1149.367 1504)">
                            <circle className="c" stroke="none" cx="4" cy="4" r="4"/>
                            <circle className="d" fill="none" cx="4" cy="4" r="3"/>
                        </g>
                        <path className="b" fill="none" strokeWidth="2" stroke="#040e26" d="M.55,4.275a7,7,0,0,1,12.9,0" transform="translate(1146.367 1515)"/>
                    </g>
                </g>
            </svg>
        )
    }
}

export class Logo extends React.Component {

    render() {
        return(
            <svg xmlns="http://www.w3.org/2000/svg" width="58" height="40.998" viewBox="0 0 58 40.998">
                <g transform="translate(-649 -19.5)">
                    <path fill={'#1cfe00'}
                          d="M427.513,336.978v14.89H416.2v-14.89H405.255v40H416.2V361.1h11.315v15.877h10.942v-40Z"
                          transform="translate(268.545 -316.977)"/>
                    <g transform="translate(649 20)">
                        <path fill={'#fe13c9'}
                              d="M407.451,376.976l-7.019-40h10.839l2.223,17.476q.308,3.138.673,6.277c.252,2.092.472,4.185.683,6.277h.246c.365-2.092.74-4.184,1.1-6.277s.74-4.184,1.114-6.277l3.815-17.476H430l3.825,17.476c.365,2.01.739,4.072,1.1,6.184s.74,4.236,1.114,6.369h.246q.308-3.2.673-6.338c.252-2.092.472-4.164.683-6.215l2.213-17.476h10.11l-6.655,40H429.88L426.8,361.1q-.493-2.338-.826-4.738t-.652-4.615h-.247q-.308,2.215-.647,4.615c-.221,1.6-.5,3.179-.832,4.738l-2.953,15.876Z"
                              transform="translate(-400.432 -336.978)"/>
                    </g>
                    <path fill={'#020000'} stroke={'rgba(0,0,0,0)'} strokeMiterlimit={10}
                          d="M4.675,40,1.6,24.123q-.492-2.339-.825-4.738t-.65-4.615H0V0H4.8L8.619,17.477c.364,2.01.737,4.072,1.1,6.184s.739,4.235,1.112,6.369h.11V40ZM16.815,24.12H11.7l.049-.428c.251-2.093.472-4.165.681-6.215l.327-2.586h9.5V0H24.74L20.733,24.12Z"
                          transform="translate(673.8 20)"/>
                </g>
            </svg>
        )
    }
}

export class PicVideo extends React.Component {

    render() {
        return(
            <svg {...this.props} xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                <g transform="translate(-321 -1765)">
                    <rect className="a" fill="#2450ff" width="22" height="22" rx="11" transform="translate(321 1765)"/>
                    <path className="b"
                          fill="#fff"
                          d="M3.584.624a.5.5,0,0,1,.832,0l3.066,4.6A.5.5,0,0,1,7.066,6H.934a.5.5,0,0,1-.416-.777Z"
                          transform="translate(336 1772) rotate(90)"/>
                </g>
            </svg>
        )
    }
}

export class PicWebinar extends React.Component {

    render() {
        return(
            <svg {...this.props} width="20" height="20" viewBox="0 0 20 20">
                <g transform="translate(-670 -566)">
                    <rect width="20" height="20" rx="10" transform="translate(670 566)" fill="#be0b0b"/>
                    <circle cx="3" cy="3" r="3" transform="translate(677 570)" fill="#fff"/>
                    <path d="M-14554.295-2099.062A7,7,0,0,1-14548-2103a7,7,0,0,1,6.3,3.935,7.988,7.988,0,0,1-6.3,3.064A7.984,7.984,0,0,1-14554.295-2099.062Z" transform="translate(15228 2680)" fill="#fff"/>
                </g>
            </svg>

        )
    }
}

export class PicAudio extends React.Component {

    render() {
        return(
            <svg {...this.props} xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                <g transform="translate(-321 -2286)">
                    <rect className="a" fill="#7f35be" width="22" height="22" rx="11" transform="translate(321 2286)"/>
                    <path className="b"
                          fill="#fff"
                          d="M-7667.311-2103.109l-3.39-2.679h-2.8a.5.5,0,0,1-.5-.5v-3.424a.5.5,0,0,1,.5-.5h2.8l3.39-2.677a.5.5,0,0,1,.811.391v9a.5.5,0,0,1-.5.5A.492.492,0,0,1-7667.311-2103.109Zm2.311-2.875a2,2,0,0,0,2-2,2.006,2.006,0,0,0-2-2.007v-1a3.007,3.007,0,0,1,3,3.008,3,3,0,0,1-3,3Zm0-3.006a1,1,0,0,1,1,1,1,1,0,0,1-1,1Z"
                          transform="translate(8000.001 4405)"/>
                </g>
            </svg>
        )
    }
}

export class TickListBullit extends React.Component {

    render() {
        return(
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11 5.5L7 10.5L5 8" stroke="#040E26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        )
    }
}

export class ButtonPlay extends React.Component {
    render() {
        return(
            <svg width="46" height="52" viewBox="0 0 46 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M45.3691 26.1085L0.563852 51.9768L0.563854 0.240126L45.3691 26.1085Z" fill="white"/>
            </svg>
        )
    }
}

export class TipPoint extends React.Component {
    render() {
        return (
            <svg className="tool_tip" width="14" height="6" viewBox="0 0 14 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7 0L13.9282 6H0.0717969L7 0Z" fill="white"/>
            </svg>
        );
    }
}

export class CourseRatingIcon extends React.Component {

    render() {
        return (
            <div className="course_rating_wrapper">
                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12">
                    <path d="M6.5,0,8.45,4.028,13,4.584,9.655,7.628,10.517,12,6.5,9.853,2.483,12l.862-4.372L0,4.584l4.55-.555Z"/>
                </svg>
                <span>{parseFloat(this.props.rating).toFixed(1)}</span>
            </div>
        );
    }
}

export class CourseRating extends React.Component {

    render() {
        const lvl = parseInt(this.props.rating);
        const cls1 = lvl >= 1 ? 'filled' : '';
        const cls2 = lvl >= 2 ? 'filled' : '';
        const cls3 = lvl >= 3 ? 'filled' : '';
        const cls4 = lvl >= 4 ? 'filled' : '';
        const cls5 = lvl === 5 ? 'filled' : '';

        return (
            <svg className="icon_course_rating" width="96" height="24" viewBox="0 0 96 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 4L9.79611 9.52786H15.6085L10.9062 12.9443L12.7023 18.4721L8 15.0557L3.29772 18.4721L5.09383 12.9443L0.391548 9.52786H6.20389L8 4Z" className={cls1}/>
                <path d="M28 4L29.7961 9.52786H35.6085L30.9062 12.9443L32.7023 18.4721L28 15.0557L23.2977 18.4721L25.0938 12.9443L20.3915 9.52786H26.2039L28 4Z" className={cls2}/>
                <path d="M48 4L49.7961 9.52786H55.6085L50.9062 12.9443L52.7023 18.4721L48 15.0557L43.2977 18.4721L45.0938 12.9443L40.3915 9.52786H46.2039L48 4Z" className={cls3}/>
                <path d="M68 4L69.7961 9.52786H75.6085L70.9062 12.9443L72.7023 18.4721L68 15.0557L63.2977 18.4721L65.0938 12.9443L60.3915 9.52786H66.2039L68 4Z" className={cls4}/>
                <path d="M88 4L89.7961 9.52786H95.6085L90.9062 12.9443L92.7023 18.4721L88 15.0557L83.2977 18.4721L85.0938 12.9443L80.3915 9.52786H86.2039L88 4Z" className={cls5}/>
            </svg>


        );
    }
}

export class SearchIcon extends React.Component {
    render() {

        return (
            <svg className="search_icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="7" cy="6" r="3.5" stroke="#040E26"/>
                <path d="M10.3535 10L12.9999 12.6464" stroke="#040E26" strokeLinecap="round"/>
            </svg>
        )
    }
}

export class SearchIcon2 extends React.Component {
    render() {

        return (
            <svg className="search_icon_2" xmlns="http://www.w3.org/2000/svg" width="16.207" height="16.207" viewBox="0 0 16.207 16.207">

                <g transform="translate(-30 -27)">
                    <g className="a" stroke="#040e26" strokeWidth="2" fill="none" transform="translate(30 27)">
                        <circle className="b" stroke="none" cx="7" cy="7" r="7"/>
                        <circle className="c" fill="none" cx="7" cy="7" r="6"/>
                    </g>
                    <path className="a" stroke="#040e26" strokeWidth="2" fill="none" d="M3,3,7,7" transform="translate(38.5 35.5)"/>
                </g>
            </svg>
        )
    }
}


export class SearchIcon3 extends React.Component {
    render() {

        return (
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.8">
                    <circle cx="8" cy="8" r="7" stroke="black" strokeWidth="2"/>
                    <path d="M13.5 14L19 19.5" stroke="black" strokeWidth="2"/>
                </g>
            </svg>

        )
    }
}



export class ArrowBack extends React.Component {
    render() {

        return (
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7 8L10.7426 8.00001" stroke="#C0C2C8" strokeLinecap="round"/>
                <path d="M6 11L3 8L6 5" stroke="#C0C2C8" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>

        )
    }
}


export class ArrowPrev extends React.Component {
    render() {

        return (
            <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M6.76343 0.229221C7.07886 0.534848 7.07886 1.03037 6.76343 1.336L1.94994 6L6.76343 10.664C7.07886 10.9696 7.07886 11.4652 6.76343 11.7708C6.44801 12.0764 5.93661 12.0764 5.62118 11.7708L0.236568 6.55339C-0.0788559 6.24776 -0.0788559 5.75224 0.236568 5.44661L5.62118 0.229221C5.93661 -0.0764069 6.44801 -0.0764069 6.76343 0.229221Z" fill="black"/>
            </svg>


        )
    }
}

export class ArrowSearchBack extends React.Component {
    render() {

        return (
            <svg viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M6.76343 0.229221C7.07886 0.534848 7.07886 1.03037 6.76343 1.336L1.94994 6L6.76343 10.664C7.07886 10.9696 7.07886 11.4652 6.76343 11.7708C6.44801 12.0764 5.93661 12.0764 5.62118 11.7708L0.236568 6.55339C-0.0788559 6.24776 -0.0788559 5.75224 0.236568 5.44661L5.62118 0.229221C5.93661 -0.0764069 6.44801 -0.0764069 6.76343 0.229221Z" />
                <path fillRule="evenodd" clipRule="evenodd" d="M15.5177 6C15.5177 6.55229 15.062 7 14.4998 7L2.01786 7C1.45571 7 1 6.55228 1 6C1 5.44771 1.45571 5 2.01786 5L14.4998 5C15.062 5 15.5177 5.44772 15.5177 6Z" />
            </svg>

        )
    }
}

export class DropdownArrow extends React.Component {
    render() {

        return (
            <svg className="dropdown_arrow" width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.99995 5L0.669822 0.500001L9.33008 0.5L4.99995 5Z" fill="#040E26"/>
            </svg>
        )
    }
}

export class CheckboxTick extends React.Component {
    render() {

        return (
            <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7 1.5L3 6.5L1 4" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        )
    }
}

export class Burger extends React.Component {
    render() {
        return (
            <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="16" height="2" fill="#040E26"/>
                <rect y="5" width="16" height="2" fill="#040E26"/>
                <rect y="10" width="16" height="2" fill="#040E26"/>
            </svg>
        )
    }
}

export class Close extends React.Component {
    render() {
        return (
            <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="1" y="11" width="15.5" height="2" transform="rotate(-45 1 11)" fill="#040E26"/>
                <rect x="2" width="15.5" height="2" transform="rotate(45 2 0)" fill="#040E26"/>
            </svg>

        )
    }
}

export class Subtract extends React.Component {
    render() {
        return (
            <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M4 0C1.79086 0 0 1.79086 0 4V10C0 12.2091 1.79086 14 4 14C6.20914 14 8 12.2091 8 10V4C8 1.79086 6.20914 0 4 0ZM4 6C5.10457 6 6 5.10457 6 4C6 2.89543 5.10457 2 4 2C2.89543 2 2 2.89543 2 4C2 5.10457 2.89543 6 4 6Z" fill="#040E26"/>
            </svg>
        )
    }
}

export class Star extends React.Component {
    render() {
        return (
			<svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M6.52447 0.463524C6.67415 0.00286841 7.32585 0.00286996 7.47553 0.463525L8.68386 4.18237C8.75079 4.38838 8.94277 4.52786 9.15938 4.52786H13.0696C13.554 4.52786 13.7554 5.14767 13.3635 5.43237L10.2001 7.73075C10.0248 7.85807 9.95149 8.08375 10.0184 8.28976L11.2268 12.0086C11.3764 12.4693 10.8492 12.8523 10.4573 12.5676L7.29389 10.2693C7.11865 10.1419 6.88135 10.1419 6.70611 10.2693L3.54267 12.5676C3.15081 12.8523 2.62357 12.4693 2.77325 12.0086L3.98157 8.28976C4.04851 8.08375 3.97518 7.85807 3.79994 7.73075L0.636495 5.43237C0.244639 5.14767 0.446028 4.52786 0.93039 4.52786H4.84062C5.05723 4.52786 5.24921 4.38838 5.31614 4.18237L6.52447 0.463524Z" />
			</svg>
        )
    }
}

export class Plug extends React.Component {
    render() {
        return (
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="8" cy="8" r="8" fill="#C0C2C8"/>
            </svg>
        )
    }
}
