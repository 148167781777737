import React from "react";

import './index.css';
import Preloader from "../../../utils/preloader";
import {obj2arr, arr2obj} from "../../../utils/utils";
import TextareaAutosize from "react-autosize-textarea";
import ReactTooltip from "react-tooltip";

import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import API from "../../../utils/api";
import Modal from "../../../modal";
import LessonModal from "./modal";
import {Bin, TypePdf, TypeText, TypeVideo, TypeWebinar} from "../../../course-v3/landing/icons";

class EditCourseLessonsV2 extends React.Component {
    constructor (props) {
        super();

        const data = props.courseData;

        const typesMap = {
            video: {
                icon: <TypeVideo />,
                value: 'видеоурок'
            },
            video_upload: {
                icon: <TypeVideo />,
                value: 'видеоурок'
            },
            text: {
                icon: <TypeText />,
                value: 'текстовый урок'
            },
            pdf: {
                icon: <TypePdf />,
                value: 'PDF'
            },
            webinar: {
                icon: <TypeWebinar />,
                value: 'вебинар'
            }
        };

        this.state = {
            loading: false,
            lessons: data.lessons,
            modalView: null,
            typesMap: typesMap
        };

        this.delete = this.delete.bind(this);
    }

    addLesson = () => {
        const blank = {
            title: '',
            description: '',
            type: 'video',
            content: {
                is_cloud: false,
                url: ''
            }
        };

        this.setState({
            modalView: blank
        });
    };

    handleSave = (id, lesson) => {
        const obj = arr2obj(this.state.lessons, 'id');
        obj[id] = lesson;

        const arr = obj2arr(obj);

        const sorted = arr.sort((a, b) => {
            if (a.order < b.order) return -1
            if (b.order < a.order) return 1
            return 0
        })

        this.setState({lessons: sorted, modalView: null}, this.updateCourseData);
    };

    updateCourseData = (withOrder = false) => {
        this.props.updateData({lessons: this.state.lessons});

        if (withOrder) {
            this.setState({loading: true});
            this.updateOrder();
        }
    };

    delete(id){
        const t = this;
        const {lessons} = t.state;
        const objLessons = arr2obj(lessons, 'id');
        delete objLessons[id];

        const arr = obj2arr(objLessons);

        const sorted = arr.sort((a, b) => {
            if (a.order < b.order) return -1
            if (b.order < a.order) return 1
            return 0
        })

        this.setState({
            lessons: sorted
        }, this.updateCourseData);
    }

    deleteLesson = (id) => {
        const confirm = window.confirm('Удалить урок?');

        if (confirm) {
            const t = this;
            const p = t.props;
            const d = {data: {
                    lesson_id: id
                }};

            t.setState({loading: true});
            API.delete('/whub/v1/lesson/' + p.courseData.id, d)
                .then(function (r) {
                    console.log(r)
                    t.setState({loading: false});
                    t.delete(id);
                })
                .catch(function (error) {
                    t.setState({loading: false});
                    console.log(error.response);
                    alert(error)
                });
        }

    };

    updateOrder = () => {
        const t = this;
        const courseId = t.props.courseData.id;
        const requests = [];

        const lessons = this.state.lessons;

        this.state.lessons.forEach((lesson, idx) => {
            const d = {
                lesson_id: lesson.id,
                type: lesson.type,
                title: lesson.title,
                description: lesson.description,
                content: lesson.content,
                duration: lesson.duration,
                order: idx,
            };

            lessons[idx].order = idx;

            const request = API.put('/whub/v3/lesson/' + courseId, d)
                .then((r) => console.log(r))
                .catch(function (error) {
                    t.setState({loading: false});
                    alert(error)
                });;

            requests.push(request)
        })

        Promise.allSettled(requests).then(() => {
            this.props.updateData({lessons: lessons});
            t.setState({loading: false});
        })
    }

    onSortEnd = ({oldIndex, newIndex}) => {
        this.setState(({lessons}) => ({
            lessons: arrayMove(lessons, oldIndex, newIndex),
        }), () => this.updateCourseData(true));
    };

    render() {
        const t = this;
        const p = t.props;

        const {lessons, loading, modalView, typesMap} = t.state;

        const DragHandle = SortableHandle(() => <div className="drag_handle">::</div>);

        const SortableItem = SortableElement(({data}) => {
            const type = data.type ? data.type : 'video';
            const typeIcon = typesMap[type].icon;
            const typeTip =typesMap[type].value;

            return (
                <div className="lesson_item li_draggable">
                    <DragHandle />
                    <div className="li_inner" onClick={() => t.setState({modalView: data})}>
                        <div className="type_icon" data-tip={typeTip}>{typeIcon}</div>
                        {data.title}
                    </div>
                    <div className="li_delete" data-tip="удалить урок" onClick={() => t.deleteLesson(data.id)}><Bin /></div>
                </div>
            )
        });
        

        const SortableList = SortableContainer(() => {
            return (
                <div className="edit_lessons_list">
                    {lessons.map((lesson, idx) => (
                        <SortableItem key={lesson.id} index={idx} data={lesson} sortIndex={idx}/>
                    ))}
                </div>
            );
        });

        const preloader = loading ? <Preloader/> : '';

        const modalComponent = modalView ? <LessonModal {...p} lessonData={modalView} courseId={p.courseData.id} onSave={t.handleSave} /> : null;
        const modal = modalComponent ? <Modal modalContent={'edit_course'} blockComponent={modalComponent} onModalClose={() => t.setState({modalView: null})} /> : '';

        return (
            <div className="course_edit_block ceb_lessons">
                <div className="course_name_block">
                    <TextareaAutosize spellCheck="false" className="course_name_input" disabled={true} value={p.courseData.title} name="courseName" placeholder="название курса" />
                </div>

                <SortableList onSortEnd={t.onSortEnd} useDragHandle={true} lockAxis="y" />

                <div className="ce_button_holder">
                    <button onClick={this.addLesson}>Добавить урок</button>
                </div>

                {modal}

                {preloader}

                <ReactTooltip delayShow={5} effect={'solid'} />
            </div>
        );
    }
}


export default EditCourseLessonsV2;