import React from "react";

import './index.css';
import SignInForm from "../sign-in/sign-in-form";
import SignUpForm from "./sign-up-form";

class ModalAuthWrapper extends React.Component {
    constructor (props) {
        super();
        this.state = {
            form: props.form ? props.form : 'sign-up'
        };
    }

    render () {
        const t = this;
        const p = t.props;
        const s = t.state;
        const registrationType = p.registrationType ? p.registrationType : 'student';

        const content = s.form === 'sign-up' ? <SignUpForm {...p}
                                                          registrationType={registrationType}
                                                          onSuccess={p.onSuccess}
                                                          instantAuth={true} /> : <SignInForm {...p} onSuccess={p.onSuccess} />;

        return (
            <div className="modal_auth_wrapper">
                <div className="modal_auth_form_toggler">
                    <span
                        className={s.form === 'sign-in' ? 'active' : ''}
                        onClick={() => t.setState({form: 'sign-in'})}>вход</span><span
                    className={s.form === 'sign-up' ? 'active' : ''}
                    onClick={() => t.setState({form: 'sign-up'})}>регистрация</span>
                </div>
                {content}
            </div>
        );
    }
}

export default ModalAuthWrapper;