import React from "react";
import { Link } from 'react-router-dom';
import './index.css';

class Breadcrumbs extends React.Component {

    constructor (props) {
        super();
    }

    render() {
        const t = this;
        const p = t.props;
        const data = p.data;
        const crumbs = data.crumbs.map((crumb, idx) => {
            if (crumb.url) {
                return <li key={idx}><span>/</span><Link to={crumb.url}>{crumb.title}</Link></li>;
            }
            return <li key={idx}><span>/</span><span>{crumb.title}</span></li>;
        });

        return (
            <ul className={'breadcrumbs ' + data.type}>
                <li><Link to={'/'}>Главная</Link></li>

                {crumbs}
            </ul>

        );
    }
}

export default Breadcrumbs;