import React from 'react';
import ProfileModal from './commonModal';
import './index.css';

class DeleteModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      pass: '',
    }
  }

  render() {
    return (
      <div>
        <ProfileModal title="" width="345px" closeModal={this.props.close}>
          <div className="password_modal">
            <div className="password_modal_title">
              <span>Удаление аккаунта</span>
            </div>

            <div className="delete_modal_info">
              <span>Вы собираетесь удалить свой аккаунт на W-HUB. Связанные с аккаунтом данные будут удалены в течение 7 дней, без возможности восстановления.</span>
            </div>

            <div className='password_modal_row'>
              <div className='password_modal_input_label'>
                <span>Если вы подтверждаете удаление своего аккаунта, введите свой пароль</span>
              </div>
              <div className='password_modal_input'>
                <input value={this.state.pass} onChange={(e) => this.setState({pass: e.target.value})}/>
              </div>
            </div>

            <div className="delete_modal_btn">
              <span>Удалить аккаунт</span>
            </div>
          </div>

        </ProfileModal>
      </div>
    )
  }
}

export default DeleteModal;