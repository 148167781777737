import React from 'react';
import './date-start.css';
import moment from "moment";

class HeaderDateStart extends React.Component {
    constructor (props) {
        super();

        this.state = {
        };
    }

    render() {
        const t = this;
        const {data} = t.props;

        if (data.meta.date_start) {
            return <div className="header_date_start">
                Старт {moment(data.meta.date_start).format('DD.MM.YYYY')} в {moment(data.meta.date_start).format('HH:mm')}
            </div>;
        }

        return '';
    }
}

export default HeaderDateStart;
