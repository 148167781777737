import React from 'react';

import './company-type-select.css';

class CompanyTypeSelect extends React.Component {
    constructor (props) {
        super();

        this.state = {
            currentType: props.companyType
        }
    }

    handleRadioChange = (e) => {
        const obj = {};
        obj[e.target.name] = e.target.value;
        this.setState(obj);
    };

    handleSubmit = () => {
        this.props.onSelect(this.state.currentType);
        //this.props.demoFunc();
    };

    render() {
        const s = this.state;

        return (
            <div className="company_type_select">
                <div className="cts_list">

                    <div className="radio_wrapper">
                        <input checked={s.currentType === 'ooo'} onChange={this.handleRadioChange} value="ooo" id="cts_ooo" type="radio" name="currentType" />
                        <label htmlFor="cts_ooo" className="advert_item">
                            <div className="point"></div>
                            <h4>Юридическое лицо (ООО)</h4>
                        </label>
                    </div>

                    <div className="radio_wrapper">
                        <input checked={s.currentType === 'ip'} onChange={this.handleRadioChange} value="ip" id="cts_ip" type="radio" name="currentType" />
                        <label htmlFor="cts_ip" className="advert_item">
                            <div className="point"></div>
                            <h4>Индивидуальный предприниматель (ИП)</h4>
                        </label>
                    </div>

                    <div className="radio_wrapper locked">
                        <input checked={s.currentType === 'sz'} onChange={this.handleRadioChange} value="sz" id="cts_sz" type="radio" name="currentType" />
                        <label htmlFor="cts_sz" className="advert_item">
                            <div className="point"></div>
                            <h4>Самозанятость</h4>
                        </label>
                    </div>

                    <div className="radio_wrapper locked">
                        <input checked={s.currentType === 'fiz'} onChange={this.handleRadioChange} value="fiz" id="cts_fiz" type="radio" name="currentType" />
                        <label htmlFor="cts_fiz" className="advert_item">
                            <div className="point"></div>
                            <h4>Физическое лицо</h4>
                            <p>К сожалению, физические лица не могут в полной мере использовать возможности маркетплейса W-Hub :-(</p>
                        </label>
                    </div>
                </div>

                <div className="button_holder">
                    <button disabled={s.currentType === 'fiz'} onClick={this.handleSubmit}>продолжить</button>
                </div>
            </div>
        );
    }
}

export default CompanyTypeSelect;