import React from 'react';
import './index.css';
import Button from "../../utils/button";

class CategoriesButtons extends React.Component {
    constructor (props) {
        super();

    }

    categoriesMapper = (el) => {
        const {icon} = el;
        const btnIcon = icon && icon.length ? <img src={icon} alt="" /> : '';

        return <Button key={el.slug} icon={btnIcon} url={'/categories/' + el.slug} label={el.name} className="button_category button_default" />
    };

    render() {
        const t = this;
        const {categories} = t.props;

        return (
            <div className="categories_buttons">
                {categories.map(t.categoriesMapper)}
            </div>
        );
    }
}

export default CategoriesButtons;
