import React from 'react';

import './index.css';
import logo_platron from './payments_logos/platron.png';

class PaymentsOptions extends React.Component {
    constructor (props) {
        super();
        this.state = {
        }
    }

    componentDidMount() {

    };
    componentWillUnmount() {
    };

    render() {

        return (
            <div className="about_wrapper payments_page">
                <h1>оплата</h1>

                <p>Прием платежей производится через расчетный центр Платрон <a className="img_link" href="http://www.platron.ru/" rel="noopener noreferrer" target="_blank"><img src={logo_platron} alt="" /></a></p>

                <p>Оптата осуществляется посредством банковских карт <a rel="noopener noreferrer" target="_blank" href="https://front.platron.ru/support/ru/russianstandard/">VISA, MasterCard, American Express, МИР</a></p>

            </div>
        );
    }
}

export default PaymentsOptions;