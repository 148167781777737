import React from "react";

import {useLocation, useHistory} from 'react-router-dom';

import {parseQS, serrializer} from "../utils/utils";
import SearchEngine from "./search-engine";

const SearchV2 = (props) => {
    const location = useLocation();
    let history = useHistory();

    const params = parseQS(decodeURI(location.search.replace('?','')));

    const updateSearch = (newParams, cb) => {
        if (!newParams) {
            newParams = {};
        }

        const oldParams = parseQS(decodeURI(location.search.replace('?','')));
        const params = Object.assign(oldParams, newParams);
        const qStr = serrializer(params);
        history.push('/search?' + qStr);

        setTimeout(() => {

            if (cb) {
                cb();
            }
        });
    };

    return (
        <SearchEngine {...props} params={params} updateSearchFunc={updateSearch} />
    );
};


export default SearchV2;