import React from 'react';
import {Link} from 'react-router-dom';
import './school-profile.css';
import API from '../../../utils/api';
import {arr2obj, obj2arr} from "../../../utils/utils";
import Dropdown from "../../../utils/dropdown/dropdown";
import Spinner from "../../../utils/spinner";
import UserpicUpload from "../userpic-upload";

import NameChangeModal from './modals/nameChangeModal';

import editIcon from '../student-profile/icons/edit.svg';

class SchoolProfileForm extends React.Component {
    constructor (props) {
        super();
        const ud = props.userData;

        const contactsTemplate = [
            {value: '', label: 'Сайт', name: 'website'},
            {value: '', label: 'E-mail', name: 'email'},
            {value: '', label: 'Телефон', name: 'phone'},
            {value: '', label: 'Instagram', name: 'instageam'},
            {value: '', label: 'Facebook', name: 'facebook'},
            {value: '', label: 'Вконтакте', name: 'vkontakte'},
            {value: '', label: 'Telegram', name: 'telegram'},
            {value: '', label: 'WhatsApp', name: 'whatsapp'},
            {value: '', label: 'Youtube', name: 'youtube'}
        ];

        this.state = {
            loading: false,
            showHint: false,
            showModal: false,
            displayName: ud.display_name ? ud.display_name : '',
            nicename: ud.slug ? ud.slug : '',
            about: ud.about ? ud.about : '',
            schoolType: ud.school_type ? ud.school_type : 'online-school',
            valid: false,
            contacts: ud.contacts ? ud.contacts : contactsTemplate
        }
    }

    handleSubmit = () => {
        const t = this;
        const {displayName, about, schoolType, contacts} = t.state;
        const {userData, reloadUserData} = t.props;

        const d = {
            display_name: displayName,
            about: about,
            school_type: schoolType,
            contacts: contacts
        };

        t.setState({loading: true});
        API.put('/whub/v3/user/' + userData.id, d)
            .then(function (r) {
                t.setState({loading: false, valid: false});
                reloadUserData();
            })
            .catch(function (error) {
                t.setState({loading: false});
                console.log(error.response);
            });

    };

    validate = () => {
        const t = this;
        const {displayName, schoolType, schoolDescription, contacts} = t.state;
        const {userData} = t.props;

        const contactsTemplate = [
            {value: '', label: 'Сайт', name: 'website'},
            {value: '', label: 'E-mail', name: 'email'},
            {value: '', label: 'Телефон', name: 'phone'},
            {value: '', label: 'Instagram', name: 'instagram'},
            {value: '', label: 'Facebook', name: 'facebook'},
            {value: '', label: 'Вконтакте', name: 'vkontakte'},
            {value: '', label: 'Telegram', name: 'telegram'},
            {value: '', label: 'WhatsApp', name: 'whatsapp'},
            {value: '', label: 'Youtube', name: 'youtube'}
        ];
        const originalContacts = userData.contacts ? userData.contacts : contactsTemplate;

        const valid = displayName && (displayName !== userData.display_name
            || schoolType !== userData.school_type
            || schoolDescription !== userData.about
            || JSON.stringify(contacts) !== JSON.stringify(originalContacts));

        t.setState({valid: valid});
    };

    handleTextInput = (e) => {
        const t = this;
        const obj = {};

        obj[e.target.name] = e.target.value;
        t.setState(obj, t.validate);
    };

    handleContactInput = (e) => {
        const t = this;
        const {contacts} = t.state;
        const obj = arr2obj(contacts, 'name');
        obj[e.target.name].value = e.target.value;

        t.setState({contacts: obj2arr(obj)}, t.validate);
    };

    getSelectedOption = (arr, val) => {
        const obj = arr2obj(arr, 'value');
        if (obj[val]) {
            return obj[val];
        }
        return arr[0];
    };

    handleSchoolTypeChange = (d) => {
        this.setState({schoolType: d.data.value}, this.validate);
    };

    handleShowHint = (show) => {
      this.setState({showHint: show});
    }

    showModal = () => {
      this.setState({showModal: true})
    }

    closeModal = () => {
      this.setState({showModal: false})
    }

    switchLoader = (state) => {
      this.setState({loading: state});
    }

    contactsMapper = (contact, idx) => {
        const t = this;
        return (
          <div key={idx}className="school_profile_form_row">
            <div className='school_profile_form_input_label'>
              <span>{contact.label}</span>
            </div>
            <div className="school_profile_form_input">
              <input type="text" name={contact.name} value={contact.value} placeholder=" " onChange={t.handleContactInput} />
            </div>
          </div>
        ) 
    };

    render() {
        const t = this;
        const p = t.props;
        const {loading, valid, about, displayName, schoolType, contacts, nicename} = t.state;

        const schoolTypesList = [
            {value: 'online-school', label: 'онлайн-школа'},
            {value: 'school', label: 'школа'},
            {value: 'university', label: 'университет'},
            {value: 'institute', label: 'институт'},
            {value: 'academy', label: 'академия'},
            {value: 'college', label: 'колледж'},
            {value: 'author', label: 'автор'},
            {value: 'expert', label: 'эксперт'},
            {value: 'blogger', label: 'блогер'},
            {value: 'instructor', label: 'преподаватель'},
            {value: 'teacher', label: 'учитель'},
            {value: 'lecturer', label: 'лектор'},
            {value: 'trainer', label: 'тренер'}
        ];
        const schoolTypeSelected = t.getSelectedOption(schoolTypesList, schoolType);

        return (
            <div className="school_profile_form">

            { this.state.showModal ? <NameChangeModal {...t.props} close={this.closeModal} loading={this.switchLoader}/> : ""}
                
                <div>
                  <UserpicUpload {...p} />
                  <dl className="link_info">
                    <dt>Страница школы:</dt>
                    <dd><Link rel="noreferrer noopener" target="_blank" to={'/users/' + p.userData.slug}>{'https://w-hub.ru/users/' + p.userData.slug}</Link></dd>
                    <dd 
                      onClick={() => this.handleShowHint(!this.state.showHint)}
                      onMouseOut={() => this.handleShowHint(false)}
                      className="link_info_tip">?
                       { this.state.showHint ? 
                       <div className="link_info_tip_content">Ваш адрес страницы на W-HUB совпадает с вашим общедоступным именем пользователя, для изменения адреса страницы необходимо изменить имя пользователя</div> 
                       : "" }
                    </dd>
                  </dl>
                </div>

                <div className="school_profile_form_row">
                  <div className='school_profile_form_input_label'>
                    <span>Имя пользователя</span>
                  </div>
                  <div className="school_profile_form_input">
                    <input disabled type="text" name="nicename" value={nicename} placeholder=" " onChange={t.handleTextInput} />
                    <div onClick={() => this.showModal()} 
                      style={{backgroundImage: `url(${editIcon})`}} 
                      className="student_profile_form_input_edit"></div>
                  </div>
                </div>

                <div className="school_profile_form_row">
                  <div className='school_profile_form_input_label'>
                    <span>Тип Школы</span>
                  </div>
                  <div className="school_profile_form_input">
                    <Dropdown onChange={t.handleSchoolTypeChange} name="schoolType" selected={schoolTypeSelected} ddArr={schoolTypesList} />
                  </div>
                </div>

                <div className="school_profile_form_row">
                  <div className='school_profile_form_input_label'>
                    <span>Название школы</span>
                  </div>
                  <div className="school_profile_form_input">
                    <input type="text" name="displayName" value={displayName} placeholder=" " onChange={t.handleTextInput} />
                  </div>
                </div>

                {/* <dl>
                    <dt>nicename</dt>
                    <dd><input type="text" name="nicename" value={nicename} placeholder=" " onChange={t.handleTextInput} /></dd>
                </dl> */}

                <div className="school_profile_form_row">
                  <div className='school_profile_form_input_label'>
                    <span>Описание школы</span>
                  </div>
                  <div className="school_profile_form_input school_profile_form_input_textarea">
                    <textarea onChange={t.handleTextInput} name="about" value={about} />
                  </div>
                </div>

                <div className="school_contacts">
                    <h2 className="school_profile_form_heading">Контактные данные</h2>
                    <h3 className="school_profile_form_semi_title">Укажите дополнительные способы связи для Ваших студентов!</h3>
                    {contacts.map(t.contactsMapper)}
                </div>

                <div><button className="student_profile_form_save_button" onClick={this.handleSubmit} disabled={!valid} >сохранить</button></div>

                <Spinner show={loading} />
            </div>
        );
    }
}

export default SchoolProfileForm;