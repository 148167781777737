import React from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import API from "../utils/api";

import CollectionsHeader from "../collections-v2/header";
import CoursesFeatured from "../course-snippets/featured/v3";
import CollectionFeed from "./collection-feed";
import BlogFeed from "../course-snippets/blog";
import MainSchools from '../main/v3/schools';
import Preloader from '../utils/preloader';


class CollectionsV2 extends React.Component {
    constructor (props) {
      super();

      this.state = {
          loading: false,
          collectionObject: {
            articles: [],
            featured: [],
            school: [],
            quarantine: [],
          },
      }
    }

    loadMainObject = () => {
      const t = this;
      
      t.setState({loading: true});
      API.get('/whub/v3/main')
          .then(function (r) {
              t.setState({loading: false});
              t.props.setMainPageObj(r.data);

              if (r.duration && r.duration > 6000) {
                  console.log('long wait!: +' + r.duration + 'ms');
              }
          })
          .catch(function (error) {
              console.log(error);
          });
    };

    loadCollections = () => {
      const pageParams = this.props.match.params;

      API.get('/whub/v3/feed/' + pageParams.collectionId)
            .then((r)  => {
              console.log(r);
              this.setState({ collectionObject: r.data })
            })
            .catch(function (error) {
                console.log(error);

                window.location = "/";
            });
    }

    prepareSchoolLine = (schools) => {
      const result = [];
      schools.forEach((school) => {
        result.push(
          {
            ...school,
            type: school.type ? school.type : '',
            name: school.display_name ? school.display_name : '',
            courses_count: school.courses_num ? school.courses_num : 0,
          }
        )
      })
      return result;
    }

    prepareFeatured = (featured) => {
      const result = [];
      featured.forEach((collection) => {
        result.push(
          {
            ...collection,
            images_stack: collection.images ? collection.images : null,
          }
        )
      })
      return result;
    }

    prepareCollectionLine = (collections) => {
      const result = [];
      collections.forEach((collection) => {
        result.push(
          {
            ...collection,
            images_stack: collection.images ? collection.images : null,
          }
        )
      })
      return result;
    }

    prepareArticles = (articles) => {
      const result = [];
      articles.forEach(article => {
        const { author_data } = article;
        const prepareAuthor = author_data ? {
          ...author_data,
          name: author_data.display_name
        } : null;
        result.push(
          {
            ...article,
            author: prepareAuthor ? prepareAuthor : null,
            images_stack: article.images ? article.images : null,
            date: article.date ? moment.unix(article.date).format("MM/DD/YYYY") : null,
            description: article.description.length > 140 ? article.description.slice(0, 140 - 1) + '...' : article.description,
          }
        )
      })

      return result;
    }

    componentDidMount() {
      this.loadCollections();
      if (!this.props.mainPageLoaded) {
          this.loadMainObject(); 
      }
    };
    

    render() {
      const t = this;
      const p = t.props;
      const s = t.state;
      const o = this.state.collectionObject;

      const preloader = s.loading ? <Preloader /> : '';

      const featuredBlock = o.featured?.items?.length ? <CoursesFeatured {...p} data={this.prepareFeatured(o.featured.items)} /> : '';

      const firstLine = o.first_line?.items?.length ? <CollectionFeed {...p} title={o.first_line.title} courses={this.prepareCollectionLine(o.first_line.items)} /> : '';

      const secondLine = o.second_line?.items?.length ? <CollectionFeed {...p} title={o.second_line.title} courses={this.prepareCollectionLine(o.second_line.items)} /> : '';

      const feedArticles = o.articles?.items?.length ?  <BlogFeed
            {...p}
            articles={this.prepareArticles(o.articles.items)}
            title={'Блог'}
            tagLine="Читать еще про образование?"/> : '';

      const feedSchools = o.school?.items?.length ? <MainSchools data={this.prepareSchoolLine(o.school.items)} /> : '';

      const multiline = o.multiline?.length ? o.multiline.map(line => {
        return <CollectionFeed {...p} key={line.title} title={line.title} courses={this.prepareCollectionLine(line.items)} />
      }) : '';

      return (
        <div>
          <CollectionsHeader title={o.feed_title} subtitle={o.feed_subtitle} {...p} />

          {featuredBlock}

          {firstLine}

          {feedArticles}

          {secondLine}

          {feedSchools}

          {preloader}

          {multiline}
        </div>
      )
    }
}

export default withRouter(CollectionsV2);