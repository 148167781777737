import React, { useEffect, useState } from "react";
import { Link, Redirect, useParams, useLocation } from 'react-router-dom';

import './index.css';
import '../styles/auth-tabs.css';
import SignUpForm from "./sign-up-form";

import schoolPic from './school-pic.png';
import studentPic from './student-pic.png';
import SignUpGift from "./signup-gift";
import {parseQS} from "../utils/utils";
import API from "../utils/api";
import Preloader from "../utils/preloader";

function SignUpFormPage(props) {
    let pageParams = useParams();
    let locationParams = parseQS(useLocation().search.replace('?',''));

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [giftId, setGiftId] = useState(null);
    const [gift, setGift] = useState(null);
    const [giftCode, setGiftCode] = useState(null);
    const [giftPath, setGiftPath] = useState(null);

    useEffect(() => {
        document.body.classList.add('no_search');

        return () => {
            document.body.classList.remove('no_search');
        }
    });

    const processAuthorizedGift = (gift) => {
        setLoading(true);

        const data = {
            email: props.userData.email,
            gift_code: giftCode,
            gift_id: gift.id
        };

        API.post('/wp/v2/users/register', data, {headers: {'Authorization': ''}})
            .then(function (r) {
                props.reloadUserData(() => {
                    setLoading(false);
                    setGiftPath('/course/' + gift.slug);
                    setSuccess(true);
                });
            })
            .catch(function (error) {
                if (error.response) {
                    const message = error.response.data.message;
                    alert(message);
                }
                setLoading(false);
            });

    };

    const submitSuccess = () => {
        setSuccess(true);
    };

    const handleGiftSelect = (gift) => {
        setGiftId(gift.id);
        setGift(gift);
        setGiftPath('/course/' + gift.slug);

        if (props.authorized) {
            processAuthorizedGift(gift);
        }
    };

    const handleExistingUser = (mail) => {
        setGiftPath('/signin?email=' + mail + '&afterAuth=/course/' + gift.slug);
    };

    const redirectLocation = props.authorized && props.userData.role === 'school' ? '/user/profile' : '/';

    const redirect = props.authorized && (props.userData.role === 'school' || !locationParams.giftCode) ? <Redirect to={redirectLocation}/> : '';
    const redirectAfterGift = giftPath && success ? <Redirect to={giftPath}/> : '';

    const pic = pageParams.type === 'school' ? schoolPic : studentPic;
    const text = pageParams.type === 'school' ? 'регистрация школы' : 'регистрация студента';
    const header = props.authorized ? '' : <h4>{text}</h4>;
    const alterLink = pageParams.type === 'school' ? <Link className="alter_link" to="/signup/student">Или вы студент?</Link> : <Link className="alter_link" to="/signup/school">Или основать школу?</Link>
    const showAfterLink = !success && !props.authorized ? alterLink : '';

    const form = props.authorized ? '' : <SignUpForm {...props}
                                                     registrationType={pageParams.type}
                                                     onSuccess={submitSuccess}
                                                     giftCode={giftCode}
                                                     selectedGiftId={giftId}
                                                     setExistingUser={handleExistingUser}/>;

    const preloader = loading ? <Preloader/> : '';

    const formDescriptionStudent = <div className="signup_form_description">
        <p>Регистрируйся, чтобы:</p>
        <ul>
            <li>создать аккаунт Студента на W-Hub;</li>
            <li>проходить бесплатные курсы;</li>
            <li>приобретать платные курсы;</li>
            <li>добавлять курсы в избранное;</li>
            <li>использовать систему автоматического сохранения прогресса;</li>
            <li>выставлять оценки пройденным курсам;</li>

        </ul>
    </div>;
    const formDescriptionSchool = <div className="signup_form_description">
        <p>Регистрируйся, чтобы:</p>
        <ul>
            <li>создать Онлайн-школу на W-Hub;</li>
            <li>создавать платные и бесплатные видео- и/или онлайн-курсы;</li>
            <li>создавать вебинары;</li>
            <li>размещать образовательные курсы и материалы любых форматов на маркетплейсе;</li>
            <li>использовать бесплатные сервисы, включая:<br />
                - конструктор курсов<br />
                - рассылку писем и сообщений<br />
                - инструменты дизайна страницы курса
            </li>
            <li>использовать инструменты статистики и аналитики;</li>
        </ul>
    </div>;

    const formDescription = pageParams.type === 'school' ? formDescriptionSchool : formDescriptionStudent;

    return (
        <div className="sign_up_wrapper">

            <div className="sign_up_form_wrapper">
                <div className="suf_header">
                    <img src={pic} alt="" />
                    {header}
                </div>
                <SignUpGift {...props} onIsGift={setGiftCode} onGiftIdSelect={handleGiftSelect} />

                <div className="form_with_description">
                    {formDescription}
                    {form}
                </div>
                {showAfterLink}
            </div>

            {preloader}
            {redirect}
            {redirectAfterGift}
        </div>
    )
}

export default SignUpFormPage;