import React from 'react';
import {Link} from 'react-router-dom';

import './text-modal.css';


class TextModal extends React.Component {
    constructor (props) {
        super();

        this.state = {
            email: '',
            valid: false
        }
    }

    handleSubmit = () => {
        const t = this;
        const s = t.state;

        t.props.onSuccess(s.email);
    };

    renderButtons = (buttons = []) => {
        const t = this;
        const p = t.props;

        return buttons.map((button, idx) =>
            <Link key={idx} to={button.button_url}><button className="small" onClick={p.onCloseBtn}>{button.button_text}</button></Link>
        );
    };

    render() {
        const t = this;
        const p = t.props;

        const buttons = t.renderButtons(p.modalData.additional_buttons);

        return (
            <div className="text_modal">
                <div className="tm_inner">
                    <div className="tm_text">
                        {p.modalData.text}
                    </div>
                    <div className="tm_buttons">
                        {buttons}
                        <button className="small" onClick={p.onCloseBtn}>закрыть</button>
                        {/*<a rel="noopener noreferrer" href={this.props.url} target="_blank"><button>перейти</button></a>*/}
                    </div>
                </div>
            </div>
        );
    }
}

export default TextModal;
