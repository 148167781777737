import React from 'react';
import './basic-params.css';
import {Certificate, Difficulty, TypeText, TypeVideo, TypeWebinar, Views} from "../../../icons";
import {Plural} from "../../../../../utils/utils";
import {sortLessonsByType} from "../../../duration-utils";

class HeaderBasicParams extends React.Component {
    constructor (props) {
        super();

        this.state = {
        };
    }

    defineCourseType = (sorted, count) => {
        let type = 'text';

        const typesMap = {
            video: {
                icon: <TypeVideo />,
                title: 'Видеокурс'
            },
            text: {
                icon: <TypeText />,
                title: 'Обучающий курс'
            },
            webinar: {
                icon: <TypeWebinar />,
                title: 'Вебинар'
            },
            online: {
                icon: <TypeWebinar />,
                title: 'Онлайн курс'
            }
        };

        if (sorted.video && sorted.video.count > count/2) {
            type = 'video';
        }

        if (sorted.webinar) {
            type  = 'webinar';

            if (sorted.webinar.count > 1) {
                type = 'online'
            }
        }

        return typesMap[type];
    };

    render() {
        const t = this;
        const p = t.props;
        const data = p.data;

        const sortedLessons = sortLessonsByType(data.lessons);
        let courseType = t.defineCourseType(sortedLessons.sorted, data.lessons.length);

        if (data.meta && data.meta.online_course_status) {
            courseType = {
                icon: <TypeWebinar />,
                title: 'Онлайн курс'
            };
        }

        const difficulty = data.meta.difficulty;
        let text;

        switch (parseInt(difficulty)) {
            case 1:
                text = 'начальный уровень';
                break;
            case 2:
                text = 'средний уровень';
                break;
            case 3:
                text = 'продвинутый уровень';
                break;
            default:
                text = 'любой уровень';
        }

        const views = data.views;
        let viewsCounter = '';

        if (views && views !== -1) {
            viewsCounter = <div className="bp_item">
                <Views />
                <span>{views} просмотр{Plural(views, '', 'а', 'ов')}</span>
            </div>
        }

        return (
            <div className="header_basic_params">
                <div className="bp_item">
                    {courseType.icon}
                    <span>{courseType.title}</span>
                </div>
                <div className="bp_item">
                    <Difficulty lvl={difficulty} />
                    <span>{text}</span>
                </div>
                {viewsCounter}
                <div className="bp_item bp_item_certificate">
                    <Certificate />
                    <span>Сертификат W-HUB</span>
                </div>
            </div>
        );
    }
}

export default HeaderBasicParams;
