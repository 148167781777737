import React, {useState, useEffect} from 'react';
import {generateKeyId, testValid} from "../../utils";

import './index.css';

const CITextInput = props => {
    const [mounted, setMounted] = useState(false);
    const [valid, setValid] = useState(false);

    useEffect(() => {
        if (!mounted) {
            onMount();
        }
    });

    const onMount = () => {
        setMounted(true);

        validate();
    };

    const handleChange = (e) => {
        const obj = {};
        obj[props.name] = e.target.value;

        validate(e.target.value);
        props.onChange(obj);
    };

    const handleKeyDown = (e) => {
        const {accept} = props;

        if (accept) {
            if (parseInt(e.which) === 8 || parseInt(e.which) === 46) {
            }
            else {
                if (!e.key.match(accept)) {
                    e.preventDefault();
                    return false;
                }
            }
        }

    };

    const validate = (value = props.value) => {
        const {name, isValid, isRequired, onValid} = props;

        if (isRequired) {
            let v = !!value.length;
            if (isValid) {
                v = testValid(isValid, value);
            }

            setValid(v);
            onValid(name, v);
        }

    };

    const cls_invalid = props.isValidationOn && !valid ? 'invalid' : '';
    const cls = props.className ? 'text_input ' + cls_invalid + ' ' + props.className : 'text_input ' + cls_invalid;


    return (<div className="input_wrapper">
        <input
        id={props.id ? props.id : generateKeyId()}
        className={cls}
        placeholder={props.placeholder || ''}
        type="text"
        value={props.value}
        onKeyDown={handleKeyDown}
        onChange={handleChange} />
    </div>);
};

export default CITextInput;