import React from 'react';
import Hammer from 'react-hammerjs';
import './index.css';

class Roadmap extends React.Component {
    constructor (props) {
        super();

        this.state = {
            current: 0,
            step: 300
        };
    }

    componentDidMount = () => {
        this.updateStep();
    };

    updateStep = () => {
        const t = this;
        const wW = window.innerWidth;
        let step = 300;

        if (wW <= 700) {
            step = 270;
        }

        t.setState({step: step});
    };

    handleStepClick = (idx) => {
        const t = this;
        const wW = window.innerWidth;
        const {current} = t.state;

        if (wW < 700 && idx !== current) {
            t.slideTo(idx);
        }
    };

    roadMapper = (step, idx) => {
        return (
            <dl key={idx} onClick={() => this.handleStepClick(idx)} className="feed_item">
                <dt>
                    <div className="idx"><span>{idx+1}</span></div>
                    {step.title}
                </dt>
                <dd>
                    {step.description}
                </dd>
            </dl>
        );
    };

    slide = (dir) => {
        const t = this;
        const p = t.props;
        const roadmap = p.data.meta.roadmap;

        const {current} = t.state;

        const next = current + dir;

        if (next >= 0 && next < roadmap.length) {
            t.slideTo(next);
        }
    };

    slideTo = (idx) => {
        this.setState({current: idx});
    };

    render() {
        const t = this;
        const p = t.props;
        const roadmap = p.data.meta.roadmap;

        const {current, step} = t.state;

        const style = {marginLeft: current * -step};

        const arrLeft = current > 0 ? <div onClick={() => t.slide(-1)} className="arr arr_left"> </div> : '';

        const hasRightArr = current < roadmap.length-1 && roadmap.length > 4 && current < roadmap.length - 4;
        const arrRight = hasRightArr ? <div onClick={() => t.slide(1)} className="arr arr_right"> </div> : '';

        const hammerOptions = {
            touchAction:'compute',
            recognizers: {
                swipe: {
                    threshold: 10
                }
            }
        };

        return (
            <div className="cl_roadmap">
                <div className="cl_centrer">
                    <h2>Как проходит обучение</h2>
                </div>
                <div className="roadmap_wrapper">
                    <div className="cl_centrer">
                        <div className="roadmap_carousel">
                            <div ref={t.holder} className="feed_holder">
                                <Hammer
                                    direction={'DIRECTION_HORIZONTAL'}
                                    options={hammerOptions}
                                    onSwipeLeft={() => this.slide(1)}
                                    onSwipeRight={() => this.slide(-1)}>
                                    <div className="feed" style={style}>
                                        {roadmap.map(t.roadMapper)}
                                    </div>
                                </Hammer>
                            </div>

                            {arrLeft}
                            {arrRight}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Roadmap;
