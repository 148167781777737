import { Redirect } from 'react-router-dom';
import {useParams} from 'react-router-dom';
import React from "react";

function LessonRedirect(props) {
    let pageParams = useParams();

    return <Redirect to={'/course/' + pageParams.id + '/' + pageParams.lesson_id} />;
}

export default LessonRedirect;