import React from 'react';
import Select from 'react-select';

const CIMultiSelect = props => {

    const handleChange = (selected) => {
        const obj = {};
        obj[props.name] = selected;
        props.onChange(obj);
    };

    return (<Select
        isMulti
        isOptionDisabled={props.isOptionDisabled}
        defaultValue={props.defaultValue}
        options={props.options}
        isSearchable={props.isSearchable}
        onChange={handleChange} />);
};

export default CIMultiSelect;