import {useEffect} from 'react';
import {useParams} from 'react-router-dom';

function PageParamsHook(props) {
    let pageParams = useParams();

    useEffect(() => {
        if (props.onParams) {
            props.onParams(pageParams);
        }
    });

    return false;
}

export default PageParamsHook;