import React from 'react';

import './index.css';

class Demo extends React.Component {
    constructor (props) {
        super();
    }

    render() {
        return (
            <div className="demo_note">
                <p>В рамках бета-тестирования данный функицонал недоступен, так&nbsp;как находится в стадии разработки.<br />
                Он будет доступен в самое ближайшее время!</p>
                <button onClick={this.props.onDemoClose}>Закрыть</button>
            </div>
        );
    }
}

export default Demo;
