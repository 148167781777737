import React from 'react';

import './index.css';
import {Mail, Mobile, Phone, Telegram, Whatsapp} from "./icons";

class Help extends React.Component {
    constructor (props) {
        super();
        this.state = {
        }
    }

    componentDidMount() {

    };
    componentWillUnmount() {
    };

    render() {
        const d = new Date();
        const dayOfWeek = d.getDay();

        let options = {
                timeZone: 'Europe/Moscow',
                hour: 'numeric',
            },
            formatter = new Intl.DateTimeFormat([], options),
            hoursInMoscow = parseInt(formatter.format(d));

        let cls_online = '';
        if (hoursInMoscow >= 10 && hoursInMoscow < 18 && dayOfWeek < 6) {
            cls_online = 'online';
        }

        return (
            <div className="about_wrapper help_wrapper">
                <div className="hw_left">
                    <div className={'hw_photo ' + cls_online}> </div>
                    <div className="hw_text">
                        <h1>Дария</h1>
                        <p>Консультирую, обучаю, помогаю разместить курсы на платформе W-Hub.</p>
                        <dl>
                            <dt>Время работы</dt>
                            <dd>пн–пт с 10:00 до 18:00</dd>
                        </dl>
                        <dl>
                            <dt>пн–пт с 10:00 до 18:00</dt>
                            <dd><a href="mailto:control@w-hub.ru" target="_blank" rel="noreferrer noopener">control@w-hub.ru</a></dd>
                        </dl>
                    </div>
                </div>
                <div className="hw_right">
                    <div className="hwr_inner">
                        <div className="hw_contacts">
                            <p><Mail /><a href="mailto:sales@w-hub.ru" target="_blank" rel="noreferrer noopener">sales@w-hub.ru</a></p>
                            <p><Phone />8 (499) 110-88-40</p>
                            <p><Mobile />8 (962) 868-93-56</p>
                            <p><Telegram /><a href="https://t.me/whubmarket" target="_blank" rel="noreferrer noopener">Telegram</a></p>
                            <p><Whatsapp /><a href="https://wa.me/message/3IZEGNYEUKESG1" target="_blank" rel="noreferrer noopener">WhatsApp</a></p>
                        </div>
                        <div className="hw_qr">
                            <div className="qr"> </div>
                            <p>Бизнес-аккаунт W-Hub<br />в WhatsApp</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Help;