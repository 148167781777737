import React from 'react';

import './authorized.css';
import SignInForm from "../sign-in/sign-in-form";
import SignUp from "../sign-up";

class UnauthedForm extends React.Component {
    constructor (props) {
        super();
        this.state = {
            activeTab: 'signin'
        }
    }

    switchTab = (e) => {
        const tab = e.target.getAttribute('data-tab');
        this.setState({activeTab: tab});
    };

    render() {
        const tabContent = {
            signin: <SignInForm {...this.props} />,
            signup: <SignUp {...this.props} />
        };

        return (
            <div>
                <div className="auth_tabs">
                    <span onClick={this.switchTab} data-tab="signin" className={this.state.activeTab === 'signin' ? 'active' : ''}>вход</span>
                    <span onClick={this.switchTab} data-tab="signup" className={this.state.activeTab === 'signup' ? 'active' : ''}>регистрация</span>
                </div>
                <div className="sidebar_forms">
                    {tabContent[this.state.activeTab]}
                </div>
            </div>
        );
    }
}

export default UnauthedForm;