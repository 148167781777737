import React from 'react';
import ProfileModal from './commonModal';
import ProfileDatePickerCalendar from '../ProfileDatePickerCalendar';
import API from '../../../../utils/api';
import './index.css';

import pinIcon from '../icons/pin.svg';

class PassportModal extends React.Component {
  constructor(props) {
    super(props)

    this.uploadPassportScan = React.createRef();

    this.state = {
      series_number: "",
      series: "",
      number: "",
      issued: "",
      department_code: "",
      date_issued: "",
      file: null,
      validate: {
        series: false,
        number: false,
        issued: false,
        department_code: false,
        date_issued: false,
        file: false
      }
    }
  }

  componentDidMount() {
    const { profile } = this.props.userData;
    const passport = profile.passport || {};
    this.setState({...passport});
  }

  handleSeriesNumberChange = (value) => {
    const seriesNumberValue = value.replace(" ", "");
    if (seriesNumberValue.match(/^[0-9]*$/gm)) {
      const stringValue = seriesNumberValue.toString();
      const series = stringValue.substring(0, 4);
      const number = stringValue.substring(4, 10);

      this.setState({series, number});
    }
  }

  handleFile = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      this.setState({file})
    }
  }
  
  openFileExplorer = () => {
    this.uploadPassportScan.current.click()
  }

  handleChange = (field, value) => {
    const {state} = this;
    const { validate } = state;
    validate[field] = false;
    state[field] = value;

    this.setState(state);
  }

  savePassport = () => {
    const { validate } = this.state;
    let validationError = false;

    if (!this.state.series || this.state.series.length === 0) {
      validate.series = true;
      validationError = true;
    }
    if (!this.state.number || this.state.number.length === 0) {
      validate.number = true;
      validationError = true;
    }
    if (!this.state.issued || this.state.issued.length === 0) {
      validate.issued = true;
      validationError = true;
    }
    if (!this.state.department_code || this.state.department_code.length === 0) {
      validate.department_code = true;
      validationError = true;
    }
    if (!this.state.date_issued || this.state.date_issued.length === 0) {
      validate.date_issued = true;
      validationError = true;
    }
    if (!this.state.file || this.state.file.length === 0) {
      validate.file = true;
      validationError = true;
    }

    if (validationError ) {
      this.setState({validate});
      return
    } 


    const data = {
      series: this.state.series,
      number: this.state.number,
      issued: this.state.issued,
      department_code: this.state.department_code,
      date_issued: this.state.date_issued,
    }

    this.props.loading(true);

    if (this.state.file) {
      const file = this.state.file;
      const formData = new FormData();

      formData.append('file', file);
      formData.append('image_type', "save");

      API.post(`/whub/v3/media?test=true`, formData)
        .then(result => {
          const passport_1 = result.data.save.normal;
          const { documents } = this.props.userData.profile;

          API.post('/whub/v3/profile', {passport: data, documents: {...documents, passport_1}})
            .then(function (r) {

            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(() => {
              this.props.loading(false);
              this.props.authFunc();
              this.props.close();
            });
        });
     } else {
      API.post('/whub/v3/profile', {passport: data})
            .then(function (r) {

            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(() => {
              this.props.loading(false);
              this.props.authFunc();
              this.props.close();
            });
     }
  }

  render() {
    return (
      <div>
        <ProfileModal title="" width="370px" closeModal={this.props.close}>
          <div className="passport_modal">

            <div className="password_modal_title">
              <span>Паспорт</span>
            </div>

            <div className="passport_modal_row">
              <div className="passport_modal_input_label">
                <span>Серия, Номер</span>
              </div>
              <div className={`passport_modal_input ${this.state.validate.series || this.state.validate.number ? "validate_error" : ""}`}>
                <input maxLength="11" value={this.state.series + ' ' + this.state.number} onChange={(e) => this.handleSeriesNumberChange(e.target.value)}></input>
              </div>
            </div>

            <div className="passport_modal_row">
              <div className="passport_modal_input_label">
                <span>Выдан</span>
              </div>
              <div className={`passport_modal_input ${this.state.validate.issued ? "validate_error" : ""}`}>
                <input maxLength="120" value={this.state.issued} onChange={(e) => this.handleChange("issued", e.target.value )}></input>
              </div>
            </div>

            <div className="passport_modal_row">
              <div className="passport_modal_input_label">
                <span>Код подразделения</span>
              </div>
              <div className={`passport_modal_input ${this.state.validate.department_code ? "validate_error" : ""}`}>
                <input maxLength="3" value={this.state.department_code} onChange={(e) => this.setState({department_code: e.target.value})}></input>
              </div>
            </div>

            <div className="passport_modal_row">
              <div className="passport_modal_input_label">
                <span>Дата выдачи</span>
              </div>
              <div className={`${this.state.validate.department_code ? "validate_error" : ""}`}>
                <ProfileDatePickerCalendar {...this.props} type="modal" value={this.state.date_issued} onChange={(value) => this.setState({date_issued: value})}/>
              </div>
            </div>

            <div className="passport_modal_row">
              <div onClick={this.openFileExplorer} className={`passport_modal_attach ${this.state.validate.file ? "validate_error" : ""}`}>
                {this.state.file ? <span>Файл прикреплен</span> : <span>Прикрепить фаил</span>}
                <div style={{backgroundImage: `url(${pinIcon})`}} className="student_profile_form_input_edit">
                  <input type="file" ref={this.uploadPassportScan} onChange={this.handleFile} style={{display: "none"}}></input>
                </div>
              </div>
            </div>

            <div>
              <div className="passport_modal_attach_desc">
                <span>Файл в формате jpg, jpeg, png размером до 10Мб.</span>
              </div>
            </div>

            <div onClick={this.savePassport} className="passport_modal_save_btn">
                <span>Сохранить</span>
            </div>
          </div>
        </ProfileModal>
      </div>
    )
  }
}

export default PassportModal;