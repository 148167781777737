import React from 'react';
import { Link } from 'react-router-dom';

class CourseFeaturedAd extends React.Component {
    constructor (props) {
        super();
    }

    render() {
        const p = this.props.data;

        const linkBlock = p.externalUrl ? <a href={p.url} className="course_preview_link" target="_blank" rel="noopener noreferrer">
            <div className="img_wrapper">
                <img className={p.cover_cls ? p.cover_cls : ''} src={p.cover} alt="" />
                <p className="cp_label">{p.label}</p>
            </div>

            <h3>{p.title}</h3>
            <p className="teaser">{p.teaser}</p>
        </a> : <Link to={p.url} className="course_preview_link">
            <div className="img_wrapper">
                <img className={p.cover_cls ? p.cover_cls : ''} src={p.cover} alt="" />
                <p className="cp_label">{p.label}</p>
            </div>

            <h3>{p.title}</h3>
            <p className="teaser">{p.teaser}</p>
        </Link>

        return (
            <div className="course_featured_item">
                {linkBlock}
            </div>
        );
    }
}

export default CourseFeaturedAd;
