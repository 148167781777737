import React from 'react';

export class Icon1 extends React.Component {

    render() {
        return(
            <svg xmlns="http://www.w3.org/2000/svg" width="55" height="53.978" viewBox="0 0 55 53.978">
                <g transform="translate(-0.65 -1.544)">
                    <path fill="#040e26" className="a"
                          d="M38.253,38.933a.145.145,0,0,1-.106-.145V36.715a15.87,15.87,0,0,0,2.658-5.26,10.744,10.744,0,0,0,2.3-4.647c.557-1.872,1.064-4.586-.4-6.531l-.067-.089c.708-3.4,1.588-11.451-4.525-15.53A3.767,3.767,0,0,0,39.189,1.91a.362.362,0,0,0-.128-.256.385.385,0,0,0-.273-.084c-.078,0-7.723.869-11.407.106C23.6.89,19.446,3.76,18.593,6.691a5.572,5.572,0,0,0-3.622,2.045c-1.672,2.056-2.179,5.338-1.521,9.735.045.557.111,1.114.212,1.672l-.067.084c-1.466,1.939-.931,4.653-.4,6.531a10.68,10.68,0,0,0,2.3,4.642,15.937,15.937,0,0,0,2.658,5.3v2.04a.139.139,0,0,1-.106.145C12.815,40.136.65,43.993.65,53.622a1.9,1.9,0,0,0,1.9,1.9h51.2a1.9,1.9,0,0,0,1.9-1.9C55.65,43.993,43.485,40.136,38.253,38.933Zm-10.1,7.383a10.03,10.03,0,0,1-8.225-4.09A3.9,3.9,0,0,0,21.714,40.1a12.733,12.733,0,0,0,2.747,1.371,11.061,11.061,0,0,0,7.378,0,13.006,13.006,0,0,0,2.736-1.382,3.9,3.9,0,0,0,1.8,2.157,10.03,10.03,0,0,1-8.225,4.073ZM16.476,25.877c-.674-2.379-.39-3.243-.162-3.544a.775.775,0,0,1,.669-.29h.162a.362.362,0,0,0,.379-.334c0-.05.424-5.177,3.46-7.021,1.516-.925,3.466-.886,5.79.106,4.759,2.051,8.3,1.906,10.459,1.427A4.831,4.831,0,0,1,38.816,21.5v.072a.364.364,0,0,0,.373.485.869.869,0,0,1,.814.284c.228.3.513,1.165-.162,3.544-.864,3.048-1.566,3.343-1.761,3.343a.357.357,0,0,0-.329.306A11.616,11.616,0,0,1,30.7,38.253a7.6,7.6,0,0,1-5.088,0,11.36,11.36,0,0,1-7.044-8.721.357.357,0,0,0-.329-.306C18.042,29.209,17.339,28.925,16.476,25.877Z"
                          transform="translate(0)"/>
                </g>
            </svg>
        )
    }
}

export class Icon2 extends React.Component {

    render() {
        return(
            <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" viewBox="0 0 55 55">
                <path fill="#040e26" d="M55,29.464v5.893H46.161V22.589h1.964A6.883,6.883,0,0,1,55,29.464ZM35.357,35.357V29.464a6.883,6.883,0,0,1,6.875-6.875H44.2V35.357Zm-31.429,0H21.514a2.114,2.114,0,0,1,2.035,1.964H3.929Zm29.464,1.964H25.559A2.116,2.116,0,0,1,27.6,35.357h5.795ZM48.125,2.946a.983.983,0,0,1,.982.982V20.683a8.87,8.87,0,0,0-.982-.058h-.982V2.946ZM27.5,0H44.2a.983.983,0,0,1,.982.982V20.625H42.232a8.85,8.85,0,0,0-8.839,8.839v3.929H27.6A3.88,3.88,0,0,0,25.536,34V1.964A1.966,1.966,0,0,1,27.5,0ZM13.75,0V12.915L11.57,10.19a.982.982,0,0,0-1.534,0l-2.18,2.724V0ZM3.929.982A.983.983,0,0,1,4.911,0h.982V15.714a.982.982,0,0,0,1.749.613L10.8,12.376l3.162,3.952a.982.982,0,0,0,1.749-.613V0h5.893a1.966,1.966,0,0,1,1.964,1.964V34a3.872,3.872,0,0,0-2.058-.607H3.929ZM.982,41.25A.983.983,0,0,1,0,40.268V3.929a.983.983,0,0,1,.982-.982h.982V38.3a.982.982,0,0,0,.982.982H33.393V41.25ZM48.125,55H42.232a6.883,6.883,0,0,1-6.875-6.875v-10.8H55v10.8A6.883,6.883,0,0,1,48.125,55Z"/>
            </svg>
        )
    }
}

export class Icon3 extends React.Component {

    render() {
        return(
            <svg xmlns="http://www.w3.org/2000/svg" width="54.25" height="53.661" viewBox="0 0 54.25 53.661">
                <g transform="translate(0 0)">
                    <g transform="translate(0 18.172)">
                        <path fill="#040e26" d="M55,23.006V53.7a.645.645,0,0,1-.641.641H31.8a3.95,3.95,0,0,1-7.851,0H1.391A.64.64,0,0,1,.75,53.7v-30.7a.64.64,0,0,1,.641-.641H4.2V50.259a.645.645,0,0,0,.641.641H24.406a2.83,2.83,0,0,1,2.819,2.8.641.641,0,0,0,1.281,0,2.786,2.786,0,0,1,2.811-2.8h19.59a.645.645,0,0,0,.641-.641V22.365h2.811A.645.645,0,0,1,55,23.006Z"
                              transform="translate(-0.75 -22.365)"/>
                    </g>
                    <path fill="#040e26" d="M46.871,19.715a18.345,18.345,0,0,1,.256,3.067,16.53,16.53,0,0,1-.111,1.9,6.247,6.247,0,0,1,3.11,5.4v4.161a6.255,6.255,0,0,1-6.245,6.254H41.531a1.925,1.925,0,0,1-1.922-1.922V25.755a1.925,1.925,0,0,1,1.922-1.922H43.24c.026-.367.043-.709.043-1.051a14.659,14.659,0,0,0-.325-3.067H32.125a2.806,2.806,0,0,0-2.811,2.8V40.151h1.768a3.751,3.751,0,0,1,0,7.5H29.314v2.691a4.154,4.154,0,0,1,.607-.478,4.107,4.107,0,0,1,2.2-.632H51.074V19.715Zm-21.657,0H14.389a14.659,14.659,0,0,0-.325,3.067c0,.342.017.692.043,1.051h1.7a1.925,1.925,0,0,1,1.922,1.922V38.57a1.94,1.94,0,0,1-1.5,1.88,2.244,2.244,0,0,0,2.127,1.529h5.809a3.749,3.749,0,0,1,3.212-1.828h.658V22.517A2.781,2.781,0,0,0,25.213,19.715Zm2.161,27.937a3.745,3.745,0,0,1-3.2-1.828H18.353A6.1,6.1,0,0,1,12.3,40.373a6.255,6.255,0,0,1-5.083-6.134V30.078a6.252,6.252,0,0,1,3.118-5.391,16.36,16.36,0,0,1-.111-1.905,18.345,18.345,0,0,1,.256-3.067H6.29V49.232H25.213a4.045,4.045,0,0,1,2.221.658,3.545,3.545,0,0,1,.6.453V47.652h-.658ZM25.213,19.715H14.389a14.659,14.659,0,0,0-.325,3.067c0,.342.017.692.043,1.051h1.7a1.925,1.925,0,0,1,1.922,1.922V38.57a1.94,1.94,0,0,1-1.5,1.88,2.244,2.244,0,0,0,2.127,1.529h5.809a3.749,3.749,0,0,1,3.212-1.828h.658V22.517A2.781,2.781,0,0,0,25.213,19.715Z"
                          transform="translate(-1.557 -3.807)"/>
                    <g transform="translate(6.937)">
                        <path fill="#040e26" d="M49.22,27.366v4.161A4.975,4.975,0,0,1,44.256,36.5H41.907a.645.645,0,0,1-.641-.641V23.043a.64.64,0,0,1,.641-.641h2.349a4.538,4.538,0,0,1,.487.026,15.315,15.315,0,0,0,.2-2.358A15.7,15.7,0,0,0,39.173,7.836a2.51,2.51,0,0,1-2.9.231,14.1,14.1,0,0,0-14.447,0,2.532,2.532,0,0,1-2.9-.231A15.726,15.726,0,0,0,13.159,20.07a15.313,15.313,0,0,0,.2,2.358,4.422,4.422,0,0,1,.487-.026h2.341a.635.635,0,0,1,.641.641V35.858a.64.64,0,0,1-.641.641H15.2v.521a3.535,3.535,0,0,0,3.528,3.528h6.647a2.466,2.466,0,0,1,2.375-1.828h3.708a2.469,2.469,0,0,1,0,4.938H27.751a2.473,2.473,0,0,1-2.375-1.828H18.729a4.815,4.815,0,0,1-4.81-4.81V36.5h-.077A4.977,4.977,0,0,1,8.87,31.526V27.366A4.932,4.932,0,0,1,12.1,22.727,16.962,16.962,0,0,1,18.182,6.793a2.486,2.486,0,0,1,1.042-3,19.181,19.181,0,0,1,19.624,0A2.489,2.489,0,0,1,39.9,6.785a16.858,16.858,0,0,1,6.091,15.942A4.916,4.916,0,0,1,49.22,27.366Z"
                              transform="translate(-8.87 -1.095)"/>
                    </g>
                </g>
            </svg>
        )
    }
}

export class Icon4 extends React.Component {

    render() {
        return(
            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
                <g transform="translate(-3 -2)">
                    <g transform="translate(3 2)">
                        <path fill="#040e26" d="M8.085,26.166a.848.848,0,1,1-.847-.833A.84.84,0,0,1,8.085,26.166Zm3.39-5V49.5a.84.84,0,0,1-.847.833H3.847A.84.84,0,0,1,3,49.5V21.166a.84.84,0,0,1,.847-.833h6.78A.84.84,0,0,1,11.475,21.166Zm-1.695,5a2.543,2.543,0,1,0-2.542,2.5,2.521,2.521,0,0,0,2.542-2.5Zm31.356,4.167H49.61a3.334,3.334,0,1,0,0-6.666H32.136a31.8,31.8,0,0,0,7.1-17.008,4.007,4.007,0,0,0,.025-.45,4.21,4.21,0,0,0-1.1-2.833A4.288,4.288,0,0,0,33.7,2.2a4.186,4.186,0,0,0-2.9,3.528c-.746,6.183-3.9,11.616-9.364,16.141a7.753,7.753,0,0,1-4.949,1.792H13.169V47.832h3.22a4.786,4.786,0,0,1,3.78,1.817A6.647,6.647,0,0,0,25.407,52H41.983a4.239,4.239,0,0,0,2.22-.733A2.266,2.266,0,0,0,45.373,49.5c0-1.383-1.983-2.5-3.39-2.5H36.051a.833.833,0,1,1,0-1.667h8.9a2.917,2.917,0,1,0,0-5.833H38.593a.833.833,0,1,1,0-1.667h8.9a2.917,2.917,0,1,0,0-5.833H41.136a.833.833,0,1,1,0-1.667Z"
                              transform="translate(-3 -2)"/>
                    </g>
                </g>
            </svg>
        )
    }
}

export class Icon5 extends React.Component {

    render() {
        return(
            <svg xmlns="http://www.w3.org/2000/svg" width="55" height="51" viewBox="0 0 55 51">
                <path fill="#040e26" d="M21.348,42.55C16.324,43.689,4.64,47.321,4.64,56.444a1.8,1.8,0,0,0,1.818,1.789H55.636a1.8,1.8,0,0,0,1.818-1.789c0-9.112-11.684-12.755-16.7-13.894a.137.137,0,0,1-.1-.137V40.451A14.907,14.907,0,0,0,43.2,35.478a9.926,9.926,0,0,0,2.206-4.389c.509-1.789,1.024-4.335-.388-6.172l-.061-.078c.679-3.22,1.527-10.823-4.345-14.675a3.547,3.547,0,0,0,1.036-2.594.338.338,0,0,0-.127-.239.374.374,0,0,0-.261-.078c-.042.012-7.418.823-10.951.1-3.636-.739-7.618,1.974-8.442,4.77a5.348,5.348,0,0,0-3.478,1.932c-1.606,1.944-2.1,5.045-1.46,9.2.042.537.109,1.061.2,1.6l-.061.078c-1.406,1.831-.891,4.4-.382,6.172a9.836,9.836,0,0,0,2.206,4.371,14.971,14.971,0,0,0,2.557,5.009v1.926A.131.131,0,0,1,21.348,42.55Zm9.7,6.977a9.689,9.689,0,0,1-7.878-3.864,3.718,3.718,0,0,0,1.691-2.01,12.31,12.31,0,0,0,2.636,1.294,10.786,10.786,0,0,0,7.084,0,12.57,12.57,0,0,0,2.63-1.306,3.712,3.712,0,0,0,1.727,2.039,9.69,9.69,0,0,1-7.884,3.846ZM19.839,30.213c-.648-2.248-.376-3.065-.152-3.351a.751.751,0,0,1,.642-.274h.158a.344.344,0,0,0,.364-.316c0-.048.406-4.89,3.321-6.637,1.46-.871,3.327-.841,5.563.1,4.569,1.92,7.969,1.789,10.042,1.342A4.506,4.506,0,0,1,41.3,26.063v.066a.336.336,0,0,0,.055.322.354.354,0,0,0,.309.131.844.844,0,0,1,.782.268c.218.286.491,1.1-.152,3.351-.83,2.88-1.5,3.148-1.691,3.166a.343.343,0,0,0-.315.286,11.014,11.014,0,0,1-6.775,8.235,7.384,7.384,0,0,1-4.89,0,10.758,10.758,0,0,1-6.793-8.223.343.343,0,0,0-.315-.286C21.336,33.361,20.663,33.093,19.839,30.213ZM59.084,36.4l-6.06,5.963a1.551,1.551,0,0,1-2.17,0l-3.291-3.238a1.771,1.771,0,0,1-.522-1.751,1.8,1.8,0,0,1,1.312-1.291,1.837,1.837,0,0,1,1.78.514l1.818,1.789,4.6-4.526a1.839,1.839,0,0,1,2.569,0h0a1.769,1.769,0,0,1-.036,2.54Z"
                      transform="translate(-4.64 -7.233)"/>
            </svg>
        )
    }
}

export class Icon6 extends React.Component {

    render() {
        return(
            <svg xmlns="http://www.w3.org/2000/svg" width="55" height="47.232" viewBox="0 0 55 47.232">
                <path fill="#040e26" d="M53.114,39.769V19.737a1.968,1.968,0,0,0-.138-.689V18.89a2.479,2.479,0,0,0-.374-.551h0a1.476,1.476,0,0,0-.394-.3L28.6,4.27a1.968,1.968,0,0,0-1.968,0L3.014,18.044a1.968,1.968,0,0,0,0,3.385L26.628,35.2a1.968,1.968,0,0,0,1.968,0L49.179,23.161V39.769a5.9,5.9,0,1,0,3.936,0Z"
                      transform="translate(-2.05 -4.006)"/>
                <path fill="#040e26" d="M43.181,26.5V19.349a1.988,1.988,0,0,0-.959-1.7,1.854,1.854,0,0,0-1.9,0L24.091,27.426,7.864,17.651a1.854,1.854,0,0,0-1.909,0A1.989,1.989,0,0,0,5,19.369V26.5a1.993,1.993,0,0,0,.859,1.639,31.875,31.875,0,0,0,36.463,0A1.993,1.993,0,0,0,43.181,26.5Z"
                      transform="translate(1.482 7.869)"/>
            </svg>
        )
    }
}