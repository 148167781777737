import React from 'react';

class DlItem extends React.Component {
    constructor (props) {
        super();
    }

    handleChange = (e) => {
        const t = this;
        const p = t.props;
        const k = e.target.name;
        const v = e.target.value;


        p.update(p.idx, k, v);
    };

    render() {
        const t = this;
        const p = t.props;

        return (
            <div className="course_dl_wrapper">
                <dl>
                    <dt>
                        <input maxLength={p.dtLength} placeholder={p.dtPlaceholder} onChange={t.handleChange} value={p.dt} name={p.dtName} />
                    </dt>
                    <dd>
                        <textarea maxLength={p.ddLength} placeholder={p.ddPlaceholder} onChange={t.handleChange} value={p.dd} name={p.ddName} />
                    </dd>
                </dl>
                <div data-idx={p.idx} onClick={p.removeItem} className="dl_item_delete">X</div>
            </div>
        );
    }
}

export default DlItem;
