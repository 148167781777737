import React from 'react';
import PdfViewer from "../../utils/pdf-viewer";
import API from '../../utils/api';
import './pdf.css';

class LessonPdf extends React.Component {
    constructor (props) {
        super();

        this.state = {
          statusSended: false,
        }
    }

    sendStatus = () => {
      if (!this.state.statusSended) {
        const id = this.props.lessonId;
        const lessons = this.props.courseData.lessons;
        const isStudent = this.props.userData.role === 'student';

        const current = lessons.find(el => el.id === parseInt(id,10));

        if (current.studyStatus !== 'finished' && isStudent) {
          this.setState({statusSended: true})
          API.put('/whub/v3/progress/' + id, {status: 'finished'})
          .then(result => {
            this.props.updateProgress(result.data);
          })
        }
      }
    }
 
    render() {
        const t = this;
        const p = t.props;
        const {content} = p;

        return (
            <div className="lesson_pdf_wrapper">
                <PdfViewer url={content.url} sendStatus={this.sendStatus}/>
            </div>
        )
    }
}

export default LessonPdf;
