import React from 'react';
import { Link } from 'react-router-dom';

import './index.css';
import {Logo} from "../../../utils/icons";

class LHeader extends React.Component {
    constructor (props) {
        super();

        this.state = {
        };
    }

    componentDidMount = () => {
    };

    componentWillUnmount = () => {
    };

    render() {
        return (
            <div className="ls_header_wrapper">
                <div className="l_centrer">
                    <div className="ls_logo">
                        <Link to={'/'}><Logo />
                        <span><nobr>W-HUB</nobr></span></Link>
                    </div>

                    <div className="ls_auth_links">
                        <Link to={'/signup/school'}><button>Создать курс</button></Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default LHeader;
