import React from 'react';

import './index.css';
import API from "../../../../../utils/api";

class FilesUploader extends React.Component {
    constructor (props) {
        super();

        this.state = {
        }
    }

    componentDidMount = () => {

    };

    handleUpload = (e) => {
        const t = this;
        const {setLoading} = t.props;
        const file = e.target.files[0];
        if (file) {
            console.log(file.name)
            setLoading(true);
            API.post('/whub/v3/cloud?file=' + file.name)
                .then(function (r) {
                    setLoading(false);
                    t.prepareUpload(r.data, file)
                })
                .catch(function (error) {
                    setLoading(false);
                    console.log(error);
                });
        }
    };

    prepareUpload = (d, file) => {
        const t = this;
        const {attr, obj, id} = d;
        const {onUpload} = t.props;
        delete obj.filename;
        delete obj.type;

        obj['Content-Type'] = file.type;

        const formData = t.convertToFormData(obj, file);

        onUpload({
            formData: formData,
            url: attr.action,
            id: id
        });

    };

    convertToFormData = (obj, file) => {
        const formData = new FormData();

        Object.keys(obj).forEach((key) => {
            formData.append(key, obj[key]);
        });
        formData.append('file', file);

        return formData;
    };

    handleHover = () => {
        const t = this;
        const {completed, failed, reset} = t.props;

        if ((completed || failed) && reset) {
            reset();
        }

    };

    render() {
        const t = this;
        const {progress, uploading, completed, failed} = t.props;

        let label = uploading ? 'Идет загрузка: ' + progress + '%. Ожидайте!' : 'Выберите или перетащите сюда видео в формате .mp4';

        if (completed) {
            label = 'Видео обрабатывается. По завершении обработки мы пришлем вам уведомление.'
        }

        if (failed) {
            label = 'При загрузке файла произошла ошибка, попробуйте еще раз!'
        }

        return (
            <div className="media_uploader">
                <label onMouseOver={t.handleHover} htmlFor={'mediaUpload'} className={'img_upload_block ' + this.props.className}>
                    <span>{label}</span>

                    <input disabled={uploading} type="file" accept="video/*" id={'mediaUpload'} onChange={this.handleUpload} />
                </label>

            </div>
        );
    }
}

export default FilesUploader;