import React from 'react';
import moment from 'moment';

import '../notifications.css';
import API from "../../utils/api";
import Preloader from "../../utils/preloader";

import logo from "../../header-v2/logo.png";
import arrowIco from '../profile-forms/student-profile/icons/arrow.svg'

import mainIcon from "./icons/main.svg";
import certIcon from './icons/cert.svg';
import hoorayIcon from './icons/cheers.svg';
import startIcon from './icons/start.svg';
import paymentIcon from './icons/payment.svg';
import timeIcon from './icons/time.svg';
import likeIcon from './icons/like.svg';
import excellentIcon from './icons/excellent.svg';
import taskIcon from './icons/task.svg';

class UserNotifications extends React.Component {
  constructor (props) {
    super();
    this.state = {
        loading: true,
        loadedLength: 0,
        limit: 10,
        page: 1,
        pages: 1,
        notifications: []
    };
  }

  iconType = {
    admin: mainIcon,
    task: taskIcon,
    excellent: excellentIcon,
    like: likeIcon,
    payment: paymentIcon,
    cerificate: certIcon,
    start: startIcon,
    hooray: hoorayIcon,
    time: timeIcon,
  }

  loadNotifications = (skip = 0) => {
    const isTest = process.env.REACT_APP_CUSTOM_NODE_ENV === 'dev' ? '&test=1' : '';
    const { limit } = this.state;
    API.get(`/whub/v3/notification?limit=${limit}&skip=${skip}${isTest}`).then(result => {
      const { data } = result;
      this.setState({ notifications: data.notifications, pages: Math.round(data.total / this.state.limit), loading: false});
    }).catch(error => console.log(error))
    .finally(() => this.setState({ loading: false}));
  }

  changePagination(value) {
    const offset = (value - 1) * this.state.limit;
    this.setState({page: value, loading: true});
    this.loadNotifications(offset);
  }

  renderPaginationOptions() {
    const options = Array.apply(0, Array(this.state.pages)).map((el, i) => {
      return (
        <option key={(i+1)*54}>{i + 1}</option>
      )
    });

    return options;
  }

  readNotifications = () => {
    API.post('/whub/v3/notification/')
  }

  componentDidMount() {
    this.loadNotifications();
    this.readNotifications();
  };

  render() { 
    const preloader = this.state.loading ? <Preloader/> : '';
    const supportScript = `<script data-b24-form="click/13/o8px73" data-skip-moving="true">{(function(w,d,u){var s=d.createElement('script');s.async=true;s.src=u+'?'+(Date.now()/180000|0);var h=d.getElementsByTagName('script')[0];h.parentNode.insertBefore(s,h);})(window,document,'https://cdn-ru.bitrix24.ru/b23230650/crm/form/loader_13.js')};</script>`
    
    return (<div>
      <ul className="notif_list">
        {this.state.notifications.map((notification, index) => {
            let avatar = notification.author && notification.author.avatar ? notification.author.avatar : null;
            const name = notification.author && notification.author.name ? notification.author.name : null;
            const slug = notification.author && notification.author.slug ? notification.author.slug : null;
            const icon = this.iconType[notification.type] ? this.iconType[notification.type] : mainIcon;
            let filteredContent = name && slug ? notification.content.replace(name, slug) : notification.content;
            const letter = slug && slug.length ? slug.split('')[0] : "";

            if (filteredContent.indexOf('getSupport()') >= 0 && filteredContent.indexOf('<a href="#"') >= 0) {
              const index = filteredContent.indexOf('<a href="#"'); 
              const start = filteredContent.slice(0, index);
              const end = filteredContent.slice(index, filteredContent.length);

              filteredContent = start + supportScript + end;
              filteredContent = filteredContent.replace('onclick="getSupport()"', '')
            }

            if (!avatar && letter.length === 0) {
              avatar = logo;
            }
          
            return (<li key={notification._id ? notification._id : notification.content} className="notif_list_row">
            <div className="notif_list_row_ico" style={{'backgroundImage': `url(${icon})`}}></div>
            <div className={`notif_list_row_logo ${avatar ? "" : "letter"}`} style={{'backgroundImage' : `url(${avatar})`}}>{avatar ? "" : letter}</div>
            <div className="notif_list_row_info">
              <span className="notif_list_row_info_date">{moment(notification.createdAt).format('DD.MM.YYYY')}</span>
              <span className={ ( !notification.read ? "unread" : "" ) + "notif_list_row_info_content"} dangerouslySetInnerHTML={{__html: filteredContent}}></span>
            </div>
          </li>)
          }
        )}
      </ul>
      { this.state.pages > 1 ? <div className="notifications_pagination_selector">
          <select value={this.state.page} onChange={(e) => this.changePagination(e.target.value)}>
            {this.renderPaginationOptions()}
          </select>
          <div className="notifications_pagination_selector_ico" style={{backgroundImage: `url(${arrowIco})`}}></div>
        </div> : "" }
      {preloader}
    </div>)
  }
}

export default UserNotifications;