import React, {useState} from 'react';
import { useHistory } from 'react-router-dom';

import './index.css';
import CITextInput from "../../../utils/controlled-input/ci-text-input";

const MainSearchBar = (props) => {
    const [search, setSearch] = useState('');
    let history = useHistory();

    const handleSubmit = (e) => {
        e.preventDefault();

        if (search.length) {
            history.push('/search?request=' + search);
        }
    };

    const stateSetter = (stateObj) => {
        setSearch(stateObj.search);
    };

    const IconSearch = () => {
        return (
            <svg className="msb_icon" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.8242 17.8008L12.832 12.8438C13.957 11.543 14.5898 9.89062 14.5898 8.0625C14.5898 4.05469 11.2852 0.75 7.27734 0.75C3.23438 0.75 0 4.05469 0 8.0625C0 12.1055 3.26953 15.375 7.27734 15.375C9.07031 15.375 10.7227 14.7422 12.0234 13.6172L16.9805 18.6094C17.1211 18.7148 17.2617 18.75 17.4375 18.75C17.5781 18.75 17.7188 18.7148 17.8242 18.6094C18.0352 18.3984 18.0352 18.0117 17.8242 17.8008ZM7.3125 14.25C3.86719 14.25 1.125 11.4727 1.125 8.0625C1.125 4.65234 3.86719 1.875 7.3125 1.875C10.7227 1.875 13.5 4.65234 13.5 8.0625C13.5 11.5078 10.7227 14.25 7.3125 14.25Z" fill="#1F57C3"/>
            </svg>
        )
    };

    return (
        <div className="main_search_bar">
            <IconSearch />
            <form onSubmit={handleSubmit}>
                <CITextInput name="search" value={search} onChange={stateSetter} placeholder="Найди свой курс" />
            </form>

        </div>
    );
};

export default MainSearchBar;
