import React from 'react';
import ProfileModal from './commonModal';
import API from '../../../../utils/api';
import './index.css';

import ProfileDatePickerCalendar from '../ProfileDatePickerCalendar';

import pinIcon from '../icons/pin.svg';
import arrowIcon from '../icons/arrow.svg';

class DiplomaModal extends React.Component {
  constructor(props) {
    super(props)

    this.uploadNameScan = React.createRef();
    this.uploadDiplomaScan = React.createRef();

    this.state = {
      series: "",
      number: "",
      education: "",
      qualification: "",

      changed: false,
      previous_surname: "",
      previous_last_name: "",
      previous_middle_name: "",
      date_changed: "",
      act_entry: "",
      registration: "",
      files: {
        diploma: null,
        name_change: null,
      },
      documents: {
        diploma: null,
        name_change: null,
      },
      validate: {
        series: false,
        number: false,
        education: false,
        qualification: false,
        diploma: false,

        previous_surname: false,
        previous_last_name: false,
        previous_middle_name: false,
        date_changed: false,
        act_entry: false,
        registration: false,
        name_change: false,
      }
    }
  }

  componentDidMount() {
    const { profile } = this.props.userData;
    const diploma = profile.diploma || {};
    const different_name = profile.different_name || {};

    let changed = false;

    Object.keys(different_name).forEach(key => {
      if (different_name[key].length > 0) {
        changed = true;
      }
    })

    const newState = {
      ...diploma,
      ...different_name,
      changed
    }
    
    this.setState({...newState});
  }

  openFileExplorer = (type) => {
    if (type === 'diploma') {
      this.uploadDiplomaScan.current.click()
    } else if (type === 'name_change') {
      this.uploadNameScan.current.click()
    }
  }

  handleFile = (e, type) => {
    const { files } = this.state;
    if (e.target.files[0]) {
      files[type] = e.target.files[0]
      this.setState({files})
    }
  }

  handleSeriesNumberChange = (value, type) => {
    if (value.match(/^[0-9]*$/gm)) {
      const newState = this.state;
      newState[type] = value;
      this.setState(newState);
    }
  }

  handleRadioChange = (e, type) => {
    const value = type === 'yes' ? true : false;
    this.setState({changed: value})
  }

  saveDiploma = async () => {
    const { validate } = this.state;
    let validationError = false;

    if (!this.state.series || this.state.series.length === 0) {
      validate.series = true;
      validationError = true;
    }
    if (!this.state.number || this.state.number.length === 0) {
      validate.number = true;
      validationError = true;
    }
    if (!this.state.education || this.state.education.length === 0) {
      validate.education = true;
      validationError = true;
    }
    if (!this.state.qualification || this.state.qualification.length === 0) {
      validate.qualification = true;
      validationError = true;
    }
    if (!this.state.files.diploma || this.state.files.diploma.length === 0) {
      validate.diploma = true;
      validationError = true;
    }

    if (validationError ) {
      this.setState({validate});
      return
    } 


    const diploma = {
      series: this.state.series,
      number: this.state.number,
      education: this.state.education,
      qualification: this.state.qualification,
    }

    const different_name = {
      previous_surname: this.state.previous_surname,
      previous_last_name: this.state.previous_last_name,
      previous_middle_name: this.state.previous_middle_name,
      date_changed: this.state.date_changed,
      act_entry: this.state.act_entry,
      registration: this.state.registration,
    }

    const data = {
      diploma
    }

    if (this.state.changed) {
      data.different_name = {...different_name};
    }

    this.props.loading(true);

    const files = [];

    Object.keys(this.state.files).forEach(key => {
      files.push({type: key, data: this.state.files[key]});
    })

    for await (let file of files) {
      const type = file.type;
      
      if (file.data) {
        const formData = new FormData();
        formData.append('file', file.data);
        formData.append('image_type', "save");

        await API.post(`/whub/v3/media?test=true`, formData)
          .then(result => {
            const resultStr = result.data.save.normal;
            const { documents } = this.state;
            documents[type] = resultStr;
            this.setState({documents});
          });
      }
    }

    const { documents } = this.props.userData.profile;

    if (this.state.documents.diploma) {
      documents.diploma = this.state.documents.diploma;
    }

    if (this.state.documents.name_change) {
      documents.name_change = this.state.documents.name_change;
    }

    data.documents = {...documents};

    API.post('/whub/v3/profile', data)
      .then(function (r) {

      })
      .catch(function (error) {
          console.log(error);
      })
      .finally(() => {
        this.props.loading(false);
        this.props.authFunc();
        this.props.close();
      });
  }

  render() { 
    return (
      <div>
        <ProfileModal contentStyle={{}} title="" width="380px" closeModal={this.props.close}>
          <div className="diploma_modal">

            <div className="diploma_modal_title">
              <span>Диплом</span>
            </div>

            <div className="diploma_modal_row">
              <div className="diploma_modal_input_label">
                <span>Серия</span>
              </div>
              <div className={`diploma_modal_input ${this.state.validate.series ? "validate_error" : ""}`}>
                <input maxLength="20" value={this.state.series} onChange={(e) => this.handleSeriesNumberChange(e.target.value, 'series')}></input>
              </div>
            </div>

            <div className="diploma_modal_row">
              <div className="diploma_modal_input_label">
                <span>Номер</span>
              </div>
              <div className={`diploma_modal_input ${this.state.validate.number ? "validate_error" : ""}`}>
                <input maxLength="20" value={this.state.number} onChange={(e) => this.handleSeriesNumberChange(e.target.value, 'number')}></input>
              </div>
            </div>

            <div className="diploma_modal_row">
              <div className="diploma_modal_input_label">
                <span>Дата выдачи</span>
              </div>
              <div className={`${this.state.validate.education ? "validate_error" : ""}`}>
                <ProfileDatePickerCalendar {...this.props} type="modal" value={this.state.education} onChange={(value) => this.setState({education: value})}/>
              </div>
            </div>

            <div className="diploma_modal_row">
              <div className="diploma_modal_input_label">
                <span>Уровень образования</span>
              </div>
              <div className={`diploma_modal_input ${this.state.validate.qualification ? "validate_error" : ""}`}>
                {/* <input maxLength="20" value={this.state.qualification} onChange={(e) => this.setState({qualification: e.target.value})}></input> */}
                <select value={this.state.qualification} onChange={(e) => this.setState({qualification: e.target.value})}>
                  <option>Среднее профессиональное образование</option>
                  <option>Высшее образование\Бакалавриат</option>
                  <option>Высшее образование\Магистратура</option>
                  <option>Высшее образование\Специалитет</option>
                  <option>Высшее образование\Аспирантура</option>
                </select>
                <div className="diploma_modal_input_arrow" style={{backgroundImage: `url(${arrowIcon})`}}></div>
              </div>
            </div>

            <div className="diploma_modal_row">
              <div className="diploma_modal_input_label">
                <span>Отличается ли ваша фамилия, имя или отчество от данных в дипломе?</span>
              </div>
              
              <div className="diploma_modal_radio_group">
                <div className="diploma_modal_radio_group_item">
                  <div className="diploma_modal_radio_group_radio">
                    <input 
                      onChange={(e) => this.handleRadioChange(e, 'no')}
                      value={!this.state.changed} checked={!this.state.changed}
                      type="radio" 
                      id="changed"></input>
                    { !this.state.changed ? <div className="diploma_modal_radio_group_radio_selected"></div> : ''}
                  </div>
                  <div className="diploma_modal_radio_group_radio_label">
                    <span>Нет</span>
                  </div>
                </div>

                <div className="diploma_modal_radio_group_item">
                  <div className="diploma_modal_radio_group_radio">
                    <input 
                      onChange={(e) => this.handleRadioChange(e, 'yes')}
                      value={this.state.changed}
                      checked={this.state.changed} type="radio" id="changed"></input>
                    { this.state.changed ? <div className="diploma_modal_radio_group_radio_selected"></div> : ''}
                  </div>
                  <div className="diploma_modal_radio_group_radio_label">
                    <span>Да</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="diploma_modal_attach_desc">
              <span>Укажите изменения отличные от фактических и указанные в дипломе (если менялись)</span>
            </div>

            { this.state.changed ? <div className="diploma_modal_changeName_form">
              
              <div className="diploma_modal_changeName_form_input">
                <input  
                  disabled={!this.state.changed}
                  maxLength="30"
                  placeholder='Прежняя фамилия'
                  value={this.state.previous_last_name}
                  onChange={(e) => this.setState({previous_last_name: e.target.value})}>
                </input>
              </div>

              <div className="diploma_modal_changeName_form_input">
                <input
                  disabled={!this.state.changed}
                  maxLength="25"
                  placeholder='Прежнее имя'
                  value={this.state.previous_surname}
                  onChange={(e) => this.setState({previous_surname: e.target.value})}>
                </input>
              </div>

              <div className="diploma_modal_changeName_form_input">
                <input
                  disabled={!this.state.changed}
                  maxLength="30"
                  placeholder='Прежнее отчество'
                  value={this.state.previous_middle_name}
                  onChange={(e) => this.setState({previous_middle_name: e.target.value})}>
                </input>
              </div>

              <div className="diploma_modal_changeName_form_input">
                <ProfileDatePickerCalendar
                  {...this.props}
                  type="modal"
                  disabled={!this.state.changed}
                  value={this.state.date_changed}
                  placeholder={"Дата изменения"}
                  onChange={(value) => this.setState({date_changed: value})}/>
              </div>

              <div className="diploma_modal_changeName_form_input">
                <input
                  disabled={!this.state.changed}
                  maxLength="20"
                  placeholder='Запись акта №'
                  value={this.state.act_entry}
                  onChange={(e) => this.setState({act_entry: e.target.value})}>                    
                </input>
              </div>

              <div className="diploma_modal_changeName_form_registration diploma_modal_changeName_form_input">
                <textarea
                  disabled={!this.state.changed}
                  type="textarea"
                  rows="2"
                  wrap="hard"
                  maxLength="120"
                  placeholder='Место государственной регистрации'
                  value={this.state.registration}
                  onChange={(e) => this.setState({registration: e.target.value})}>                     
                </textarea>
              </div>

              <div className="diploma_modal_attach_desc">
                <span>Загрузите документ о смене Фамилии и/или Имени и/или Отчества (свидетельство о браке или др.)</span>
              </div>

              <div onClick={() => this.openFileExplorer('name_change')} className="diploma_modal_changeName_form_attach">
                {this.state.files.name_change ? <span>Файл прикреплен</span> : <span>Прикрепить фаил</span>}
                <div style={{backgroundImage: `url(${pinIcon})`}} className="student_profile_form_input_edit">
                <input type="file" ref={this.uploadNameScan} onChange={(e) => {this.handleFile(e, 'name_change')}} style={{display: "none"}}></input>
                </div>
              </div>

              <div className="diploma_modal_attach_desc">
                <span>Файл в формате jpg, jpeg, png размером до 10Мб.</span>
              </div>

            </div> : ""}

            <div className="passport_modal_row">
              <div className="diploma_modal_input_label">
                <span>Загрузите диплом о высшем или среднем профессионально образование</span>
              </div>
              <div onClick={() => this.openFileExplorer('diploma')} className={`diploma_modal_attach ${this.state.validate.diploma ? "validate_error" : ""}`}>
                {this.state.files.diploma ? <span>Файл прикреплен</span> : <span>Прикрепить фаил</span>}
                <div style={{backgroundImage: `url(${pinIcon})`}} className="student_profile_form_input_edit">
                <input type="file" ref={this.uploadDiplomaScan} onChange={(e) => {this.handleFile(e, 'diploma')}} style={{display: "none"}}></input>
                </div>
              </div>
            </div>

            <div className="diploma_modal_attach_desc">
              <span>Файл в формате jpg, jpeg, png размером до 10Мб.</span>
            </div>

            <div>
              <div onClick={() => this.saveDiploma()}className="diploma_modal_save_btn">
                <span>Сохранить</span>
              </div>
            </div>
          </div>
        </ProfileModal>
      </div>
    )
  }
}

export default DiplomaModal;