import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { EditorState, Modifier, convertFromHTML, BlockMapBuilder } from 'draft-js';

import './text.css';


class TestCustomOption extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    editorState: PropTypes.object,
  };

  state = {
    showModal: false,
    linkURL: '',
    linkTitle: '',
    errorText: '',
  }

  switchModal = () => {
    this.setState(
      {
        showModal: !this.state.showModal,
        linkURL: '',
        linkTitle: '',
        errorText: '',
      });
  }

  formLink = () => {
    const linkURL = this.state.linkURL
    const url = linkURL.startsWith('http://') || linkURL.startsWith('https://') ? linkURL : 'http://' + linkURL;

    return `<a href="${url}">${this.state.linkTitle}</a>`
  }

  buttonDisabled = () => {
    return this.state.linkURL.length === 0 || this.state.linkTitle.length === 0;
  }

  addLink = () => {
    const { onChange, editorState } = this.props;

    if (!this.state.linkURL.startsWith('https://forms.yandex.ru')) {
      this.setState({errorText: 'Ссылка должна начинаться с https://forms.yandex.ru'})
      return
    }

    const htmlContent = convertFromHTML(this.formLink());
    const content = BlockMapBuilder.createFromArray(htmlContent.contentBlocks)

    const toInsert = Modifier.replaceWithFragment(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      content,
    )
    
    onChange(EditorState.push(editorState, toInsert, 'insert-characters'));
    this.setState({errorText: ''});
    this.switchModal();
  };

  render() {
    return (
      <div className="rdw-link-wrapper">
        <div className="rdw-option-wrapper" onClick={this.switchModal}>Тест</div>
        {this.state.showModal && 
        <div className="add-link-modal">
          <label for="linkTitle">Текст ссылки</label>
          <input onChange={(e) => this.setState({linkTitle: e.target.value})} name="linkTitle"></input>

          <label for="linkURL">Ссылка на тест</label>
          <input onChange={(e) => this.setState({linkURL: e.target.value})} name="linkURL"></input>
          <span className="add-link-modal_errorText">{this.state.errorText}</span>

          <button onClick={this.addLink} disabled={this.buttonDisabled()}>Добавить</button>
        </div>
        }
      </div>
    );
  }
}

export default TestCustomOption;