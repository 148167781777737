import React from 'react';
import './index.css';
import HeaderBg from "./components/bg";
import HeaderRating from "./components/rating";
import HeaderButtons from "./components/buttons";
import HeaderBasicParams from "./components/basic-params";
import {html2plaintext} from "../../../../utils/utils";
import HeaderDateStart from "./components/date-start";

class Header extends React.Component {
    constructor (props) {
        super();

        this.state = {
        };
    }

    render() {
        const t = this;
        const p = t.props;
        const data = p.data;

        return (
            <div className="cl_header">
                <HeaderBg {...p} />

                <div className="cl_centrer">
                    <div className="header_content">
                        <HeaderRating {...p} />
                        <HeaderDateStart {...p} />
                        <h1>{html2plaintext(data.title)}</h1>
                        <HeaderBasicParams {...p} />
                    </div>

                    <HeaderButtons {...p} />
                </div>
            </div>
        );
    }
}

export default Header;
