const createImage = url =>
    new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener('load', () => resolve(image));
        image.addEventListener('error', error => reject(error));
        image.setAttribute('crossOrigin', 'anonymous');
        image.src = url
    });

export default async function getCroppedImg(src, pixelCrop, cropSize, toCoords) {

    if (toCoords) {
        return [
            pixelCrop.x,
            pixelCrop.y,
            pixelCrop.width,
            pixelCrop.height,
            cropSize.width,
            cropSize.height
        ].join(',')
    }
    else {
        const image = await createImage(src);
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        canvas.width = cropSize.width;
        canvas.height = cropSize.height;

        ctx.drawImage(
            image,
            pixelCrop.x,
            pixelCrop.y,
            pixelCrop.width,
            pixelCrop.height,
            0,
            0,
            cropSize.width,
            cropSize.height
        );

        return canvas.toDataURL('image/jpeg');
    }

}
