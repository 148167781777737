import React from 'react';
import {getDropdownSelected} from "../../../../../utils/utils";
import Dropdown from "../../../../../utils/dropdown/dropdown";
import ru from "date-fns/locale/ru";
import {addDays} from "@progress/kendo-date-math";
import DatePicker from "react-datepicker";

class LessonTypeWebinar extends React.Component {
    constructor (props) {
        super();

        const content = props.content;

        const platformsList = [
            {label: 'Zoom', value: 'zoom'},
            {label: 'Youtube Live', value: 'youtube'},
            {label: 'Webinar.ru', value: 'webinar.ru'}
        ];

        const lecturersList = this.makeLecturersList(props.courseData.authors);

        const lecturer = content.lecturer ? content.lecturer : null;
        const date = content.date ? new Date(content.date) : addDays(new Date(), 3);
        const url = content.url ? content.url : '';
        const platform = content.platform ? content.platform : 'zoom';

        this.state = {
            platform: platform,
            lecturersList: lecturersList,
            lecturer: lecturer,
            date: date,
            url: url,
            originalState: {
                platform: platform.slice(),
                date: new Date(date),
                url: url.slice(),
                lecturer: lecturer ? lecturer.slice() : null
            },
            platformsList: platformsList,
            datePickerFocused: false,
            valid: false,
            changes: false
        }
    }

    componentDidMount() {
        this.validate();
    }

    makeLecturersList = (authors) => {
        let list = [];
        if (authors && authors.length) {
            list.push({label: 'выбрать...', value: null});

            authors.forEach((author) => {
                list.push({label: author.name, value: author.id});
            });
        }

        return list;
    };

    validate = () => {
        const t = this;
        const {url, date, platform, lecturer, originalState} = t.state;

        const changes = url !== originalState.url
            || new Date(date).getTime() !== new Date(originalState.date).getTime()
            || platform !== originalState.platform
            || lecturer !== originalState.lecturer;

        t.setState({valid: true, changes: changes}, t.updateLesson);
    };

    onDropdownChange = (d) => {
        const t = this;
        const obj = {};
        obj[d.name] = d.data.value;
        t.setState(obj, t.validate);
    };

    handleTextInput = (e) => {
        const obj = {};
        obj[e.target.name] = e.target.value;
        this.setState(obj, this.validate);
    };

    updateLesson = () => {
        const t = this;
        const {updateTypeContent} = t.props;
        const {url, platform, date, lecturer, valid, changes} = t.state;

        const d = {
            content: {
                url: url,
                platform: platform,
                date: date.getTime()
            },
            state: {
                valid: valid,
                changes: changes
            }
        };

        if (lecturer) {
            d.content.lecturer = lecturer;
        }

        updateTypeContent(d)
    };

    render() {
        const t = this;
        const {platform, platformsList, lecturer, lecturersList, date, url, datePickerFocused} = t.state;

        const platformSelected = getDropdownSelected(platformsList, platform);
        const lecturerSelected = lecturersList.length ? getDropdownSelected(lecturersList, lecturer) : null;

        const cls_focused = datePickerFocused ? 'focused' : '';
        return (
            <div>
                <dl className="z_up_3">
                    <dt>Лектор:</dt>
                    <dd>
                        {lecturerSelected ? <Dropdown onChange={t.onDropdownChange} name="lecturer" className="dd_cats" selected={lecturerSelected} ddArr={lecturersList} /> : 'для выбора лектора необходимо добавить хотя бы одного автора на вкладке "лендинг"!'}

                    </dd>
                </dl>
                <dl className="z_up">
                    <dt>Платформа проведения:</dt>
                    <dd>
                        <Dropdown onChange={t.onDropdownChange} name="platform" className="dd_cats" selected={platformSelected} ddArr={platformsList} />
                    </dd>
                </dl>
                <dl className={cls_focused}>
                    <dt>Дата и время:</dt>
                    <dd>
                        <DatePicker
                            className="text_input"
                            selected={date}
                            locale={ru}
                            minDate={addDays(new Date(), 3)}
                            onChange={date => t.setState({date: date}, () => t.validate())}
                            showTimeSelect
                            timeCaption="время"
                            dateFormat="dd.MM.yyyy HH:mm"
                            onCalendarOpen={() => t.setState({datePickerFocused: true})}
                            onCalendarClose={() => t.setState({datePickerFocused: false})}
                        />
                    </dd>
                </dl>
                <dl>
                    <dt>ссылка на вебинар:</dt>
                    <dd>
                        <input type="text" value={url} name="url" onChange={t.handleTextInput} />
                        <div className="course_form_hint">вы можете вставить ссылку на вебинар в любое время, но не позднее, чем за час до начала вебинара!</div>
                    </dd>
                </dl>
            </div>
        );
    }
}

export default LessonTypeWebinar;
