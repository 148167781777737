import React from 'react';

import SearchResultV2 from "./search-results";
import API from "../utils/api";
import {compareObjects, serrializer} from "../utils/utils";

let searchTimeout = null;

class SearchEngine extends React.Component {
    constructor (props) {
        super();

        this.state = {
            params: {},
            loading: false,
            dataLoaded: [],
            totalLength: 0,
            curPage: 1,
            initialLoadDone: false,
            bottomLoading: false,
            canLoad: true
        };

        this.handleScroll = this.handleScroll.bind(this);
    }

    updateParams = (params) => {
        const t = this;
        t.props.updateSearchFunc(params, this.processSearch);
    };

    processSearch = () => {
        const t = this;
        const s = t.state;

        if (!s.loading) {
            this.handleSearchRequest();
        }
    };

    bottomLoad = () => {
        const t = this;
        const s = t.state;
        const nextPage = s.curPage + 1;

        if (s.dataLoaded.length < s.totalLength) {
            t.setState({curPage: nextPage}, () => {
                t.handleSearchRequest({paging: true});
            });
        }

    };

    handleSearchRequest = (additional_params) => {
        const t = this;
        const s = t.state;
        const p = t.props;
        const params = p.params;
        let curPage = s.curPage;

        if (!additional_params) {
            additional_params = {};
        }

        if (!additional_params.paging) {
            curPage = 1;
            t.setState({curPage: 1});
        }

        const product_type = [];
        const rObj = {
            paged: curPage,
            s: params.request ? params.request : p.searchRequest,
            per_page: 30,
            orderby: 'rating',
            product_type: []
        };

        if (params.video) {
            product_type.push('video');
        }
        if (params.audio) {
            product_type.push('audio');
        }
        rObj.product_type = product_type.join(',');

        if (params.category) {
            rObj.category = params.category;
        }

        if (params.subcategory) {
            rObj.category = params.subcategory;
        }

        if (params.order ) {
            rObj.order = params.order;
        }
        if (params.orderby ) {
            rObj.orderby = params.orderby;
        }

        if (params.min_price) {
            rObj.min_price = params.min_price;
        }

        if (params.max_price) {
            rObj.max_price = params.max_price;
        }

        if (params.free) {
            rObj.max_price = '0.01';
        }

        if (params.withDiscount) {
            rObj.withDiscount = true;
        }


        if (additional_params.paging && s.totalLength <= s.dataLoaded.length) {
            return false;
        }

        // if (additional_params.paging) {
        //     rObj.offset = s.dataLoaded.length;
        // }

        if (additional_params.paging) {
            t.setState({bottomLoading: true});
        }
        else {
            t.setState({loading: true});
        }

        API.get('/whub/v3/course?' + serrializer(rObj))
            .then(function (r) {
                const totalFound = r.data.total;

                const data = r.data.results;
                let output = data;

                if (additional_params.paging) {
                    output = s.dataLoaded.concat(data);
                }

                if (output.length >= totalFound) {
                    t.setState({canLoad: false});
                    document.body.classList.remove('no_footer');
                }
                else {
                    t.setState({canLoad: true});
                    document.body.classList.add('no_footer');
                }

                if (!s.initialLoadDone) {
                    t.setState({initialLoadDone: true, params: p.params}, () => {
                        t.setState({
                            loading: false,
                            bottomLoading: false,
                            dataLoaded: output,
                            totalLength: totalFound
                        });
                    });
                }
                else {
                    t.setState({
                        loading: false,
                        bottomLoading: false,
                        dataLoaded: output,
                        totalLength: totalFound
                    });
                }

            })
            .catch(function (error) {
                t.setState({loading: false, pagingLoading: false});
                console.log(error);
            });
    };

    handleScroll() {
        const s = this.state;
        const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
        const body = document.body;
        const html = document.documentElement;
        const footerHeight = document.querySelectorAll('.page_footer')[0].clientHeight;
        const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight) - footerHeight;
        const windowBottom = windowHeight + window.pageYOffset;
        if (windowBottom >= docHeight) {
            if (!s.bottomLoading && s.canLoad && s.initialLoadDone) {
                console.log('bottom')
                this.bottomLoad();
            }
        }
    }

    componentDidMount() {
        const t = this;
        const s = t.state;
        window.addEventListener("scroll", this.handleScroll);
        document.body.classList.add('no_footer');

        if (!s.initialLoadDone) {
            t.processSearch();
        }
    };

    componentDidUpdate() {
        const t = this;
        const p = t.props;
        const s = t.state;

        if (!s.loading && s.initialLoadDone) {
            if (p.searchRequest && s.params.request !== undefined && (p.searchRequest.toString() !== s.params.request.toString())) {
                const newParamsObj = p.params;
                newParamsObj.request = p.searchRequest;
                t.setState({params: newParamsObj});

                if (searchTimeout) {
                    clearTimeout(searchTimeout);
                }
                searchTimeout = setTimeout(() => {
                    t.processSearch();
                }, 500);
            }
            else {
                if (!compareObjects(p.params, s.params) && !s.loading) {
                    t.setState({loading: true});
                    const newParamsObj = p.params;
                    newParamsObj.searchRequest = p.searchRequest;
                    t.setState({params: newParamsObj})
                    t.processSearch();
                }
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
        document.body.classList.remove('no_footer');
    }


    render() {
        const t = this;
        const p = t.props;
        const s = t.state;

        const defaultParams = {
            request: p.searchRequest,
            free: false,
            orderby: 'rating',
            min_price: 0,
            max_price: 100000
        };

        const searchParams = Object.assign(defaultParams, p.params);

        return <SearchResultV2
            {...p}
            searchParams={searchParams}
            dataLoaded={s.dataLoaded}
            bottomLoading={s.bottomLoading}
            initialLoadDone={s.initialLoadDone}
            filterCheckboxFunc={t.updateParams}
            paramsFunc={t.updateParams}
            loadedLength={s.totalLength}
            loading={s.loading}
        />;
    }
}

export default SearchEngine;
