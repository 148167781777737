import React from 'react';
import API from '../../utils/api';

import './text.css';

class LessonText extends React.Component {
    constructor (props) {
        super();
        this.state = {
          statusSended: false,
        }
    }

    sendStatus = () => {
      const bottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight - 300;
      const content = document.getElementsByClassName('lesson_text_wrapper');
      const isStudent = this.props.userData.role === 'student';
      const contentHeight = content[0].offsetHeight;
  
      if (bottom || window.innerHeight > contentHeight) {
        const id = this.props.lessonId;

        if (isStudent && !this.state.statusSended) {
          API.put('/whub/v3/progress/' + id, {status: 'finished'})
          .then(result => {
            this.props.updateProgress(result.data);
          })
          window.removeEventListener('scroll', this.sendStatus);
          this.setState({statusSended: true})
        }
      }
    };

    componentDidMount() {
      this.sendStatus();
      window.addEventListener('scroll', this.sendStatus, {
        passive: true
      });
    }

    componentWillUnmount() {
      window.removeEventListener('scroll', this.sendStatus);
      this.setState({statusSended: false});
    }

    render() {
        const t = this;
        const p = t.props;
        const {content} = p;

        return (
            <div className="lesson_text_wrapper" dangerouslySetInnerHTML={{ __html: content.text }}>
            </div>
        )
    }
}

export default LessonText;
