import React from 'react';

export class Cam extends React.Component {

    render() {
        return(
            <svg width="24" height="24" viewBox="0 0 24 24">
                <g transform="translate(2 5)">
                    <path fill="#ffd83e" opacity="0.4"
                          d="M3.743.216A1.383,1.383,0,0,0,2.37.152L.888.9A1.618,1.618,0,0,0,0,2.356V8.19A1.622,1.622,0,0,0,.888,9.647l1.481.747A1.372,1.372,0,0,0,3,10.547a1.4,1.4,0,0,0,.743-.216,1.429,1.429,0,0,0,.669-1.218V1.435A1.432,1.432,0,0,0,3.743.216"
                          transform="translate(15.588 2.226)"/>
                    <path fill="#ffd83e"
                          d="M9.905,15H4.113A3.91,3.91,0,0,1,0,10.939V4.061A3.91,3.91,0,0,1,4.113,0H9.905a3.91,3.91,0,0,1,4.113,4.061v6.878A3.91,3.91,0,0,1,9.905,15"
                          transform="translate(0 0)"/>
                </g>
            </svg>
        )
    }
}

export class Calendar extends React.Component {

    render() {
        return(
            <svg width="24" height="24" viewBox="0 0 24 24">
                <g transform="translate(3 2)">
                    <path fill="none" stroke="#afebff" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.5" className="a" d="M0,.473H17.824" transform="translate(0.093 6.931)"/>
                    <path fill="none" stroke="#afebff" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.5" className="a" d="M.459.473H.468" transform="translate(12.984 10.837)"/>
                    <path fill="none" stroke="#afebff" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.5" className="a" d="M.459.473H.468" transform="translate(8.546 10.837)"/>
                    <path fill="none" stroke="#afebff" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.5" className="a" d="M.459.473H.468" transform="translate(4.099 10.837)"/>
                    <path fill="none" stroke="#afebff" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.5" className="a" d="M.459.473H.468" transform="translate(12.984 14.723)"/>
                    <path fill="none" stroke="#afebff" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.5" className="a" d="M.459.473H.468" transform="translate(8.546 14.723)"/>
                    <path fill="none" stroke="#afebff" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.5" className="a" d="M.459.473H.468" transform="translate(4.099 14.723)"/>
                    <path fill="none" stroke="#afebff" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.5" className="a" d="M.463,0V3.291" transform="translate(12.581 0)"/>
                    <path fill="none" stroke="#afebff" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.5" className="a" d="M.463,0V3.291" transform="translate(4.502 0)"/>
                    <path fill="none" stroke="#afebff" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.5" className="a"
                          d="M13.238,0H4.771C1.834,0,0,1.636,0,4.643v9.05c0,3.054,1.834,4.728,4.771,4.728h8.458c2.946,0,4.771-1.645,4.771-4.652V4.643C18.009,1.636,16.184,0,13.238,0Z"
                          transform="translate(0 1.579)"/>
                </g>
            </svg>
        )
    }
}

export class VideoPreviewBtnPlay extends React.Component {

    render() {
        return(
            <svg width="24" height="32" viewBox="0 0 24 32">
                <path d="M14.336,2.5a2,2,0,0,1,3.328,0L29.927,20.891A2,2,0,0,1,28.263,24H3.737a2,2,0,0,1-1.664-3.109Z" transform="translate(24) rotate(90)" fill="#fff"/>
            </svg>
        )
    }
}

export class VideoUploadIcon extends React.Component {

    render() {
        return(
            <svg width="24" height="24" viewBox="0 0 24 24">
                <g transform="translate(2 2)">
                    <path fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.5" d="M10,0A10,10,0,1,1,0,10,10,10,0,0,1,10,0Z"/>
                    <path fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.5"
                          d="M5.16,3.969A14.915,14.915,0,0,1,1.432,6.512a3.983,3.983,0,0,1-.664.214.5.5,0,0,1-.459-.27,5.24,5.24,0,0,1-.166-.689A16.6,16.6,0,0,1,0,3.364,15.624,15.624,0,0,1,.158.879C.186.723.268.364.292.306A.5.5,0,0,1,.518.06.494.494,0,0,1,.768,0a3.49,3.49,0,0,1,.575.178A14.782,14.782,0,0,1,5.15,2.746c.068.073.255.269.286.309a.5.5,0,0,1,.011.6C5.414,3.7,5.223,3.9,5.16,3.969Z"
                          transform="translate(7.89 6.498)"/>
                </g>
            </svg>
        )
    }
}

export class PhotoUploadIcon extends React.Component {

    render() {
        return(
            <svg width="24" height="24" viewBox="0 0 24 24">
                <rect width="24" height="24" fill="none"/>
                <g transform="translate(2 3)">
                    <path d="M14.888,3.36h0a.962.962,0,0,1-.87-.551C13.731,2.2,13.366,1.424,13.15,1A1.675,1.675,0,0,0,11.6,0c-.012,0-4.684,0-4.7,0A1.676,1.676,0,0,0,5.35,1c-.215.423-.58,1.2-.867,1.808a.963.963,0,0,1-.87.551h0A3.612,3.612,0,0,0,0,6.972v5.936A3.613,3.613,0,0,0,3.613,16.52H14.888A3.612,3.612,0,0,0,18.5,12.908V6.972A3.612,3.612,0,0,0,14.888,3.36Z"
                          transform="translate(0.75 0.75)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.5"/>
                    <path d="M0,3.182A3.179,3.179,0,1,0,3.182,0,3.19,3.19,0,0,0,0,3.182Z" transform="translate(6.821 7.152)"
                          fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.5"/>
                    <path d="M.9,2a1.085,1.085,0,0,1-.3-.078A1.008,1.008,0,0,1,.282,1.7,1.021,1.021,0,0,1,0,1,.971.971,0,0,1,.082.607,1.018,1.018,0,0,1,.318.265,1.144,1.144,0,0,1,.622.07,1.023,1.023,0,0,1,1.7.285a.962.962,0,0,1,.19.261L1.917.6A.974.974,0,0,1,2,1a1.016,1.016,0,0,1-.292.705A1,1,0,0,1,1.1,2L1,2Z"
                          transform="translate(14 6)"/>
                </g>
            </svg>

        )
    }
}

export class CheckboxTick extends React.Component {

    render() {
        return(
            <svg width="11.241" height="7.557" viewBox="0 0 11.241 7.557">
                <path d="M-1,1.373,2.374,4.746,8.12-1" transform="translate(2.061 2.061)"
                      fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10"
                      strokeWidth="1.5"/>
            </svg>


        )
    }
}

export class CloseCross extends React.Component {

    render() {
        return(
            <svg width="18.121" height="18.121" viewBox="0 0 18.121 18.121">
                <g transform="translate(-934.939 -70.939)">
                    <line fill="none" stroke="#000" strokeLinecap="round" strokeWidth="1.5" x2="16" y2="16" transform="translate(936 72)"/>
                    <line fill="none" stroke="#000" strokeLinecap="round" strokeWidth="1.5" x1="16" y2="16" transform="translate(936 72)"/>
                </g>
            </svg>

        )
    }
}

export class ArrowDown extends React.Component {

    render() {
        return(
            <svg width="24" height="24" viewBox="0 0 24 24">
                <g transform="translate(5 8.5)">
                    <path fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.5" d="M14,0,7,7,0,0"/>
                </g>
            </svg>
        )
    }
}