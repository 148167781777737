import React from 'react';
import { Link } from 'react-router-dom';

import './index.css';

import faq from './faq.svg';
import treed from './3d.svg';
import manual from './video-manual.svg';
import sales from './sales.svg';
import {reachGoalLink} from "../../../../utils/utils";

class LSPreFooter extends React.Component {
    constructor (props) {
        super();

        this.state = {
        };
    }

    componentDidMount = () => {
    };

    componentWillUnmount = () => {
    };

    render() {
        return (
            <div className="ls_pre_footer_wrapper">
                <div className="l_content">
                    <ul className="items">
                        <li>
                            <img src={faq} alt={''} />
                            <h2>Остались вопросы?</h2>
                            <p><Link to={'/about/school-faq'} data-tag="80212912" data-goal="faq_click" onClick={reachGoalLink}>Вопросы и ответы</Link></p>
                        </li>
                        <li>
                            <img src={treed} alt={''} />
                            <h2>3D Кампус</h2>
                            <p>скоро</p>
                        </li>
                        <li>
                            <img src={manual} alt={''} />
                            <h2>Видео-инструкция</h2>
                            <p>скоро</p>
                        </li>
                        <li>
                            <img src={sales} alt={''} />
                            <h2>Нужна помощь?</h2>
                            <p><Link to={'/about/help'} data-tag="80212912" data-goal="help_click" onClick={reachGoalLink}>Свяжитесь с нами!</Link></p>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default LSPreFooter;
