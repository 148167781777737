import React from 'react';

class ParamCourseFor extends React.Component {
    constructor (props) {
        super();

        this.state = {
        };
    }

    render() {
        const t = this;
        const p = t.props;
        const data = p.data;

        return (
            <div className="params_section">
                <h3>Курс для</h3>

                <div className="cl_param_course_for">
                    <p>{data.meta.course_for}</p>
                </div>
            </div>
        );
    }
}

export default ParamCourseFor;
