import React from 'react';

import './support.css';
import {EmailTest} from "../utils/utils";

class GiftModal extends React.Component {
    constructor (props) {
        super();

        this.state = {
            email: '',
            valid: false
        }
    }

    handleSubmit = () => {
        const t = this;
        const s = t.state;

        t.props.onSuccess(s.email);
    };

    handleTextInput = (e) => {
        const obj = {};
        obj[e.target.name] = e.target.value;
        this.setState(obj, this.validate);

    };

    validate = () => {
        const t = this;
        const s = t.state;

        if (EmailTest(s.email)) {
            t.setState({valid: true})
        }
        else {
            t.setState({valid: false})
        }
    };

    render() {
        const t = this;
        const s = t.state;

        return (
            <div className="feedback_form_modal">
                <div className="ffm_inner">

                    <div className="default_input_wrapper">
                        <input onChange={t.handleTextInput} type="text" name="email" placeholder=" " />
                        <label>e-mail</label>
                    </div>
                    <button disabled={!s.valid} onClick={this.handleSubmit}>Подарить!</button>
                </div>
            </div>
        );
    }
}

export default GiftModal;
