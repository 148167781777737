import React from 'react';
import { Link } from 'react-router-dom';

import './index.css';

import bubbleRight from './bubble_right.svg';
import bubbleLeft from './bubble_left.svg';

class LPSpecial extends React.Component {
    constructor (props) {
        super();

        this.state = {
        };
    }

    componentDidMount = () => {
    };

    componentWillUnmount = () => {
    };

    render() {
        return (
            <div className="lp_special_wrapper">
                <div className="lp_bg"></div>
                <div className="l_content">
                    <h2>Специальное предложение</h2>
                    <p className="special_description">Для поддержки бизнеса и предпринимателей мы разработали специальный тариф PromoHub</p>

                    <div className="plate">
                        <img className="bubble_left" src={bubbleLeft} alt={''} />
                        <img className="bubble_right" src={bubbleRight} alt={''} />

                        <div className="plan_name">promoHUB</div>
                        <div className="plan_old_price">499</div>
                        <div className="plan_price">1*</div>
                        <div className="plan_period">₽/мес.</div>
                        <ul className="plan_options">
                            <li><div className="tick"></div>20 ГБ** в облачном сервисе W-Hub.Cloud</li>
                            <li><div className="tick"></div>доступ к конструктору видеокурсов</li>
                            <li><div className="tick"></div>возможность размещать видеокурсы на маркетплейсе</li>
                            <li><div className="tick"></div>возможность размещать платные видеокурсы на маркетплейсе</li>
                            <li><div className="tick"></div>доступ к статистике продаж и просмотров</li>
                            <li><div className="tick"></div>доступ к расширенной аналитике</li>
                        </ul>

                        <Link to={'/signup/school'}><button>Начать работу</button></Link>
                    </div>

                    <div className="stars">
                        <p>* При подключение тарифа промо на 2 мес. 20 ГБ (оплата 1 руб.) — 2 месяца по цене 1 руб. после пробного периода или промо периода 499 руб. за месяц</p>
                        <p>** Объем 20 ГБ может быть увеличен в ходе рассмотрения заявки</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default LPSpecial;
