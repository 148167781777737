import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import './item.css'
import PatternPlug from "../../utils/pattern-plug";
import {Plural} from "../../utils/utils";

class BlogItem extends React.Component {
    constructor (props) {
        super()
    }

    render() {
        const t = this;
        const {post} = t.props;

        const {id, title, description, images_stack} = post;

        let img = '';
        let src = null;
        if (images_stack && images_stack.cover) {
            src = post.images_stack.cover.normal;
            img = <img src={src} alt="" />;
        }

        const plug = !src ? <PatternPlug title={title} /> : '';

        let author = '';
        if (post.author) {
            const pAuthor = post.author;

            if (pAuthor.name && pAuthor.slug) {
                author = <Link to={'/users/' + pAuthor.slug}>{pAuthor.name}</Link>
            }
        }

        const date = t.getPostDate(post);

        return (
            <div className="blog_feed_item">
                <Link className="bfi_cover" to={'/blog/' + id}>
                    {plug}
                    {img}
                </Link>

                <div className="bfi_info">
                    {date}
                    <div className="bfi_author">{author}</div>
                </div>

                <Link to={'/blog/' + id}>
                    <h3>{title}</h3>
                    <h4>{description}</h4>
                </Link>

                <div className="blog_buttons">

                </div>
            </div>

        );
    }

    getPostDate = (post) => {
        let dateOutput = ' ';

        if (post.date && post.date.length) {
            const postDate = moment(new Date(post.date));
            const now = moment();
            const days = now.diff(postDate, 'days');

            if (days > 7) {
                dateOutput = postDate.format('DD.MM.YYYY');
            }
            else if (days < 1) {
                const hours = now.diff(postDate, 'hours');
                const minutes = now.diff(postDate, 'minutes');

                const hoursOutput = hours + ' час' + Plural(hours, '', 'а', 'ов') + ' назад';
                const minutesOutput = minutes + ' минут' + Plural(minutes, 'у', 'ы', '') + ' назад';

                dateOutput = hours ? hoursOutput : minutesOutput;
            }
            else {
                dateOutput = days + ' ' + Plural(days, 'день', 'дня', 'дней') + ' назад';
            }

            dateOutput = postDate.format('DD.MM.YYYY');
        }

        return <span>{dateOutput}</span>;
    }
}

export default BlogItem;
