import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import './index.css';
import AuthorizedUser from "./authorized";

function UserHeader(props) {

    let location = useLocation();

    const authLink = location.pathname.match(/signin/g) ? '' : <Link onClick={props.closeFunc} to={'/signin'}>Вход</Link>;
    const regLink = location.pathname.match(/signup/g) ? '' : <Link onClick={props.closeFunc} to={'/signup'}>Регистрация</Link>;

    const authLinks = <div className="auth_links">
        {authLink}
        {regLink}
    </div>;

    const content = props.authorized ? <AuthorizedUser {...props} /> : authLinks;

    return (
        <div className="header_user_section">
            {content}
        </div>
    );
}

export default UserHeader;