import React from 'react';
import {addKeyIds, generateKeyId, removeKeyIds} from "./key-ids";
import Preloader from "../../../utils/preloader/index";
import DlItemVideo from "./dl-item-video";
import {removeParamFromArray} from "../../../utils/utils";

class SelfAdvertModal extends React.Component {
    constructor (props) {
        super();

        const meta = props.courseMeta;
        const data = meta['self_advert'] ? addKeyIds(meta['self_advert']) : [];

        this.state = {
            loading: false,
            maxItems: 6,
            data: data,
            valid: false
        };

        this.removeItem = this.removeItem.bind(this);
    }

    validate = (cb) => {
        const t = this;
        const {data} = t.state;

        let valid = !!data.length;

        data.forEach((el) => {
            if (!el.valid || !el.title.length) {
                valid = false;
            }
        });

        t.setState({
            valid: valid
        }, cb)
    };

    updateValid = (idx, valid) => {
        const t = this;
        const {data} = t.state;
        const item = data[idx];

        item.valid = valid;

        t.setState({data: data}, t.validate);
    };

    updateData = (idx, k, v) => {
        const t = this;
        const {data} = t.state;
        const item = data[idx];

        item[k] = v;

        t.setState({data: data}, t.validate);
    };

    handleSave = () => {
        const t = this;
        const p = t.props;

        const {data} = t.state;
        const noIds = removeKeyIds(data);
        const output = removeParamFromArray(noIds, 'valid');

        const d = {'self_advert': output};

        t.setState({loading: true});
        p.onSave(d, (r) => {
            t.setState({loading: false});
        });
    };

    dataMapper = (el, idx) => {
        const t = this;

        return <DlItemVideo
            key={el.key_id}
            dt={el.url}
            dtLength={100}
            dtPlaceholder="ссылка на ролик"
            dtName="url"
            dd={el.title}
            ddLength={75}
            ddPlaceholder="подпись (макс. 75 символов)"
            ddName="title"
            idx={idx}
            update={t.updateData}
            validate={t.updateValid}
            removeItem={t.removeItem.bind(t, el)}
            valid={el.valid || false}/>
    };


    removeItem(id){
        this.setState(prevState => ({
            data: prevState.data.filter(el => el !== id )
        }), this.validate);
    }

    addItem = () => {
        const {data, maxItems} = this.state;

        if (data.length < maxItems) {
            data.push({type: 'video', url: '', title: '', valid: false, key_id: generateKeyId()});
            this.setState({data: data}, this.validate);
        }
    };

    render() {
        const t = this;
        const {data, maxItems, loading, valid} = t.state;
        const p = t.props;

        const addButtonText = data.length ? 'добавить еще' : 'добавить';

        const preloader = loading ? <Preloader/> : '';

        return (
            <div className="course_edit_modal">
                <div className="cem_inner">
                    <div className="cem_text">
                        <h3>Блок "Реклама"</h3>
                        <p className="block_description">Вы можете добавить до {maxItems} ссылок на видеоролики, рассказывающие о вашем курсе.</p>
                    </div>
                    <div className="cem_content cem_dl">
                        {data.map(t.dataMapper)}
                        <button disabled={data.length >= maxItems} className="small light_border" onClick={t.addItem}>{addButtonText}</button>
                    </div>
                    <div className="cem_buttons">
                        <button className="small" onClick={p.onModalClose}>отмена</button>
                        <button disabled={!valid} className="small" onClick={t.handleSave}>сохранить</button>
                    </div>
                </div>

                {preloader}
            </div>
        );
    }
}

export default SelfAdvertModal;
