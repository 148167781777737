import React from 'react';
import { Link } from 'react-router-dom';

import './authorized.css';
import {html2plaintext} from "../utils/utils";

class AuthorizedSidebar extends React.Component {
    constructor (props) {
        super();
        this.state = {
            opened: false
        }
    }

    render() {
        const ud = this.props.userData;
        let outputName = ud.name.length ? ud.name : ud.email;
        if (ud.display_name && ud.display_name.length) {
            outputName = ud.display_name;
        }

        const hasElka = ud['elka_registered'];

        const elkaUrls = {
            social: '/dedmorozonline',
            commercial: '/greeting'
        };

        const elkaLink = hasElka ? <Link onClick={this.props.closeFunc} to={elkaUrls[hasElka]} className="red">Елка!</Link> : '';

        const role = ud.role;
        const account_type = role === 'school' ? 'школа' : 'студент';

        const purchasedLink = role !== 'school' && ud.purchased ? <Link onClick={this.props.closeFunc} to={'/user/purchased'}>Мое обучение<span>({ud.purchased.length})</span></Link> : '';
        const favouritesLink = role !== 'school' && ud.favourites ? <Link onClick={this.props.closeFunc} to={'/user/favourites'}>Избранное<span>({ud.favourites.length})</span></Link> : '';
        const profileLink = <Link onClick={this.props.closeFunc} to={'/user/profile'}>Профиль</Link>;

        const coursesLink = role === 'school' &&  ud.courses_created && ud.courses_created.publish ? <Link onClick={this.props.closeFunc} to={'/user/courses'}>курсы <span>({ud.courses_created.publish.length})</span></Link> : '';

        const exit = <Link onClick={this.props.closeFunc} to={'/logout'} className="ua_logout">Выход</Link>;

        return (
            <div className="sidebar_profile">
                <div className="sp_userdata">
                    <div className="sp_userpic"><span>{outputName[0]}</span></div>
                    <div className={'sp_username_text ' + role}>{html2plaintext(outputName)}</div>
                    <div className="sp_role">{account_type}</div>
                </div>

                <div className="sp_links">
                    {elkaLink}
                    {purchasedLink}
                    {favouritesLink}
                    {profileLink}

                    {coursesLink}
                    {exit}


                </div>

            </div>
        );
    }
}

export default AuthorizedSidebar;