import React from "react";
import './index.css';

class Preloader extends React.Component {
    constructor (props) {
        super();
    }

    render() {
        const t = this;
        const {className} = t.props;

        const cls = className ? className : '';

        return (
            <div className={'preloader_wrapper ' + cls}>
                <div className="logo_wrapper">
                    <div className="logo">
                        <span className="w">W</span><span className="hub">H</span>
                    </div>
                </div>
            </div>
        )
    }
}

export default Preloader;