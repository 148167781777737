import React from 'react';
import loadImage from 'blueimp-load-image'

class ImgUploadV2 extends React.Component {
    constructor(props) {
        super();

        this.input = React.createRef();
    }

    handleUpload = (e) => {
        const t = this;
        const {onChange, onFile} = t.props;
        const file = e.target.files[0];

        if (file) {
            if (onFile) {
                onFile(file);
            }

            loadImage(
                file, (canvas) => {
                    const base64 = canvas.toDataURL('image/jpeg');
                    onChange(base64);
                    t.input.current.value = null;
                },
                { maxWidth: 3000,
                    maxHeight: 3000,
                    canvas: true,
                    orientation: true}
            );
        }
    };

    render() {
        const t = this;
        const p = t.props;

        return <input ref={t.input} id={p.id} type="file" onChange={t.handleUpload} accept={p.accept} />;
    }
}

export default ImgUploadV2;