import React from 'react';
import './rating.css';
import {CourseRating} from "../../../icons";
import {Plural} from "../../../../../utils/utils";

class HeaderRating extends React.Component {
    constructor (props) {
        super();

        this.state = {
        };
    }

    render() {
        const t = this;
        const p = t.props;
        const data = p.data;
        const hasRating = data.average_rating && data.average_rating.score;

        if (hasRating) {
            const r = data.average_rating;

            return <div className="header_rating">
                <CourseRating rating={r.score}/>
                <span className="rating_count">{parseFloat(r.score).toFixed(1)}</span>
                <span className="votes_count">{r.votes} оцен{Plural(r.votes, 'ка', 'ки', 'ок')}</span>
            </div>;
        }

        return '';
    }
}

export default HeaderRating;
