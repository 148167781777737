import React from 'react';

class PatternPlug extends React.Component {
    render() {
        const bgStyle = {backgroundColor: '#434A5C'};

        return (
            <div className="img_plug" style={bgStyle}>&nbsp;</div>
        );
    }
}

export default PatternPlug;
