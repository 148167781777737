import React from 'react';

import './index.css';

class DocumentsListPage extends React.Component {
    constructor (props) {
        super();
        this.state = {
        }
    }

    componentDidMount() {

    };
    componentWillUnmount() {
    };

    render() {

        return (
            <div className="about_wrapper payments_page">
                <h1>документы</h1>

                <p><a href="/docs/privacy_policy.docx" rel="noopener noreferrer" target="_blank">Политика конфиденциальности</a></p>
                <p><a href="/docs/offer_student.docx" rel="noopener noreferrer" target="_blank">Договор-оферта для доступа к контенту</a></p>
                <p><a href="/docs/offer_school.docx" rel="noopener noreferrer" target="_blank">Лицензионный договор-оферта</a></p>

            </div>
        );
    }
}

export default DocumentsListPage;