import React from 'react';

import API from "../utils/api";

import notificationIcon from './ico/notification.svg';
import playIcon from './ico/play.svg';
import calendarIcon from './ico/calendar.svg';
import certIcon from './ico/cert.svg';
import eduIcon from './ico/edu.svg';
import exitIcon from './ico/exit.svg';
import expertsIcon from './ico/experts.svg';
import paymentIcon from './ico/payment.svg';
import readIcon from './ico/read.svg';
import profileIcon from './ico/profile.svg';

import supportIcon from './ico/support.svg';
import reviewIcon from './ico/review.svg';


import {Link, useParams} from 'react-router-dom';
import {logout} from "../utils/utils";


function StudentDashboardLinks(props) {
    const userData = props.userData;

    const [unreadNotifications, setUnreadNotifications] = React.useState(0);

    React.useEffect(() => {
      API.get('/whub/v3/notification/').then(result => {
        const { data } = result;
        setUnreadNotifications(data.unread);
      });
    }, [])

    let pageParams = useParams();

    const activeCls = (c) => {
        return c === pageParams.profilePage ? 'active' : ''
    };

    const activeClsEdu = () => {
      const tabs = ['purchased', 'archived', 'favourites', 'entries', 'finished', 'continue-education']
      return  tabs.includes(pageParams.profilePage) ? 'active' : '';
    }

    // const purchasedLink = userData.purchased ? <Link className={activeCls('purchased')} to={'/user/purchased'}>мои курсы ({userData.purchased.length - parseInt(userData.archived_courses.length)})</Link> : '';
    // const entriesLink = userData.course_entries ? <Link className={activeCls('entries')} to={'/user/entries'}>записи ({userData.course_entries.length})</Link> : '';
    // const favouritesLink = userData.favourites ? <Link className={activeCls('favourites')} to={'/user/favourites'}>избранное ({userData.favourites.length})</Link> : '';
    // const archivedLink = userData.archived_courses && userData.archived_courses.length ? <Link className={activeCls('archived')} to={'/user/archived'}>архив ({userData.archived_courses.length})</Link> : '';

    const notificationsLink = <Link onClick={props.hideMenu} className={"img_link " + activeCls('notifications')} to={'/user/notifications'}>
      <img alt="bell" src={notificationIcon} /> Уведомления
      { unreadNotifications > 0 ? <div className="notifications_count">{ unreadNotifications }</div> : "" }
      </Link>
    const continueLink = <Link onClick={props.hideMenu} className={"img_link " + activeCls('continue')} to={'/user/continue'}>
      <img alt="play" src={playIcon}/> Продолжить</Link>
    
    const calendarLink = <Link onClick={props.hideMenu} className={"img_link " + activeCls('calendar')} to={'/user/calendar'}>
      <img alt="calendar" src={calendarIcon}/>Календарь</Link>

    const myEducationLink = <Link onClick={props.hideMenu} className={"img_link " + activeClsEdu()} to={'/user/purchased'}>
      <img alt="edu" src={eduIcon}/>Мое обучение</Link>

    const readingLink = <Link onClick={props.hideMenu} className={"img_link " + activeCls('reading')} to={'/user/reading'}>
      <img alt="read" src={readIcon}/>Читаю</Link>

    const expertsLink = <Link onClick={props.hideMenu} className={"img_link " + activeCls('specialists')} to={'/user/experts'}>
      <img alt="experts" src={expertsIcon}/>Эксперты</Link>

    const certificatesLink = <Link onClick={props.hideMenu} className={"img_link " + activeCls('certificates')} to={'/user/certificates'}>
      <img alt="cert" src={certIcon}/>Сертификаты</Link>

    const profileLink = <Link onClick={props.hideMenu} className={"img_link " + activeCls('profile')} to={'/user/profile'}>
      <img alt="profile" src={profileIcon}/>Профиль</Link>

    const paymentsLink = <Link onClick={props.hideMenu} className={"img_link " + activeCls('payments')} to={'/user/payments'}>
      <img alt="payments" src={paymentIcon}/>Платежи</Link>

    const exitLink = <a href='/' onClick={logout} className={"img_link " + activeCls('exit')}>
      <img alt="exit" src={exitIcon}/>Выйти</a>

    const supportBtn = <div id="support-button" className="img_link">
      <img alt="support" src={supportIcon}/>Служба поддержки
    </div>
    const reviewBtn = <div className="img_link">
      <img alt="review" src={reviewIcon}/>Оставь свой отзыв
    </div>

    const logoStyle = userData.avatar ? 
      {
        backgroundImage: `url(${userData.avatar})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain'
      }
     : {}

    return (
        <div className="profile_nav_feed">
          <div className="profile_user_data">
            <div className="profile_user_data_avatar_outer">
              <div className="profile_user_data_avatar_inner" style={logoStyle}>
                {userData.avatar ? '' : Array.from(userData.slug)[0]}
              </div>
            </div>
            <div className="profile_user_data_name">
              {userData.first_name && userData.last_name? <span className="profile_user_data_name_main">{userData.first_name}</span> : ""}
              {userData.first_name && userData.last_name? <span className="profile_user_data_name_main">{userData.last_name}</span> : ""}
              <span className="profile_user_data_name_display">{userData.slug}</span>
              <span className="profile_user_data_name_email">{userData.email}</span>
            </div>
          </div>
            {/* {purchasedLink}
            {entriesLink}
            {favouritesLink}
            {archivedLink} */}

            {notificationsLink}
            {continueLink}
            {calendarLink}

            <div className="profile_user_data_divider"></div>

            {myEducationLink}
            {readingLink}
            {expertsLink}
            {certificatesLink}

            <div className="profile_user_data_divider"></div>

            {profileLink}
            {paymentsLink}
            {exitLink}

            <div className="profile_user_data_divider"></div>
            
            <div>
              <script data-b24-form="click/13/o8px73" data-skip-moving="true">{(function(w,d,u){var s=d.createElement('script');s.async=true;s.src=u+'?'+(Date.now()/180000|0);var h=d.getElementsByTagName('script')[0];h.parentNode.insertBefore(s,h);})(window,document,'https://cdn-ru.bitrix24.ru/b23230650/crm/form/loader_13.js')};</script>
              {supportBtn}
            </div>

            <div>
              <script data-b24-form="click/11/8637iq" data-skip-moving="true">{(function(w,d,u){var s=d.createElement('script');s.async=true;s.src=u+'?'+(Date.now()/180000|0);var h=d.getElementsByTagName('script')[0];h.parentNode.insertBefore(s,h);})(window,document,'https://cdn-ru.bitrix24.ru/b23230650/crm/form/loader_11.js')};</script>
              {reviewBtn}
            </div>
        </div>
    );
}

export default StudentDashboardLinks;