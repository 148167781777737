import React from 'react';

import './search-empty.css';
import icon from './images/empty-search.png';
import CoursesFeed from "../course-snippets/feed/v3";

class SearchEmpty extends React.Component {
    constructor (props) {

        super();
    }

    render() {
        return this.props.initialLoadDone ? <div className="search_empty">
            <img src={icon} alt="" />
            <p className="empty_text">Увы, по вашему запросу ничего не найдено.<br />
                Но, может быть, что-то из этого будет вам полезно:</p>

            <CoursesFeed {...this.props} courses={this.props.latestCourses} />
        </div> : '';
    }
}

export default SearchEmpty;
