import { Redirect } from 'react-router-dom';
import {useParams} from 'react-router-dom';
import React from "react";

function CourseRedirect(props) {
    let pageParams = useParams();

    return <Redirect to={'/course/' + pageParams.id} />;
}

export default CourseRedirect;