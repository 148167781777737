import React from 'react';

import {Link} from 'react-router-dom';

import './index.css';
import './footer-socials.css';
import {SocialIcon} from "react-social-icons";
import {TelegramIconSocialGrey, ZenIconGrey} from "../../utils/icons";

class Footer extends React.Component {
    constructor (props) {
        super();

        this.state = {
            footerShown: false
        };
    }

    render() {
        const t = this;
        const cls_footer_shown = t.state.footerShown ? 'footer_shown' : '';
        const socialIconsSize = { height: 32, width: 32 };
        return (
            <div className="page_footer">
                <div className="pf_inner">

                    <div className={'footer_links ' + cls_footer_shown}>
                        <div className="footer_hidden_block">
                            <p><span className="pseudo_link" onClick={this.props.modalSupport}>Обратная связь</span></p>
                            <p><span className="pseudo_link pl_dashed" onClick={() => t.setState({footerShown: true})}>показать все ссылки</span></p>
                        </div>
                        <div className="fl_block">
                            <h5>Студентам</h5>
                            <div className="footer-socials">
                                <SocialIcon bgColor="#fff" fgColor="#96A3AE" url="https://vk.com/Whubmarket" style={socialIconsSize} target="_blank" rel="noopener noreferrer" />
                                <SocialIcon bgColor="#fff" fgColor="#96A3AE" url="https://www.youtube.com/channel/UCVKyoLKoYrJmOSJ1UYx5JMg" style={socialIconsSize} target="_blank" rel="noopener noreferrer" />
                                <a href="https://t.me/whubru" target="_blank" rel="noopener noreferrer"><TelegramIconSocialGrey /></a>
                                <a className="zen_link" href="https://zen.yandex.ru/id/5f71bfd0f687cf28c2f1fe18" target="_blank" rel="noopener noreferrer"><ZenIconGrey /></a>
                            </div>
                            {/*<p><Link className="red" to="/collections/books">Подборки аудиокниг</Link></p>*/}
                            <p><Link to="/about/student-faq">Вопросы и ответы</Link></p>
                            <p><a href="/docs/whub-manual-student.pdf">Руководство для студента (.pdf)</a></p>
                        </div>
                        <div className="fl_block">
                            <h5>Школам</h5>
                            <div className="footer-socials">
                                <a href="https://t.me/whubmarket" target="_blank" rel="noopener noreferrer"><TelegramIconSocialGrey /></a>
                            </div>
                            <p><Link to="/landings/school">Все о школах</Link></p>
                            <p><Link to="/about/school-faq">Вопросы и ответы</Link></p>
                            <p><Link to="/about/services">Сервисы</Link></p>
                            <p><a href="/docs/whub-manual-school.pdf">Руководство для школы (.pdf)</a></p>
                        </div>
                        <div className="fl_block">
                            <h5>Общее</h5>
                            {/*<p><span>О проекте</span></p>*/}
                            {/*<p><span>Команда</span></p>*/}
                            <p className="tech_support_link"><span className="pseudo_link" onClick={this.props.modalSupport}>Обратная связь</span></p>
                            <p><Link to="/about/details">Реквизиты</Link></p>
                            <p><Link to="/about/docs">Документы</Link></p>

                        </div>
                    </div>
                    <div className="footer_bottom">
                        <p className="cpr">W-Hub © 2023</p>
                        <p className="tech_support_link"><span className="pseudo_link" onClick={this.props.modalSupport}>Техническая поддержка</span></p>
                    </div>

                </div>
            </div>
        );
    }
}

export default Footer;
