import React from 'react';

import './index.css';

class ContactDetails extends React.Component {
    constructor (props) {
        super();
        this.state = {
        }
    }

    componentDidMount() {

    };
    componentWillUnmount() {
    };

    render() {

        return (
            <div className="about_wrapper">
                <h1>реквизиты</h1>

                <p>
                    ООО "ЛВМ СОЛЮШЕН"<br />
                    “LVM SOLUTION” LLC<br />
                    ИНН  9718146563<br />
                    КПП  771801001<br />
                    ОГРН  1197746593637<br />
                    ОКПО    41712416<br />
                    РАСЧЕТНЫЙ СЧЕТ   40702810738000098857<br />
                    БАНК  ПАО Сбербанк<br />
                    БИК  044525225<br />
                    КОРР.СЧЕТ   30101810400000000225<br />
                    ТЕЛЕФОН:   8 (499) 110-88-40<br />
                    EMAIL:  info@w-hub.ru

                </p>

                <p>
                    <strong>Адрес:</strong> Попов проезд, 1-1, Москва, 107014, Россия
                </p>

            </div>
        );
    }
}

export default ContactDetails;