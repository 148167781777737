import React from 'react';
import './text-modal.css';
import PlayerV2 from "../utils/player-v2";

class VideoModal extends React.Component {
    constructor (props) {
        super();

        this.state = {
            valid: true
        }
    }

    render() {
        const t = this;
        const {valid} = t.state;
        const {url} = t.props;

        const canNotPlay = <div className="cannot_play">
            <h3>Ошибка воспроизведения видео!</h3>
            <p>Мы оповестим школу о проблеме с этим видео!</p>
        </div>;

        return (
            <div className="video_modal">
                {valid ? <PlayerV2 url={url} onCanNotPlay={() => t.setState({valid: false})} /> : canNotPlay}
            </div>
        );
    }
}

export default VideoModal;
