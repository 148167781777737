import React from 'react';

import CoursesFeed from "../../../../course-snippets/feed/v3";
import API from "../../../../utils/api";
import {serrializer} from "../../../../utils/utils";

import './course-related.css';
import Spinner from "../../../../utils/spinner";

class CourseRelated extends React.Component {
    constructor (props) {
        super();

        this.state = {
            ids: null,
            courses: null,
            loaded: false
        };
    }

    loadRelated = () => {
        const t = this;
        const p = t.props;

        if (p.ids && p.ids.length) {
            t.setState({loading: true});
            API.get('/whub/v3/course?' + serrializer({include: p.ids, per_page: 5}))
                .then(function (r) {

                    const data = r.data.results;

                    t.setState({courses: data, loading: false});
                })
                .catch(function (error) {
                    console.log(error);
                    t.setState({loading: false});
                });
        }

    };
    componentDidMount() {
        const t = this;
        const p = t.props;

        if (p.ids && p.ids.length) {
            this.setState({ids: p.ids}, t.loadRelated);
        }
    };

    componentDidUpdate() {
        const t = this;
        const p = t.props;
        const {ids} = t.state;

        if (p.ids && p.ids.length && ids !== p.ids) {
            this.setState({ids: p.ids}, t.loadRelated);
        }
    };

    render() {
        const {loading, courses} = this.state;

        return courses ? <div className="course_related">
            <h5>вас может заинтересовать:</h5>
            <CoursesFeed {...this.props} courses={courses} />
            <Spinner show={loading} />
        </div> : '';
    }
}

export default CourseRelated;
