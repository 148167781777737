import React from 'react';
import {Link} from 'react-router-dom';

import './index.css';

import './school-plans.css';
import LSFreeServices from "../landings/school/components/free-services";
import LEasyToUse from "../landings/common/easy-to-use";
import LPayments from "../landings/common/payments";
import LSPreFooter from "../landings/school/components/pre-footer";
import {YMInitializer} from "react-yandex-metrika";

class SchoolServices extends React.Component {
    constructor (props) {
        super();

    }

    componentDidMount() {
    };
    componentWillUnmount() {
    };

    render() {
        const env = process.env.REACT_APP_CUSTOM_NODE_ENV;
        const isProd = env !== 'dev' && env !== 'stage';
        const counter = isProd ? <YMInitializer accounts={[80213062]} options={{
            webvisor: true,
            clickmap:true,
            trackLinks:true,
            accurateTrackBounce:true}} version="2"/> : '';

        return (
            <div className="landing_wrapper school_landing_wrapper school_services">
                {counter}
                <LSFreeServices />
                <LEasyToUse />
                <LPayments />

                <div className="btn_holder">
                    <Link to="/signup/school"><button className="v2">Присоединиться!</button></Link>
                </div>

                <LSPreFooter />

            </div>
        );
    }
}

export default SchoolServices;