import React from 'react';

import './index.css';

import Data from './plates-data';

import vs from './vs.svg';
import {Link} from "react-router-dom";

class LSComparison extends React.Component {
    constructor (props) {
        super();

        this.state = {
            curTab: 'left',
            curPlate1: 'whub',
            curPlate2: 'other'
        };
    }

    componentDidMount = () => {
    };

    componentWillUnmount = () => {
    };

    render() {
        const t = this;
        const s = t.state;
        const keys = {
            left: 'whub_vs_productions',
            right: 'whub_vs_platforms'
        };
        const curPlate = keys[s.curTab];
        const d = Data[curPlate];
        const d1 = Data['whub_vs_productions'];
        const d2 = Data['whub_vs_platforms'];

        const feed = d.items.map((item, idx) => {
            return <div className="plates_row" key={'item' + idx}>
                <dl>
                    <dt>{item.dt}</dt>
                    <dd>{item.other}</dd>
                </dl>
                <dl className="whub">
                    <dt>{item.dt}</dt>
                    <dd>{item.whub}</dd>
                </dl>
            </div>;
        });

        const plate1 = d1.items.map((item, idx) => {
            return <div className="plates_row" key={'item' + idx}>
                <dl className="other">
                    <dt>{item.dt}</dt>
                    <dd>{item.other}</dd>
                </dl>
                <dl className="whub">
                    <dt>{item.dt}</dt>
                    <dd>{item.whub}</dd>
                </dl>
            </div>;
        });

        const plate2 = d2.items.map((item, idx) => {
            return <div className="plates_row" key={'item' + idx}>
                <dl className="other">
                    <dt>{item.dt}</dt>
                    <dd>{item.other}</dd>
                </dl>
                <dl className="whub">
                    <dt>{item.dt}</dt>
                    <dd>{item.whub}</dd>
                </dl>
            </div>;
        });

        return (
            <div className="ls_comparison_wrapper">
                <div className="l_centrer">
                    <div className="switcher">
                        <div
                            className={s.curTab === 'left' ? 'switch active' : 'switch'}
                            onClick={() => t.setState({curTab: 'left'})}><nobr>W-HUB</nobr> vs Продюсерские центры</div>
                        <div
                            className={s.curTab === 'right' ? 'switch active' : 'switch'}
                            onClick={() => t.setState({curTab: 'right'})}><nobr>W-HUB</nobr> vs Платформы для создания онлайн школ</div>
                    </div>

                    <div className="plates">
                        <div className="plate_bg"></div>
                        <div className="plates_vs"><img src={vs} alt={''} /></div>
                        <div className="plates_inner">
                            <div className="plates_header">
                                <div className="plates_row">
                                    <h2>{d.header_other}</h2>
                                    <h2 className="whub">{d.header_whub}</h2>
                                </div>
                                {feed}
                            </div>
                        </div>
                    </div>
                </div>


                <div className="ls_comparison_block">
                    <div className="l_content">
                        <h2><nobr>W-HUB</nobr> vs Продюсерские центры</h2>
                        <div className={'plate ' + s.curPlate1}>
                            <div className="plate_switcher">
                                <div
                                    className={s.curPlate1 === 'other' ? 'switch active' : 'switch'}
                                    onClick={() => t.setState({curPlate1: 'other'})}>П.Ц.</div>
                                <div
                                    className={s.curPlate1 === 'whub' ? 'switch active' : 'switch'}
                                    onClick={() => t.setState({curPlate1: 'whub'})}><nobr>W-HUB</nobr></div>
                            </div>
                            {plate1}
                        </div>
                    </div>
                </div>
                <div className="ls_comparison_block">
                    <div className="l_content">
                        <h2><nobr>W-HUB</nobr> vs Платформы для создания онлайн школ</h2>
                        <div className={'plate ' + s.curPlate2}>
                            <div className="plate_switcher">
                                <div
                                    className={s.curPlate2 === 'other' ? 'switch active' : 'switch'}
                                    onClick={() => t.setState({curPlate2: 'other'})}>Платформа</div>
                                <div
                                    className={s.curPlate2 === 'whub' ? 'switch active' : 'switch'}
                                    onClick={() => t.setState({curPlate2: 'whub'})}><nobr>W-HUB</nobr></div>
                            </div>
                            {plate2}
                        </div>
                    </div>
                </div>

                <div className="button_holder">
                    <Link to={'/signup/school'}><button>Присоединиться</button></Link>
                </div>

            </div>
        );
    }
}

export default LSComparison;
