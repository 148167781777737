import React from 'react';
import {Flake3, Flake4, Flake5, Flake6, Flake7, Flake8, Flake9} from "./flakes";

import './background.css';

class Background extends React.Component {

    render() {
        return(
            <div className="header_background_wrapper">
                <div className="hb_stroke"> </div>
                <div className="flake gradient_flake"> </div>
                <Flake3 />
                <Flake4 />
                <Flake5 />
                <Flake6 />
                <Flake7 />
                <Flake8 />
                <Flake9 />
            </div>
        )
    }
}

export default Background;