import React from 'react';
import './index.css';
import {SkillsListTick} from "../../icons";
import {Plural} from "../../../../utils/utils";

class FutureSkills extends React.Component {
    constructor (props) {
        super();

        this.state = {
        };
    }

    renderTitle = (title) => {

        const splitTitle = title.split(' ');
        const range = splitTitle[0];
        const rangeUnit = splitTitle[1];

        const rangeUnitsList = [
            {label: 'час' + Plural(range, '', 'а', 'ов'), value: 'hours'},
            {label: Plural(range, 'день', 'дня', 'дней'), value: 'days'},
            {label: 'недел' + Plural(range, 'я', 'и', 'ь'), value: 'weeks'},
            {label: 'месяц' + Plural(range, '', 'а', 'ев'), value: 'months'}
        ];

        let rangeUnitSelected = 'день';

        for (let i=0;i<rangeUnitsList.length;i++) {
            if (rangeUnitsList[i].value === rangeUnit) {
                rangeUnitSelected = rangeUnitsList[i].label;
            }
        }

        return 'За ' + range + ' ' + rangeUnitSelected + ' вы научитесь:';
    };

    render() {
        const t = this;
        const p = t.props;
        const data = p.data.meta.future_skills;
        const list = data.items.map((item, idx) => {
            return <li key={idx}><SkillsListTick />{item}</li>
        });

        return (
            <div className="cl_future_skills">
                <div className="cl_centrer">
                    <h2>{t.renderTitle(data.title)}</h2>
                    <ul>{list}</ul>
                </div>
            </div>
        );
    }
}

export default FutureSkills;
