import React from 'react';
import SchoolProfileForm from "./profile-forms/school-profile";
import StudentProfileForm from './profile-forms/student-profile';

class Profile extends React.Component {
    constructor (props) {
        super();

        this.state = {
            input: null
        }
    }

    render() {
        const t = this;
        const p = t.props;
        const ud = p.userData;

        const profileFrom = ud.role === 'school' ? <SchoolProfileForm {...p} /> : < StudentProfileForm {...p} />;


        return (
            <div className="user_profile">


                {profileFrom}
            </div>
        );
    }
}

export default Profile;