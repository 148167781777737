import React from 'react';

import { Redirect } from 'react-router-dom';

import API from '../utils/api';
import API2 from './api-simple';

import './index.css';
import ReactPlayer from "react-player";

class Sandbox extends React.Component {
    constructor (props) {
        super();

        this.state = {
            invite_code: '',
            photo_base64: '',
            letter_base64: '',
            video_id: '',
            pdf: '',
            pdfCheck: false
        };
    }

    componentDidMount = () => {

    };

    xhrPost = (url, data, cb) => {
        var xhr = new XMLHttpRequest();
        xhr.onreadystatechange = function() {
            if (this.readyState === 4) {
                if (this.status >= 200 && this.status < 300) {
                    var r = {};
                    if(xhr.responseText.length){
                        r = JSON.parse(xhr.responseText);
                    }

                    cb(r);
                }
                else {
                    cb({'error': true, 'error_code': this.status})

                }
            }
        };

        xhr.open('POST', url, true);
        xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8");
        xhr.setRequestHeader("accept", "application/json, text/javascript, */*; q=0.01");
        xhr.send(data);
    };

    // ids
    // QwpMRiWkPfU - photo (portrait) + letter (portrait)
    // 540: https://socialmsk20.videodedmoroz.ru/download?f=low&v=fcCPYJn5BJ2&_s=b6c3eb0bf55cbd0b78c10589e78c356c
    // 1080: https://socialmsk20.videodedmoroz.ru/download?f=pc&t=%D0%9F%D0%BE%D0%B7%D0%B4%D1%80%D0%B0%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D0%B5+%D0%B4%D0%BB%D1%8F+%D0%90%D0%B0%D1%80%D0%BE%D0%BD%D0%B0&v=fcCPYJn5BJ2&_s=aabd13a9ba02e30fdf6b7cd511bb8b41
    //
    // gdDfDSsTjfK - photo (landscape) + letter (landscape)
    // 540: https://socialmsk20.videodedmoroz.ru/download?f=low&v=WmEKL0QFWwb&_s=2c72fb1ddbbfc2e9325e5a48bb010c70
    // 1080: https://socialmsk20.videodedmoroz.ru/download?f=pc&t=%D0%9F%D0%BE%D0%B7%D0%B4%D1%80%D0%B0%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D0%B5+%D0%B4%D0%BB%D1%8F+%D0%90%D0%B0%D1%80%D0%BE%D0%BD%D0%B0&v=WmEKL0QFWwb&_s=cbbe544986cfae5c401e9069da956a1b
    //
    // Z5B62jFYgiK - photo (portrait) + letter (landscape)
    // 540: https://socialmsk20.videodedmoroz.ru/download?f=low&v=StoFRHb4ztG&_s=78322abd8a0708cd9392df1b2279d960
    // 1080: https://socialmsk20.videodedmoroz.ru/download?f=pc&t=%D0%9F%D0%BE%D0%B7%D0%B4%D1%80%D0%B0%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D0%B5+%D0%B4%D0%BB%D1%8F+%D0%90%D0%B0%D1%80%D0%BE%D0%BD%D0%B0&v=StoFRHb4ztG&_s=70304df91c895144fbd3dffe26082e69
    //
    // cxCh7vnyQmf - photo (landscape) + letter (portrait)
    // 540: https://socialmsk20.videodedmoroz.ru/download?f=low&v=PkkYx1QdZwW&_s=962a422e905d8f05a288f7cba05814e1
    // 1080: https://socialmsk20.videodedmoroz.ru/download?f=pc&t=%D0%9F%D0%BE%D0%B7%D0%B4%D1%80%D0%B0%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D0%B5+%D0%B4%D0%BB%D1%8F+%D0%90%D0%B0%D1%80%D0%BE%D0%BD%D0%B0&v=PkkYx1QdZwW&_s=90e022e745e6c602fc10a3e021c7b261
    //
    // SgwldigNB5s - photo (landscape)
    // 540: https://socialmsk20.videodedmoroz.ru/download?f=low&v=bGksUDYjlUC&_s=69ada83824c56221fec128278ef95711
    // 1080: https://socialmsk20.videodedmoroz.ru/download?f=pc&t=%D0%9F%D0%BE%D0%B7%D0%B4%D1%80%D0%B0%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D0%B5+%D0%B4%D0%BB%D1%8F+%D0%90%D0%B0%D1%80%D0%BE%D0%BD%D0%B0&v=bGksUDYjlUC&_s=b154bafe3ed23400c3050546c3b3bb65

    handleIdChange = (e) => {
        this.setState({video_id: e.target.value});
    };

    handlePdfChange = (e) => {
        this.setState({pdf: e.target.value, pdfCheck: false});
    };

    checkReady = () => {
        const t = this;
        const s = t.state;
        const formData = new FormData();
        formData.append('id', s.video_id);
        formData.append('token', 'vb4d1b8e7510a188');

        console.log(s.video_id)

        API2.post('/whub/v1/dedmoroz/status', formData)
            .then(function (r) {
                if (r.data.order) {
                    const o = r.data.order;
                    console.log(o.url_540p_mp4)
                    console.log(o.url_1080p_mp4)
                }
            })
            .catch(function (error) {
                console.log(error)
            });
    };

    handlePayment = (id) => {
        const formData = new FormData();
        formData.append('id', id);
        formData.append('token', 'vb4d1b8e7510a188');

        console.log(id)

        API2.post('/whub/v1/dedmoroz/paid', formData)
            .then(function (response) {
                console.log(response)
            })
            .catch(function (error) {
                console.log(error)
            });
    };

    formDataTest = () => {
        const t = this;
        const s = t.state;

        let d = {
            person: {
                name: 'x1',
                gender: 'boy',
                nameId: 1407
            },
            scene: 2,
            photos: [
                {
                    base64: s.photo_base64,
                    top: 0,
                    left: 0,
                    zoom: 1,
                    orient: 'landscape',
                    comment: 1
                }
            ],
            additional: {
                age: 9
            },
            behavior: {
                achievements: 15,
                requests: 7,
                behavior: 'good'
            }
        };

        if (s.letter_base64.length) {
            d.letter = {
                base64: s.letter_base64,
                top: 0,
                left: 0,
                zoom: 1,
                orient: 'portrait'
            }
        }

        const formData = this.jsonToFormData(d);
        formData.append('photos[]', '');
        formData.append('photos[]', '');
        formData.append('photos[]', '');

        //const url = 'https://socialmsk20.videodedmoroz.ru/forms/vdm2017?mp=W10=';
        const url = '/whub/v1/dedmoroz';
        //
        // const headers = {
        //     'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        // };

        // t.xhrPost(url, formData, (r) => {
        //     console.log(r)
        // });

        API2.post(url, formData)
            .then(function (r) {
                if (r.data.url) {
                    t.handlePayment(r.data.url);
                }
                else {
                    console.log(r)
                }
            })
            .catch(function (error) {
                console.log(error)
            });
    };

    jsonToFormData = (data) => {
        const formData = new FormData();

        this.buildFormData(formData, data);

        return formData;
    };

    buildFormData = (formData, data, parentKey) => {
        if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
            Object.keys(data).forEach(key => {
                this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
            });
        } else {
            const value = data == null ? '' : data;

            formData.append(parentKey, value);
        }
    };

    toBase64 = (file, cb) => new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    });

    handleUpload = (e) => {
        const t = this;
        const name = e.target.name;
        const file = e.target.files[0];

        if (file) {
            t.toBase64(file, (base64) => {
                const obj = {};
                obj[name] = base64;
                console.log(obj)
                t.setState(obj)
            });
        }
    };

    resetSchoolData = () => {

        API.post('/whub/v3/utils', {"action": "reset_school_data_completed", "user_id": 85})
            .then(function (response) {
                console.log(response)
            })
            .catch(function (error) {
                console.log(error)
            });
    };

    checkPDF = (n) => {
        console.log(n)
    };


    render() {
        const t = this;
        const s = t.state;
        const redirect = !t.props.authorized || t.props.userData.role !== 'administrator' ? <Redirect to={{pathname: "/"}}/> : '';
        // const redirect = '';

        const {pdf} = t.state;


        return (
            <div className="sandbox">
                <div>
                    <input placeholder="ссылка на PDF" type="text" value={pdf} onChange={t.handlePdfChange} />
                    <button className="small" onClick={() => t.setState({pdfCheck: true})}>проверить</button>
                </div>
                <br />
                <br />
                <br />
                <br />
                <ReactPlayer
                    url="https://socialmsk20.videodedmoroz.ru/download?f=pc&t=%D0%9F%D0%BE%D0%B7%D0%B4%D1%80%D0%B0%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D0%B5+%D0%B4%D0%BB%D1%8F+%D0%90%D0%B0%D1%80%D0%BE%D0%BD%D0%B0&v=bGksUDYjlUC&_s=b154bafe3ed23400c3050546c3b3bb65"
                    controls
                    light
                    playing
                    className="react-player"
                    width="100%"
                    height="100%"/>
                <div>
                    <input type="text" value={s.video_id} onChange={t.handleIdChange} />
                    <button disabled={!s.video_id.length} className="small" onClick={() => t.handlePayment(s.video_id)}>оплатить</button>
                    <button disabled={!s.video_id.length} className="small" onClick={t.checkReady}>проверить готовность</button>
                </div>
                <div>
                    <label>photo</label>
                    <input type="file" name="photo_base64" onChange={t.handleUpload} />
                    <br />
                    <br />
                    <label>letter</label>
                    <input type="file" name="letter_base64" onChange={t.handleUpload} />
                    <br />
                    <br />
                    <button onClick={t.formDataTest}>тест отправки формы</button>
                </div>
                <br />
                <button onClick={t.resetSchoolData}>сбросить данные школы 85</button>
                {redirect}
            </div>
        );
    }
}

export default Sandbox;
