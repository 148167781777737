import React, { useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import getCroppedImg from './crop-image';

import Radio from "../radio";
import Slider from 'rc-slider';

import './index.css';
import './slider.css';

const CropResize = (props) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, []);

    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(
                props.img,
                croppedAreaPixels,
                props.cropSize,
                props.toCoords ? props.toCoords : false
            );

            props.onChange(props.outputName, croppedImage);
            props.onChange(props.inputName, null);
        } catch (e) {
            console.error(e)
        }
    }, [props, croppedAreaPixels]);

    const orientation = props.hideOrientation ? '' : <div className="orientation_wrapper">
        <Radio {...props} name="orientation" value="portrait" label="Вертикально" />
        <Radio {...props} name="orientation" value="landscape" label="Горизонтально" />
    </div>;

    const shape = props.isRound ? 'round' : 'rect';

    return (
        <div className="crop_resize_wrapper">

            <div className="cropper_wrapper">
                <Cropper
                    image={props.img}
                    crop={crop}
                    zoom={zoom}
                    minZoom={1}
                    maxZoom={3}
                    aspect={props.cropSize.width / props.cropSize.height}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                    cropShape={shape}
                />
            </div>
            <div className="crop_tools">
                {orientation}
                <div className="zoom_slider">
                    <Slider
                        value={zoom}
                        onChange={(zoom) => setZoom(zoom)}
                        min={1}
                        max={3}
                        step={0.01}/>
                </div>
                <div className="crop_buttons">
                    <button className="ok_button" onClick={showCroppedImage}>Сохранить</button>
                    <button className="cancel_button" onClick={() => props.onChange(props.inputName, null)}>Отмена</button>
                </div>
            </div>
        </div>
    )
};

export default CropResize;
