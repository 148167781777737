import React from 'react';
import API from '../utils/api';
import './index.css';

import EditorJS from '@editorjs/editorjs';
import ImageTool from '@editorjs/image';
import Header from '@editorjs/header';
import List from '@editorjs/list';

class StudentFaq extends React.Component {
  editor = new EditorJS({
    tools: {
      header: Header,
      image: ImageTool,
      list: List,
    },
    readOnly: true,
  });

  constructor (props) {
      super();
      this.state = {
      }
  }

  toggleItem = (el) => {

  };

  componentDidMount() {
    API.get('whub/v3/publish/faq/student')
      .then((data) => {
        this.editor.render(data.data);
      });
  };

  componentWillUnmount() {

  };

  render() {
      return (
        <div id="editorjs"></div>
      )
  }
}

export default StudentFaq;