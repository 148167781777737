import React from 'react';

import FSFormIP from "./forms/ip";
import FSFormOOO from "./forms/ooo";
import FSFormSZ from "./forms/sz";

function FinishSignupForm(props) {

    const forms = {
        ip: <FSFormIP {...props} />,
        ooo: <FSFormOOO {...props} />,
        sz: <FSFormSZ {...props} />
    };

    return forms[props.companyType];
}

export default FinishSignupForm;