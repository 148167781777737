import React from 'react';

import './search-suggests.css';

class SearchSuggests extends React.Component {
    constructor (props) {

        super();

        this.state = {
            selected: null
        };
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    handleKeyPress = (e) => {
        const code = e.keyCode;
        const t = this;

        if (code === 13) {
            t.handleSuggestSubmit();
        }
        if (code === 38) {
            t.handleArrow(-1);
        }
        if (code === 40) {
            t.handleArrow(1);
        }
    };

    handleSuggestSubmit = () => {
        const t = this;
        const s = t.state;
        const p = t.props;

        if (s.selected) {
            p.suggestClick(p.suggests[s.selected]);
        }
    };

    handleArrow = (dir) => {
        const t = this;
        const s = t.state;
        const p = t.props;
        const l = p.suggests.length;
        let next = 0;

        if (!s.selected && s.selected !== 0) {
            if (dir === -1) {
                next = l-1;
            }
        }
        else {
            next = s.selected + dir;

            if (next >= l) {
                next = 0;
            }
            if (next < 0) {
                next = l-1;
            }
        }

        t.setSelected(next);
    };

    setSelected = (idx) => {
        const t = this;
        const p = t.props;
        t.setState({selected: idx});
        p.onSelect(p.suggests[idx]);
    };

    componentDidMount () {
        window.addEventListener('keyup', this.handleKeyPress)
    }
    componentWillUnmount() {
        window.removeEventListener("keyup", this.handleKeyPress);
    }

    render() {
        const t = this;
        const s = t.state;
        const p = t.props;
        const list = p.suggests.map((suggest, idx) => {
            const cls = s.selected === idx ? 'selected' : '';
            return <li
                className={cls}
                onMouseEnter={() => t.setSelected(idx)}
                key={idx}><span onClick={() => t.handleSuggestSubmit()}>{suggest}</span></li>;
        });

        return <ul className="search_suggests_list">
            {list}
        </ul>;
    }
}

export default SearchSuggests;
