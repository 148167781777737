import React from 'react';

class ListItem extends React.Component {
    constructor (props) {
        super();
    }

    handleChange = (e) => {
        const t = this;
        const p = t.props;
        const k = e.target.name;
        const v = e.target.value;


        p.update(p.idx, k, v);
    };

    render() {
        const t = this;
        const p = t.props;

        return (
            <li>
                <div className="item_inner">
                    <textarea maxLength={p.maxLength} placeholder={p.placeholder} onChange={t.handleChange} value={p.value} name={p.name} />
                    <div data-idx={p.idx} onClick={p.removeItem} className="item_delete">X</div>
                </div>
            </li>
        );
    }
}

export default ListItem;
