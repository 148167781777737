import React from 'react';

import './index.css';

import {cloudUpload} from "../utils";

class UPWItem extends React.Component {
    constructor (props) {
        super();

        this.state = {
            loading: false
        }
    }



    componentDidMount = () => {
        const p = this.props;
        if (!this.state.loading && p.file) {
            this.setState({loading: true});
            cloudUpload(p.file, this.handleProgress, this.handleFinish, this.handleCleanup);
        }
    };

    handleProgress = (progress) => {
        const p = this.props;
        p.updateUploadProgress(p.lessonId, progress);
    };

    handleFinish = (url) => {
        const p = this.props;
        p.afterUpload(url, p.uploadObj);
    };

    handleCleanup = () => {
        const p = this.props;
        p.uploadCleanup(p.uploadObj);
    };

    render() {
        const p = this.props;

        return (
            <li>{p.title}<span>{p.progress} %</span></li>
        );
    }
}

export default UPWItem;
