import React from "react";
import { Link, Redirect } from 'react-router-dom';

import './index.css';
import '../styles/auth-tabs.css';
import SignInForm from "./sign-in-form";

class SignIn extends React.Component {
    constructor (props) {
        super();

        this.state = {
            path: '/'
        }
    }

    componentDidMount() {
        document.body.classList.add('minimal');
    };
    componentWillUnmount() {
        document.body.classList.remove('minimal');
    };

    render() {
        const t = this;
        const s = t.state;
        const p = t.props;
        const redirect = p.authorized ? <Redirect to={{pathname: s.path}}/> : '';

        const tabs = !p.hideTabs ? <div className="auth_tabs">
            <span className="active">вход</span>
            <Link to={'/signup'}>регистрация</Link>
        </div> : <h4>вход</h4>;

        return (
            <div className="sign_in_wrapper">
                {tabs}
                <SignInForm {...this.props} updateRedirectPath={(path) => t.setState({path: path})} />
                {redirect}
            </div>
        )
    }
}

export default SignIn;