import React from 'react';
import ImgUploadV2 from "../../../utils/img-upload-v2";
import CropResize from "../../../utils/crop-resize";
import Modal from "../../../modal";
import API from "../../../utils/api";
import Spinner from "../../../utils/spinner";

import './index.css';

class UserpicUpload extends React.Component {
    constructor (props) {
        super();

        this.state = {
            loading: false,
            emitSrc: props.getSrc ? true : false,
            userpicInput: null,
            image_type: props.image_type ? props.image_type : "avatar",
            cropData: null,
            file: null,
            src: props.image_type !== 'author_avatar' && props.userData?.avatar ? props.userData.avatar : null
        }
    }

    componentDidMount() {
      if (this.props.src) {
        this.setState({src: this.props.src});
      }
    }

    upload = () => {
        const t = this;
        const {cropData, file, image_type} = t.state;
        const {reloadUserData} = t.props;
        const formData = new FormData();

        formData.append('file', file);
        formData.append('image_type', image_type);
        formData.append('crop', cropData);

        t.setState({loading: true});

        API.post('/whub/v3/media', formData)
            .then(function (r) {
                console.log(r)
                t.setState({loading: false, src: r.data.src});

                if (t.state.emitSrc) {
                  t.props.getSrc(r.data.src);
                } else {
                  reloadUserData();
                }
            })
            .catch(function (error) {
                t.setState({loading: false});
                console.log(error.response)
            });

    };

    handleFileLoad = (file) => {
        this.setState({file: file});
    };

    handleUserpicSelect = (base64) => {
        this.setState({userpicInput: base64});
    };

    handleCropChange = (name, value) => {
        const t = this;
        const obj = {};

        obj[name] = value;
        t.setState(obj, () => {
            if (name === 'cropData' && value) {
                t.upload();
            }
        });
    };

    hideModal = () => {
        this.setState({userpicInput: null});
    };

    render() {
        const t = this;
        const p = t.props;
        const ud = p.userData;
        const {loading, userpicInput, src} = t.state;

        const crop = <CropResize {...p} onChange={t.handleCropChange} inputName="userpicInput" outputName="cropData" cropSize={{width: 320, height: 320}} orientation={'portrait'} toCoords={true} isRound={true} hideOrientation={true} img={userpicInput} />;
        const modal = userpicInput ? <Modal modalContent={'custom'} customContent={crop} onModalClose={t.hideModal} /> : '';

        const letter = ud.display_name.length ? ud.display_name.split('')[0] : <span className="ul_empty">логотип школы</span>;
        const avatar = src ? <img className="avatar" src={src} alt="" /> : '';

        return (
            <div className="userpic_upload_wrapper">
                <div className="userpic_upload_inner">
                    <div className="userpic_letter">{letter}</div>
                    {avatar}
                    <ImgUploadV2 onChange={t.handleUserpicSelect} onFile={t.handleFileLoad} name="userpic" id="photoUpload" accept="image/*" />
                    <label htmlFor="photoUpload">загрузить</label>

                    {modal}

                    <Spinner show={loading} />
                </div>
                <button className="v2 small button_upload"><label htmlFor="photoUpload">Загрузить аватар</label></button>
            </div>
        );
    }
}

export default UserpicUpload;