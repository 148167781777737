import React from 'react';
import './index.css';
import {FoldSwitcherDots} from "../../icons";

class Description extends React.Component {
    constructor (props) {
        super();

        this.descriptionBlock = React.createRef();
        this.anchor = React.createRef();
        this.state = {
            canFold: true,
            isFolded: true
        };
    }

    componentDidMount() {
        this.checkCanFold();
    };

    checkCanFold = () => {
        const t = this;
        const h = t.descriptionBlock.current.clientHeight;
        if (h < 200) {
            t.setState({canFold: false});
        }
    };

    switchFold = () => {
        const t = this;
        const {isFolded} = t.state;

        t.setState({isFolded: !isFolded}, () => {
            if (!isFolded) {
                t.anchor.current.scrollIntoView();
            }
        })
    };

    render() {
        const t = this;
        const p = t.props;
        const data = p.data;

        const {canFold, isFolded} = t.state;

        const foldSwitcherText = isFolded ? 'Развернуть описание' : 'Свернуть описание';
        const foldSwitcher = canFold ? <div onClick={t.switchFold} className="fold_switcher">{foldSwitcherText}<FoldSwitcherDots /></div> : '';
        const cls_folded = canFold && isFolded ? 'folded' : '';

        return (
            <div className={'cl_description ' + cls_folded}>
                <div ref={t.anchor} className="cl_anchor"> </div>
                <div className="cl_centrer">
                    <h2>О курсе</h2>
                    <div ref={t.descriptionBlock} className="course_description" dangerouslySetInnerHTML={{ __html: data.description }}>
                    </div>

                    {foldSwitcher}
                </div>
            </div>
        );
    }
}

export default Description;
