import React from 'react';
import LHeader from "../common/header";

import '../common/index.css';
import LPWelcome from "./components/welcome";
import LPSplash from "./components/splash";
import LPOpportunities from "./components/opportunities";
import LPayments from "../common/payments";
import LAdvantages from "../common/advantages";
import LEasyToUse from "../common/easy-to-use";
import LPSpecial from "./components/special";
import LPForm from "./components/form";

class LandingPromo extends React.Component {
    constructor (props) {
        super();

        this.state = {
        };
    }

    componentDidMount = () => {
        document.body.classList.add('no_header');
    };

    componentWillUnmount = () => {
        document.body.classList.remove('no_header');
    };

    render() {
        return (
            <div className="landing_wrapper promo_landing_wrapper">
                <LHeader />
                <LPWelcome />
                <LPSplash />
                <LPOpportunities />
                <LPayments />
                <LAdvantages />
                <LEasyToUse className={'no_header'} />
                <LPSpecial />
                <LPForm {...this.props} />
            </div>
        );
    }
}

export default LandingPromo;
