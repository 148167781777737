import React from 'react';

import './index.css';

class BottomDisclaimer extends React.Component {
    constructor (props) {
        super();
    }

    render() {
        return (
            <div className="bottom_disclaimer">
                <div className="bd_inner">

                    <p>Платформа W-Hub использует cookies для максимальной персонализации и качества поставляемого контента, закрывая это уведомление и/или продолжая пользоваться сайтом, вы соглашаетесь со всем, что мы можем сделать относительно этой информации!</p>

                    <button className={'small'} onClick={this.props.onBottomDisclamerClose}>X</button>
                </div>
            </div>
        );
    }
}

export default BottomDisclaimer;
