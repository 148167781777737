import React from "react";

import {useParams, useHistory} from 'react-router-dom';

import {arr2obj, obj2arr, serrializer} from "../utils/utils";
import SearchEngine from "./search-engine";
import {TitleComponent} from "../utils/title";
import Breadcrumbs from "../utils/breadcrumbs";
import MetaTags from "react-meta-tags";
import CategoriesMeta from './categories-meta';
import {crumbBuilder} from "../utils/breadcrumbs/crumb-builder";

const CategoryPage = (props) => {
    const pageParams = useParams();
    const categoryName = pageParams.category_slug;
    const subcategoryName = pageParams.subcategory_slug;
    let history = useHistory();

    let pageTitle = 'Поиск';
    let pageDescription = props.metaDescription;
    let params = {};
    let breadcrumbs = '';

    const categoriesArr = obj2arr(props.categories);
    const categoriesObjWithSlug = arr2obj(categoriesArr, 'slug');


    let slug = categoryName;
    let obj = categoriesObjWithSlug;

    let isSubcategoryPage = false;
    if (subcategoryName && categoriesObjWithSlug[categoryName] && categoriesObjWithSlug[categoryName].children) {
        const subcategoriesArr = categoriesObjWithSlug[categoryName].children;
        slug = subcategoryName;
        obj = arr2obj(subcategoriesArr, 'slug');
        isSubcategoryPage = true;
    }


    if (obj[slug]) {
        const categoryNormalName = obj[slug].name;
        pageTitle = 'W-Hub: ' + categoryNormalName;
        const crumbs = crumbBuilder('category', {category: categoriesObjWithSlug[categoryName], pageParams: pageParams});
        breadcrumbs = <Breadcrumbs data={crumbs} />;

        params = {category: categoryName};

        if (isSubcategoryPage) {
            params.subcategory = slug;
        }

        if (CategoriesMeta[categoryName]) {
            pageTitle = CategoriesMeta[categoryName].title;
            pageDescription = CategoriesMeta[categoryName].description;
        }
    }

    const updateSearch = (newParams, cb) => {
        if (!newParams) {
            newParams = {};
        }
        const qStr = serrializer(newParams);
        history.push('/search?' + qStr);

        setTimeout(() => {

            if (cb) {
                cb();
            }
        });
    };

    return (
        <div>
            <MetaTags>
                <meta name="description" content={pageDescription} />
            </MetaTags>;
            <TitleComponent title={pageTitle}/>
            {breadcrumbs}
            <SearchEngine {...props} params={params} updateSearchFunc={updateSearch} />
        </div>
    );
};


export default CategoryPage;