import React from 'react';
import ProfileModal from './commonModal';
import API from '../../../../utils/api';
import './index.css';

import editIcon from '../icons/edit.svg';

class SnilsModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      snils: '',
    }
  }

  componentDidMount() {
    const { profile } = this.props.userData;
    const snils = profile.snils || '';
    this.setState({snils});
  }

  handleSnilsChange = (e) => {
    e.preventDefault();
    const { value } = e.target;
    let snilsField = value;
    if (value.length === 9 && value.length >= this.state.snils.length) {
      snilsField = value + '-';
    } else if (snilsField.length === 10 && snilsField.length < this.state.snils.length) {
      snilsField = snilsField.substring(0, snilsField.length - 1);
    } else if (snilsField.length === 10 && snilsField.indexOf('-') === -1) {
      snilsField = snilsField.substring(0, 9) + '-' + snilsField.substring(9, 10);
    }

    if (snilsField.match(/^[0-9]{0,9}$/gm) || snilsField.match(/^\d{9}[-]\d*$/gm)) {
      this.setState({snils: snilsField});
    }
  }

  saveSnils = () => {
    const data = {
      snils: this.state.snils
    }

    this.props.loading(true);

    API.post('/whub/v3/profile', data)
      .then(function (r) {

      })
      .catch(function (error) {
          console.log(error);
      })
      .finally(() => {
        this.props.loading(false);
        this.props.authFunc();
        this.props.close();
      });
  }

  render() {
    return (
      <div>
        <ProfileModal title="" width="370px" closeModal={this.props.close}>
          <div className="snils_modal">
            <div className="snils_modal_title">
              <span>СНИЛС</span>
            </div>

            <div>
              <div className="snils_modal_input">
                <input maxLength={12} value={this.state.snils} onChange={(e) => this.handleSnilsChange(e)}/>
                <div style={{backgroundImage: `url(${editIcon})`}} className="student_profile_form_input_edit"></div>
              </div>
            </div>

            <div onClick={this.saveSnils} className="snils_modal_save_btn">
              <span>Сохранить</span>
            </div>
          </div>

        </ProfileModal>
      </div>
    )
  }
}

export default SnilsModal;