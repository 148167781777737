import React from 'react';
import PlayerV2 from "../../utils/player-v2";
import Tracker from "../../utils/tracker";
import API from '../../utils/api';

import './video.css';
import PlayerWithAd from "../../utils/player-v2/player-with-ad";
import {checkOwner} from "../../utils/utils";

class LessonVideo extends React.Component {
    constructor (props) {
        super();

        this.state = {
            started: false,
            statusSended: false,
        }
    }
    
    finishLesson = () => {
      const isStudent = this.props.userData.role === 'student';

      if (!isStudent) {
        return
      }
      
      const id = this.props.lessonId;
      if (!this.state.statusSended) {
        this.setState({statusSended: true})
        API.put('/whub/v3/progress/' + id, {status: 'finished'}).then(result => {
          this.props.updateProgress(result.data);
        })
      }
    }

    handlePlayStarted = () => {
      this.setState({started: true});
      this.finishLesson();
    }

    render() {
        const t = this;
        const p = t.props;
        const {content, courseData, lessonId, authorized, progress, userData} = p;
        const {started} = t.state;

        const isCode = !content.url.match(/https:\/\//g) && !content.url.match(/http:\/\//g);

        const externalSrc = isCode ? 'https://youtu.be/' + content.url : content.url;
        const cloudSrc = content.embed_link ? content.embed_link : '';

        const src = content.is_cloud ? cloudSrc : externalSrc;

        const player_iframe = <iframe src={src}
                                      frameBorder="0" title="video_iframe"
                                      allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                                      allowFullScreen />;


        const hasDiscount = !!parseInt(courseData.discount_amount_percentage);
        const price = hasDiscount ? courseData.price : courseData.regular_price;
        const isFree = !price;

        const isOwner = checkOwner(courseData.id, userData);

        const player_v2 = isFree && !isOwner ? <PlayerWithAd
            courseId={courseData.id}
            lessonId={lessonId}
            url={src}
            authorized={authorized}
            progress={progress}
            onPlayStarted={() => t.handlePlayStarted()} /> : <PlayerV2
            courseId={courseData.id}
            lessonId={lessonId}
            url={src}
            authorized={authorized}
            progress={progress}
            onPlayStarted={() => t.handlePlayStarted()} />;

        // const player_v2 = <PlayerV2
        //     courseId={courseData.id}
        //     lessonId={lessonId}
        //     url={src}
        //     authorized={authorized}
        //     progress={progress}
        //     onPlayStarted={() => t.setState({started: true})} />;

        const player = content.is_cloud ? player_iframe : player_v2;

        const tracker = started ? <Tracker id={courseData.id} event="videoStarted" childId={lessonId} /> : '';

        return (
            <div className="player_wrapper">
                <div className="player_inner">
                    {player}
                    {tracker}
                </div>
            </div>
        )
    }
}

export default LessonVideo;
