import React from 'react';
import './index.css';

class SchoolHomeworks extends React.Component {
    constructor (props) {
        super();
        this.state = {
        };
    }

    componentDidMount() {
        document.body.classList.add('no_search');
    };
    componentWillUnmount() {
        document.body.classList.remove('no_search');
    };

    render() {

        return (
            <div className="school_homeworks">

                <h1>Домашние задания</h1>
                <p>Раздел в разработке.</p>

            </div>
        );
    }
}

export default SchoolHomeworks;