import React from 'react';
import { Link } from 'react-router-dom';
import {CourseRatingIcon} from "../../../utils/icons";
import {Plural, html2plaintext} from "../../../utils/utils";

import './item.css'
import CourseLevel from "../../../utils/course-level";
import CourseButton from "../../button";
import Spinner from "../../../utils/spinner";
import PatternPlug from "../../../utils/pattern-plug";

class CourseFeaturedItem extends React.Component {
    constructor (props) {
        super();

        this.state = {
            loading: false
        }
    }

    handleClick = () => {
        if (this.props.closeFunc) {
            this.props.closeFunc();
        }
    };

    handlePurchaseClick = () => {
        const t = this;
        const {data, onPurchase} = t.props;

        t.setState({loading: true});

        onPurchase(data, () => {
            t.setState({loading: false});
        });
    };

    render() {
        const t = this;
        const {loading} = t.state;
        const {itemData} = t.props;

        const url = '/course/' + itemData.slug;

        let title = itemData.title || '';

        if (title && title.length && title.length > 50) {
            title = title.substring(0,50) + '...';
        }

        let img = '';
        let src = null;
        if (itemData.images_stack) {
            const stack = itemData.images_stack;

            if (stack.preview) {
                src = stack.preview.normal;
                img = <img src={src} alt="" />;
            }
            else if (stack.cover) {
                src = stack.cover.normal;
                img = <img src={src} alt="" />;
            }
        }


        let category = '';
        let subcategory = '';
        if (itemData.categories && itemData.categories[0]) {
            const categoryData = itemData.categories[0]
            category = categoryData.name;

            if (categoryData.children && categoryData.children[0]) {
                const subcategoryData = categoryData.children[0];
                subcategory = ' | ' + subcategoryData.name;
            }
        }


        const meta = itemData.meta || {};

        let lessons = '';
        let lessonsCount = meta.lessons_count || null;

        if (lessonsCount) {
            lessons = <p className="cp_lessons_count">{lessonsCount} урок{Plural(lessonsCount, '', 'а', 'ов')}</p>;
        }


        let teaser = '';
        if (itemData.short_description) {
            teaser = <p className="teaser">{html2plaintext(itemData.short_description)}</p>;
        }


        const rating = parseInt(itemData.average_rating) ? <CourseRatingIcon rating={itemData.average_rating} /> : '';

        const plug = !src ? <PatternPlug title={title} /> : '';

        return (
            <div className="course_featured_item">
                <Link onClick={this.handleClick} to={url} className="featured_link">
                    <div className="additional_info_bar">

                    </div>

                    <div className="img_wrapper">
                        {plug}
                        {img}
                        <div className="featured_fade"> </div>
                    </div>
                </Link>

                <div className="featured_overlay">

                    <Link onClick={this.handleClick} to={url} className="featured_link featured_overlay_link">
                        <div className="cp_params">
                            {rating}
                            {lessons}
                            <CourseLevel lvl={meta.difficulty || 0} />
                        </div>
                        <h4>{category}{subcategory}</h4>
                        <div className="featured_text">
                            <h3>{html2plaintext(title)}</h3>
                            {teaser}
                        </div>
                    </Link>

                    <div className="button_holder">
                        <CourseButton
                            {...this.props}
                            isFeatured
                            onClick={this.handleClick}
                            onPurchase={this.handlePurchaseClick}
                            data={itemData}/>
                    </div>

                </div>

                <Spinner show={loading} />
            </div>

        );
    }
}

export default CourseFeaturedItem;
